import React from 'react';
import LikeIcon from '../LikeIcon';
import './index.scss';

const Activities = (props) => {
  const {
    userActivities, selectedDrawing, selectVc, updateBranch,
  } = props;
  return (
    userActivities.map((activity, index) => (
      <li key={activity.id}>
        <div
          className={`portfolio ${selectedDrawing === activity.id ? 'active' : ''}`}
          onClick={() => { selectVc(activity); }}
          role="button"
          tabIndex={index}
        >
          <a>
            <img src={activity.src} alt="" />
          </a>
          {/* <button type="button" className="likeButton" >
            <span>{activity.likesCount}</span>
            <LikeIcon />
          </button> */}
        </div>
      </li>
    ))
  );
};

export default Activities;
