/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, {
  useEffect, useState, useRef, Fragment,
} from 'react';
import { isEmpty } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import parse from 'html-react-parser';
import CustomSlider from '../../CustomSlider';

import domContentParser from '../../../utils/domContentParser';
import './index.scss';

const DadaAffairs = (props) => {
  const { data, icon, redirectToBlog } = props;
  const [slide, setSlide] = useState({});
  const [blogIndex, setBlogIndex] = useState(0);

  useEffect(() => {
    if (!isEmpty(data)) {
      const slideData = preparedSlideData(data, blogIndex);
      setSlide(slideData);
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const slideData = preparedSlideData(data, blogIndex);
      setSlide(slideData);
    }
  }, [blogIndex]);

  const preparedSlideData = (data, index) => {
    const currentSlide = data[index];
    const {
      id,
      title,
      thumbnail,
      author,
      published_at,
      content,
    } = currentSlide;
    const domTree = domContentParser(content);

    return {
      id,
      title,
      thumbnail,
      author,
      published_at,
      ...domTree,
    };
  };

  return (
    <Fragment>
      {!isEmpty(slide) && (
        <div className="dadaAffairsContainer resp-wrap">
          <div className="newsHeader">
            <div className=" d-flex justify-content-end">
              <div className="headingTag">DADAffair</div>
            </div>

            <div className="blog-title" onClick={() => redirectToBlog(slide.id)} role="button">{parse(slide.title)}</div>
          </div>

          <div className="d-wrap">
            <div className="dadaContent-container w-40">
              <div className="content-container">
                <div className="description pr-medium ">
                  {slide.heading && (
                    <h3>
                      <strong>{parse(slide.heading)}</strong>
                    </h3>
                  )}
                  {slide.allContent && <p>{parse(slide.allContent)}</p>}
                </div>
                <div className="read">
                  <span className="content" onClick={() => redirectToBlog(slide.id)} role="button" tabIndex={0}>read more </span>
                  <span
                    className="open-link-icon"
                    onClick={() => redirectToBlog(slide.id)}
                    role="button"
                    tabIndex={0}
                  >
                    <img src={icon} alt="open-link-icon" />
                  </span>
                </div>
              </div>

              <div className="details-container">
                <div className="details">
                  <div className="author pl-none">{slide.author}</div>
                  <div className="published">
                    <span>published on:</span> &nbsp;
                    <strong>
                      {slide.published_at
                        ? moment(slide.published_at).format('LL')
                        : ''}
                    </strong>
                  </div>
                  <div className="pagination-container">
                    <CustomSlider
                      data={data}
                      setIndex={setBlogIndex}
                      index={blogIndex}
                    />
                  </div>
                </div>
              </div>

            </div>
            <div className="dadaEditorialImg w-60" onClick={() => redirectToBlog(slide.id)} role="button" tabIndex={0}>
              <img src={slide.thumbnail} alt="blog-two" />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default DadaAffairs;
