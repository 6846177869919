import React, { Component } from 'react';
import {
  Button, List, ListItem,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import ReactTippyWrapper from '../ReactTippyWrapper';
import linkIcon from '../../assets/images/link.svg';
import twitterIcon from '../../assets/images/twitter.svg';
import tooltipClose from '../../assets/images/tooltip-close.svg';
import './index.scss';

class ShareTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolTipOpen: false,
    };

    this.handleTooltipClose = this.handleTooltip.bind(this, false);
    this.handleTooltipOpen = this.handleTooltip.bind(this, true);
  }

  handleTooltip(open, event) {
    this.setState({ toolTipOpen: open });
    return false;
  }

  render() {
    const { buttonClassName, shareLink } = this.props;
    const { toolTipOpen } = this.state;
    return (
      <Button className={buttonClassName} onClick={this.handleTooltipOpen}>
        <div className="tippyWrapperReset">
          <ReactTippyWrapper
            trigger="mouseover"
            hideOnClick="true"
            theme="dadaTippy"
            open={toolTipOpen}
            html={(
              <div>
                <List className="tooltip">
                  <ListItem className="tooltipList">
                    <img src={linkIcon} alt="" className="icon" />
                    <div className="link">
                      {shareLink}
                    </div>
                  </ListItem>
                  <ListItem className="tooltipList">
                    <img src={twitterIcon} alt="" className="icon" />
                    <p>Twitter</p>
                  </ListItem>
                  <a onClick={this.handleTooltipClose} href className="closeTooltip">
                    <img src={tooltipClose} alt="" />
                  </a>
                </List>
              </div>
                      )}
            size="small"
            interactive="true"
          >
            <div className="shareForLightbox" />
          </ReactTippyWrapper>
        </div>
      </Button>
    );
  }
}

ShareTooltip.propTypes = {
  shareLink: PropTypes.string,
  buttonClassName: PropTypes.string,
};

ShareTooltip.defaultProps = {
  shareLink: 'dada.nyc/vc/colores',
  buttonClassName: 'btnSecondary',
};

export default (ShareTooltip);
