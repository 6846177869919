import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _last from 'lodash/last';

import VcCarousel from '../../components/FeedCarousel/index';
import VCExpansionPanel from '../../components/VCExpansionPanel/index';
import {
  curatorActivity, toggleExpansionPanel,
  getConversationTree, likeActivity, updateVcConversationBranch, editVcDetails,
} from './actions';
import { interactionRequested } from '../VisualConversationFeed/actions';
import { openLoginForm } from '../AuthModal/actions';
import {
  controlCollapsedState, visualConversationStore,
} from '../../utils/vcUtils';
import { openDrawingTool } from '../DrawingTool/actions';

class VisualConversation extends Component {
  constructor(props) {
    super(props);
    this.onLikeActivity = this.onLikeActivity.bind(this);
    this.onLoadTree = this.onLoadTree.bind(this);
    this.onUpdateActiveBranch = this.onUpdateActiveBranch.bind(this);
    this.onCurationActivity = this.onCurationActivity.bind(this);
    this.onExpansionPanelChange = this.onExpansionPanelChange.bind(this);
    this.state = { scrollProgress: 0 };
  }

  onLikeActivity(e, activityId, likesCount, parentIdsTrail) {
    e.stopPropagation();
    const {
      vcId, onLikeActivity, isAuthenticated, openLoginDialog, interactionRequested,
    } = this.props;
    if (isAuthenticated) {
      onLikeActivity(vcId, activityId, likesCount, parentIdsTrail);
    } else {
      interactionRequested(vcId);
      openLoginDialog();
    }
  }

  onCurationActivity(activityId) {
    const {
      vcId, onCurationActivity,
    } = this.props;
    onCurationActivity(vcId, activityId);
  }

  onLoadTree() {
    const { vcId, loadTree, activeBranch } = this.props;

    const lastActiveId = _last(activeBranch).id;
    loadTree(vcId, lastActiveId);
  }

  onUpdateActiveBranch(nodeDataName) {
    const { vcId, data, updateActiveBranch } = this.props;

    updateActiveBranch(vcId, data, nodeDataName);
  }

  onExpansionPanelChange(expanded) {
    const { toggleExpansionPanel, isTreeLoaded, vcId } = this.props;

    toggleExpansionPanel(vcId, expanded);

    if (expanded && !isTreeLoaded) {
      this.onLoadTree();
    }
  }

  getConversationScrollProgress = (val) => {
    this.setState({ scrollProgress: val });
  }

  render() {
    const {
      data, isLikeRequestInitiated, isTreeLoaded, activeBranch, editVcDetailsInitiated,
      selectedId, isAuthenticated, openDrawingTool, vcId, authenticatedUserDetails, editVcDetails,
      isCurationRequestInitiated, isVisible, isExpanded, disableToggle, authCommentAction,
      authFollowAction, statesLocation, history,
    } = this.props;
    const { scrollProgress } = this.state;
    return (
      <div>
        <div>
          <VcCarousel
            data={activeBranch}
            isLikeRequestInitiated={isLikeRequestInitiated}
            isCurationRequestInitiated={isCurationRequestInitiated}
            onCurationActivity={this.onCurationActivity}
            onLikeActivity={this.onLikeActivity}
            isVisible={isVisible}
            selectedId={selectedId}
            isAuthenticated={isAuthenticated}
            openDrawingTool={openDrawingTool}
            vcId={vcId}
            level={authenticatedUserDetails.level}
            isCurator={authenticatedUserDetails.isCurator}
            authFollowAction={authFollowAction}
            location={statesLocation}
            history={history}
            getConversationScrollProgress={this.getConversationScrollProgress}
          />
        </div>
        <div>
          <VCExpansionPanel
            imgData={activeBranch}
            disableToggle={disableToggle}
            isVisible={isVisible}
            data={data}
            selectedId={selectedId}
            onExpansionChange={this.onExpansionPanelChange}
            isExpanded={isExpanded}
            editVcDetails={editVcDetails}
            editVcDetailsInitiated={editVcDetailsInitiated}
            authenticatedUserDetails={authenticatedUserDetails}
            loadTree={this.onLoadTree}
            isTreeDataLoaded={isTreeLoaded}
            onUpdateActiveBranch={this.onUpdateActiveBranch}
            authCommentAction={authCommentAction}
            scrollProgress={scrollProgress}
          />
        </div>
      </div>
    );
  }
}

VisualConversation.propTypes = {
  vcId: PropTypes.number.isRequired,
  isLikeRequestInitiated: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  onLikeActivity: PropTypes.func.isRequired,
  openLoginDialog: PropTypes.func.isRequired,
  loadTree: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  toggleExpansionPanel: PropTypes.func.isRequired,
  disableToggle: PropTypes.bool,
};

VisualConversation.defaultProps = {
  disableToggle: false,
};

const mapStateToProps = (state, props) => {
  const {
    id, isVisible, disableToggle, authCommentAction, authFollowAction,
  } = props;
  const vc = state[visualConversationStore(id)];
  const data = vc.get('data').toJS();
  const activeBranchData = vc.get('activeBranchData').toJS();
  const selectedId = vc.get('selectedId');
  const editVcDetailsInitiated = vc.get('editVcDetailsInitiated');
  const isExpanded = vc.get('isExpanded');

  return {
    data,
    isVisible: !!isVisible,
    selectedId,
    isExpanded,
    disableToggle,
    authCommentAction,
    authFollowAction,
    editVcDetailsInitiated,
    activeBranch: activeBranchData,
    vcId: id,
    isLikeRequestInitiated: vc.get('isLikeRequestInitiated'),
    isTreeLoaded: vc.get('isTreeLoaded'),
    isAuthenticated: state.authenticationStatus.get('isAuthenticated'),
    authenticatedUserDetails: state.authenticatedUserDetails.toJS(),
    isCurationRequestInitiated: vc.get('isCurationRequestInitiated'),
    statesLocation: state.router.location,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLikeActivity: (
    vcId, activityId, likesCount, parentIdsTrail,
  ) => dispatch(likeActivity(vcId, activityId, likesCount, parentIdsTrail)),
  onCurationActivity: (
    vcId, activityId,
  ) => dispatch(curatorActivity(vcId, activityId)),
  openLoginDialog: () => dispatch(openLoginForm(ownProps.authLikeAction)),
  loadTree: (vcId, lastActiveTrailId) => {
    dispatch(getConversationTree(vcId, lastActiveTrailId));
  },
  updateActiveBranch: (vcId, treeData, nodeDataName) => {
    const parsedCollapsedTree = controlCollapsedState(treeData, nodeDataName);
    dispatch(updateVcConversationBranch(vcId, parsedCollapsedTree, parsedCollapsedTree.activeTrail, nodeDataName));
  },
  openDrawingTool: (
    isReply, previousActivityId, vcId, title,
  ) => dispatch(openDrawingTool(isReply, previousActivityId, vcId, title)),
  toggleExpansionPanel: (vcId, isExpanded) => dispatch(toggleExpansionPanel(vcId, isExpanded)),
  interactionRequested: vcId => dispatch(interactionRequested(vcId)),
  editVcDetails: (id, level, title) => dispatch(editVcDetails(id, level, title)),

});

export default connect(mapStateToProps, mapDispatchToProps)(VisualConversation);
