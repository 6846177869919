import React from 'react';
import { Route, Switch } from 'react-router';

import NotFound404 from '../components/NotFound404';
import AuthenticatorHOC from '../containers/Authenticator';
import HomePage from '../components/HomePage';
import IndividualVCFeedPage from '../containers/IndividualVCFeedPage';
import BlogsLandingPage from '../containers/BlogsLandingPage';
import EditorialHomePage from '../containers/Editorial';
import BlogPost from '../components/BlogPostModal';
import createOrUpdateBlogPost from '../components/BlogPostEdit';
import NewBlogPost from '../components/BlogPostNew';
import ResetPassword from '../containers/ResetPassword';

const AuthenticatedHomePage = AuthenticatorHOC(HomePage);
const AuthIndividualVCFeedPage = AuthenticatorHOC(IndividualVCFeedPage);

const AuthenticatedBlogsLandingPage = AuthenticatorHOC(BlogsLandingPage);
const AuthenticatedBlogsPostPage = AuthenticatorHOC(BlogPost);
const AuthenticatedcreateOrUpdateBlogPostPage = AuthenticatorHOC(createOrUpdateBlogPost);
const AuthenticatedNewBlogPostPage = AuthenticatorHOC(NewBlogPost);
const Routes = () => (
  <Switch>
    <Route exact path="/conversation/:id" component={AuthenticatedHomePage} />
    <Route
      exact
      path="/individual-feed/:vcId"
      component={AuthIndividualVCFeedPage}
    />
    <Route exact path="/not-found-404" component={NotFound404} />
    <Route
      exact
      path="/editorial"
      component={EditorialHomePage}
    />
    <Route exact path="/editorial/category" component={AuthenticatedBlogsLandingPage} />
    <Route exact path="/editorial/category/:id" component={AuthenticatedBlogsPostPage} />
    <Route exact path="/editorial/category/:id/edit" component={AuthenticatedcreateOrUpdateBlogPostPage} />
    <Route exact path="/editorial/new" component={AuthenticatedNewBlogPostPage} />
    <Route path="/" component={AuthenticatedHomePage} />
    <Route path="/:id" component={AuthenticatedHomePage} />

  </Switch>
);

export default Routes;
