function autoDataAppender(formData, fieldName, data = []) {
  data.forEach((value, index) => formData.append(`data[attributes][${fieldName}][${index}]`, value));
}

export const postDrawingSchema = (parent_id, title, auth_lvl, blob_file, emails, users, tags = []) => {
  const data = new window.FormData();
  data.append('data[type]', 'visual_statuses');
  data.append('data[attributes][parent_id]', parent_id);
  data.append('data[attributes][title]', title);
  data.append('data[attributes][auth_lvl]', auth_lvl);
  data.append('data[attributes][blob_file]', blob_file);
  autoDataAppender(data, 'emails', emails);
  autoDataAppender(data, 'users', users);
  if (tags.length) {
    data.append('data[attributes][tags]', tags.toString());
  }
  return data;
};

export const postDraftSchema = (parent_id, root_id, { blob, backgroundColor }) => {
  const data = new window.FormData();
  data.append('data[type]', 'sketchpad_storages');
  data.append('data[attributes][parent_id]', parent_id);
  data.append('data[attributes][root_id]', root_id);
  data.append('data[attributes][imglb]', blob);
  data.append('data[attributes][bg_color]', backgroundColor);
  return data;
};

export const putDraftSchema = ({ blob, backgroundColor }) => {
  const data = new window.FormData();
  data.append('data[type]', 'sketchpad_storages');
  data.append('data[attributes][imglb]', blob);
  data.append('data[attributes][bg_color]', backgroundColor);
  return data;
};
