global.initializeCanvas = function () {
    dadaCanvas.resetSketchpadVar();
    dadaCanvas.init_color_pickers();
    dadaCanvas.initCanvas("#sketchpad_control_group", {
        initializeLayersVisor: false,
        size: {
            width: 800,
            height: 600
        }
    });
    return dadaCanvas.injectBackground({
        paper_standard: null
    });
};
