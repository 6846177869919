dadaCanvas.marker = {
    options: {
        name: 'Marker',
        id_tag: 'marker',
        strokes: 1,
        bristles: [],
        actualLineWeight: 1,
        lineWeight: 1,
        max: 2,
        min: 1,
        lineColor: '#000000',
        mutant: false,
        usePressure: true,
        isBrush: true
    },
    init: function init() {
        self = this
        this.options.bristles = [];
        for (i = 0; i < this.options.strokes; i++) {
            var ease = Math.random() * 0.05 + 0.6; //original formula Math.random() * 0.05 + 0.7
            this.options.bristles.push({
                dx: dadaCanvas.dummy.width / 2,
                dy: dadaCanvas.dummy.height / 2,
                ax: 0,
                ay: 0,
                div: 0.3, //original value = 0.1
                ease: ease
            });
        }
        this.calcBrushSize(parseInt($("#brush_size_slider").slider("value")));
        this.setBrushColor(dadaCanvas.actualColor.rgba);
        // Set in dadaCanvas.dummy
        dadaCanvas.ctx_dummy.lineJoin = "round";
        dadaCanvas.ctx_dummy.lineCap = "round";
        dadaCanvas.ctx_dummy.lineWidth = (this.options.lineWeight == 1 ? 0.99 : this.options.lineWeight);
        dadaCanvas.ctx_dummy.strokeStyle = this.options.lineColor;

        dadaCanvas._.each(dadaCanvas.ctxs, function (value, key, list) {
            value.lineJoin = "round";
            value.lineCap = "round";
            value.lineWidth = (self.options.lineWeight == 1 ? 0.99 : self.options.lineWeight);
            value.strokeStyle = self.options.lineColor;

        })
    },
    draw: function () {
        dadaCanvas.ctx_dummy.lineWidth = this.options.lineWeight;
        for (i = 0; i < this.options.strokes; i++) {
            var dx = this.options.bristles[i].dx;
            var dy = this.options.bristles[i].dy;
            var dx1 = this.options.bristles[i].ax = (this.options.bristles[i].ax + (this.options.bristles[i].dx - dadaCanvas.currX) * this.options.bristles[i].div) * this.options.bristles[i].ease;
            this.options.bristles[i].dx -= dx1;
            var dx2 = this.options.bristles[i].dx;
            var dy1 = this.options.bristles[i].ay = (this.options.bristles[i].ay + (this.options.bristles[i].dy - dadaCanvas.currY) * this.options.bristles[i].div) * this.options.bristles[i].ease;
            this.options.bristles[i].dy -= dy1;
            var dy2 = this.options.bristles[i].dy;

            dadaCanvas.ctx_dummy.beginPath();
            dadaCanvas.ctx_dummy.moveTo(dx, dy);

            dadaCanvas.stroke_properties.push({
                x: dx2,
                y: dy2,
                dx: dx,
                dy: dy,
                lineWidth: (this.options.lineWeight == 1 ? 0.99 : this.options.lineWeight),
                lineColor: this.options.lineColor
            })

            dadaCanvas.ctx_dummy.clearRect(0, 0, dadaCanvas.dummy.width, dadaCanvas.dummy.height);
            dadaCanvas.ctx_dummy.moveTo(dadaCanvas.stroke_properties[0].x, dadaCanvas.stroke_properties[0].y);

            for (j = 1; j < dadaCanvas.stroke_properties.length; j++) {
                dadaCanvas.ctx_dummy.lineTo(dadaCanvas.stroke_properties[j].x, dadaCanvas.stroke_properties[j].y);
            }
            dadaCanvas.ctx_dummy.stroke();
            dadaCanvas.ctx_dummy.closePath();
        }

    },
    up: function () {
        dadaCanvas.ctx_dummy.clearRect(0, 0, dadaCanvas.dummy.width, dadaCanvas.dummy.height);
        pressure = 0;
        for (i = 0; i < this.options.strokes; i++) {
            this.options.bristles[i].ax = 0;
            this.options.bristles[i].ay = 0;
        }
        if (dadaCanvas.stroke_properties.length > 0) {

            if (dadaCanvas.stroke_properties.length == 1) {
                dadaCanvas._.each(dadaCanvas.ctxs, function (value, key, list) {
                    value.beginPath();
                    value.strokeStyle = dadaCanvas.actualBrush.options.lineColor;
                    value.arc(dadaCanvas.currX, dadaCanvas.currY, dadaCanvas.actualBrush.options.lineWeight / 180, 0, 2 * Math.PI);
                    value.stroke();
                })
            } else {
                dadaCanvas._.each(dadaCanvas.ctxs, function (value, key, list) {
                    value.beginPath();
                    value.moveTo(dadaCanvas.stroke_properties[0].x, dadaCanvas.stroke_properties[0].y)
                    for (i = 1; i < dadaCanvas.stroke_properties.length; i++) {
                        value.lineTo(dadaCanvas.stroke_properties[i].x, dadaCanvas.stroke_properties[i].y);
                    }
                    value.stroke();
                    value.closePath();
                })
            }
        }
        dadaCanvas.stroke_properties = []
    },
    out: function () {
        pressure = 0;
        for (i = 0; i < this.options.strokes; i++) {
            this.options.bristles[i].ax = 0;
            this.options.bristles[i].ay = 0;
        }
    },
    mouseSpeedCalcs: function () {
    },
    calculateY: function calculateY(val) {
    },
    calculateColor: function calculateColor(val) {
        this.options.lineColor = val;
    },
    calculateLineWeightUsingPresure: function calculateLineWeightUsingPresure(pressure) {
        // return this.options.actualLineWeight-(this.options.actualLineWeight/2)+(this.options.actualLineWeight*pressure);
    },
    calcBrushSize: function calcBrushSize(size) {
        this.options.lineWeight = 0;
        for (i = 1; i <= size; i++) {
            this.options.lineWeight += i;
        }
        this.options.actualLineWeight = this.options.lineWeight;
        dadaCanvas.ctx.lineWidth = (this.options.lineWeight == 1 ? 0.99 : this.options.lineWeight);
        dadaCanvas.ctx_dummy.lineWidth = (this.options.lineWeight == 1 ? 0.99 : this.options.lineWeight);
        dadaCanvas.ctx_final.lineWidth = (this.options.lineWeight == 1 ? 0.99 : this.options.lineWeight);
    },
    setBrushColor: function setBrushColor(bcolor) {
        this.options.lineColor = bcolor;
        dadaCanvas.ctx.strokeStyle = this.options.lineColor;
        dadaCanvas.ctx_dummy.strokeStyle = this.options.lineColor;
        dadaCanvas.ctx_final.strokeStyle = this.options.lineColor;
    },
    is_mutant: function () {
        return this.options.mutant;
    }

}
