import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// project imports
import { loadingBarReducer } from 'react-redux-loading-bar';
import authDialog from '../containers/AuthModal/reducer';
import forgetPasswordForm from '../containers/ForgetPasswordForm/reducer';
import loginForm from '../containers/LoginForm/reducer';
import resetPasswordForm from '../containers/ResetPassword/reducer';
import signUpForm from '../containers/SignUpForm/reducer';
import * as AuthenticationReducers from '../containers/Authenticator/reducers';
import snackbarStatus from '../containers/ApplicationSnackbar/reducer';
import welcomeMessage from '../containers/WelcomeMessage/reducer';
import vcFeed from '../containers/VisualConversationFeed/reducer';
import DrawingToolReducer from '../containers/DrawingTool/reducer';
import profileView from '../containers/ProfileView/reducer';

// Explore
import exploreView from '../containers/ExploreView/reducer';
import profileCarousel from '../containers/ProfileCarousel/reducer';
import pictureCarousel from '../containers/PictureCarousel/reducer';
import exploreVcFeed from '../containers/ExploreConversationFeed/reducer';
import modalVcFeed from '../containers/ConversationsModal/reducer';

import blogsPage from '../containers/BlogsLandingPage/reducerCreator';
import editorialReducer from '../containers/Editorial/reducer';


export default history => combineReducers({
  forgetPasswordForm,
  authDialog,
  loginForm,
  resetPasswordForm,
  signUpForm,
  snackbarStatus,
  vcFeed,
  welcomeMessage,
  DrawingToolReducer,
  profileView,
  exploreView,
  profileCarousel,
  pictureCarousel,
  exploreVcFeed,
  modalVcFeed,
  loadingBar: loadingBarReducer,
  blogsPage,
  editorialReducer,
  ...AuthenticationReducers,
  router: connectRouter(history),
});
