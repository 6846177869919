import React, { useState } from 'react';
import './index.scss';
import BtnSlider from './BtnSlider';

const CustomSlider = (props) => {
  const { data, index, setIndex } = props;

  const nextSlide = () => {
    if (index !== data.length - 1) {
      setIndex(index + 1);
    }
  };

  const prevSlide = () => {
    if (index !== 0) {
      setIndex(index - 1);
    }
  };

  return (
    <div className="container-slider">
      <BtnSlider moveSlide={prevSlide} direction="prev" isVisible={index !== 0} />
      <span className="slide-count">
        {index + 1}<span>/</span>{data.length}
      </span>
      <BtnSlider moveSlide={nextSlide} direction="next" isVisible={index !== data.length - 1} />
    </div>
  );
};

export default CustomSlider;
