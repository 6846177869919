export const REQUEST_FORGET_PASSWORD = 'REQUEST_FORGETPASSWORD';
export const REQUEST_FORGET_PASSWORD_SUCCESS = 'REQUEST_FORGET_PASSWORD_SUCCESS';
export const REQUEST_FORGET_PASSWORD_FAILURE = 'REQUEST_FORGET_PASSWORD_FAILURE';
export const REQUEST_FORGET_PASSWORD_INITIATED = 'REQUEST_FORGET_PASSWORD_INITIATED';
export const RESET_FORGOT_PASSWORD_MSGS = 'RESET_FORGOT_PASSWORD_MSGS';

export const requestForgotPassword = params => ({ type: REQUEST_FORGET_PASSWORD, params });
export const requestForgetPasswordInitiated = () => ({ type: REQUEST_FORGET_PASSWORD_INITIATED });
export const requestForgetPasswordSuccess = successMsg => ({ type: REQUEST_FORGET_PASSWORD_SUCCESS, successMsg });
export const requestForgetPasswordFailure = error => ({ type: REQUEST_FORGET_PASSWORD_FAILURE, error });
export const resetForgotPasswordMsgs = () => ({ type: RESET_FORGOT_PASSWORD_MSGS });
