import lodashIsString from 'lodash/isString';
import lodashIsFunc from 'lodash/isFunction';
import lodashIsPlainObject from 'lodash/isPlainObject';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsFinite from 'lodash/isFinite';

// eslint-disable-next-line no-useless-escape,max-len
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Cheap mechanism to check for JSON String
const jsonRegex = /{(.*?)}/;

export function isValidEmail(email) {
  return emailRegex.test(String(email).toLowerCase());
}

export function isString(value) {
  return lodashIsString(value);
}

export function isFunction(value) {
  return lodashIsFunc(value);
}

export function isValidObject(value) {
  return lodashIsPlainObject(value);
}

export function isValidFilledObject(value) {
  return isValidObject(value) && Object.keys(value).length;
}

export function isEmpty(value) {
  return lodashIsEmpty(value);
}

export function isValidNumber(value) {
  return lodashIsFinite(value);
}

export function isEnterKey(event) {
  return event.key === 'Enter';
}

export function isImage(fileType) {
  return fileType === 'image/png' || fileType === 'image/jpeg';
}

export function isValidFileSize(fileSize, validSize) {
  return fileSize <= validSize;
}

export function shouldUploadPhoto(fileType, fileSize, validSize) {
  return isImage(fileType) && isValidFileSize(fileSize, validSize);
}

export function isJSONString(value) {
  return jsonRegex.test(value);
}

export function isCurrentUser(userId, currentUserId) {
  return userId === currentUserId;
}
