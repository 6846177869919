/* eslint-disable import/named */
import { fromJS } from 'immutable';
import {
  LIKE_ACTIVITY_INITIATED,
  SAVE_VC_CONVERSATION,
  LIKE_ACTIVITY_SUCCESS,
  LIKE_ACTIVITY_FAILURE,
  UPDATE_VC,
  GET_CONVERSATION_TREE_INITIATED,
  GET_CONVERSATION_TREE_FAILURE,
  GET_CONVERSATION_TREE_SUCCESS,
  UPDATE_VC_CONVERSATION_BRANCH,
  REFRESH_VC,
  CURATOR_ACTIVITY_INITIATED,
  CURATOR_ACTIVITY_FAILURE,
  CURATOR_ACTIVITY_SUCCESS,
  EDIT_VC_INITITATED,
  EDIT_VC_SUCCESS,
  EDIT_VC_FAILIURE,
  TOGGLE_VC_EXPANSION_PANEL,
  UPDATE_ACTIVE_BRANCH_DATA,
  UPDATE_VC_CONVERSATION_BRANCH_ACTIVITY,
} from './actions';
import {
  controlCollapsedState,
  generateActiveBranch,
} from '../../utils/vcUtils';

const createReducer = (vcId) => {
  const initialState = fromJS({
    data: {},
    activeTrail: [],
    activeBranchData: [],
    selectedId: null,
    isLikeRequestInitiated: false,
    isGetTreeRequestInitiated: false,
    isTreeLoaded: false,
    isCurationRequestInitiated: false,
    editVcDetailsInitiated: false,
    isExpanded: false,
  });

  const vc = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_VC_CONVERSATION(vcId): {
        const data = fromJS(action.data);
        const activeTrail = fromJS(action.activeTrail);

        return state.merge({
          data,
          activeTrail,
          activeBranchData: generateActiveBranch(
            action.data,
            action.activeTrail,
          ),
        });
      }
      case UPDATE_VC_CONVERSATION_BRANCH(vcId): {
        const data = fromJS(action.data);
        const activeTrail = fromJS(action.activeTrail);

        return state.merge({
          data,
          activeTrail,
          activeBranchData: generateActiveBranch(
            action.data,
            action.activeTrail,
          ),
          selectedId: action.selectedId,
        });
      }
      case UPDATE_VC_CONVERSATION_BRANCH_ACTIVITY(vcId): {
        const parsedTree = state.get('data').toJS();
        const parsedCollapsedTree = controlCollapsedState(
          parsedTree,
          action.selectedId,
        );
        return state.merge({
          data: parsedCollapsedTree,
          activeTrail: parsedCollapsedTree.activeTrail,
          activeBranchData: generateActiveBranch(
            parsedCollapsedTree,
            parsedCollapsedTree.activeTrail,
          ),
          selectedId: action.selectedId,
        });
      }
      case GET_CONVERSATION_TREE_INITIATED(vcId):
        return state.set('isGetTreeRequestInitiated', true);
      case GET_CONVERSATION_TREE_FAILURE(vcId):
        return state.set('isGetTreeRequestInitiated', false);
      case GET_CONVERSATION_TREE_SUCCESS(vcId): {
        const data = fromJS(action.data);
        const activeTrail = fromJS(action.activeTrail);
        return state.merge({
          isGetTreeRequestInitiated: false,
          data,
          isTreeLoaded: true,
          activeTrail,
          activeBranchData: generateActiveBranch(
            action.data,
            action.activeTrail,
          ),
        });
      }
      case LIKE_ACTIVITY_INITIATED(vcId):
        return state.set('isLikeRequestInitiated', true);
      case UPDATE_VC(vcId):
        return state.merge({ data: fromJS(action.data) });
      case LIKE_ACTIVITY_SUCCESS(vcId):
      case LIKE_ACTIVITY_FAILURE(vcId):
        return state.set('isLikeRequestInitiated', false);
      case CURATOR_ACTIVITY_INITIATED(vcId):
        return state.set('isCurationRequestInitiated', true);
      case CURATOR_ACTIVITY_SUCCESS(vcId):
      case CURATOR_ACTIVITY_FAILURE(vcId):
        return state.set('isCurationRequestInitiated', false);
      case EDIT_VC_INITITATED:
        return state.set('editVcDetailsInitiated', true);
      case EDIT_VC_SUCCESS:
        return state.set('editVcDetailsInitiated', false);
      case EDIT_VC_FAILIURE:
        return state.set('editVcDetailsInitiated', false);
      case REFRESH_VC(vcId):
        return initialState;
      case TOGGLE_VC_EXPANSION_PANEL(vcId):
        return state.set('isExpanded', fromJS(action.isExpanded));
      default:
        return state;
      case UPDATE_ACTIVE_BRANCH_DATA(vcId): {
        const data = fromJS(action.data);

        return state.merge({
          data,
          activeBranchData: generateActiveBranch(
            action.data,
            action.activeTrail,
          ),
        });
      }
    }
  };
  return vc;
};

export default createReducer;
