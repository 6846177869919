/* eslint-disable no-undef */
import React, { PureComponent } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

class DraftSelectionModal extends PureComponent {
  render() {
    const {
      openDraftSelectionModal, existingDraft, workingDraft, isFromPostConfirmationModal,
      onWorkingDraftSelect, onExistingDraftSelect,
    } = this.props;
    return (
      <div>
        <Dialog
          open={openDraftSelectionModal}
          className="dadaModal materialModalScrollFix draftModal"
          scroll="body"
          classes={{ paper: 'dadaDialogPaper', container: 'vhAlignCenter' }}
          BackdropProps={{
            classes: {
              root: 'dadaBackDrop',
            },
          }}
        >
          <DialogTitle
            disableTypography
            className="dadaModalTitle text-center"
          >
            {isFromPostConfirmationModal
              ? <h3 className="dadaTitle">Which draft would you like to keep working on?</h3>
              : <h3 className="dadaTitle">Which draft would you like to save?</h3>}
          </DialogTitle>
          <DialogContent className="dadaModalContent py0">
            <ul className="draftsImages">
              <li>
                <div role="button" onClick={onExistingDraftSelect} tabIndex={0}>
                  <img className="draftsImage" src={existingDraft} alt="" />
                </div>
              </li>
              <li>
                <div role="button" onClick={onWorkingDraftSelect} tabIndex={0}>
                  <img className="draftsImage" src={workingDraft} alt="" />
                </div>
              </li>
            </ul>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default DraftSelectionModal;
