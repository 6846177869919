import { fromJS } from 'immutable';
// Project imports
import {
  CLOSE_AUTH_DIALOG, OPEN_FORGOT_PASSWORD_DIALOG, OPEN_LOGIN_DIALOG, OPEN_SIGN_UP_DIALOG, RESET_AUTH_DIALOG,
} from './actions';
import { OPEN_RESET_PASSWORD_FORM } from '../ResetPassword/actions';

const initialState = {
  isOpen: false,
  loginForm: false,
  forgotPasswordForm: false,
  resetPasswordForm: false,
  signUpForm: false,
  actionToDispatch: null,
};

const authDialog = (
  state = fromJS(initialState),
  action,
) => {
  switch (action.type) {
    case OPEN_LOGIN_DIALOG:
      return state.merge({
        loginForm: true,
        signUpForm: false,
        forgotPasswordForm: false,
        isOpen: true,
        actionToDispatch: action.actionToDispatch,
      });
    case OPEN_SIGN_UP_DIALOG:
      return state.merge({
        loginForm: false,
        signUpForm: true,
        forgotPasswordForm: false,
        isOpen: true,
        actionToDispatch: action.actionToDispatch,
      });
    case OPEN_FORGOT_PASSWORD_DIALOG:
      return state.merge({
        loginForm: false,
        signUpForm: false,
        forgotPasswordForm: true,
        isOpen: true,
        actionToDispatch: action.actionToDispatch,
      });
    case OPEN_RESET_PASSWORD_FORM:
      return state.merge({
        resetPasswordForm: true, loginForm: false, signUpForm: false, forgotPasswordForm: false, isOpen: true,
      });
    case CLOSE_AUTH_DIALOG:
      return state.merge({ isOpen: false });
    case RESET_AUTH_DIALOG:
      return state.merge({
        loginForm: false,
        signUpForm: false,
        forgotPasswordForm: false,
        resetPasswordForm: false,
        actionToDispatch: null,
      });
    default:
      return state;
  }
};

export default authDialog;
