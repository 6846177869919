// React & Redux Imports
import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Form validation Imports
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
// Material UI Imports
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core';

// Utils
import queryString from 'querystring';
// Project Imports
import { generateAuthHeaders } from '../../utils/authUtils';
import { requestResetPassword, resetResetPasswordMsgs, requestResetPasswordFailure } from './actions';
import resetPasswordEndpointParamsSchema from './schemas';
import { INVALID_REQUEST } from '../../constants';
import styles from './styles';
import './index.scss';
import { closeAuthDialog } from '../AuthModal/actions';

const ResetPassword = (props) => {
  const {
    closeAuthDialog, values, handleChange, touched, errors, isRequesting, apiError, classes, setTouched, resetAllMsgs,
    successMsg,
  } = props;
  const { password, confirmPassword, token } = values;

  const handleVisibility = () => {
    setTouched(false);
    resetAllMsgs();
  };
  return (
    <Fragment>
      <div className="resetPassword">
        <Form>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.formLabel}>Change Password</div>
            {
              ((
                (errors.password || errors.confirmPassword) && (touched.password || touched.confirmPassword)
              )
                || (apiError) || successMsg)
              && (
                <div className={successMsg ? 'responseMsg success' : 'responseMsg error'}>
                  {
                    errors.password || errors.confirmPassword || apiError || successMsg
                  }
                  <Button className={classes.close} onClick={handleVisibility}>x</Button>
                </div>
              )
            }
            <DialogContentText id="alert-dialog-slide-description">
              <input
                placeholder="code"
                name="token"
                onChange={handleChange}
                value={token}
                required
              // className="responseMsg error"
              />
              <input
                placeholder="New password"
                name="password"
                onChange={handleChange}
                value={password}
                // className="responseMsg error"
                type="password"
              />
              <input
                placeholder="Confirm new password"
                name="confirmPassword"
                onChange={handleChange}
                value={confirmPassword}
                // className="responseMsg error"
                type="password"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions
            justify="center"
            className={`${classes.aligntCenterBtn} ${classes.spacing0}`}
          >
            <Grid>
              <Button type="submit" disabled={isRequesting} className={`${classes.blackbtn} ${classes.mb40}`}>
                Set password
              </Button>
            </Grid>
          </DialogActions>
        </Form>
      </div>
      <Hidden smUp>
        <Button className="closeBtn" onClick={closeAuthDialog}>
          <Icon className={classes.icon}>close</Icon>
        </Button>
      </Hidden>
    </Fragment>
  );
};

const ResetPasswordWithFormik = withFormik(
  {
    mapPropsToValues: props => ({
      password: '',
      confirmPassword: '',
      token: '',
    }),
    validationSchema: Yup.object().shape({
      token: Yup.string()
        .required('Reset token is required!'),
      password: Yup.string()
        .min(8, 'Password has to be longer than 8 characters!')
        .required('Password is required!'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords don't match")
        .required('Confirm Password is required'),
    }),
    handleSubmit: (values, { props }) => {
      const { password, confirmPassword, token } = values;
      const { requestResetPassword } = props;
      const params = resetPasswordEndpointParamsSchema(password, confirmPassword, token);
      requestResetPassword(params);
    },
  },
)(ResetPassword);

ResetPassword.propTypes = {
  values: PropTypes.shape({
    confirmPassword: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  classes: PropTypes.object.isRequired,
  apiError: PropTypes.string,
  isRequesting: PropTypes.bool.isRequired,
  setTouched: PropTypes.func.isRequired,
  resetAllMsgs: PropTypes.func.isRequired,
  successMsg: PropTypes.string,
  closeAuthDialog: PropTypes.func.isRequired,
};

ResetPassword.defaultProps = {
  apiError: null,
  successMsg: null,
};


const mapStateToProps = (state) => {
  const { resetPasswordForm } = state;
  return {
    success: resetPasswordForm.get('success'),
    isRequesting: resetPasswordForm.get('isRequesting'),
    apiError: resetPasswordForm.get('error'),
    successMsg: resetPasswordForm.get('successMsg'),
  };
};

const mapDispatchToProps = dispatch => ({
  closeAuthDialog: () => { dispatch(closeAuthDialog()); },
  requestResetPassword: (values) => {
    // if (window.location.search) {
    //   const queryParams = queryString.parse(window.location.search.substring(1));
    //   const headers = generateAuthHeaders(queryParams.uid, queryParams.client, queryParams['access-token']);
    const params = resetPasswordEndpointParamsSchema(values.password, values.password_confirmation, values.token);
    dispatch(requestResetPassword(params));
    // } else {
    // dispatch(requestResetPasswordFailure(INVALID_REQUEST));
    // }
  },
  resetAllMsgs: () => { dispatch(resetResetPasswordMsgs()); },
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordWithFormik));
