import React from 'react';

import Scrollbar from 'react-scrollbars-custom';
import {
  Grid, Dialog, Typography, Button,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import DrawingTool from '../DrawingTool';
import {
  CLOSE_DRAWING_TOOL_HOME_PAGE,
  POST_DRAWING_HOME_PAGE,
} from '../DrawingTool/actions';
import {
  getBlogsResults,
  createOrUpdateBlog,
} from './action';
import LevelsComponent from '../../components/LevelsComponent';
import './index.scss';
import CoreShell from '../../components/CoreShell';
import { REQUEST_AUTH_NAVBAR_BLOG_PAGE } from '../AuthModal/actions';
import { authenticationRequest, LOGOUT_HOME_PAGE } from '../Authenticator/actions';
import Navbar from '../Navbar';
import BlogEditModal from '../../components/BlogEditModal';
import BlogPostModal from '../../components/BlogPostModal';


const BlogsLandingPage = ({
  history, location, blogs, getBlogsResults, isAuthenticated, createOrUpdateBlog, is_blogger,
}) => {
  const [metaData, setMetaData] = React.useState({});
  const [selectedBlog, setSelectedBlog] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [noBlogs, setNoBlogs] = React.useState(false);
  const [blogsAvailable, setBlogsAvailable] = React.useState(true);

  React.useEffect(() => {
    async function fetchData() {
      await getBlogsResults(isAuthenticated);
    }
    fetchData();
  }, []);
  React.useEffect(() => {
    async function fetchData() {
      if (blogs && blogs.length > 0) {
        setMetaData(blogs[0].feed);
        const path = location.pathname;
        if (path !== '/editorial/category') {
          const paramsArray = path.split('/');
          const blogId = Number(paramsArray[paramsArray.length - 1]);
          const selectedBlog = blogs.find(obj => obj.id === blogId);
          setSelectedBlog(selectedBlog);
          setOpen(true);
        }
        setBlogsAvailable(true);
      } else if (blogs && blogs.length === 0) {
        setNoBlogs(true);
        setBlogsAvailable(false);
      }
    }
    fetchData();
  }, [blogs]);
  let uniqueBlogs = null;
  let filteredBlogs = null;
  if (blogs) {
    filteredBlogs = blogs.filter(x => x.is_published === null || x.is_published === true).map(x => x.categories);
    if (filteredBlogs.length > 0) {
      uniqueBlogs = [...new Set(filteredBlogs.flat(1))];
    }
  }

  const handleClick = (event, data) => {
    if (event.target.className === 'editorLikeBtn' || event.target.className === 'likeButton') {
      event.preventDefault();
      return;
    }
    setSelectedBlog(data);
    setOpen(true);
  };
  const closeBlogBox = () => {
    setOpen(false);
    history.push('/editorial/category');
  };

  const handleEditClick = (data) => {
    setSelectedBlog(data);
    // setEditOpen(true);
    history.push(`/editorial/category/${data.id}/edit`);
  };

  const closecreateOrUpdateBlogBox = () => {
    setEditOpen(false);
  };

  const editEditorial = async (blogId, payload) => {
    await createOrUpdateBlog(blogId, payload);
    setEditOpen(false);
    await getBlogsResults(isAuthenticated);
  };

  return (
    <CoreShell history={history} location={location}>
      <Navbar
        authAction={REQUEST_AUTH_NAVBAR_BLOG_PAGE}
        logoutAction={LOGOUT_HOME_PAGE}
        history={history}
      />
      <DrawingTool
        postDrawingAction={POST_DRAWING_HOME_PAGE}
        postCloseAction={CLOSE_DRAWING_TOOL_HOME_PAGE}
      />
      {editOpen ? (
        <BlogEditModal
          editEditorial={editEditorial}
          isOpen={editOpen}
          onClose={closecreateOrUpdateBlogBox}
          data={selectedBlog}
        />
      ) : null}
      {/* <Link
          to={{
            pathname: `/editorial/category/${selectedBlog.id}`,
          }}
        /> */}
      {
        blogs && blogs.length > 0 && blogs.find(x => x.is_published === null || x.is_published === false) && (
          <React.Fragment key="unpublished">
            <div className="blogContentWrap">
              <div className="themeContainer">
                <Scrollbar
                  style={{ width: '100%' }}
                  className="scrollContainer carouselScrollHorizontalTrack"
                >
                  <Grid container direction="row" wrap="nowrap">
                    {blogs.length > 0
                      && blogs.filter(x => x.is_published === null || x.is_published === false).map((elem, index) => (
                        <Link
                          key="unpublished_link"
                          to={{
                            pathname: `/editorial/category/${elem.id}`,
                          }}
                          className="link"

                        >
                          <Grid
                            key={`blog-${elem.title}`}
                            item
                            onClick={e => handleClick(e, elem)
                            }
                          >
                            <div className="blogsContainer">
                              <div className="imgWithInteractions">
                                <div
                                  style={{
                                    backgroundImage: `url(${elem.thumbnail})`,
                                  }}
                                  className="blogImage"
                                />
                                {is_blogger ? (
                                  <div className="interactions">
                                    <button onClick={() => handleEditClick(elem)} type="button" className="likeButton">
                                      <span className="editorLikeBtn" />
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                              <div className={`blogTitle ${index % 2 === 0 ? 'blogTitle-1' : 'blogTitle-2'}`}>
                                {elem.title ? parse(elem.title) : ''}
                              </div>
                              <LevelsComponent className="levelsCom" level={elem.accesability_level} />
                            </div>
                          </Grid>
                        </Link>
                      ))}
                  </Grid>
                </Scrollbar>
                <div className="themeTitle">unpublished</div>
              </div>
            </div>
            <hr className="blogsSectionHorizontalBar" />
          </React.Fragment>
        )}
      {uniqueBlogs && uniqueBlogs.map(theme => (
        <React.Fragment key={theme}>
          <div className="blogContentWrap">
            <div className="themeContainer">
              <Scrollbar
                style={{ width: '100%' }}
                className="scrollContainer carouselScrollHorizontalTrack"
              >
                <Grid container direction="row" wrap="nowrap">
                  {blogs.length > 0
                    && blogs.filter(x => x.is_published === true).map((elem, index) => (
                      elem.categories.includes(theme) && elem.categories.length > 0 && (
                        <Link
                          to={{
                            pathname: `/editorial/category/${elem.id}`,
                          }}
                          className="link"
                        >
                          <Grid
                            key={`blog-${elem.title}`}
                            item
                            onClick={e => handleClick(e, elem)
                            }
                          >
                            <div className="blogsContainer">
                              <div className="imgWithInteractions">
                                <div
                                  style={{
                                    backgroundImage: `url(${elem.thumbnail})`,
                                  }}
                                  className="blogImage"
                                />
                                {
                                  isAuthenticated && is_blogger ? (
                                    <div className="interactions">
                                      <button onClick={() => handleEditClick(elem)} type="button" className="likeButton">
                                        <span className="editorLikeBtn" />
                                      </button>
                                    </div>
                                  )
                                    : null
                                }
                              </div>
                              <div className={`blogTitle ${index % 2 === 0 ? 'blogTitle-1' : 'blogTitle-2'}`}>
                                {parse(elem.title)}
                              </div>
                              <LevelsComponent className="levelsCom" level={elem.accesability_level} />
                            </div>
                          </Grid>
                        </Link>
                      )
                    ))}
                </Grid>
              </Scrollbar>
              <div className="themeTitle">{theme}</div>
            </div>
          </div>
          <hr className="blogsSectionHorizontalBar" />
        </React.Fragment>
      ))}
      <Dialog
        open={noBlogs && !blogsAvailable}
        style={{ overflowY: 'hidden' }}
        className="blogPanel blogCanvas"
        onClose={() => {
          setNoBlogs(false);
          history.push('/');
        }
            }
          // scroll="body"
        classes={{ paper: 'dadaDialogPaper editModalBox', container: 'vhAlignCenter' }}
        BackdropProps={{
          classes: {
            root: 'dadaBackDrop',
          },
        }}
      >
        <div style={{
          padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center',
        }}
        >
          <Typography variant="h4">There are no blogs accessible to you for now!</Typography>
          <Button
            onClick={() => {
              setNoBlogs(false);
              history.push('/');
            }
            }
            style={{
              marginTop: 30, width: '80%', cursor: 'pointer', alignSelf: 'center',
            }}
            type="submit"
            className="BlogbtnPost"
          > <span className="blogProm blogBtn">Ok</span>
          </Button>
        </div>
      </Dialog>
    </CoreShell>
  );
};

const mapStateToProps = (state) => {
  const { blogsPage, authenticatedUserDetails, authenticationStatus } = state;
  return {
    is_blogger: authenticatedUserDetails.get('is_blogger'),
    isAuthenticated: authenticationStatus.get('isAuthenticated'),
    blogs: blogsPage.get('blogs') ? blogsPage.get('blogs').toJS() : null,
    updatedBlog: blogsPage.get('updatedBlog'),
  };
};
const mapDispatchToProps = dispatch => ({
  getBlogsResults: isAuthenticated => dispatch(getBlogsResults(isAuthenticated)),
  createOrUpdateBlog: (blogId, payload) => dispatch(createOrUpdateBlog(blogId, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlogsLandingPage);
