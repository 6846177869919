import React from 'react';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

const PostConfirmationModal = (props) => {
  const {
    open, onPost, onCancel, isDraftAction, isPosting,
  } = props;
  return (
    <Dialog
      open={open}
      className="dadaModal materialModalScrollFix dadaActionModal"
      scroll="body"
      classes={{ paper: 'dadaDialogPaper', container: 'vhAlignCenter' }}
      BackdropProps={{
        classes: {
          root: 'dadaBackDrop',
        },
      }}
    >
      <DialogTitle
        disableTypography
        className="dadaModalTitle text-center"
      >
        <Button className="btnReset closeModalIcon" onClick={onCancel}>
          <span className="icon" />
        </Button>
        <h3 className="dadaTitle">Are you sure you want to <strong>post</strong> this drawing?</h3>
      </DialogTitle>
      <DialogActions className="dadaModalFooter hasBlockBtns">
        <Button className="btn btnGreen" onClick={onPost} disabled={isPosting}>
          Yes
        </Button>
        <Button className="btn btnTransparent" onClick={onCancel}>
          {isDraftAction() && <CircularProgress />}
          Not yet
        </Button>
      </DialogActions>
    </Dialog>

  );
};

export default PostConfirmationModal;
