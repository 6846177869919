import React from 'react';
import range from 'lodash/range';
import './index.scss';

const LevelDots = (props) => {
  const { level, trail, isBlog } = props;
  if (trail && (level > -1) && isBlog) {
    if (level === 4) {
      return <span key={level} className="badgeDot orangeDot level-4" />;
    }
    if (level === 0) {
      return <span key={level} className="badgeDot level-0" />;
    }
    return (range(level)).slice(0, 3).map(level => (
      <span key={level} className={`badgeDot level-${level + 1}`} />
    ));
  }
  if (trail && level) {
    if (level === 4) {
      return <span key={level} className="badgeDot level-4" />;
    }
    return (range(level)).slice(0, 3).map(level => (
      <span key={level} className={`badgeDot level-${level + 1}`} />
    ));
  }

  if (!trail && level) {
    return <span className={`badgeDot level-${level}`} />;
  }
  return '';
};

export default LevelDots;
