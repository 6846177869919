import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { openSignUpForm, openLoginForm } from '../AuthModal/actions';
import ResponsiveLogo from '../../components/LogoComponent';

import CloseIcon from '../../assets/images/message-close.svg';
import carouselItemImage from '../../assets/images/listing.jpg';
import './index.scss';

const styles = () => ({
  root: {
    backgroundColor: '#faf4ec',
  },
  paper: {
    backgroundColor: '#faf4ec',
    paddingTop: 4,
    paddingLeft: 1,
    borderRadius: 0,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.17)',
    overflow: 'hidden',
    left: '0 !important',
    top: '0 !important',
    width: '100%',
    maxWidth: '100%',
    borderBottom: 'solid 1px #000000',
  },
  navHeader: {
    display: 'flex',
    padding: '0px 25px 11px',
    borderBottom: '1px solid #eeeeee',
    justifyContent: 'space-between',
  },
  outlineNone: {
    outline: 'none',
  },
  overwritingButton: {
    width: '100%',
    height: '100%',
    borderRadius: 0,
    padding: 0,
  },
  responsiveUserAvatar: {
    width: 'auto',
    height: 30,
    margin: '0px auto 3px',
    borderRadius: 0,
  },
  userName: {
    fontSize: 13,
    color: '#1e1e1e',
    lineHeight: 1,
    textAlign: 'center',
  },
});

const options = ['Notifications (5)', 'Search people', 'FAQ', 'Blog'];

class ResponsiveNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { openSignUpForm, openLoginForm, classes } = this.props;
    return (
      <Hidden smUp>
        <div className="headerMain">
          <ResponsiveLogo />
          <div>
            <ul className="resNavItme">
              <li className="notSignedIn">
                <Button
                  className="signLogin"
                  onClick={() => {
                    openLoginForm();
                  }}
                >
                  Log In
                </Button>
                <Button
                  className="signLogin"
                  onClick={() => {
                    openSignUpForm();
                  }}
                >
                  Sign Up
                </Button>
              </li>
              <li>
                <Grid direction="column" container>
                  <img alt="user profile" src={carouselItemImage} className={classes.responsiveUserAvatar} />
                  <div className={classes.userName}>Eric</div>
                </Grid>
              </li>

              <li>
                <IconButton
                  aria-label="More"
                  aria-owns={open ? 'long-menu' : null}
                  aria-haspopup="true"
                  onClick={event => this.handleClick(event)}
                  className={classes.overwritingButton}
                >
                  <div className="hamburger">
                    <span className="hamburgerItem" />
                  </div>
                </IconButton>
                <Menu
                  classes={{ paper: classes.paper }}
                  id="long-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={event => this.handleClose(event)}
                >
                  <li className={`${classes.navHeader} ${classes.outlineNone}`}>
                    <div>
                      <ResponsiveLogo />
                    </div>
                    <div>
                      <Button className="closeModal" onClick={() => this.handleClose()}>
                        <img src={CloseIcon} alt="dada logo" />
                      </Button>
                    </div>
                  </li>
                  {options.map(option => (
                    <MenuItem
                      paperprops={{
                        classes: {
                          root: classes.paper,
                        },
                      }}
                      key={option}
                      selected={option === 'Pyxis'}
                      onClick={() => this.handleClose()}
                      className="CustomNavItem"
                      style={{ transformOrigin: 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </li>
            </ul>
          </div>
        </div>
      </Hidden>
    );
  }
}

ResponsiveNav.propTypes = {
  openSignUpForm: PropTypes.func.isRequired,
  openLoginForm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  openSignUpForm: () => {
    dispatch(openSignUpForm(ownProps.authAction));
  },
  openLoginForm: () => {
    dispatch(openLoginForm(ownProps.authAction));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResponsiveNav));
