import React, { Component, Fragment } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import VCPicture from '../VCPicture';
import Loader from '../../components/Loader';
import Scroller from '../../components/CustomScroller';

import { getIndividualPictures } from './actions';
import './index.scss';
import styles from './styles';

class PictureCarousel extends Component {
  constructor(props) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
    this.slider = React.createRef();
    this.singleSliderContainer = React.createRef();
  }

  loadMore() {
    const {
      loadPictures, nextPage, isRequesting, criteria,
    } = this.props;
    if (!isRequesting && nextPage) {
      loadPictures({ query: criteria, page: nextPage });
    }
  }

  render() {
    const {
      list, isRequesting, hasNext, authFollowAction, history,
    } = this.props;
    return (
      <Fragment>
        {!isEmpty(list) && (
          <div className="pictureCarouselWrapper">
            <Scroller
              loading={isRequesting}
              hasMore={hasNext}
              initialLoad={false}
              loadMore={this.loadMore}
              loader={<Loader box={{ width: '360px', height: '430px' }} />}
            >
              {list.map((id, index) => (
                <VCPicture
                  id={id}
                  authFollowAction={authFollowAction}
                  key={id}
                  history={history}
                />
              ))}
            </Scroller>
            <hr className="feedHorizontalBar" />
          </div>
        )}
      </Fragment>
    );
  }
}

// withWidth is used so that props trigger when width changes.
const mapStateToProps = (state) => {
  const { pictureCarousel } = state;
  const list = pictureCarousel.get('list').toJS();
  const nextPage = pictureCarousel.get('nextPage');
  const hasNext = pictureCarousel.get('hasNext');
  const isRequesting = pictureCarousel.get('isRequesting');
  return {
    list,
    nextPage,
    hasNext,
    isRequesting,
  };
};

const mapDispatchToProps = dispatch => ({
  loadPictures: (params) => {
    dispatch(getIndividualPictures(params));
  },
});

const PictureCarouselWithStyles = withStyles(styles)(PictureCarousel);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PictureCarouselWithStyles);
