export const OPEN_DRAWING_TOOL_DIALOG = 'OPEN_DRAWING_TOOL_DIALOG';
export const CLOSE_DRAWING_TOOL_DIALOG = 'CLOSE_DRAWING_TOOL_DIALOG';
export const POST_DRAWING = 'POST_DRAWING';
export const POST_DRAWING_INITIATED = 'POST_DRAWING_INITIATED';
export const POST_DRAWING_FAILURE = 'POST_DRAWING_FAILURE';
export const POST_DRAWING_SUCCESS = 'POST_DRAWING_SUCCESS';
export const POST_DRAFT = 'POST_DRAFT';
export const POST_DRAFT_SUCCESS = 'POST_DRAFT_SUCCESS';
export const POST_DRAFT_FAILURE = 'POST_DRAFT_FAILURE';
export const POST_DRAFT_INITIATED = 'POST_DRAFT_INITIATED';
export const PUT_DRAFT = 'PUT_DRAFT';
export const PUT_DRAFT_INITIATED = 'PUT_DRAFT_INITIATED';
export const PUT_DRAFT_SUCCESS = 'PUT_DRAFT_SUCCESS';
export const PUT_DRAFT_FAILURE = 'PUT_DRAFT_FAILURE';
export const GET_DRAFT_INDEX = 'GET_DRAFT_INDEX';
export const GET_DRAFT_INDEX_INITIATED = 'GET_DRAFT_INDEX_INITIATED';
export const GET_DRAFT_INDEX_SUCCESS = 'GET_DRAFT_INDEX_SUCCESS';
export const GET_DRAFT_INDEX_FAILURE = 'GET_DRAFT_INDEX_FAILURE';
export const GET_DRAFT = 'GET_DRAFT';
export const GET_DRAFT_INITIATED = 'GET_DRAFT_INITIATED';
export const GET_DRAFT_SUCCESS = 'GET_DRAFT_SUCCESS';
export const GET_DRAFT_FAILURE = 'GET_DRAFT_FAILURE';
export const CLOSE_DRAFT_MODAL = 'CLOSE_DRAFT_MODAL';
export const DELETE_DRAFT = 'DELETE_DRAFT';
export const DELETE_DRAFT_INITIATED = 'DELETE_DRAFT_INITIATED';
export const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS';
export const DELETE_DRAFT_FAILURE = 'DELETE_DRAFT_FAILURE';
export const OPEN_SAVE_DRAFT_CONFIRMATION_MODAL = 'OPEN_SAVE_DRAFT_CONFIRMATION_MODAL';
export const POST_CONFIRMATION_MODAL = 'POST_CONFIRMATION_MODAL';
export const REQUEST_POST = 'REQUEST_POST';
export const SAVE_DRAFT_REQUEST = 'SAVE_DRAFT_REQUEST';
export const RELOAD_DRAWING_REDUCER_STATE = 'RELOAD_DRAWING_REDUCER_STATE';
export const SET_CANVAS_INITIAL_AUTHENTICATION_STATUS = 'SET_CANVAS_INITIAL_AUTHENTICATION_STATUS';
export const POST_DRAWING_HOME_PAGE = 'POST_DRAWING_HOME_PAGE';
export const CLOSE_DRAWING_TOOL_HOME_PAGE = 'CLOSE_DRAWING_TOOL_HOME_PAGE';
export const POST_DRAWING_INDIVIDUAL_VC_PAGE = 'POST_DRAWING_INDIVIDUAL_VC_PAGE';
export const CLOSE_DRAWING_TOOL_INDIVIDUAL_VC_PAGE = 'CLOSE_DRAWING_TOOL_INDIVIDUAL_VC_PAGE';

export const openDrawingTool = (isReply = false, previousActivityId = 0, vcId = 0, title = 'My Title') => (
  {
    type: OPEN_DRAWING_TOOL_DIALOG, isReply, previousActivityId, vcId, title,
  });
export const closeDrawingTool = () => ({ type: CLOSE_DRAWING_TOOL_DIALOG });
export const postCloseDrawingTool = actionType => ({ type: actionType });

export const postDrawing = () => ({
  type: POST_DRAWING,
});

export const postDrawingDynamic = (actionType, payload, isReply, activityId, vcId) => ({
  type: actionType,
  payload,
  isReply,
  activityId,
  vcId,
});
export const postDrawingInitiated = () => ({ type: POST_DRAWING_INITIATED });
export const postConfirmationModal = () => ({ type: POST_CONFIRMATION_MODAL });
export const postDrawingFailure = () => ({ type: POST_DRAWING_FAILURE });
export const postDrawingSuccess = () => ({ type: POST_DRAWING_SUCCESS });
export const postDraftInitiated = () => ({ type: POST_DRAFT_INITIATED });
export const postDraftFailure = () => ({ type: POST_DRAFT_FAILURE });
export const postDraftSuccess = (draft_id, isFromConfirmationClose) => ({
  type: POST_DRAFT_SUCCESS, draft_id, isFromConfirmationClose,
});
export const postDraft = (payload, isFromConfirmationClose, dynamicCloseAction) => ({
  type: POST_DRAFT,
  payload,
  isFromConfirmationClose,
  closeAction: dynamicCloseAction,
});
export const putDraftFailure = () => ({ type: PUT_DRAFT_FAILURE });
export const putDraftSuccess = (draft_id, isFromConfirmationClose) => ({
  type: PUT_DRAFT_SUCCESS, draft_id, isFromConfirmationClose,
});
export const putDraft = (id, payload, isFromConfirmationClose, dynamicCloseAction) => ({
  type: PUT_DRAFT,
  id,
  payload,
  isFromConfirmationClose,
  closeAction: dynamicCloseAction,
});
export const putDraftInitiated = () => ({ type: PUT_DRAFT_INITIATED });
export const getDraftIndex = (paramData, isSaveDraftRequested, workingDraft) => ({
  type: GET_DRAFT_INDEX, paramData, isSaveDraftRequested, workingDraft,
});
export const getDraftIndexInitiated = () => ({ type: GET_DRAFT_INDEX_INITIATED });
export const getDraftIndexSuccess = (draftId, bgColor, noDraft) => ({
  type: GET_DRAFT_INDEX_SUCCESS, draftId, bgColor, noDraft,
});
export const getDraftIndexFailure = () => ({ type: GET_DRAFT_INDEX_FAILURE });
export const getDraft = (id, isSaveDraftRequested, workingDraft) => ({
  type: GET_DRAFT, id, isSaveDraftRequested, workingDraft,
});
export const getDraftInitiated = () => ({ type: GET_DRAFT_INITIATED });
export const getDraftSuccess = (draft, imgWithBg, isSaveDraftRequested, workingDraft) => ({
  type: GET_DRAFT_SUCCESS, draft, imgWithBg, isSaveDraftRequested, workingDraft,
});
export const getDraftFailure = () => ({ type: GET_DRAFT_FAILURE });
export const closeDraftModal = () => ({ type: CLOSE_DRAFT_MODAL });
export const deleteDraft = id => ({ type: DELETE_DRAFT, id });
export const deleteDraftInitiated = () => ({ type: DELETE_DRAFT_INITIATED });
export const deleteDraftSuccess = () => ({ type: DELETE_DRAFT_SUCCESS });
export const deleteDraftFailure = () => ({ type: DELETE_DRAFT_FAILURE });
export const openSaveDraftConfirmationModal = () => ({ type: OPEN_SAVE_DRAFT_CONFIRMATION_MODAL });
export const requestPost = () => ({ type: REQUEST_POST });
export const saveDraftRequest = () => ({ type: SAVE_DRAFT_REQUEST });
export const reloadDrawingToolState = () => ({ type: RELOAD_DRAWING_REDUCER_STATE });
export const setCanvasInitialAuthenticationStatus = status => ({ type: SET_CANVAS_INITIAL_AUTHENTICATION_STATUS, status });
