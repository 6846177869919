import React, {
  Children,
  useState,
  useRef,
  useCallback,
  Fragment,
  useEffect,
} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import './index.scss';

const Scroller = (props) => {
  const {
    children,
    loader,
    loading,
    loadMore,
    hasMore,
    classes,
    initialLoad,
  } = props;
  const arrayChildren = Children.toArray(children);
  const observer = useRef();
  let slider = useRef();
  const lastDataElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  useEffect(() => {
    if (initialLoad) {
      loadMore();
    }
  }, []);

  return (
    <Fragment>
      <div className="scroller-wrapper">
        <Scrollbar
          style={{ width: '100%' }}
          className="scrollContainer carouselScrollHorizontalTrack"
          ref={(ref) => {
            slider = ref;
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            {arrayChildren.map((child, index) => {
              const key = index;
              if (arrayChildren.length === index + 1) {
                return (
                  <div
                    key={key}
                    ref={lastDataElementRef}
                  >
                    {!loading ? (
                      <div
                        className={`items ${classes}`}
                      >
                        {child}
                      </div>
                    ) : (
                      loader
                    )}
                  </div>
                );
              }
              return (
                <div key={key} className={`items ${classes}`}>
                  {child}
                </div>
              );
            })}
          </div>
        </Scrollbar>
      </div>
    </Fragment>
  );
};

export default Scroller;
