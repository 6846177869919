import { combineEpics } from 'redux-observable';
// Project epics import
import * as authFlowEpics from '../containers/AuthModal/epic';
import * as authenticatorEpics from '../containers/Authenticator/epics';
import { forgetPasswordSuccessEpic, resquestForgetPasswordEpic } from '../containers/ForgetPasswordForm/epic';
import requestResetPasswordEpic from '../containers/ResetPassword/epic';
import { requestGetVCFeedEpic, requestReloadVCFeedEpic } from '../containers/VisualConversationFeed/epics';
import {
  getSpecificConversationTreeEpic, likeActivityEpic,
  editVcDetails, curatorActivityEpic,
} from '../containers/VisualConversation/epics';
import { requestGetVCCommentsEpic, likeCommentsEpic, postCommentsEpic } from '../containers/VCComments/epics';
import * as drawingToolEpics from '../containers/DrawingTool/epic';
import {
  getProfileEpic, getUserActivitiesEpic, updateProfileEpic, followUserEpic, loadSelectedVcEpic,
} from '../containers/ProfileView/epic';
import { createVCDynamicReducersEpic, initiateVcFeedPageEpic } from '../containers/IndividualVCFeedPage/epics';

// Explore
import { getExploreChallengeEpic, getSearchResultsEpic, clearExploreViewState } from '../containers/ExploreView/epic';
import requestGetExploreProfileEpic from '../containers/ProfileCarousel/epic';
import requestGetIndividualPictureEpic from '../containers/PictureCarousel/epic';
import { exploreVCFeedEpic, exploreVCFeedFilterEpic } from '../containers/ExploreConversationFeed/epic';
import modalVcFeedEpic from '../containers/ConversationsModal/epics';

import { getBlogsResultsEpic, createOrUpdateBlogEpic } from '../containers/BlogsLandingPage/epic';
import { getEditorialResultsEpic } from '../containers/Editorial/epic';

const importSpreader = epics => Object.keys(epics).map(key => epics[key]);

const allAuthFlowEpics = importSpreader(authFlowEpics);
const allDrawingToolEpics = importSpreader(drawingToolEpics);
const allAuthenticatorEpics = importSpreader(authenticatorEpics);

const rootEpics = combineEpics(
  ...allAuthFlowEpics,
  ...allDrawingToolEpics,
  ...allAuthenticatorEpics,
  requestResetPasswordEpic,
  resquestForgetPasswordEpic,
  forgetPasswordSuccessEpic,
  requestGetVCFeedEpic,
  likeActivityEpic,
  getSpecificConversationTreeEpic,
  requestGetVCCommentsEpic,
  likeCommentsEpic,
  postCommentsEpic,
  requestReloadVCFeedEpic,
  getProfileEpic,
  editVcDetails,
  curatorActivityEpic,
  createVCDynamicReducersEpic,
  initiateVcFeedPageEpic,
  getUserActivitiesEpic,
  followUserEpic,
  updateProfileEpic,
  loadSelectedVcEpic,
  getExploreChallengeEpic,
  getSearchResultsEpic,
  requestGetExploreProfileEpic,
  requestGetIndividualPictureEpic,
  exploreVCFeedEpic,
  exploreVCFeedFilterEpic,
  modalVcFeedEpic,
  getBlogsResultsEpic,
  createOrUpdateBlogEpic,
  clearExploreViewState,
  getEditorialResultsEpic,
);

export default rootEpics;
