export const REQUEST_SIGN_UP_SUCCESS = 'REQUEST_SIGN_UP_SUCCESS';
export const REQUEST_SIGN_UP_FAILURE = 'REQUEST_SIGN_UP_FAILURE';
export const REQUEST_SIGN_UP_INITIATED = 'REQUEST_SIGN_UP_INITIATED';
export const RESET_SIGN_UP_MESSAGES = 'RESET_SIGN_UP_MESSAGES';

export const requestSignUp = (actionToDispatch, payload) => ({ type: actionToDispatch, payload });
export const requestSignUpSuccess = (tokensObject, userObject) => ({
  type: REQUEST_SIGN_UP_SUCCESS,
  data: {
    ...tokensObject,
    ...userObject,
  },
});
export const requestSignUpFailure = error => ({ type: REQUEST_SIGN_UP_FAILURE, error });
export const requestSignUpInitiated = () => ({ type: REQUEST_SIGN_UP_INITIATED });
export const resetSignUpMessages = () => ({ type: RESET_SIGN_UP_MESSAGES });
