/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

import cloneDeep from 'lodash/cloneDeep';
import compareAsc from 'date-fns/compare_asc';

import {
  GET_ACTIVITY_LIKES,
  TREE_EXTERNAL_ACTIVE,
  TREE_EXTERNAL_COLLAPSE,
  TREE_NODE_ACTIVE_OPACITY,
  TREE_NODE_INACTIVE_OPACITY,
} from '../constants';

import { getRequest } from './apiRequestUtils';
import { apiDeserializer } from './jsonApiSerializer';

export const visualConversationStore = id => `visualConversation${id}`;

export function findNodesByName(nodeName, nodeSet, hits) {
  if (hits.length > 0) {
    return hits;
  }

  hits = hits.concat(nodeSet.filter(node => node.name === nodeName));

  nodeSet.forEach((node) => {
    if (node.children && node.children.length > 0) {
      hits = findNodesByName(nodeName, node.children, hits);
    }
  });

  return hits;
}

function markNodeActive(node) {
  node[TREE_EXTERNAL_ACTIVE] = true;
  node.styles.opacity = TREE_NODE_ACTIVE_OPACITY;
}

function unmarkNodeActive(node) {
  node[TREE_EXTERNAL_ACTIVE] = false;
  node.styles.opacity = TREE_NODE_INACTIVE_OPACITY;
}

function expandNode(node) {
  node[TREE_EXTERNAL_COLLAPSE] = false;
}

function collapseNode(node) {
  node[TREE_EXTERNAL_COLLAPSE] = true;
  unmarkNodeActive(node);
  if (node.children && node.children.length > 0) {
    node.children.forEach((child) => {
      collapseNode(child);
    });
  }
}

function autoExpand(node) {
  let selectedNode = node;
  let maxDepth = node.depthLevel;
  let parentIdsTrail = node.depthLevel === 0 ? [...node.attributes.parentIdsTrail]
    : [...node.attributes.parentIdsTrail, node.name];

  markNodeActive(selectedNode);

  do {
    if (selectedNode.children && selectedNode.children.length) {
      expandNode(selectedNode);
      selectedNode = findNodesByName(selectedNode.nextChild, selectedNode.children, [])[0];
      markNodeActive(selectedNode);
      maxDepth = selectedNode.depthLevel;
      parentIdsTrail = [...selectedNode.attributes.parentIdsTrail, selectedNode.name];
    } else {
      selectedNode = undefined;
    }
  } while (selectedNode);

  return {
    maxDepth,
    parentIdsTrail,
  };
}

function findNodesAtDepth(level, nodeSet, accumulator) {
  accumulator = accumulator.concat(nodeSet.filter(node => node.depthLevel === level));

  nodeSet.forEach((node) => {
    if (node.children && node.children.length > 0) {
      accumulator = findNodesAtDepth(level, node.children, accumulator);
    }
  });

  return accumulator;
}

function collapseNeighborNodes(targetNode, nodeSet) {
  const neighbors = findNodesAtDepth(targetNode.depthLevel, nodeSet, []).filter(
    node => node.name !== targetNode.name,
  );
  neighbors.forEach(neighbor => collapseNode(neighbor));
}

export const conversationTreesResponseParser = (responseFromApi) => {
  let globalChildren = {};
  const parsedProfiles = [];
  const {
    data: parents,
    included: {
      activities: children,
      profiles,
    },
  } = responseFromApi;

  function parseChildren(child_ids, parentIdsTrail) {
    if (child_ids.length === 0) {
      return {};
    }
    const childrenArray = child_ids
      .map(child => createChildNode(globalChildren[child], parentIdsTrail)); // eslint-disable-line no-use-before-define
    const children = {};
    childrenArray.forEach((child) => {
      children[child.id] = child;
    });
    return children;
  }

  function createParentNode(seed) {
    const {
      id,
      type,
      attributes: {
        src,
        level,
        title,
        last_modified_at,
        likes_count,
        liked_by_user,
        curated_by_user,
        drawing_count,
        comments_count,
        user_can_reply,
        created_at,
        child_ids,
      },
      relationships: {
        owner: {
          data: {
            id: ownerId,
          },
        },
      },
    } = seed;
    const parentIdsTrail = [];
    const node = {
      id,
      type,
      src,
      level,
      title,
      last_modified_at: new Date(last_modified_at * 1000),
      likes_count,
      liked_by_user,
      curated_by_user,
      user_can_reply,
      drawing_count,
      comments_count,
      ownerId,
      child_ids,
      created_at: new Date(created_at * 1000),
      parentIdsTrail,
      children: parseChildren(child_ids, parentIdsTrail),
    };
    return node;
  }

  function createChildNode(seed, parentIdsTrail) {
    const {
      id,
      type,
      attributes: {
        src,
        likes_count,
        child_ids,
        drawing_count,
        liked_by_user,
        curated_by_user,
        user_can_reply,
        created_at,
      },
      relationships: {
        owner: {
          data: {
            id: ownerId,
          },
        },
      },
    } = seed;
    const newParentIdsTrail = parentIdsTrail.concat(seed.id);
    const node = {
      id,
      type,
      src,
      created_at: created_at ? new Date(created_at * 1000) : new Date(),
      likes_count: likes_count || 0,
      drawing_count,
      liked_by_user,
      curated_by_user,
      user_can_reply,
      ownerId,
      child_ids,
      parentIdsTrail,
      children: parseChildren(child_ids, newParentIdsTrail),
    };
    return node;
  }

  function createProfile(profile) {
    const {
      id,
      type,
      attributes: {
        name,
        level,
        followed_by_user,
        photo,
        country,
      },
    } = profile;

    const parsedProfile = {
      id,
      type,
      name,
      followed_by_user,
      level,
      photo,
      country,
    };

    return parsedProfile;
  }

  globalChildren = children;
  let parentToChildHierarichy;
  if (parents.constructor === Array) {
    parentToChildHierarichy = parents.map(seed => createParentNode(seed));
  } else {
    parentToChildHierarichy = createParentNode(parents);
  }
  Object.entries(profiles).forEach(([key, value]) => {
    parsedProfiles.push(createProfile(value));
  });

  return {
    nodes: parentToChildHierarichy,
    profiles: parsedProfiles,
  };
};

export const generateTreeData = (node, depthLevel) => ({
  name: node.id, // name must be a unique value
  attributes: {
    comments_count: node.comments_count,
    created_at: node.created_at,
    drawing_count: node.drawing_count,
    displayDrawingCount: node.drawing_count - 1,
    liked_by_user: node.liked_by_user,
    curated_by_user: node.curated_by_user,
    id: node.id,
    last_modified_at: node.last_modified_at,
    level: node.level,
    likes_count: node.likes_count,
    ownerId: node.ownerId,
    src: node.src,
    title: node.title,
    user_can_reply: node.user_can_reply,
    parentIdsTrail: node.parentIdsTrail,
  },
  styles: {
    opacity: TREE_NODE_INACTIVE_OPACITY,
  },
  collapsed: true,
  depthLevel,
  nextChild: node.child_ids.length ? node.child_ids[0] : null,
  children: node.child_ids.map(nodeId => generateTreeData(node.children[nodeId], depthLevel + 1))
    .sort((prevNode, nextNode) => compareAsc(prevNode.attributes.created_at, nextNode.attributes.created_at)),
});

export const generateActiveTrail = (node, accumulator) => {
  accumulator.push(node.attributes.id);

  if (node.nextChild) {
    generateActiveTrail(
      node.children.find(child => child.attributes.id === node.nextChild),
      accumulator,
    );
  }

  return accumulator;
};

export const generateActiveBranch = (node, activeTrail, accumulator = [], currentIndex = 0) => {
  accumulator.push({ ...node.attributes });

  const nextIndex = currentIndex + 1;

  if (nextIndex <= activeTrail.length - 1) {
    generateActiveBranch(
      node.children.find(child => child.attributes.id === activeTrail[nextIndex]),
      activeTrail,
      accumulator,
      nextIndex,
    );
  }

  return accumulator;
};

export const controlCollapsedState = (treeData, nodeDataName) => {
  const data = cloneDeep([treeData]);
  const targetNode = findNodesByName(nodeDataName, data, [])[0];
  if (targetNode[TREE_EXTERNAL_COLLAPSE] && !targetNode[TREE_EXTERNAL_ACTIVE]) {
    const { maxDepth: currentMaxDepth, parentIdsTrail } = autoExpand(targetNode);
    // Set value at root
    data[0].currentMaxDepth = currentMaxDepth;
    data[0].activeTrail = [data[0].name, ...parentIdsTrail];

    if (targetNode.depthLevel) {
      collapseNeighborNodes(targetNode, data);
    }
  }

  return data[0];
};

export const getActivityLikes = async (activityId) => {
  const { data } = await getRequest(GET_ACTIVITY_LIKES(activityId));
  return apiDeserializer(data);
};
