import React from 'react';
import * as PropTypes from 'prop-types';
import LoadingBar from 'react-redux-loading-bar';
import { withStyles } from '@material-ui/core';

import styles from './styles';

function LinearLoader(props) {
  const { scope, classes } = props;

  return (
    scope
      ? <LoadingBar className={classes.loader} scope={scope} />
      : <LoadingBar className={classes.loader} />
  );
}

export default withStyles(styles)(LinearLoader);
