import React from 'react';
import blog_logo from '../../../assets/images/blogLogo.svg';
import './index.scss';

const Header = (props) => {
  const { handleRedirect } = props;
  return (
    <div className="main blogWrap">
      <div className="blog">
        <div className="blogHeader" role="button" tabIndex={0}>
          <a role="button" tabIndex={0} onClick={handleRedirect} className="blogLogo"><img src={blog_logo} alt="editorial-logo" /></a>
          <span className="editorialHeading">editorial</span>
        </div>
      </div>
    </div>
  );
};


export default Header;
