// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import {
  debounceTime, mergeMap, catchError,
} from 'rxjs/operators';
// Utils
import { conversationTreesResponseParser, generateActiveTrail, generateTreeData } from '../../utils/vcUtils';
// Project Imports
import createProfileReducer from '../VCProfile/reducerCreator';
import createVCReducers from '../VisualConversation/reducerCreator';
import createCommentsReducers from '../VCComments/reducerCreator';
import attachDynamicReducers from '../../reducers/reducerInjector';
import { refreshVc, saveVcConversation } from '../VisualConversation/actions';
import { refreshProfiles, saveVCProfile } from '../VCProfile/actions';
import { openSnackbar } from '../ApplicationSnackbar/actions';
import { GET_ALL_CONVERSATIONS_ENDPOINT, UNEXPECTED_ERROR } from '../../constants';
import {
  GET_VC_FEED, getVcFeedFailure, getVcFeedInitiated, getVcFeedSuccess, RELOAD_VC_FEED, refreshVcFeed,
} from './actions';
import { reloadDrawingToolState } from '../DrawingTool/actions';
import { refreshComments } from '../VCComments/actions';
import { generateAuthHeaderFromStore } from '../../utils/authUtils';


function apiResponseParser(response) {
  let url = null;
  let page = null;

  if (response.data.links.next) {
    url = new URL(response.data.links.next);
    page = new URLSearchParams(url.search).get('page');
  }

  const { nodes, profiles } = conversationTreesResponseParser(response.data);
  const vcReducers = {};
  const commentReducers = {};
  nodes.forEach((vc) => {
    vcReducers[`visualConversation${vc.id}`] = createVCReducers(vc.id);
    commentReducers[`vcComments${vc.id}`] = createCommentsReducers(vc.id);
  });

  const profileReducers = {};
  profiles.forEach((profile) => {
    profileReducers[`vcProfile${profile.id}`] = createProfileReducer(profile.id);
  });
  attachDynamicReducers({ ...vcReducers, ...commentReducers, ...profileReducers });
  const vcActions = nodes.map((vc) => {
    const parsedTree = generateTreeData(vc, 0);
    return saveVcConversation(vc.id, parsedTree, generateActiveTrail(parsedTree, []));
  });
  const profileActions = profiles.map(profile => saveVCProfile(profile.id, profile));
  const idsList = nodes.map(vc => vc.id);
  return {
    vcActions,
    profileActions,
    idsList,
    page,
  };
}

function refreshVCState(state) {
  const stateKeys = Object.keys(state);
  const refreshVcData = [];
  const refreshVcComments = [];
  const refreshVcProfiles = [];

  stateKeys.forEach((element) => {
    const matchedProfile = element.match(/^(vcProfile)(\S+)/);
    if (matchedProfile) {
      refreshVcProfiles.push(refreshProfiles(matchedProfile[2]));
      return;
    }

    const matchedVc = element.match(/^(visualConversation)(\d+)/);
    if (matchedVc) {
      refreshVcData.push(refreshVc(+matchedVc[2]));
      return;
    }

    const matchedComment = element.match(/^(vcComments)(\d+)/);
    if (matchedComment) {
      refreshVcComments.push(refreshComments(+matchedComment[2]));
    }
  });

  return {
    refreshVcData,
    refreshVcComments,
    refreshVcProfiles,
  };
}

export const requestGetVCFeedEpic = (action$, state$, { getRequest }) => action$.ofType(GET_VC_FEED).pipe(
  debounceTime(500),
  mergeMap(action => concat(
    of(getVcFeedInitiated()),
    from(getRequest(GET_ALL_CONVERSATIONS_ENDPOINT, action.headers, action.params)).pipe(
      mergeMap((response) => {
        const {
          profileActions, vcActions, idsList, page,
        } = apiResponseParser(response);
        return [
          ...profileActions,
          ...vcActions,
          getVcFeedSuccess(idsList, page),
        ];
      }),
      catchError((error) => {
        console.error(error);
        return [
          getVcFeedFailure(UNEXPECTED_ERROR),
          openSnackbar(UNEXPECTED_ERROR),
        ];
      }),
    ),
  )),
);

export const requestReloadVCFeedEpic = (action$, state$, { getRequest }) => action$.ofType(RELOAD_VC_FEED).pipe(
  debounceTime(500),
  mergeMap(action => concat(
    of(getVcFeedInitiated()),
    from(getRequest(GET_ALL_CONVERSATIONS_ENDPOINT, generateAuthHeaderFromStore(state$.value), action.params))
      .pipe(
        mergeMap((response) => {
          const { refreshVcData, refreshVcComments, refreshVcProfiles } = refreshVCState(state$.value);
          const {
            profileActions, vcActions, idsList, page,
          } = apiResponseParser(response);
          return [
            refreshVcFeed(),
            ...refreshVcData,
            ...refreshVcComments,
            ...refreshVcProfiles,
            ...profileActions,
            ...vcActions,
            getVcFeedSuccess(idsList, page),
            reloadDrawingToolState(),
          ];
        }),
        catchError((error) => {
          console.error(error);
          return [
            getVcFeedFailure(UNEXPECTED_ERROR),
            openSnackbar(UNEXPECTED_ERROR),
          ];
        }),
      ),
  )),
);
