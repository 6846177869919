const styles = () => ({
  welcomeMsg: {
    padding: '29px 0px',
    backgroundColor: 'rgba(250, 244, 236, 0.7)',
    marginBottom: 30,
    position: 'relative',
  },
  container: {
    maxWidth: 793,
    margin: '0px auto',
  },
  welcomeMsgTxt: {
    fontSize: 18,
    letterSpacing: '0.04px',
    color: '#1e1e1e',
  },
  closeBtn: {
    width: 'auto',
    height: 'auto',
    border: 'none',
    borderRadius: 0,
    padding: 5,
    minWidth: 1,
    minHeight: 1,
    display: 'block',
    fontSize: 0,
    background: 'transparent',
    position: 'absolute',
    right: 0,
    top: 0,
    lineHeight: 0,
  },
});

export default styles;
