import React from 'react';
import './color_display.css';

const ColorPickerSelector = ({ onClick, color, className }) => (
  <div>
    <div className={className}>
      <div className="dot-border">
        <div className="dot-color">
          <div
            aria-hidden
            className="dot"
            style={{ backgroundColor: color }}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  </div>
);

export default ColorPickerSelector;
