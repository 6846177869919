import createHistory from 'history/createBrowserHistory'; // eslint-disable-line
import { applyMiddleware, compose } from 'redux';
import { createStore } from 'redux-dynamic-reducer';
import { routerMiddleware } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
// Project imports
import createRootReducer from './reducers';
import rootEpic from './epics';
import {
  deleteRequest, getRequest, postRequest, putRequest,
} from './utils/apiRequestUtils';

const history = createHistory();

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    getRequest, postRequest, putRequest, deleteRequest,
  },
});
const middlewares = [epicMiddleware, routerMiddleware(history)];

const enhancers = [
  applyMiddleware(...middlewares),
];

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
/* eslint-disable no-underscore-dangle */
const composeEnhancers = process.env.NODE_ENV !== 'production'
        && typeof window === 'object'
        && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Prevent recomputing reducers for `replaceReducer`
    shouldHotReload: false,
  })
  : compose;

const store = createStore(
  createRootReducer(history), // new root reducer with router state
  {},
  composeEnhancers(...enhancers),
);
epicMiddleware.run(rootEpic);

export { store, history };
