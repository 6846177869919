/* eslint-disable import/prefer-default-export */
// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { toJS } from 'immutable';
import { concat } from 'rxjs/observable/concat';
import { mergeMap, catchError, throttleTime } from 'rxjs/operators';
import userActivitiesPageParser from '../../utils/profileViewUtil';
import {
  getUserInfo,
  updateProfile,
  userActivities,
  followUserAPI,
} from '../../api/index';

import {
  GET_USER_ACTIVITIES,
  GET_USER_INFO,
  userInfoSuccess,
  userInfoFailure,
  userActivitiesSuccess,
  userActivitiesFailure,
  UPDATE_USER_PROFILE,
  updateUserProfileSuccess,
  updateUserProfileFailure,
  updateUserProfileInitiated,
  FOLLOW_USER,
  followUserSuccess,
  followUserFailure,
  userActivitiesInitiated,
  followUserInitiated,
  updateUserView,
  LOAD_SELECTED_VC,
  loadSelectedVc,
  setSelectedVc,
  setSelectedDrawing,
  clearUserProfile,
} from './actions';
import { generateAuthHeaderFromStore, uidHandler } from '../../utils/authUtils';
import {
  HttpStatus,
  UNAUTHORIZED_ERROR,
  UNEXPECTED_ERROR,
} from '../../constants';
import {
  updateAuthenticatedTokens,
  updateAuthenticatedUser,
} from '../Authenticator/actions';
import { updateVcProfile } from '../VCProfile/actions';
import { INITIATE_INDIVIDUAL_VC_FEED_PAGE } from '../IndividualVCFeedPage/actions';
import { getSpecificConversationTree$ } from '../VisualConversation/epics';
import {
  toggleExpansionPanel,
  updateActiveBranchData,
  updateVcConversationBranchActivity,
} from '../VisualConversation/actions';
import { createVCDynamicReducers$ } from '../IndividualVCFeedPage/epics';

function updateVcProfileInState(id, currentProfile, updatedProfile) {
  return updateVcProfile(id, { ...currentProfile, ...updatedProfile });
}

export const getProfileEpic = (action$, state$) => action$.ofType(GET_USER_INFO).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    from(
      getUserInfo(action.id, generateAuthHeaderFromStore(state$.value)),
    ).pipe(
      mergeMap((response) => {
        const { data } = response;
        return [userInfoSuccess(data)];
      }),
      catchError((error) => {
        console.error(error);
        return of(userInfoFailure());
      }),
    ),
  )),
);

export const getUserActivitiesEpic = (action$, state$) => action$.ofType(GET_USER_ACTIVITIES).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    of(userActivitiesInitiated()),
    from(userActivities(action.id, action.params)).pipe(
      mergeMap((response) => {
        const { data } = response;
        if (data.length > 0 && !action.selectedVc) {
          return [
            loadSelectedVc(data[0].id, data[0].rootId),
            userActivitiesSuccess(userActivitiesPageParser(data)),
            userActivitiesInitiated(),
          ];
        }
        return [
          userActivitiesSuccess(userActivitiesPageParser(data)),
          userActivitiesInitiated(),
        ];
      }),
      catchError((error) => {
        console.error(error);
        return of(userActivitiesFailure());
      }),
    ),
  )),
);

export const updateProfileEpic = (action$, state$) => action$.ofType(UPDATE_USER_PROFILE).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    of(updateUserProfileInitiated()),
    from(
      updateProfile(
        action.id,
        action.payload,
        generateAuthHeaderFromStore(state$.value),
      ),
    ).pipe(
      mergeMap((response) => {
        const {
          email, photo, name, id, country,
        } = response;
        let updateActions = [
          updateAuthenticatedTokens(email),
          updateAuthenticatedUser(email, name, photo),
          updateUserProfileSuccess(),
        ];

        const userView = state$.value.profileView.get('userView').toJS();
        updateActions = [
          updateUserView({ ...userView, ...response }),
          ...updateActions,
        ];

        const vcProfile = state$.value[`vcProfile${id}`];
        if (vcProfile) {
          const updateVcProfileAction = updateVcProfileInState(
            id,
            vcProfile.toJS(),
            { photo, name, country },
          );
          updateActions = [updateVcProfileAction, ...updateActions];
        }

        uidHandler(email);
        return [...updateActions];
      }),
      catchError((error) => {
        const apiError = error.response.data.errors[0];
        const { title, status } = apiError;
        if (apiError && status === HttpStatus.BAD_GATEWAY) {
          return of(updateUserProfileFailure(title));
        }
        return of(updateUserProfileFailure(UNEXPECTED_ERROR));
      }),
    ),
  )),
);

export const followUserEpic = (action$, state$) => action$.ofType(FOLLOW_USER).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    of(followUserInitiated()),
    from(
      followUserAPI(action.id, generateAuthHeaderFromStore(state$.value)),
    ).pipe(
      mergeMap((response) => {
        const { followed, action } = response.data;
        let followActions = [followUserSuccess()];
        const followed_by_user = action === 'follow';

        const vcProfile = state$.value[`vcProfile${followed.id}`];
        if (vcProfile) {
          const updateVcProfileAction = updateVcProfileInState(
            followed.id,
            vcProfile.toJS(),
            { followed_by_user },
          );
          followActions = [updateVcProfileAction, ...followActions];
        }

        const userProfile = state$.value.profileView.get('userView');
        if (!userProfile.isEmpty()) {
          const userView = userProfile.toJS();
          userView.followedByUser = followed_by_user;
          followActions = [updateUserView(userView), ...followActions];
        }

        return [...followActions];
      }),
      catchError((error) => {
        console.error(error);
        return of(followUserFailure());
      }),
    ),
  )),
);

export const loadSelectedVcEpic = (action$, state$) => action$.ofType(LOAD_SELECTED_VC).pipe(
  mergeMap((action) => {
    if (!state$.value[`visualConversation${action.vcId}`]) {
      return concat(
        createVCDynamicReducers$(action.vcId, state$.value),
        getSpecificConversationTree$(
          { vcId: action.vcId, fetchId: action.activityId },
          state$.value,
        ),
        of(toggleExpansionPanel(action.vcId, true)),
        of(setSelectedDrawing(action.activityId)),
        of(setSelectedVc(action.vcId)),
        of(updateVcConversationBranchActivity(action.vcId, action.activityId)),
      );
    }
    return concat(
      getSpecificConversationTree$(
        { vcId: action.vcId, fetchId: action.activityId },
        state$.value,
      ),
      of(toggleExpansionPanel(action.vcId, true)),
      of(setSelectedDrawing(action.activityId)),
      of(setSelectedVc(action.vcId)),
      of(updateVcConversationBranchActivity(action.vcId, action.activityId)),
    );
  }),
);
