import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import './blogsMenu.scss';

class SectionDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      anchorEl: null,
      blogSectionValue: props.selectedSection ? props.selectedSection : 'blog\'s sections',
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.triggerSectionInputChange = this.triggerSectionInputChange.bind(this);
    this.onMenuSelectHandler = this.onMenuSelectHandler.bind(this);
  }

  onMenuSelectHandler(section) {
    this.setState({ anchorEl: null, blogSectionValue: section });
    this.triggerSectionInputChange(section);
  }

  getSections(section) {
    const sections = [];
    const { blogSectionValue } = this.state;
    for (let i = section.length; i >= 0; i--) {
      sections.push(
        <MenuItem
          key={i}
          onClick={() => this.onMenuSelectHandler(section[i])}
          className={blogSectionValue === i ? 'selectedItem' : ''}
        >
          <span>{section[i]}</span>

          {i === section && <span className="lineIcon" />}
        </MenuItem>,
      );
    }
    return sections;
  }


  handleClose() {
    this.setState({ anchorEl: null });
  }

  triggerSectionInputChange(section) {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
    nativeInputValueSetter.call(this.myRef.current, section);
    this.myRef.current.dispatchEvent(new window.Event('input', { bubbles: true }));
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  render() {
    const { anchorEl, blogSectionValue } = this.state;
    const {
      handleChange, blogsSection,
    } = this.props;
    return (
      <div className="customLevelWrap">
        <div>
          <Button
            aria-owns={anchorEl ? 'simple-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className="levelBtn"
          >
            <span className="selectedLevel">
              {blogSectionValue}
            </span>
            <span className="lineIcon" />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            className="LevelDropdown"
            PopoverClasses={{
              paper: 'LevelDropdownContent',
            }}
          >

            {this.getSections(blogsSection || [])}
          </Menu>
          <input
            name="section"
            value={blogSectionValue}
            onChange={handleChange}
            ref={this.myRef}
            className="dNone"
          />
        </div>
      </div>
    );
  }
}
export default SectionDropdown;
