import { fromJS } from 'immutable';
import {
  AUTHENTICATION_REQUEST_FAILED, AUTHENTICATION_REQUEST_INITIATED,
  AUTHENTICATION_SUCCESS, NOT_AUTHENTICATED,
  SAVE_AUTHENTICATED_TOKEN,
  SAVE_AUTHENTICATED_USER, UPDATE_AUTHENTICATED_TOKENS, UPDATE_AUTHENTICATED_USER,
  UNAUTHENTICATE,
} from './actions';

const authenticationTokensInitialState = fromJS({
  accessToken: null,
  uid: null,
  client: null,
});

export const authenticationTokens = (state = authenticationTokensInitialState, action) => {
  switch (action.type) {
    case SAVE_AUTHENTICATED_TOKEN:
    case AUTHENTICATION_SUCCESS:
      return fromJS(action.data);
    case UPDATE_AUTHENTICATED_TOKENS:
      return state.merge({
        uid: action.uid,
      });
    case UNAUTHENTICATE:
      return authenticationTokensInitialState;
    default:
      return state;
  }
};

const authenticatedUserDetailsInitialState = fromJS({
  userId: null,
  email: null,
  name: null,
  photo: null,
  level: null,
  isCurator: false,
  is_blogger: false,
});

export const authenticatedUserDetails = (state = authenticatedUserDetailsInitialState, action) => {
  switch (action.type) {
    case SAVE_AUTHENTICATED_USER:
    case AUTHENTICATION_SUCCESS:
      return state.merge({
        userId: action.data.userId,
        email: action.data.email,
        name: action.data.name,
        photo: action.data.photo,
        level: action.data.level,
        isCurator: action.data.is_curator,
        is_blogger: action.data.is_blogger,
      });
    case UPDATE_AUTHENTICATED_USER:
      return state.merge({
        email: action.email,
        name: action.name,
        photo: action.photo,
      });
    case UNAUTHENTICATE:
      return authenticatedUserDetailsInitialState;
    default:
      return state;
  }
};

const authenticationStatusInitialState = fromJS({
  isRequesting: true,
  isAuthenticated: false,
  initiallyAuthenticated: false,
});

export const authenticationStatus = (state = authenticationStatusInitialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_REQUEST_INITIATED:
      return state.merge({ isRequesting: true, isAuthenticated: false });
    case AUTHENTICATION_SUCCESS:
      return state.merge({ isRequesting: false, isAuthenticated: true });
    case AUTHENTICATION_REQUEST_FAILED:
    case NOT_AUTHENTICATED:
    case UNAUTHENTICATE:
      return state.merge({
        isRequesting: false,
        isAuthenticated: false,
      });
    default:
      return state;
  }
};
