import "./application.css";
import "./spectrum_1.8.0.css";

// For Edge
require('canvas-toBlob');

global.dadaCanvas = {};
require('./libraries/focusable.js');
require('./libraries/draggable.js');
require('./libraries/slider.js');
require('./libraries/spectrum.js');
dadaCanvas._ = require('./libraries/underscore.min.js');
require('./libraries/kinetic.js');
require('./src/round-brush.js');
require('./src/eraser.js');
require('./src/eyedropper.js');
require('./src/flat-brush.js');
require('./src/marker.js');
require('./src/pencil.js');
require('./libraries/rainbowvis.js');
require('./src/sketchpad-storage.js');
require('./src/sketchpad-kinetic.js');
require('./src/canvas.js');

function getBackgroundColor() {
    return dadaCanvas.SketchpadStorage.params.bg_color || 'rgb(255,255,255)';
}

function exportCanvasImage() {
    if(isCanvasEmpty()) {
        return;
    }
    dadaCanvas.ctx_final.globalCompositeOperation="destination-over";
    var imgData =  dadaCanvas.ctx_final.getImageData(0,0,2048,1536);
    dadaCanvas.ctx_final.fillStyle= getBackgroundColor();
    dadaCanvas.ctx_final.fillRect(0,0,2048,1536);
    var imageURI = dadaCanvas.ctx_final.canvas.toDataURL('image/png',1.0);
    dadaCanvas.ctx_final.clearRect(0,0,2048,1536);
    dadaCanvas.ctx_final.globalCompositeOperation="source-over";
    dadaCanvas.ctx_final.putImageData(imgData,0,0);
    return imageURI;
}
dadaCanvas.exportCanvasImage = exportCanvasImage;

function exportCanvasImageAndBackground() {
    if (isCanvasEmpty()) {
        return;
    }

    var imageURI = dadaCanvas.ctx_final.canvas.toDataURL('image/png', 1.0);
    return {
        imageURI: imageURI,
        backgroundColor: getBackgroundColor()
    };
}

dadaCanvas.exportCanvasImageAndBackground = exportCanvasImageAndBackground;

function exportCanvasBlobAndBackground() {
    return new Promise(function (resolve, reject) {
        if (isCanvasEmpty()) {
            reject();
        }
        dadaCanvas.ctx_final.canvas.toBlob(function (blob) {
            resolve({
                blob: blob,
                backgroundColor: getBackgroundColor()
            });
        });
    });
}
dadaCanvas.exportCanvasBlobAndBackground = exportCanvasBlobAndBackground;

function exportCanvasBlob() {
    return new Promise(function(resolve, reject) {
        if(isCanvasEmpty()) {
            reject();
        }
        dadaCanvas.ctx_final.globalCompositeOperation="destination-over";
        var imgData =  dadaCanvas.ctx_final.getImageData(0,0,2048,1536);
        dadaCanvas.ctx_final.fillStyle= getBackgroundColor();
        dadaCanvas.ctx_final.fillRect(0,0,2048,1536);
        dadaCanvas.ctx_final.canvas.toBlob(function(blob) {
            dadaCanvas.ctx_final.clearRect(0,0,2048,1536);
            dadaCanvas.ctx_final.globalCompositeOperation="source-over";
            dadaCanvas.ctx_final.putImageData(imgData,0,0);
            resolve(blob);
        });
    });
}

dadaCanvas.exportCanvasBlob = exportCanvasBlob;

function isCanvasEmpty() {
    var imageURI = dadaCanvas.ctx_final.canvas.toDataURL('image/png', 1.0);
    var blank = document.createElement('canvas');
    blank.width = 2048;
    blank.height = 1536;
    return imageURI == blank.toDataURL('image/png', 1.0);
}

dadaCanvas.isCanvasEmpty = isCanvasEmpty;

function importCanvasImage(imgData, backgroundColor) {

    $.each($canvas.getChildren(), function (index, canvas_layer) {
        if (index == 1) {
            dadaCanvas.bg_color({
                rgba: backgroundColor
            }, $canvas);
            dadaCanvas.ctx = canvas_layer.getContext()._context;
        }
    });
    $.each($canvas_final.getChildren(), function (index, canvas_layer) {
        if (index == 1) {
            dadaCanvas.bg_color({
                rgba: backgroundColor
            }, $canvas_final);
            dadaCanvas.ctx_final = canvas_layer.getContext()._context;
        }
    });

    var myImage = new Image();
    myImage.src = imgData;
    myImage.onload = function () {
        dadaCanvas.erase(false);

        dadaCanvas.ctx.drawImage(myImage, 0, 0, 800, 600);
        dadaCanvas.ctx_final.drawImage(myImage, 0, 0, 2048, 1536, 0, 0, 800, 600);
        dadaCanvas.SketchpadStorage.params.bg_color = backgroundColor;
    }

}

dadaCanvas.importCanvasImage = importCanvasImage;

function applyBackgroundOnImage(imageData, backgroundColor) {
    return new Promise(function (resolve, reject) {
        var canvas = document.createElement('canvas'),
            canvas_ctx = canvas.getContext('2d'),
            myImage = new Image(),
            canvasWidth = 2048,
            canvasHeight = 1536;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        canvas_ctx.globalCompositeOperation="destination-over";

        canvas_ctx.fillStyle= backgroundColor;
        canvas_ctx.fillRect(0,0,canvasWidth,canvasHeight);

        canvas_ctx.globalCompositeOperation="source-over";

        myImage.src = imageData;
        myImage.onload = function () {
            canvas_ctx.drawImage(myImage, 0, 0, canvasWidth, canvasHeight);
            resolve(canvas_ctx.canvas.toDataURL('image/png',1.0))
        };

        myImage.onerror = function () {
            reject({ src: this.src, code: 404 })
        }
    })
}

dadaCanvas.applyBackgroundOnImage= applyBackgroundOnImage;

$(document).on('click', "i.close_slider", function() {
    $("#show_brush_size_slider").parent().addClass('hidden');
    $("#show_brush_size_slider").addClass('hide');
});

$(document).on('click', "i.close_alpha_slider", function() {
    $("#show_alpha_slider").parent().addClass('hidden');
    $("#show_alpha_slider").addClass('hide');
});
