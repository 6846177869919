import cloneDeep from 'lodash/cloneDeep';

// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import {
  throttleTime, mergeMap, catchError, debounceTime,
} from 'rxjs/operators';

// Project Imports
import { putRequestForEditVcDetails } from '../../api/index';
import {
  GET_CONVERSATION_TREE,
  getConversationTreeFailure,
  getConversationTreeInitiated,
  getConversationTreeSuccess,
  LIKE_ACTIVITY,
  likeActivityFailure,
  likeActivityInitiated,
  likeActivitySuccess,
  updateVc,
  EDIT_VC_DETAILS,
  editVcInitiated,
  editVcSuccess,
  editVcFailiure,
  curatorActivityInitiated, CURATOR_ACTIVITY, curatorActivitySuccess, curatorActivityFailure,
  updateActiveBranchData,
  updateVcConversationBranchActivity,
} from './actions';
import {
  ACTIVITIES_CURATIONS_ENDPOINT,
  ACTIVITIES_LIKE_TOGGLE_ENDPOINT,
  CONVERSATION_TREES_ENDPOINT_BY_ID,
} from '../../constants';
import { generateAuthHeaderFromStore } from '../../utils/authUtils';
import {
  controlCollapsedState,
  conversationTreesResponseParser, findNodesByName,
  generateTreeData, visualConversationStore,
} from '../../utils/vcUtils';
import createProfileReducer from '../VCProfile/reducerCreator';
import attachDynamicReducers from '../../reducers/reducerInjector';
import { saveVCProfile } from '../VCProfile/actions';
import { getRequest } from '../../utils/apiRequestUtils';

export const getSpecificConversationTree$ = ({ vcId, fetchId }, state) => of(null).pipe(
  mergeMap(() => concat(
    of(getConversationTreeInitiated(vcId)),
    from(getRequest(CONVERSATION_TREES_ENDPOINT_BY_ID(fetchId),
      generateAuthHeaderFromStore(state))).pipe(
      mergeMap((response) => {
        const { nodes, profiles } = conversationTreesResponseParser(response.data);
        const profileReducers = {};
        profiles.forEach((profile) => {
          profileReducers[`vcProfile${profile.id}`] = createProfileReducer(profile.id);
        });
        attachDynamicReducers({ ...profileReducers });
        const profileActions = profiles.map(profile => saveVCProfile(profile.id, profile));
        const parsedTree = generateTreeData(nodes, 0);
        const parsedCollapsedTree = controlCollapsedState(parsedTree, parsedTree.name);

        return [
          ...profileActions,
          getConversationTreeSuccess(vcId, parsedCollapsedTree, parsedCollapsedTree.activeTrail),
          updateVcConversationBranchActivity(vcId, fetchId),
        ];
      }),
      catchError((error) => {
        console.error(error);
        return of(getConversationTreeFailure(vcId));
      }),
    ),
  )),
);

export const getSpecificConversationTreeEpic = (
  action$,
  state$,
) => action$.ofType(GET_CONVERSATION_TREE).pipe(
  throttleTime(300),
  mergeMap(action => getSpecificConversationTree$({ vcId: action.id, fetchId: action.lastIndexId }, state$.value)),
);

export const likeActivityEpic = (action$, state$, { postRequest }) => action$.ofType(LIKE_ACTIVITY).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    of(likeActivityInitiated(action.vcId)),
    from(postRequest(
      ACTIVITIES_LIKE_TOGGLE_ENDPOINT(action.activityId), {}, generateAuthHeaderFromStore(state$.value),
    )).pipe(
      mergeMap((response) => {
        const isLiked = response.data.data.attributes.action === 'liked';
        const { activityId, vcId } = action;
        let likes_count = action.likesCount;
        let liked_by_user = false;
        if (isLiked) {
          likes_count += 1;
          liked_by_user = true;
        } else {
          likes_count -= 1;
          liked_by_user = false;
        }

        const activeTrail = state$.value[visualConversationStore(vcId)].get('activeTrail').toJS();
        const vcData = state$.value[visualConversationStore(vcId)].get('data').toJS();
        const data = cloneDeep([vcData]);
        const targetNode = findNodesByName(activityId, data, [])[0];
        targetNode.attributes.likes_count = likes_count;
        targetNode.attributes.liked_by_user = liked_by_user;

        return [
          updateActiveBranchData(vcId, data[0], activeTrail),
          likeActivitySuccess(vcId),
        ];
      }),
      catchError((error) => {
        console.error(error);
        return of(likeActivityFailure(action.vcId));
      }),
    ),
  )),
);

export const curatorActivityEpic = (action$, state$, { postRequest }) => action$.ofType(CURATOR_ACTIVITY).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    of(curatorActivityInitiated(action.vcId)),
    from(postRequest(
      ACTIVITIES_CURATIONS_ENDPOINT(action.activityId), {}, generateAuthHeaderFromStore(state$.value),
    )).pipe(
      mergeMap((response) => {
        const curated_by_user = response.data.data.attributes.action === 'curated';
        const { activityId, vcId } = action;


        const activeTrail = state$.value[visualConversationStore(vcId)].get('activeTrail').toJS();
        const vcData = state$.value[visualConversationStore(vcId)].get('data').toJS();
        const data = cloneDeep([vcData]);
        const targetNode = findNodesByName(activityId, data, [])[0];
        targetNode.attributes.curated_by_user = curated_by_user;

        return [
          updateActiveBranchData(vcId, data[0], activeTrail),
          curatorActivitySuccess(vcId),
        ];
      }),
      catchError((error) => {
        console.error(error);
        return of(curatorActivityFailure(action.vcId));
      }),
    ),
  )),
);

export const editVcDetails = (action$, state$) => action$.ofType(EDIT_VC_DETAILS).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    of(editVcInitiated()),
    from(putRequestForEditVcDetails(generateAuthHeaderFromStore(state$.value), action)).pipe(
      mergeMap(res => [editVcSuccess()]),
      catchError((error) => {
        console.error(error);
        return of(editVcFailiure());
      }),
    ),
  )),
);
