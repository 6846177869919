
export const OPEN_CONVERSATION_VIEW = 'OPEN_CONVERSATION_VIEW';
export const CLOSE_CONVERSATION_VIEW = 'CLOSE_CONVERSATION_VIEW';
export const GET_MODAL_VC_FEED = 'GET_MODAL_VC_FEED';
export const GET_MODAL_VC_FEED_INITIATED = 'GET_MODAL_VC_FEED_INITIATED';
export const GET_MODAL_VC_FEED_SUCCESS = 'GET_MODAL_VC_FEED_SUCCESS';
export const GET_MODAL_VC_FEED_FAILURE = 'GET_MODAL_VC_FEED_FAILURE';
export const CLEAR_MODAL_VC_FEED = 'CLEAR_MODAL_VC_FEED';


export const getModalVcFeed = params => ({ type: GET_MODAL_VC_FEED, vcId: params });
export const getModalVcFeedInitiated = () => ({ type: GET_MODAL_VC_FEED_INITIATED });
export const getModalVcFeedSuccess = list => ({
  type: GET_MODAL_VC_FEED_SUCCESS, list,
});
export const getModalVcFeedFailure = error => ({ type: GET_MODAL_VC_FEED_FAILURE, error });
export const clearModalVcFeed = error => ({ type: CLEAR_MODAL_VC_FEED });

export const openConversationView = () => ({ type: OPEN_CONVERSATION_VIEW });
export const closeConversationView = () => ({ type: CLOSE_CONVERSATION_VIEW });
