import { fromJS } from 'immutable';
import {
  RESET_RESET_PASSWORD_MSGS, REQUEST_RESET_PASSWORD_FAILURE,
  REQUEST_RESET_PASSWORD_INITIATED, RESET_PASSWORD_SUCCESS,
} from './actions';

const initialState = fromJS({
  isRequesting: false,
  success: false,
  error: null,
  successMsg: '',
});
const resetPasswordForm = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case REQUEST_RESET_PASSWORD_INITIATED:
      return state.merge({ error: null, isRequesting: true });
    case REQUEST_RESET_PASSWORD_FAILURE:
      return state.merge({ isRequesting: false, error: action.error });
    case RESET_PASSWORD_SUCCESS:
      return state.merge({
        isRequesting: false, success: true, isOpen: false, error: null, successMsg: action.msg,
      });
    case RESET_RESET_PASSWORD_MSGS:
      return state.merge({ successMsg: '', error: null });
    default:
      return state;
  }
};

export default resetPasswordForm;
