// React imports
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
// Material UI Imports
import {
  Grid, Dialog, Fade, withStyles,
} from '@material-ui/core';
import Scrollbar from 'react-scrollbars-custom';

import {
  getModalVcFeed,
  clearModalVcFeed,
  openConversationView,
  closeConversationView,
} from './actions';

import { visualConversationStore } from '../../utils/vcUtils';

import styles from './styles';
import './index.scss';

function Transition(props) {
  return <Fade {...props} />;
}
function getSortByClass(dataSet, classes) {
  if (dataSet.length === 1) {
    return classes.singleDialogPaper;
  }
  if (dataSet.length === 2) {
    return classes.doubleDialogPaper;
  }
  return classes.dialogPaper;
}

class ConversationModal extends Component {
  constructor(props) {
    super(props);

    this.displayConversations = this.displayConversations.bind(this);
    this.closeConversationWrapper = this.closeConversationWrapper.bind(this);
    this.renderConversations = this.renderConversations.bind(this);
    this.checkRoute = this.checkRoute.bind(this);
    this.slider = React.createRef();
    this.singleSliderContainer = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(({ search }, action) => {
      const { isConversationViewOpen } = this.props;
      if (isConversationViewOpen) {
        if (this.checkRoute()) {
          this.renderConversations(search);
          history.location.state = search;
        }
      }
    });
  }

  componentDidUpdate() {
    const { selectedId, list, isListEmpty } = this.props;
    if (!isListEmpty && !isEmpty(this.singleSliderContainer.current)) {
      const index = list.findIndex(elem => elem.id === +selectedId);
      const ratio = index - 1.3;
      const slideWidth = this.singleSliderContainer.current.clientWidth;
      this.slider.scrollLeft = slideWidth * ratio;
    }
  }

  displayConversations() {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    if (this.checkRoute()) {
      this.renderConversations(search);
    }
  }

  checkRoute() {
    const {
      history: {
        location: { pathname, search, state = '' },
      },
      isSearchEmpty,
    } = this.props;

    return pathname === '/conversation' && !isSearchEmpty && search !== state;
  }

  closeConversationWrapper() {
    const { history, clearModalVcFeed, statesLocation } = this.props;
    const pathname = statesLocation.previous ? statesLocation.previous : '/';
    history.push({
      pathname,
      previous: '/explore',
    });
    clearModalVcFeed();
  }

  renderConversations(search) {
    const {
      openConversationView,
      isConversationViewOpen,
      getModalVcFeed,
    } = this.props;
    if (!isConversationViewOpen) {
      openConversationView();
    }
    getModalVcFeed(search.slice(1));
  }

  render() {
    const {
      classes, list, isConversationViewOpen, isListEmpty,
    } = this.props;
    if (!isConversationViewOpen) {
      this.displayConversations();
    }

    return (
      <div className="conversationModal">
        <Dialog
          open={isConversationViewOpen}
          TransitionComponent={Transition}
          onClose={() => {
            this.closeConversationWrapper();
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          maxWidth={false}
          scroll="body"
          className="materialModalScrollFix"
          classes={{
            paper: getSortByClass(list, classes),
            container: 'vhAlignCenter',
          }}
          BackdropProps={{
            classes: {
              root: classes.backdropTada,
            },
          }}
        >
          <Scrollbar
            style={{ width: '100%' }}
            className="scrollContainer carouselScrollHorizontalTrack"
            ref={(ref) => {
              this.slider = ref;
            }}
          >
            {!isListEmpty && (
              <Grid container direction="row" wrap="nowrap">
                {list.map((elem, index) => (
                  <Grid item key={elem.id}>
                    <div className="slides" ref={this.singleSliderContainer}>
                      <div
                        style={{ backgroundImage: `url(${elem.src})` }}
                        role="button"
                        className="sliderImageVCModal"
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
          </Scrollbar>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    location: { search },
  } = state.router;
  const isConversationViewOpen = state.modalVcFeed.get(
    'isConversationViewOpen',
  );
  const isSearchEmpty = isEmpty(search);
  let list = [];
  let isRequesting = false;
  let selectedId = null;
  if (isConversationViewOpen && !isSearchEmpty) {
    const vc = state[visualConversationStore(search.slice(1))];
    list = vc.get('activeBranchData').toJS();
    isRequesting = vc.get('isTreeLoaded');
    selectedId = search.slice(1);
  }

  return {
    isConversationViewOpen,
    list,
    isListEmpty: isEmpty(list),
    isSearchEmpty,
    isRequesting,
    selectedId,
    statesLocation: state.router.location,
  };
};

const mapDispatchToProps = dispatch => ({
  getModalVcFeed: (params) => {
    dispatch(getModalVcFeed(params));
  },
  clearModalVcFeed: () => {
    dispatch(clearModalVcFeed());
  },
  openConversationView: () => {
    dispatch(openConversationView());
  },
  closeConversationView: () => {
    dispatch(closeConversationView());
  },
});

const ConversationModalWithStyles = withStyles(styles)(ConversationModal);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConversationModalWithStyles);
