/* eslint-disable max-len */
import React from 'react';
import './index.scss';

const NoActivites = (props) => {
  const { openDrawingTool, loggedIn } = props;
  return (
    <div className="noDrawingMsg">
      <p>You have no Drawings yet.</p>
      { loggedIn ? (
        <p>
          <a role="tab" tabIndex="0" onClick={() => { openDrawingTool(); }}>Start</a> or <a role="tab" tabIndex="-1">reply</a> to a conversation
        </p>
      ) : null }
    </div>
  );
};

export default NoActivites;
