import generateDefaultSchema from '../../utils/apiSchemaGenerator';

const signUpEndpointSchema = (name, email, password, password_confirmation) => (
  generateDefaultSchema('users', {
    name,
    email,
    password,
    password_confirmation,
  })
);

export default signUpEndpointSchema;
