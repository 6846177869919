import React, { Component, Fragment } from 'react';
import Chip from '@material-ui/core/Chip/Chip';
import ChipInput from 'material-ui-chip-input';
import { cloneDeep } from 'lodash';

import { isString } from '../../utils/validations';
import './index.scss';

const removeChipsComponent = (<div className="removeChips" />);

function chipComponent(value, key) {
  return (
    <h4>{value.value}</h4>
  );
}

function chipRenderer(
  data, key,
) {
  const {
    value,
    handleDelete,
  } = data;

  return (
    <div className="customInviteFriends">
      <Chip
        key={key}
        avatar={chipComponent(value, key)}
        deleteIcon={removeChipsComponent}
        className="customInviteChips"
        onDelete={handleDelete}
      />
    </div>
  );
}

const defaultState = {
  value: [],
  chipInputRepeatedError: false,
  chipInputLimitError: false,
};
class SearchText extends Component {
  constructor(props) {
    super(props);
    this.state = cloneDeep(defaultState);
    this.handleAddChip = this.handleAddChip.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.onBeforeStringAdd = this.onBeforeStringAdd.bind(this);
    this.chipInputCustomAdd = this.chipInputCustomAdd.bind(this);
    this.chipInputRef = null;
  }

  onBeforeStringAdd(userInput) {
    const { value } = this.state;
    let isRepeated = false;
    let isLimitExceed = false;
    isRepeated = value.filter(data => data.isNewUser).find(data => data.value === userInput);
    isLimitExceed = value.length > 3;
    this.setState({ chipInputRepeatedError: isRepeated, chipInputLimitError: isLimitExceed });
    return !isRepeated && !isLimitExceed;
  }

  handleAddChip(chip) {
    const { value } = this.state;
    let data = chip;
    let isDataStringValid = false;
    const isLimitExceed = 2;
    const isDataString = isString(data);

    if (isDataString) {
      isDataStringValid = this.onBeforeStringAdd(data);
    }

    if ((isDataString && !isDataStringValid) || data.isEmpty) {
      return;
    }

    if (isDataString && isDataStringValid) {
      data = { value: chip.toLowerCase(), isNewUser: true };
    } else {
      const isUserRepeated = value.filter(data => !data.isNewUser).find(data => data.id === chip.id);
      if (isUserRepeated) {
        this.setState({ chipInputRepeatedError: isUserRepeated });
        return;
      }
      if (value.length > isLimitExceed) {
        this.setState({ chipInputLimitError: true });
        return;
      }
    }

    this.setState(state => ({
      value: [...state.value, data],
      chipInputRepeatedError: false,
      chipInputLimitError: false,
    }), () => {
      this.triggerPropsOnChange();
    });
  }

  handleDeleteChip(chip, index) {
    this.setState((state) => {
      const newState = [...state.value];
      newState.splice(index, 1);
      return { value: newState, chipInputLimitError: false, chipInputRepeatedError: false };
    }, () => {
      this.triggerPropsOnChange();
    });
  }

  triggerPropsOnChange() {
    const { onValueChange } = this.props;
    const { value } = this.state;
    onValueChange(value.map(i => i.value));
  }

  chipInputCustomAdd() {
    this.handleAddChip(this.chipInputRef.value);
  }

  clearInput() {
    this.setState(defaultState);
  }

  render() {
    const {
      classes, onAdd, onDelete, chips, ref, icon, ...other
    } = this.props;

    const { value } = this.state;

    const { chipInputRepeatedError, chipInputLimitError } = this.state;
    let helperText = null;

    if (chipInputRepeatedError) {
      helperText = 'Already added !';
    }
    if (chipInputLimitError) {
      helperText = 'Maximum limit exceed !';
    }

    return (
      <Fragment>
        <div className="customChips inviteFriends">
          <ChipInput
            clearInputValueOnChange
            allowDuplicates={false}
            blurBehavior="ignore"
            chipRenderer={chipRenderer}
            onAdd={this.handleAddChip}
            placeholder="Search"
            onDelete={this.handleDeleteChip}
            error={chipInputRepeatedError}
            helperText={helperText}
            value={value}
            className="customChipsInputField"
            inputRef={(inputNode) => {
              this.chipInputRef = inputNode;
            }}
            {...other}
          />
          <button type="button" className="btn btnReset addChipBtn" onClick={this.chipInputCustomAdd}>
            {icon ? <img src={icon} alt="" /> : <span className="addBtnImg" />}
          </button>
        </div>
      </Fragment>
    );
  }
}

export default SearchText;
