(function (factory) {
    if (typeof define === "function" && define.amd) {

        // AMD. Register as an anonymous module.
        define(["jquery", "./version.js"], factory);
    } else {

        // Browser globals
        factory(jQuery);
    }
}(function ($) {

// This file is deprecated
    return $.ui.ie = !!/msie [\w.]+/.exec(navigator.userAgent.toLowerCase());
}));
