dadaCanvas.eyedropper = {
    options: {
        name: 'Eyedropper',
        id_tag: 'eyedropper',
        isBrush: false,
        x: 0,
        y: 0,
        tmp_canvas: null,
        tmp_ctx: null,
        isDestroying: false,
        circle_color: null,
        selection_mode: '1px',
        exclude_background: false,
        only_use_left_click: true,
        last_brush_button_selected: null,
        is_selecting_bg: false,
        color_already_selected: false
    },
    init: function () {
        if (this.options.circle_color == null) {
            this.options.circle_color = $('<div id = "circle_color" style="display:none;height:100px;width:100px;background:black;position:absolute;z-index:2000;"></div>');
            this.options.circle_color.appendTo($("body"));
        }
    },
    construct_canvas: function (canvas, ctx) {
        this.options.tmp_canvas = canvas;
        this.options.tmp_ctx = ctx;
        // this.options.tmp_canvas.addEventListener("mousedown", function (e) {
        //     e.preventDefault();
        //     res = 'down'
        //     // if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
        //     //   res = (e.which == 3 ? 'rdown' : res);
        //     // else if ("button" in e)  // IE, Opera
        //     //   res = (e.button == 2 ? 'rdown' : res);
        //     if (("which" in e && e.which != 3) || ("button" in e && e.button != 2))
        //         dadaCanvas.eyedropper.down(res, e);
        //     else return;
        // }, false);
        this.options.tmp_canvas.addEventListener("touchstart", function (e) {
            dadaCanvas.eyedropper.down('tdown', e);
        }, false);
        this.options.tmp_canvas.addEventListener("mousemove", function (e) {
            dadaCanvas.eyedropper.move('move', e);
        }, false);
        this.options.tmp_canvas.addEventListener("touchstart", function (e) {
            dadaCanvas.eyedropper.move('tmove', e);
        }, false);
        this.options.tmp_canvas.addEventListener("contextmenu", function (e) {
            e.preventDefault();
            return false;
        }, false);
      this.options.tmp_canvas.addEventListener("mouseenter", function (e) {
        dadaCanvas.eyedropper.enter('enter', e);
      }, false);
      // this.options.tmp_canvas.addEventListener("mouseleave", function (e) {
      //   dadaCanvas.eyedropper.leave('leave', e);
      // }, false);

    },
    set_last_brush_selected: function (is_selecting_bg) {
        this.options.last_brush_button_selected = ($('.tool-icons .button.stroke-tool.down').length ? $('.tool-icons .button.stroke-tool.down') : $('.tool-icons .button.toolbox_click.active'));
        if (this.options.last_brush_button_selected.hasClass('down')) {
            this.options.last_brush_button_selected.removeClass('down');
        } else {
            this.options.last_brush_button_selected.removeClass('active');
        }
        this.options.only_use_left_click = true;
        this.options.is_selecting_bg = is_selecting_bg;
    },
    reset_last_brush_selected: function () {
        if (this.options.last_brush_button_selected.hasClass('.stroke-tool')) {
            this.options.last_brush_button_selected.addClass('down');
        } else {
            this.options.last_brush_button_selected.addClass('active');
        }
        ;
    },
    down: function (res, e) {
        this.set_coordinates(res, e);
        var rgba = this.get_rgba_color();
        if (!this.options.color_already_selected) {
            if (!this.options.is_selecting_bg) {
                color_obj = tinycolor(rgba)
                rgb_obj = color_obj.toRgb();
                dadaCanvas.actualColor = {
                    hex: color_obj.toHexString(),
                    rgb: "rgb(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + ")",
                    rgb_obj: rgb_obj,
                    rgba: "rgba(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + "," + parseInt($('#alpha_box').val()) / 100 + ")"
                }
                $("#color_picker").spectrum("set", dadaCanvas.actualColor.rgba);
                // BEGIN - commenting in order to avoid updating the alpha control
                // current_alpha = color_obj.alpha * 100
                // $("#alpha_slider").slider("value", current_alpha);
                // $("#alpha_amount").text(current_alpha);
                // $('#alpha_box').val(current_alpha);
                // END - commenting in order to avoid updating the alpha control
            } else {
                $("#bg_color_picker").spectrum("set", rgba);
                color_obj = tinycolor(rgba)
                dadaCanvas.SketchpadStorage.params.bg_color = color_obj.toRgbString()
                rgb_obj = color_obj.toRgb();
                dadaCanvas.bg_color({
                    hex: color_obj.toHexString(),
                    rgb: "rgb(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + ")",
                    rgb_obj: rgb_obj,
                    rgba: rgba
                }, $canvas);
                dadaCanvas.bg_color({
                    hex: color_obj.toHexString(),
                    rgb: "rgb(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + ")",
                    rgb_obj: rgb_obj,
                    rgba: rgba
                }, $canvas_final);
            }
        }
        // if(this.options.last_brush_button_selected.length>0)
        //   this.options.last_brush_button_selected.click();
        // else
        //   this.reset();
    },
    up: function () {
    },
    move: function (res, e) {
        this.init();
        this.set_coordinates(res, e);
        var rgba = this.get_rgba_color();
        this.options.circle_color.css({
            left: e.pageX + 35,
            top: e.pageY - 135,
            background: rgba
        });
    },
    leave: function (res, e) {
      if (this.options.tmp_canvas) {
        this.options.isDestroying = true;
        this.options.tmp_canvas = null;
        this.options.tmp_ctx = null;
        this.options.isDestroying = false;
      }
        this.init();
        this.options.circle_color.hide();

        // this.options.circle_color = null;
    },
    enter: function (res, e) {
        this.init();
        this.options.circle_color.show();
    },
    get_rgba_color: function () {
        var pixel_data = null;
        if (this.options.tmp_ctx != null && this.options.tmp_ctx instanceof CanvasRenderingContext2D) {
            pixel_data = this.options.tmp_ctx.getImageData(this.options.x, this.options.y, 1, 1).data;
        } else {
            pixel_data = this.get_composed_layers_at_pixel().data;
        }
        return 'rgba(' + pixel_data[0] + ',' + pixel_data[1] + ',' + pixel_data[2] + ',' + this.scale_alpha(pixel_data[3]) + ')';
    },
    get_composed_layers_at_pixel: function () {
        // creating a one pixel size canvas to merge background and lines layer
        opcc = $("<canvas id='opcc' width='1' height='1'></canvas>");
        opcc_ctx = opcc[0].getContext("2d");
        $.each($canvas.getChildren(), function (index, canvas_layer) {
            opcc_ctx.drawImage(canvas_layer.getCanvas()._canvas, dadaCanvas.eyedropper.options.x, dadaCanvas.eyedropper.options.y, 1, 1, 0, 0, 1, 1);
        });
        pixel = opcc_ctx.getImageData(0, 0, 1, 1);
        return pixel;
    },
    scale_alpha: function (alpha) {
        return alpha / 255;
    },
    set_coordinates: function (res, e) {
        dadaCanvas.actualBrush.options.x = e.pageX - $(e.currentTarget).offset().left;
        dadaCanvas.actualBrush.options.y = e.pageY - $(e.currentTarget).offset().top;
        if (res == 'tdown' || res == 'tmove') {
            dadaCanvas.actualBrush.options.x = e.targetTouches[0].pageX - $(e.currentTarget).offset().left;
            dadaCanvas.actualBrush.options.y = e.targetTouches[0].pageY - $(e.currentTarget).offset().top;
        }
    },
    reset: function () {
        this.options.color_already_selected = false;
        if (this.options.last_brush_button_selected && this.options.last_brush_button_selected.length) {
            // this.options.last_brush_button_selected.click();
            this.reset_last_brush_selected();
            this.options.last_brush_button_selected = null;
        }
        if (this.options.circle_color && this.options.circle_color.length) {
            this.options.circle_color.remove();
            this.options.circle_color = null;
        }

        if (this.options.tmp_canvas && this.options.tmp_canvas.length) {
            this.options.isDestroying = true;
            this.options.tmp_canvas = null;
            this.options.tmp_ctx = null;
            this.options.isDestroying = false;
        }
    }
}


// ###############

// down: function(){
//    bg_ctx = $canvas.find('.background')[0].getContext()._context;
//    var pixel_data = this.get_color([dadaCanvas.ctx,bg_ctx]);
//    var rgba = 'rgba(' + pixel_data[0] + ',' + pixel_data[1] + ',' + pixel_data[2] + ',' + this.scale_alpha(pixel_data[3]) + ')';
//    $("#color_picker").spectrum("set",rgba);
//    color_obj = tinycolor(rgba)
//    dadaCanvas.actualColor={
//      hex: color_obj.toHexString(),
//      rgba:rgba
//    }
//  },
//  up: function(){},
//  get_color: function(ctx_array){
//    for (var i = 0; i <= ctx_array.length - 1; i++) {
//      pixel = ctx_array[i].getImageData(this.options.x, this.options.y, 1, 1);
//      pd = pixel.data
//      if (pd[3]!=0){
//        break;
//      };
//    };
//    return pd;
//  },

// ###############

// var rgb = getAverageRGB(document.getElementById('i'));
// document.body.style.backgroundColor = 'rgb('+rgb.r+','+rgb.g+','+rgb.b+')';
// console.log(rgb.r+' '+rgb.g+' '+rgb.b);

// function getAverageRGB(imgEl) {

//     var blockSize = 5, // only visit every 5 pixels
//         defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
//         canvas = document.createElement('canvas'),
//         context = canvas.getContext && canvas.getContext('2d'),
//         data, width, height,
//         i = -4,
//         length,
//         rgb = {r:0,g:0,b:0},
//         count = 0;

//     if (!context) {
//         return defaultRGB;
//     }

//     height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
//     width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

//     context.drawImage(imgEl, 0, 0);

//     try {
//         data = context.getImageData(0, 0, width, height);
//     } catch(e) {
//         /* security error, img on diff domain */alert('x');
//         return defaultRGB;
//     }

//     length = data.data.length;

//     while ( (i += blockSize * 4) < length ) {
//         ++count;
//         rgb.r += data.data[i];
//         rgb.g += data.data[i+1];
//         rgb.b += data.data[i+2];
//     }

//     // ~~ used to floor values
//     rgb.r = ~~(rgb.r/count);
//     rgb.g = ~~(rgb.g/count);
//     rgb.b = ~~(rgb.b/count);

//     return rgb;

// }
