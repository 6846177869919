import React from 'react';
import iro from '@jaames/iro';
import './iro_colorPicker.css';

class IroColorPicker extends React.Component {
  componentDidMount() {
    const { props } = this;
    const { bgColorPick } = props;
    // create a new iro color picker and pass component props to it
    this.colorPicker = new iro.ColorPicker(this.el, {
      ...props,
      layout: !bgColorPick ? [
        {
          component: iro.ui.Wheel,
        },
        ({
          component: iro.ui.Slider,
          options: {
            sliderType: 'value',
            sliderSize: 15,
          },
        }),
        ({
          component: iro.ui.Slider,
          options: {
            sliderType: 'alpha',
            sliderSize: 15,
          },
        }),
      ] : [{
        component: iro.ui.Wheel,
      },
      ({
        component: iro.ui.Slider,
        options: {
          sliderType: 'value',
          sliderSize: 15,
        },
      })],
    });
    // call onColorChange prop whenever the color changes
    this.colorPicker.on('color:change', (color) => {
      if (props.onColorChange) props.onColorChange(color);
      if (window.dadaCanvas.previousBrushName) {
        window.dadaCanvas.setBrushType(
          window.dadaCanvas.previousBrushName,
        );
      }
    });
  }

  componentDidUpdate() {
    // if (prevProps.onColorChange !== this.props.onColorChange) {
    //   this.setColorPicker();
    // }
    // isolate color from the rest of the props
    const { color, ...colorPickerState } = this.props;
    // update color
    if (color) this.colorPicker.color.set(color);
    // push rest of the component props to the colorPicker's state
    this.colorPicker.setState(colorPickerState);
  }

  render() {
    // console.log(this.props.onColorChange);
    // eslint-disable-next-line no-return-assign
    return <div className="colorWheel" ref={el => (this.el = el)} />;
  }
}
export default IroColorPicker;
