/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { withFormik } from 'formik';

import ChipInput from 'material-ui-chip-input';
import Button from '@material-ui/core/Button/Button';
import LevelDropdown from '../../containers/DrawingTool/LevelMenu';
import { postDrawingSchema } from '../../containers/DrawingTool/schema';

import PostConfirmationModal from '../PostConfirmationModal';
import InviteFriends from '../InviteFriends';

import { searchUsers } from '../../api';
import { prepareInviteFriendsData } from '../../api/dataParsers';
import postBtn from '../../assets/images/postBtn.svg';


import './index.scss';

function SketchForm(props) {
  const [tagsInputRef, setTagsInput] = React.useState(null);
  const [inviteFriendsData, setInviteFriendsData] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [handleConfirmationDialog, setHandleConfirmationDialog] = React.useState(false);

  function onBeforeTagAdd(value) {
    return !!value;
  }
  function onPostConfirmationModal() {
    onHandleSubmit();
    setHandleConfirmationDialog(false);
  }

  function onCancelConfirmationModal() {
    const { postConfirmationModal, fetchExistingDraft, checkExistingDraftOnCancel } = props;
    if (fetchExistingDraft) {
      checkExistingDraftOnCancel();
    } else {
      postConfirmationModal();
    }
    setHandleConfirmationDialog(false);
  }


  function onTagAddHandler(newTag) {
    setTags([...tags, newTag]);
  }

  function onTagCustomAddHandler() {
    const inputValue = tagsInputRef.value;
    if (onBeforeTagAdd(inputValue)) {
      onTagAddHandler(inputValue);
    }
  }

  function onTagDeleteHandler(tag, index) {
    const { setValues, values } = props;
    const newTagValues = [...tags];

    newTagValues.splice(index, 1);

    setTags([...newTagValues]);
    setValues({
      ...values,
      tags: newTagValues,
    });
  }

  function setTagsInputRef(node) {
    setTagsInput(node);
  }
  function onDrawingSubmit() {
    if (window.dadaCanvas.isCanvasEmpty()) {
      // eslint-disable-next-line no-alert
      window.alert('your canvas is empty, plesae draw something then post');
    } else {
      setHandleConfirmationDialog(true);
    }
  }
  const {
    values, userLevel,
    handleChange,
    isReply, setFieldValue,
    isDraftAction,
    isPosting,
  } = props;
  const {
    title, level,
  } = values;
  const onHandleSubmit = () => {
    const {
      userLevel, setRequestPost, openLoginForm, postConfirmationModal,
      postDrawing, activityId, isAuthenticated, confirmPosting,
    } = props;
    if (!isAuthenticated) { // if logged out then will  enter
      setRequestPost();
      openLoginForm();
      return;
    }

    if (window.dadaCanvas.isCanvasEmpty()) {
      // eslint-disable-next-line no-alert
      window.alert('your canvas is empty, plesae draw something then post');
      return;
    }
    window.dadaCanvas.exportCanvasBlob().then((resp) => {
      const { users, emails } = prepareInviteFriendsData(inviteFriendsData);
      const payload = postDrawingSchema(activityId, title, level || userLevel, resp, emails, users, tags);
      postDrawing(payload);
    }).catch((error) => {
      postConfirmationModal();
    });
  };

  return (
    <div className="drawingCanvasControlPanel drawCanvas">
      <form>
        {!isReply ? (
          <input
            placeholder="my title..."
            name="title"
            value={title}
            maxLength="30"
            onChange={handleChange}
            className="canvasTitle"
          />
        ) : null}
        <div className="line-sep">
          {/* <img src={Line} className="line" alt="line" /> */}
        </div>

        <div className="dtInteractions">
          {!isReply ? (
            <LevelDropdown
              handleChange={handleChange}
              userLevel={userLevel}
              setFieldValue={setFieldValue}
              isBlog={false}
            />
          ) : null}
          <div>
            <InviteFriends
              dataFetcher={searchUsers}
              onValueChange={(values) => { setInviteFriendsData(values); }}
            />
          </div>
          <div className="customChips">
            <ChipInput
              value={tags}
              onAdd={onTagAddHandler}
              onDelete={onTagDeleteHandler}
              allowDuplicates={false}
              clearInputValueOnChange
              placeholder="Add Tags"
              blurBehavior="ignore"
              inputRef={setTagsInputRef}
              className="customChipsInputField"
            />
            <div className="addImg">
              <button type="button" className="btn btnReset addChipBtn" onClick={onTagCustomAddHandler}>
                <span className="addBtnImg" />
              </button>
            </div>
          </div>
          <div className="text-center">
            {
              isReply
                ? (
                  <Button
                    type="button"
                    onClick={onDrawingSubmit}
                  >
                    <img className="postBtn" alt="" src={postBtn} />
                  </Button>
                )
                : (
                  <Button
                    type="button"
                    className="btnPost"
                    onClick={onDrawingSubmit}
                  >Start a <span className="prom">visual</span> Conversation
                  </Button>
                )
            }
            <PostConfirmationModal
              open={handleConfirmationDialog}
              onPost={onPostConfirmationModal}
              onCancel={onCancelConfirmationModal}
              isDraftAction={isDraftAction}
              isPosting={isPosting}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

const PostSketchForm = withFormik(
  {
    mapPropsToValues: props => ({
      title: props.title,
      level: props.userLevel,
      tags: [],
    }),
  },
)(SketchForm);

export default PostSketchForm;
