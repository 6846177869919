import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { withWidth } from '@material-ui/core';

import LikeListModal from '../../components/LikeListModal';

class LikeListContainer extends Component {
  static preventBubbling(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  constructor(props) {
    super(props);

    this.state = {
      isDataFetching: false,
      isModalOpen: false,
      data: [],
    };

    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClick(event) {
    LikeListContainer.preventBubbling(event);

    const { isDataFetching } = this.state;
    const { identifier, dataFetcher, count } = this.props;

    if (isDataFetching || !count) {
      return;
    }

    this.setState({ isModalOpen: true, isDataFetching: true });

    dataFetcher(identifier).then((result) => {
      this.setState({ data: result, isDataFetching: false });
    }).catch((err) => {
      this.setState({ isDataFetching: false });
    });
  }

  onClose(event) {
    LikeListContainer.preventBubbling(event);

    this.setState({ isModalOpen: false, data: [] });
  }

  render() {
    const { count } = this.props;
    const { isModalOpen, data } = this.state;

    return (
      <Fragment>
        <span
          role="button"
          tabIndex="-1"
          onClick={this.onClick}
        >
          {count}
        </span>

        {isModalOpen && (
          <div onClick={LikeListContainer.preventBubbling} role="none">
            <LikeListModal
              isOpen={isModalOpen}
              onClose={this.onClose}
              data={data}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

LikeListContainer.propTypes = {
  count: PropTypes.number.isRequired,
  identifier: PropTypes.number.isRequired,
  dataFetcher: PropTypes.func.isRequired,
};

export default (withWidth()(LikeListContainer));
