import React from 'react';
import './index.scss';

const BlogLoader = () => (
  <div className="loader-box">
    <div className="loader-container">
      <span className="loader-dot" />
      <span className="loader-dot" />
      <span className="loader-dot" />
      <span className="loader-dot" />
      <span className="loader-dot" />
    </div>
  </div>
);

export default BlogLoader;
