/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for,jsx-a11y/anchor-has-content */

import React, { PureComponent } from 'react';
import Draggable from 'react-draggable';
import * as PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import expandIcon from '../../assets/images/expand-arrow-1.svg';
import DrawingCanvas from '../DrawingCanvas';
import LinearLoader from '../linearLoader';
import './index.scss';
import BrushSizeIcon from '../BrushSizeIcon';
import ColorPicker from '../ColorPicker/color_picker';
import ColorDisplay from '../ColorPicker/color_display';
import ColorPickerSelector from '../ColorPickerSelector/color_display';
import { visualConversationStore } from '../../utils/vcUtils';
import sizes from './icons';


class SeedDrawingCanvas extends PureComponent {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.ref = React.createRef();
    this.state = {
      showFGColorWheel: false,
      showBGColorWheel: false,
      FGcolor: '#fff',
      BGcolor: '#fff',
      colorPicked: '',
      colorPickedBG: '',
      isDragDisable: false,
      colorpicker: '#00FF00',
      FGcolorPickerPosition: { x: 0, y: 0 },
      BGcolorPickerPosition: { x: 0, y: 0 },
      BrushSize: 2,
      backgroundColorPick: false,
    };
  }

  componentDidMount() {
    const { onSuccessfulMount } = this.props;
    // const onSuccessfulMount = this.props.onSuccessfulMount;  //same as above
    document.addEventListener('keydown', this.handleHideDropdown, true);
    document.addEventListener('click', this.handleClickOutside, true);
    onSuccessfulMount();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleHideDropdown, true);
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  onClose = () => {
    const state = this.state;
    this.setState({
      ...state,
      showFGColorWheel: false,
      showBGColorWheel: false,
    });
    // window.dadaCanvas.actualColor.rgba = FGcolor;
    // window.dadaCanvas.setBrushType(window.dadaCanvas.previousBrushName);
  };

  FGColorWheel = () => {
    const { state, showFGColorWheel } = this.state;
    this.setState({
      ...state,
      showFGColorWheel: !showFGColorWheel,
      showBGColorWheel: false,
    });
    window.dadaCanvas.previousBrushName = window.dadaCanvas.actualBrush.options.id_tag;
  };

  BGColorWheel = () => {
    const { state, showBGColorWheel } = this.state;
    this.setState({
      ...state,
      showBGColorWheel: !showBGColorWheel,
      showFGColorWheel: false,
    });
  };

  disableDrag = (disable) => {
    const state = this.state;
    this.setState({
      ...state,
      isDragDisable: disable,
    });
  };

  dragEndFGPicker = (event, dragElement) => {
    this.setState({
      FGcolorPickerPosition: { x: dragElement.x, y: dragElement.y },
    });
  };

  dragEndBGPicker = (event, dragElement) => {
    this.setState({
      BGcolorPickerPosition: { x: dragElement.x, y: dragElement.y },
    });
  };

  assignBrush = (brush) => {
    window.dadaCanvas.previousBrushName = brush;
  }

  handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      this.onClose();
      this.disableDrag(false);
    }
  };

  handleClickOutside = (event) => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      // this.onClose();
      this.disableDrag(false);
    }
  };

  render() {
    const {
      disableSaveDraft,
      children,
      onSaveDraftClick,
      showLoader,
    } = this.props;

    const {
      FGcolor,
      BGcolor,
      BrushSize,
      isDragDisable,
      FGcolorPickerPosition,
      BGcolorPickerPosition,
      showFGColorWheel,
      showBGColorWheel,
      colorPicked,
      colorPickedBG,
    } = this.state;

    return (
      <DrawingCanvas>
        <div className="customDrawingCanvasLib">
          <section className="overlay-modal">
            <article className="overlay-modal-in">
              <section className="horscroll_modal scrollarea">
                <div className="railarea">
                  <Draggable
                    axis="both"
                    bounds=".railarea"
                    cancel="#alpha_slider, #brush_size_slider"
                  >
                    <div className="middle3 toolbox-area toolbar-vert">
                      <div className="toggleToolboxBtn">
                        <i className="fa fa-minus" />
                      </div>
                      <div className="toolbox-area-in">
                        <div className="toolbox-cover kit-logo-v">
                          <div className="toolbox custom-color">
                            <div className="tool-icons" id="toolBox">
                              <ul>
                                <li className="marker_li toolbox_click">
                                  {/* toolbox_click highlightBtn */}
                                  <div
                                    className="button button-pen stroke-tool"
                                    id="marker"
                                    onClick={() => {
                                      this.assignBrush('marker');
                                      window.dadaCanvas.setBrushType('marker');
                                      window.dadaCanvas.setBrushSize(
                                        { type: 'marker', size: BrushSize },
                                      );
                                    }
                                    }
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <figure className="icon marker" />
                                  </div>

                                  <div className="marker_size_slider size-slider">
                                    <div
                                      id="marker_slider"
                                      className="marker_slider_in size-inner"
                                    >
                                      <ul
                                        id="brush-size-list"
                                        className="index-list"
                                      >
                                        {sizes.map(item => (
                                          <li
                                            id={`marker-li-brush-size-${item.id}`}
                                            key={`brush-size-${item.id}`}
                                          >
                                            <a
                                              aria-hidden
                                              onClick={() => {
                                                window.dadaCanvas.setBrushSize(
                                                  { type: 'marker', size: item.id },
                                                );
                                                this.setState({
                                                  BrushSize: item.id,
                                                });
                                              }
                                              }
                                            >
                                              {/* <BrushSizeIcon size={size} /> */}
                                              <img
                                                src={item.src}
                                                className="bsize"
                                                key={item.id}
                                                // onClick={() => setDotFilters(item.title)}
                                                alt={item.alt}
                                              />
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                                <li className="round_brush_li toolbox_click">
                                  {/* toolbox_click brushBtn */}
                                  <div
                                    className="button button-pen"
                                    id="round_brush"
                                    onClick={() => {
                                      this.assignBrush('round_brush');

                                      window.dadaCanvas.setBrushType(
                                        'round_brush',
                                      );
                                      window.dadaCanvas.setBrushSize(
                                        { type: 'round-brush', size: BrushSize },
                                      );
                                    }
                                    }
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <figure className="icon round_brush" />
                                  </div>

                                  <div className="round_size_slider size-slider">
                                    <div
                                      id="round_brush_slider"
                                      className="round_slider_in size-inner"
                                    >
                                      <ul
                                        id="brush-size-list"
                                        className="index-list"
                                      >
                                        {sizes.map(item => (
                                          <li
                                            id={`round-brush-li-brush-size-${item.id}`}
                                            key={`brush-size-${item.id}`}
                                          >
                                            <a
                                              aria-hidden
                                              onClick={() => {
                                                window.dadaCanvas.setBrushSize(
                                                  { type: 'round-brush', size: item.id },
                                                );
                                                this.setState({
                                                  BrushSize: item.id,
                                                });
                                              }
                                              }
                                            >
                                              {/* <BrushSizeIcon size={size} /> */}
                                              <img
                                                src={item.src}
                                                className="bsize"
                                                key={item.id}
                                                // onClick={() => setDotFilters(item.title)}
                                                alt={item.alt}
                                              />
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                                <li className="flat_brush_li toolbox_click">
                                  {/* toolbox_click brushBtn */}
                                  <div
                                    className="button button-pen stroke-tool"
                                    id="flat_brush"
                                    onClick={() => {
                                      this.assignBrush('flat_brush');

                                      window.dadaCanvas.setBrushType(
                                        'flat_brush',
                                      );
                                      window.dadaCanvas.setBrushSize(
                                        { type: 'flat-brush', size: BrushSize },
                                      );
                                    }
                                    }
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <figure className="icon flat_brush" />
                                  </div>

                                  <div className="flat_size_slider size-slider">
                                    <div
                                      id="flat_brush_slider"
                                      className="flat_slider_in size-inner"
                                    >
                                      <ul
                                        id="brush-size-list"
                                        className="index-list"
                                      >
                                        {sizes.map(item => (
                                          <li
                                            id={`flat-brush-li-brush-size-${item.id}`}
                                            key={`brush-size-${item.id}`}
                                          >
                                            <a
                                              aria-hidden
                                              onClick={() => {
                                                window.dadaCanvas.setBrushSize(
                                                  { type: 'flat-brush', size: item.id },
                                                );
                                                this.setState({
                                                  BrushSize: item.id,
                                                });
                                              }
                                              }
                                            >
                                              {/* <BrushSizeIcon size={size} /> */}
                                              <img
                                                src={item.src}
                                                className="bsize"
                                                key={item.id}
                                                // onClick={() => setDotFilters(item.title)}
                                                alt={item.alt}
                                              />
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>

                                </li>
                                <li className="pencil_li toolbox_click">
                                  {/* toolbox_click pencilBtn */}
                                  <div
                                    className="button button-pen stroke-tool"
                                    id="pencil"
                                    onClick={() => {
                                      this.assignBrush('pencil');
                                      window.dadaCanvas.setBrushType('pencil');
                                    }
                                    }
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <figure className="icon pencil" />
                                  </div>
                                </li>
                                <li className="eraser_li toolbox_click">
                                  {/* toolbox_click eraserBtn */}
                                  <div
                                    className="button button-eraser stroke-tool"
                                    id="eraser"
                                    onClick={() => {
                                      this.assignBrush('eraser');

                                      window.dadaCanvas.setBrushType('eraser');

                                      window.dadaCanvas.setBrushSize(
                                        { type: 'eraser', size: BrushSize },
                                      );
                                    }
                                    }
                                    role="button"
                                    tabIndex={0}
                                  >
                                    <figure className="icon eraser" />
                                  </div>

                                  <div className="eraser_size_slider size-slider">
                                    <div
                                      id="eraser_slider"
                                      className="eraser_slider_in size-inner"
                                    >
                                      <ul
                                        id="brush-size-list"
                                        className="index-list"
                                      >
                                        {sizes.map(item => (
                                          <li
                                            id={`eraser-li-brush-size-${item.id}`}
                                            key={`brush-size-${item.id}`}
                                          >
                                            <a
                                              aria-hidden
                                              onClick={() => {
                                                window.dadaCanvas.setBrushSize(
                                                  { type: 'eraser', size: item.id },
                                                );
                                                this.setState({
                                                  BrushSize: item.id,
                                                });
                                              }
                                              }
                                            >
                                              {/* <BrushSizeIcon size={size} /> */}
                                              <img
                                                src={item.src}
                                                className="bsize"
                                                key={item.id}
                                                // onClick={() => setDotFilters(item.title)}
                                                alt={item.alt}
                                              />
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>

                                </li>

                                <li className="go_back_li">
                                  {/* undoBtn */}
                                  <div
                                    className="button button-undo"
                                    id="btn_undo"
                                  >
                                    <figure className="icon goBack" />
                                  </div>
                                </li>
                                <li className="go_forward_li">
                                  {/* redoBtn  */}
                                  <div
                                    className="button button-redo"
                                    disabled="disabled"
                                    id="btn_redo"
                                  >
                                    <figure className="icon goForward" />
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="main-alpha-tool hidden">
                              <div className="alpha-spinner-container input-group">
                                <input
                                  className="form-control"
                                  id="alpha_box"
                                  value="100"
                                />
                              </div>
                              <div className="alpha_slider">
                                <div className="alpha_slider_in hidden">
                                  <i className="fa fa-times close_alpha_slider" />
                                  <label
                                    className="btn btn-sm btn-white tooltip_target hidden"
                                    id="show_alpha_slider"
                                  >
                                    <span id="alpha_amount" />
                                  </label>
                                  <div
                                    className="fade bottom in"
                                    id="alpha_slider_container"
                                  >
                                    <div id="alpha_slider" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <ColorPicker /> */}
                            <div className="outside colorsWrap">
                              <ColorPickerSelector
                                onClick={this.FGColorWheel}
                                color={FGcolor}
                                className="colorPickerPallete"
                              />
                              <ColorPickerSelector
                                onClick={this.BGColorWheel}
                                color={BGcolor}
                                className="overlay colorBgPallete"
                              />
                            </div>
                            <div
                              className="color-picker"
                              style={{ display: 'none' }}
                            >
                              <input
                                className="color-frame color1"
                                id="color_picker"
                                style={{ display: 'none' }}
                                type="text"
                                value="#000000"
                              />
                              <input
                                className="color-frame color2"
                                id="bg_color_picker"
                                style={{ display: 'none' }}
                                type="text"
                                value="#ffffff"
                              />
                            </div>
                            <div className="clear-button">
                              <div
                                className="btn btn-block btn-sm btn-reset"
                                data-placement="bottom"
                                data-title="Clear"
                                onClick={() => window.dadaCanvas.erase(true)}
                                role="button"
                                tabIndex={0}
                              >
                                <figure className="toTrash" />
                              </div>
                            </div>
                          </div>
                          <div className="hintSave hide">
                            <div className="savingHintTextHor">
                              <span className="saveText">save</span>
                              <span className="savingText hide">saving...</span>
                            </div>
                          </div>
                          {/* <button
                            className="save-button-toolbox"
                            disabled={disableSaveDraft}
                            onClick={onSaveDraftClick}
                            type="button"
                          /> */}
                          <div className="toolbox-bg-last" />
                        </div>
                      </div>
                    </div>
                  </Draggable>

                  {showFGColorWheel && (
                    <div className="colorpick-wrap">

                      <Draggable
                        defaultPosition={FGcolorPickerPosition}
                        onStop={this.dragEndFGPicker}
                        axis="both"
                        cancel="#brush_size_slider1"
                        disabled={isDragDisable}
                      >
                        <div className="colorpick-drag" ref={this.ref}>
                          <div className="middle3 toolbox-area">
                            <div className="color-shortcut">
                              <div
                                className="color-button black"
                                onClick={() => {
                                  this.setState({ FGcolor: 'rgba(0,0,0,1)', colorPicked: 'rgba(0,0,0,1)' });
                                  window.dadaCanvas.actualColor.rgb = 'rgba(0,0,0)';
                                  window.dadaCanvas.actualColor.rgba = 'rgba(0,0,0,1)';
                                  window.dadaCanvas.actualColor.rgb_obj = {
                                    r: 0, g: 0, b: 0, a: 1,
                                  };
                                  window.dadaCanvas.actualColor.hex = '#000000';
                                }}
                              />
                              <div
                                className="color-button white"
                                onClick={() => {
                                  this.setState({ FGcolor: 'rgba(255,255,255,1)', colorPicked: 'rgba(255,255,255,1)' });
                                  window.dadaCanvas.actualColor.rgb = 'rgba(255,255,255)';
                                  window.dadaCanvas.actualColor.rgba = 'rgba(255,255,255,1)';
                                  window.dadaCanvas.actualColor.rgb_obj = {
                                    r: 255, g: 255, b: 255, a: 1,
                                  };
                                  window.dadaCanvas.actualColor.hex = '#ffffff';
                                }}
                              />
                            </div>
                            <ColorDisplay
                              disableDrag={this.disableDrag}
                              initialColor={FGcolor}
                              colorPicked={colorPicked}
                              onChange={(color) => {
                                this.setState({ FGcolor: color.rgbaString, colorPicked: color.rgbaString });
                                window.dadaCanvas.actualColor.rgb = color.rgbString;
                                window.dadaCanvas.actualColor.rgba = color.rgbaString;
                                window.dadaCanvas.actualColor.rgb_obj = color.rgba;
                                window.dadaCanvas.actualColor.hex = color.hexString;
                                if (window.dadaCanvas.previousBrushName) {
                                  window.dadaCanvas.setBrushType(
                                    window.dadaCanvas.previousBrushName,
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Draggable>
                    </div>
                  )}

                  {showBGColorWheel && (
                    <div className="colorpick-wrap">
                      <Draggable
                        defaultPosition={BGcolorPickerPosition}
                        onStop={this.dragEndBGPicker}
                        axis="both"
                        cancel="#brush_size_slider"
                        disabled={isDragDisable}
                      >
                        <div className="colorpick-drag" ref={this.ref}>
                          <div className="middle3 toolbox-area">
                            <div className="color-shortcut color-shortcut-bg">
                              <div
                                className="color-button black"
                                onClick={() => {
                                  this.setState({ BGcolor: 'rgba(0,0,0,1)', colorPickedBG: 'rgba(0,0,0,1)' });
                                }}
                              />
                              <div
                                className="color-button white"
                                onClick={() => {
                                  this.setState({ BGcolor: 'rgba(255,255,255,1)', colorPickedBG: 'rgba(255,255,255,1)' });
                                }}
                              />
                            </div>
                            <ColorDisplay
                              backgroundColorPick
                              disableDrag={this.disableDrag}
                              initialColor={BGcolor}
                              colorPicked={colorPickedBG}
                              onChange={(selected_color) => {
                                this.setState({
                                  BGcolor: selected_color.rgbaString,
                                  colorPickedBG: selected_color.rgbaString,
                                });

                                if (window.dadaCanvas.previousBrushName) {
                                  window.dadaCanvas.setBrushType(
                                    window.dadaCanvas.previousBrushName,
                                  );
                                }
                                window.dadaCanvas.setCanvasBackgroundColor(
                                  selected_color,
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Draggable>
                    </div>
                  )}

                  <div className="content-base type-height-middle3">
                    {/* complete trash // needs to be removed once cleaned from CSS */}
                    <div className="canvasLoader">
                      {showLoader && <LinearLoader scope="drawingCanvas" />}
                      {/* unable to overwrite styles, background color being applied inline through library */}
                    </div>
                    <div className="secc-sketchpad the-content">
                      {/* secc-sketchpad-in content-base type-height-middle3 sketchpad-area toolbox-cont */}
                      <div className="secc-sketchpad-in toolbox-cont">
                        {/* secc-sketchpad the-content interactionBox sketchpad-area */}
                        <div className="interactionBox sketchpad-area">
                          {/* sketchpad-box */}
                          <div className="sketchpad-box">
                            <div id="sketchpad_container">
                              <div className="span" id="all_canvas_container">
                                <div id="dummyCanvasContainer">
                                  <div id="background_dummy_canvas_container" />
                                </div>
                                <div
                                  className="hide"
                                  id="canvasContainer_final"
                                />
                                <div id="canvasContainer" />
                                <div
                                  className="hide"
                                  id="canvasBlankContainer"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {children}
                </div>
              </section>
            </article>
          </section>
        </div>
      </DrawingCanvas>
    );
  }
}

SeedDrawingCanvas.propTypes = {
  disableSaveDraft: PropTypes.bool.isRequired,
  onSuccessfulMount: PropTypes.func.isRequired,
  onSaveDraftClick: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
};

export default SeedDrawingCanvas;
