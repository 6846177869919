var round_brush = {
    options: {
        name: 'Round brush',
        id_tag: 'round_brush',
        strokes: 15,
        bristles: [],
        actualLineWeight: 1.5,
        lineWeight: 1.5,
        max: 1,
        min: 1,
        lineColor: '#000000',
        mutant: false,
        usePressure: true,
        isBrush: true
    },
    init: function init() {
        self = this
        this.options.bristles = [];
        for (i = 0; i < this.options.strokes; i++) {
            var ease = Math.random() * 0.05 + 0.7; //original formula Math.random() * 0.05 + 0.7
            this.options.bristles.push({
                dx: dadaCanvas.dummy.width / 2,
                dy: dadaCanvas.dummy.height / 2,
                transition_x: 0,  //ax
                transition_y: 0,  //ay
                div: 0.1, //original value = 0.1
                ease: ease
            });
        }
        this.calcBrushSize(parseInt($("#brush_size_slider").slider("value")));
        this.setBrushColor(dadaCanvas.actualColor.rgba);

        // Set in dadaCanvas.dummy
        dadaCanvas.ctx_dummy.lineJoin = "round";
        dadaCanvas.ctx_dummy.lineCap = "round";
        dadaCanvas.ctx_dummy.lineWidth = (this.options.lineWeight == 1 ? 1.5 : this.options.lineWeight);
        dadaCanvas.ctx_dummy.strokeStyle = this.options.lineColor;

        dadaCanvas._.each(dadaCanvas.ctxs, function (value, key, list) {
            value.lineJoin = "round";
            value.lineCap = "round";
            value.lineWidth = (self.options.lineWeight == 1 ? 1.5 : self.options.lineWeight);
            value.strokeStyle = self.options.lineColor;
        })
    },
    draw: function () {
        dadaCanvas.ctx_dummy.lineWidth = (this.options.lineWeight == 1 ? 1.5 : this.options.lineWeight);
        dadaCanvas._.each(dadaCanvas.ctxs, function (value, key, list) {
            value.lineWidth = (self.options.lineWeight == 1 ? 1.5 : self.options.lineWeight);
        })
        var origin_x = origin_y = transition_x = transition_y = destination_x = destination_y = 0
        for (var i = 0; i < this.options.strokes; i++) {
            origin_x = this.options.bristles[i].dx; //var dx
            origin_y = this.options.bristles[i].dy; //var dy
            transition_x = this.options.bristles[i].transition_x = (this.options.bristles[i].transition_x + (this.options.bristles[i].dx - dadaCanvas.currX) * this.options.bristles[i].div) * this.options.bristles[i].ease; //var dx1
            this.options.bristles[i].dx -= transition_x;
            destination_x = this.options.bristles[i].dx; //var dx2
            transition_y = this.options.bristles[i].transition_y = (this.options.bristles[i].transition_y + (this.options.bristles[i].dy - dadaCanvas.currY) * this.options.bristles[i].div) * this.options.bristles[i].ease; //var dy1
            this.options.bristles[i].dy -= transition_y;
            destination_y = this.options.bristles[i].dy; //var dy2

            // dadaCanvas.ctx_dummy.beginPath();
            // dadaCanvas.ctx_dummy.moveTo(origin_x, origin_y);

            current_point = {
                origin_x: origin_x, //dx: dx,
                origin_y: origin_y, //dy: dy,
                destination_x: destination_x, //x: dx2
                destination_y: destination_y, //y: dy2
                lineWidth: (this.options.lineWeight == 1 ? 1.5 : this.options.lineWeight),
                lineColor: this.options.lineColor
            };
            dadaCanvas.stroke_properties.push(current_point)

            // dadaCanvas.ctx_dummy.clearRect(0,0,dadaCanvas.dummy.width,dadaCanvas.dummy.height);
            // for (var j=1; j < dadaCanvas.stroke_properties.length; j++){
            //   dadaCanvas.ctx_dummy.moveTo(dadaCanvas.stroke_properties[j].origin_x,dadaCanvas.stroke_properties[j].origin_y)
            //   dadaCanvas.ctx_dummy.lineTo(dadaCanvas.stroke_properties[j].destination_x,dadaCanvas.stroke_properties[j].destination_y) ;
            //   // dadaCanvas.ctx.dadaCanvas.dummy.bezierCurveTo(dadaCanvas.stroke_properties[j].destination_x,dadaCanvas.stroke_properties[j].destination_y,dadaCanvas.stroke_properties[j].origin_x,dadaCanvas.stroke_properties[j].origin_y)
            // }
            // dadaCanvas.ctx_dummy.stroke();
            // dadaCanvas.ctx_dummy.closePath();

        }
        dadaCanvas.ctx_dummy.beginPath();
        dadaCanvas.ctx_dummy.clearRect(0, 0, dadaCanvas.dummy.width, dadaCanvas.dummy.height);
        for (var j = 1; j < dadaCanvas.stroke_properties.length; j++) {
            dadaCanvas.ctx_dummy.moveTo(dadaCanvas.stroke_properties[j].origin_x, dadaCanvas.stroke_properties[j].origin_y)
            dadaCanvas.ctx_dummy.lineTo(dadaCanvas.stroke_properties[j].destination_x, dadaCanvas.stroke_properties[j].destination_y);
            // dadaCanvas.ctx.dadaCanvas.dummy.bezierCurveTo(dadaCanvas.stroke_properties[j].destination_x,dadaCanvas.stroke_properties[j].destination_y,dadaCanvas.stroke_properties[j].origin_x,dadaCanvas.stroke_properties[j].origin_y)
        }
        dadaCanvas.ctx_dummy.stroke();
        dadaCanvas.ctx_dummy.closePath();

    },
    up: function () {
        dadaCanvas.ctx_dummy.clearRect(0, 0, dadaCanvas.dummy.width, dadaCanvas.dummy.height);
        pressure = 0;
        for (i = 0; i < this.options.strokes; i++) {
            this.options.bristles[i].transition_x = 0;
            this.options.bristles[i].transition_y = 0;
        }
        if (dadaCanvas.stroke_properties.length > 0) {

            if (dadaCanvas.stroke_properties.length == 1) {
                dadaCanvas._.each(dadaCanvas.ctxs, function (value, key, list) {
                    value.beginPath();
                    value.strokeStyle = dadaCanvas.actualBrush.options.lineColor;
                    value.arc(dadaCanvas.currX, dadaCanvas.currY, dadaCanvas.actualBrush.options.lineWeight / 180, 0, 1 * Math.PI);
                    value.stroke();
                })
            } else {
                dadaCanvas._.each(dadaCanvas.ctxs, function (value, key, list) {
                    value.beginPath();
                    // value.moveTo(dadaCanvas.stroke_properties[0].origin_x,dadaCanvas.stroke_properties[0].origin_y)
                    for (j = 0; j < dadaCanvas.stroke_properties.length - 1; j++) {
                        value.moveTo(dadaCanvas.stroke_properties[j].origin_x, dadaCanvas.stroke_properties[j].origin_y)
                        value.lineTo(dadaCanvas.stroke_properties[j].destination_x, dadaCanvas.stroke_properties[j].destination_y);
                    }
                    value.stroke();
                    value.closePath();
                })
            }
        }
        dadaCanvas.stroke_properties = []
    },
    mouseSpeedCalcs: function () {
    },
    calculateY: function calculateY(val) {
    },
    calculateColor: function calculateColor(val) {
        this.options.lineColor = val;
    },
    calculateLineWeightUsingPresure: function calculateLineWeightUsingPresure(pressure) {
        // return this.options.actualLineWeight-(this.options.actualLineWeight/2)+(this.options.actualLineWeight*pressure);
    },
    calcBrushSize: function calcBrushSize(size) {
        this.options.lineWeight = 0;
        for (i = 1; i <= size; i++) {
            this.options.lineWeight += i
        }
        this.options.actualLineWeight = this.options.lineWeight;
        dadaCanvas.ctx_dummy.lineWidth = (this.options.lineWeight == 1 ? 1.5 : this.options.lineWeight);
        dadaCanvas.ctx.lineWidth = (this.options.lineWeight == 1 ? 1.5 : this.options.lineWeight);
        dadaCanvas.ctx_final.lineWidth = (this.options.lineWeight == 1 ? 1.5 : this.options.lineWeight);
    },
    setBrushColor: function setBrushColor(bcolor) {
        this.options.lineColor = bcolor;
        dadaCanvas.ctx_dummy.strokeStyle = this.options.lineColor;
        dadaCanvas.ctx.strokeStyle = this.options.lineColor;
        dadaCanvas.ctx_final.strokeStyle = this.options.lineColor;
    },
    is_mutant: function () {
        return this.options.mutant;
    }

}

dadaCanvas.round_brush = round_brush;
