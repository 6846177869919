import size1 from '../../assets/images/sizes/size1.svg';
import size2 from '../../assets/images/sizes/size2.svg';
import size3 from '../../assets/images/sizes/size3.svg';
import size4 from '../../assets/images/sizes/size4.svg';
import size5 from '../../assets/images/sizes/size5.svg';
import size6 from '../../assets/images/sizes/size6.svg';
import size7 from '../../assets/images/sizes/size7.svg';

export const sizes = [
  {
    id: 1,
    src: size2,
    value: '1',
    title: 'value1',
    alt: 'value1',
  },
  {
    id: 3,
    src: size3,
    value: '3',
    title: 'value3',
    alt: 'value3',
  },
  {
    id: 5,
    src: size5,
    value: '5',
    title: 'value5',
    alt: 'value5',
  },
  {
    id: 6,
    src: size6,
    value: '6',
    title: 'value6',
    alt: 'value6',
  },
  {
    id: 7,
    src: size7,
    value: '7',
    title: 'value7',
    alt: 'value7',
  },
];
export default sizes;
