import { fromJS } from 'immutable';
// Project imports
import {
  OPEN_PROFILE_VIEW, CLOSE_PROFILE_VIEW, USER_INFO_SUCCESS,
  USER_ACTIVITIES_SUCCESS, USER_ACTIVITIES_INITIATED,
  FOLLOW_USER,
  SET_SELECTED_VC,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_INITIATED,
  UPDATE_USER_PROFILE_FAILURE,
  FOLLOW_USER_INITIATED,
  FOLLOW_USER_SUCCESS,
  FOLLOW_USER_FAILURE, CLEAR_USER_PROFILE, UPDATE_USER_VIEW,
  SET_SELECTED_DRAWING, RESET_UPDATE_PROFILE_MESSAGES, USER_INFO_FAILURE,
  RESET_USER_ACTIVITIES, RESET_NEXT_PAGE,
} from './actions';

const initialState = {
  isProfileViewOpen: false,
  userView: {},
  userActivities: [],
  nextPage: 1,
  hasNext: false,
  userActivitiesInitiated: false,
  userEmail: null,
  updatingUserProfile: false,
  followUserRequestInitiated: false,
  error: null,
  selectedId: 0,
  selectedVc: null,
  successfulUpdate: false,
  invalidUser: false,
};

const profileView = (
  state = fromJS(initialState),
  action,
) => {
  switch (action.type) {
    case OPEN_PROFILE_VIEW:
      return state.merge({
        isProfileViewOpen: true,
      });
    case CLOSE_PROFILE_VIEW:
      return state.merge({
        isProfileViewOpen: false,
      });
    case USER_INFO_SUCCESS:
      return state.merge({
        userView: action.userView,
      });
    case USER_ACTIVITIES_SUCCESS:
      return state.merge({
        userActivities: [...state.get('userActivities').toJS(), ...action.response.activities],
        hasNext: action.response.hasNext,
        nextPage: action.response.nextPage,
      });
    case USER_ACTIVITIES_INITIATED:
      return state.merge({
        userActivitiesInitiated: !state.get('userActivitiesInitiated'),
      });
    case UPDATE_USER_PROFILE_INITIATED:
      return state.merge({
        updatingUserProfile: true,
      });
    case UPDATE_USER_PROFILE_SUCCESS:
      return state.merge({
        updatingUserProfile: false, successfulUpdate: true,
      });
    case UPDATE_USER_PROFILE_FAILURE:
      return state.merge({
        updatingUserProfile: false, error: action.error,
      });
    case UPDATE_USER_VIEW:
      return state.merge({
        userView: action.updatedUserView,
      });
    case FOLLOW_USER_INITIATED:
      return state.merge({
        followUserRequestInitiated: true,
      });
    case FOLLOW_USER_SUCCESS:
      return state.merge({
        followUserRequestInitiated: false,
      });
    case FOLLOW_USER_FAILURE:
      return state.merge({
        followUserRequestInitiated: false,
      });
    case SET_SELECTED_VC:
      return state.merge({
        selectedVc: action.vcId || null,
      });
    case SET_SELECTED_DRAWING:
      return state.merge({
        selectedDrawing: action.activity,
      });
    case RESET_UPDATE_PROFILE_MESSAGES:
      return state.merge({
        error: null, successfulUpdate: false,
      });
    case RESET_USER_ACTIVITIES:
      return state.merge({ userActivities: [] });
    case CLEAR_USER_PROFILE:
      return fromJS(initialState);
    case USER_INFO_FAILURE:
      return state.merge({ invalidUser: true });
    case RESET_NEXT_PAGE:
      return state.merge({ nextPage: 1 });
    default:
      return state;
  }
};

export default profileView;
