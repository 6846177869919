import { fromJS } from 'immutable';
// Proect  imports
import {
  REQUEST_LOGIN_FAILURE, REQUEST_LOGIN_INITIATED, REQUEST_LOGIN_SUCCESS, RESET_LOGIN_MESSAGES,
} from './actions';
import { OPEN_LOGIN_DIALOG, CLOSE_AUTH_DIALOG } from '../AuthModal/actions';
import { INTERACTION_REQUESTED } from '../VisualConversationFeed/actions';

const initialState = fromJS({
  isRequesting: false,
  success: false,
  error: null,
  successMsg: null,
  interactedVcsId: null,
});

const loginForm = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOGIN_INITIATED:
      return state.merge({ error: null, isRequesting: true, successMsg: null });
    case REQUEST_LOGIN_FAILURE:
      return state.merge({ isRequesting: false, error: action.error });
    case REQUEST_LOGIN_SUCCESS:
      return state.merge({ isRequesting: false, success: true });
    case RESET_LOGIN_MESSAGES:
      return state.merge({ error: null, successMsg: null });
    case OPEN_LOGIN_DIALOG:
      return state.merge({ successMsg: action.successMsg });
    case INTERACTION_REQUESTED:
      return state.merge({ interactedVcsId: action.vcId });
    case CLOSE_AUTH_DIALOG:
      return state.merge({ interactedVcsId: null });
    default:
      return state;
  }
};

export default loginForm;
