import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import Scroller from '../../components/CustomScroller';
import Loader from '../../components/Loader';
import ExploreProfile from '../ExploreProfile';
import { getExploreProfile } from './actions';
import './index.scss';
import styles from './styles';

class ProfileCarousel extends Component {
  constructor(props) {
    super(props);
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    const {
      loadProfiles, nextPage, isRequesting, criteria,
    } = this.props;
    if (!isRequesting && nextPage) {
      loadProfiles({ query: criteria, page: nextPage });
    }
  }

  render() {
    const { list, isRequesting, hasNext } = this.props;
    return (
      <Fragment>
        {!isEmpty(list) && (
          <div className="profile-wrap">
            <Scroller
              loading={isRequesting}
              hasMore={hasNext}
              initialLoad={false}
              loadMore={this.loadMore}
              loader={(
                <Loader
                  image={{ width: '70px', height: '70px' }}
                  content={{ width: '230px', height: '10px' }}
                />
              )}
            >
              {list.map((id, index) => (
                <ExploreProfile id={id} key={id} />
              ))}
            </Scroller>
            <hr className="feedHorizontalBar" />
          </div>
        )}
      </Fragment>
    );
  }
}

// withWidth is used so that props trigger when width changes.
const mapStateToProps = (state) => {
  const { profileCarousel } = state;
  const list = profileCarousel.get('list').toJS();
  const nextPage = profileCarousel.get('nextPage');
  const hasNext = profileCarousel.get('hasNext');
  const isRequesting = profileCarousel.get('isRequesting');
  return {
    list,
    nextPage,
    hasNext,
    isRequesting,
  };
};

const mapDispatchToProps = dispatch => ({
  loadProfiles: (params) => {
    dispatch(getExploreProfile(params));
  },
});

const ProfileCarouselWithStyles = withStyles(styles)(ProfileCarousel);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileCarouselWithStyles);
