// Rxjs imports
import { concat } from 'rxjs/observable/concat';
import { mergeMap } from 'rxjs/operators';
import { GET_MODAL_VC_FEED } from './actions';

import { getSpecificConversationTree$ } from '../VisualConversation/epics';
import { createVCDynamicReducers$ } from '../IndividualVCFeedPage/epics';

const ModalVcFeedEpic = (action$, state$) => action$.ofType(GET_MODAL_VC_FEED).pipe(
  mergeMap(
    (action) => {
      if (!state$.value[`visualConversation${action.vcId}`]) {
        return concat(
          createVCDynamicReducers$(action.vcId, state$.value),
          getSpecificConversationTree$({ vcId: action.vcId, fetchId: action.vcId }, state$.value),
        );
      }
      return concat(
        getSpecificConversationTree$({ vcId: action.vcId, fetchId: action.vcId }, state$.value),
      );
    },
  ),
);


export default ModalVcFeedEpic;
