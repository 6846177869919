/* eslint-disable max-len */
import React from 'react';
import '../../containers/VCProfile/index.scss';
import followIcon from '../../assets/images/follow.svg';
import followedIcon from '../../assets/images/followed.svg';

const FollowIcon = (props) => {
  const { followed } = props;

  return (
    <img
      src={followed ? followedIcon : followIcon}
      alt=""
      className="followIcon"
    />
  );
};

export default FollowIcon;
