import React from 'react';
import {
  Avatar, Button, Dialog, DialogContent, List, ListItem, ListItemText, withStyles,
} from '@material-ui/core';

import * as PropTypes from 'prop-types';
import styles from '../VCLightbox/styles';

function LikeListModal(props) {
  const { isOpen, onClose, data } = props;
  return (
    <Dialog
      open={isOpen}
      className="customModal likeListWrapper noBackdrop"
      classes={{
        paper: 'fullWidth',
      }}
    >
      <DialogContent className="dialogContent">
        <p className="modalTitle">
          Likes
        </p>
        <List className="userLikeList">
          {data.map(({ id, profile: { photo, name } }) => (
            <ListItem key={id}>
              <Avatar className="likeUserAvatar" src={photo} />
              <ListItemText primary={name} className="likeUserName" />
            </ListItem>
          ))
          }
        </List>
        <Button className="closeBtn" onClick={onClose} />
      </DialogContent>
    </Dialog>
  );
}

LikeListModal.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object.isRequired),
  onClose: PropTypes.func.isRequired,
};

LikeListModal.defaultProps = {
  isOpen: false,
  data: [],
};

export default withStyles(styles)(LikeListModal);
