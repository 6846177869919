import React from 'react';
import * as PropTypes from 'prop-types';
import person from '../../assets/images/person.svg';
import ReactTippyWrapper from '../ReactTippyWrapper';
import './index.scss';

export default class VCTreeBranchNode extends React.PureComponent {
  isOwnDrawing() {
    const { nodeData: { attributes: { ownerId } }, authenticatedUserDetails: { userId } } = this.props;
    return ownerId === userId;
  }

  render() {
    const {
      nodeData: {
        depthLevel,
        isActive,
        attributes: { src, displayDrawingCount },
      },
    } = this.props;

    const isOwnDrawing = this.isOwnDrawing();
    if (depthLevel === 0) {
      return null;
    }

    return (
      <div className="branchingActive">
        <div className="branchingImage" style={{ backgroundImage: `url(${src})` }} />
        <div className="countPerson">
          {isOwnDrawing
          && (
            <ReactTippyWrapper
              title="You drew this !"
              theme="branchNodeTippyWrapper"
              position="bottom"
              followCursor
              arrow
              animation="fade"
              duration="250"
              transitionFlip={false}
              popperOptions={{
                modifiers: {
                  inner: {
                    enabled: true,
                  },
                },
              }}
              size="regular"
            >
              <span className="myBranchReply">
                <img src={person} alt={person} />
              </span>
            </ReactTippyWrapper>
          )}
          {!!displayDrawingCount && (
            <span className={`branchNumber ${isOwnDrawing ? '' : 'top'}`}>{isActive ? null : displayDrawingCount}</span>
          )}
        </div>
      </div>
    );
  }
}

VCTreeBranchNode.propTypes = {
  nodeData: PropTypes.shape({
    attributes: PropTypes.shape({
      src: PropTypes.string,
    }),
  }),
  authenticatedUserDetails: PropTypes.object.isRequired,
};

VCTreeBranchNode.defaultProps = {
  nodeData: undefined,
};
