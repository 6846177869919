import React, { useEffect, useRef, useState } from 'react';
import './color_display.css';
import IroColorPicker from './iro_colorPicker';

const ColorDisplay = ({
  initialColor, onChange, disableDrag, backgroundColorPick, colorPicked, bgcolorPicked, ironWheelMouseOut, ironWheelMouseOver,
}) => {
  const [color, setColor] = useState(initialColor);
  const [mouseDown, setMouseDown] = useState(false);

  const handleDocumentMouseUp = (event) => {
    setMouseDown(false);
  };

  const handleDocumentMouseDown = (event) => {
    if (mouseDown) { return; }
    handleClickOutside(event);
    setMouseDown(true);
  };

  useEffect(() => {
    if (colorPicked) {
      setColor(colorPicked);
    }
  }, [colorPicked]);

  useEffect(() => {
    if (bgcolorPicked) {
      setColor(bgcolorPicked);
    }
  }, [bgcolorPicked]);

  const ref = useRef();

  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      disableDrag(false);
    }
  };

  const handleClickOutside = (event) => {
    const el = event.srcElement.className;
    // middle3
    // IroWheel
    // IroSliderGradient
    if (el === 'middle3') {
      disableDrag(false);
    } else if (el === 'IroWheel') {
      disableDrag(true);
    } else if (el === 'IroSliderGradient') {
      disableDrag(true);
    } else {
      disableDrag(true);
    }
    // if (ref.current && !ref.current.contains(event.target)) {
    //   disableDrag(true);
    // }
    // if (ref.current && ref.current.contains(event.target)) {
    //   disableDrag(true);
    // }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('mousedown', handleDocumentMouseDown, true);
    document.addEventListener('mouseup', handleDocumentMouseUp, true);
    document.querySelectorAll('.colorpick-drag')[0].addEventListener('mouseover', ironWheelMouseOver, true);
    document.querySelectorAll('.colorpick-drag')[0].addEventListener('mouseout', ironWheelMouseOut, true);

    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('mousedown', handleDocumentMouseDown, true);
      document.removeEventListener('mouseup', handleDocumentMouseUp, true);
      document.querySelectorAll('.colorpick-drag')[0].removeEventListener('mouseover', ironWheelMouseOver, true);
      document.querySelectorAll('.colorpick-drag')[0].removeEventListener('mouseout', ironWheelMouseOut, true);
    };
  });
  return (
    <div className="middle3" ref={ref}>
      <IroColorPicker
        color={color}
        width={180}
        onColorChange={(color) => {
          setColor(color.rgbaString);
          onChange(color);
        }}
        bgColorPick={!!backgroundColorPick}
      />
    </div>
  );
};

export default ColorDisplay;
