// React & Redux Imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
// Form validation Imports
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
// Material UI Imports
import Button from '@material-ui/core/Button/Button';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
// Project Imports
import signUpEndpointSchema from './schemas';
import styles from './styles';
import { requestSignUp, resetSignUpMessages } from './actions';
import { closeAuthDialog, openLoginForm } from '../AuthModal/actions';
// slides imports
import closeIcon from '../../assets/images/close.png';
import './index.scss';


class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.handleVisibility = this.handleVisibility.bind(this);
    this.signIn = this.signIn.bind(this);
  }

  componentWillUnmount() {
    const { resetSignUpMessages } = this.props;
    resetSignUpMessages();
  }

  handleVisibility() {
    const { setTouched, resetSignUpMessages } = this.props;
    resetSignUpMessages();
    setTouched(false);
  }

  signIn() {
    const { openLoginForm, actionToDispatch } = this.props;
    openLoginForm(actionToDispatch);
  }


  render() {
    const {
      closeAuthDialog, values, apiError, handleChange, touched, errors, classes,
      isRequesting,
    } = this.props;

    const {
      name, email, password, confirmPassword,
    } = values;

    return (
      <Fragment>
        <div className="centerAlignForm">
          <Form>
            <DialogContent className={classes.dialogContent}>

              {
                (((errors.name || errors.email || errors.password || errors.confirmPassword)
                  && (touched.name || touched.email || touched.password || touched.confirmPassword))
                  || apiError)
                && (
                  <div className="responseMsg error">
                    {(
                      errors.name || errors.email || errors.password
                      || errors.confirmPassword || apiError
                    )}
                    <Button className={classes.close} onClick={this.handleVisibility}>
                      <img src={closeIcon} alt="" />
                    </Button>
                  </div>
                )
              }
              <DialogContentText id="alert-dialog-slide-description">
                <input
                  placeholder="Name"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  className="responseMsg error"
                />
                <input
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={email}
                  className="responseMsg error"
                />
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  value={password}
                  className="responseMsg error"
                />
                <input
                  placeholder="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  onChange={handleChange}
                  value={confirmPassword}
                  className="responseMsg error"
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions
              justify="center"
              className={`${classes.aligntCenterBtn} ${classes.spacing0}`}
            >
              <Grid>
                <Button type="submit" className={classes.blackbtn} disabled={isRequesting}>
                  Join Us
                </Button>
              </Grid>
            </DialogActions>
          </Form>
        </div>
        <div className="modalFooter">
          <div>
            By signing up, you agree with Dadas
            <a href="http://blog.dada.nyc/terms-of-use/" className={classes.footerLink1}>
              Terms and conditions.
            </a>
          </div>
          <div>
            Already on Dada?
            <Button onClick={this.signIn} className={classes.footerLink1}>
              Sign in
            </Button>
            to your account.
          </div>
        </div>
        <Hidden smUp>
          <Button className="closeBtn" onClick={closeAuthDialog}>
            <Icon className={classes.icon}>close</Icon>
          </Button>
        </Hidden>
      </Fragment>
    );
  }
}
const SignUpWithFormik = () => withFormik(
  {
    mapPropsToValues: props => ({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    }),
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Name is required!'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required!'),
      password: Yup.string()
        .min(8, 'Password has to be longer than 8 characters!')
        .required('Password is required!'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords don't match")
        .required('Confirm Password is required'),
    }),
    handleSubmit: (values, { props }) => {
      const {
        email, name, password, confirmPassword,
      } = values;
      const { requestSignUp, actionToDispatch } = props;
      const payload = signUpEndpointSchema(name, email, password, confirmPassword);
      requestSignUp(actionToDispatch, payload);
    },
  },
)(SignUpForm);

SignUpForm.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  classes: PropTypes.object.isRequired,
  apiError: PropTypes.string,
  isRequesting: PropTypes.bool.isRequired,
  setTouched: PropTypes.func.isRequired,
  openLoginForm: PropTypes.func.isRequired,
  resetSignUpMessages: PropTypes.func.isRequired,
  closeAuthDialog: PropTypes.func.isRequired,
};

SignUpForm.defaultProps = {
  apiError: null,
};


const mapStateToProps = state => ({
  success: state.signUpForm.get('success'),
  isRequesting: state.signUpForm.get('isRequesting'),
  apiError: state.signUpForm.get('error'),
  actionToDispatch: state.authDialog.get('actionToDispatch'),
});

const mapDispatchToProps = dispatch => ({
  closeAuthDialog: () => dispatch(closeAuthDialog()),
  requestSignUp: (actionToDispatch, payload) => dispatch(requestSignUp(actionToDispatch, payload)),
  openLoginForm: actionToDispatch => dispatch(openLoginForm(actionToDispatch)),
  resetSignUpMessages: () => dispatch(resetSignUpMessages()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SignUpWithFormik()));
