export const GET_EXPLORE_VC_FEED = 'GET_EXPLORE_VC_FEED';
export const GET_EXPLORE_VC_FEED_BY_QUERYTYPE = 'GET_EXPLORE_VC_FEED_BY_QUERYTYPE';
export const GET_EXPLORE_VC_FEED_INITIATED = 'GET_EXPLORE_VC_FEED_INITIATED';
export const GET_EXPLORE_VC_FEED_SUCCESS = 'GET_EXPLORE_VC_FEED_SUCCESS';
export const GET_EXPLORE_VC_FEED_FAILURE = 'GET_EXPLORE_VC_FEED_FAILURE';
export const CLEAR_EXPLORE_VC_FEED_STATE = 'CLEAR_EXPLORE_VC_FEED_STATE';
export const GET_SEARCH_RESUTLS_EXPLORE_VC_FEED_SUCCESS = 'GET_SEARCH_RESUTLS_EXPLORE_VC_FEED_SUCCESS';

export const getExploreVcFeed = params => ({
  type: GET_EXPLORE_VC_FEED,
  params,
});
export const getExploreVcFeedByQueryType = (params, queryType) => ({
  type: GET_EXPLORE_VC_FEED_BY_QUERYTYPE,
  params,
  queryType,
});
export const getExploreVcFeedInitiated = () => ({
  type: GET_EXPLORE_VC_FEED_INITIATED,
});
export const getExploreVcFeedSuccess = (list, nextPage, hasNextPage) => ({
  type: GET_EXPLORE_VC_FEED_SUCCESS,
  list,
  nextPage,
  hasNextPage,
});
export const getSearchResultsExploreVcFeedSuccess = (
  list,
  nextPage,
  hasNextPage,
) => ({
  type: GET_SEARCH_RESUTLS_EXPLORE_VC_FEED_SUCCESS,
  list,
  nextPage,
  hasNextPage,
});
export const getExploreVcFeedFailure = error => ({
  type: GET_EXPLORE_VC_FEED_FAILURE,
  error,
});
export const clearExploreVcFeedState = () => ({
  type: CLEAR_EXPLORE_VC_FEED_STATE,
});
