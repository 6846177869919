// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import {
  debounceTime, map, mergeMap, catchError,
} from 'rxjs/operators';
// Other imports
import { hideLoading, showLoading } from 'react-redux-loading-bar';
// Project imports
import {
  REQUEST_FORGET_PASSWORD,
  REQUEST_FORGET_PASSWORD_SUCCESS,
  requestForgetPasswordFailure,
  requestForgetPasswordInitiated,
  requestForgetPasswordSuccess,
} from './actions';
import {
  EMAIL_NOT_FOUND,
  HttpStatus,
  REQUEST_FORGET_PASSWORD_ENDPOINT,
  UNEXPECTED_ERROR,
} from '../../constants';
import { openLoginForm } from '../AuthModal/actions';

export const resquestForgetPasswordEpic = (action$, state$, { postRequest }) => action$
  .ofType(REQUEST_FORGET_PASSWORD).pipe(
    debounceTime(300),
    mergeMap(action => concat(
      of(requestForgetPasswordInitiated()),
      of(showLoading('modalBar')),
      from(postRequest(REQUEST_FORGET_PASSWORD_ENDPOINT, {}, {}, action.params)).pipe(
        map(response => requestForgetPasswordSuccess(response.data.message)),
        catchError((error) => {
          const { response } = error;
          if (response && response.status === HttpStatus.NOT_FOUND) {
            return of(requestForgetPasswordFailure(EMAIL_NOT_FOUND));
          }
          return of(requestForgetPasswordFailure(UNEXPECTED_ERROR));
        }),
      ),
      of(hideLoading('modalBar')),
    )),
  );

export const forgetPasswordSuccessEpic = (action$, state$) => action$.ofType(REQUEST_FORGET_PASSWORD_SUCCESS).pipe(
  map(action => (openLoginForm(state$.value.authDialog.get('actionToDispatch'), action.successMsg))),
);
