/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for,jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import './index.scss';

class DrawingCanvas extends Component {
  componentDidMount() {
    window.initializeCanvas();
  }

  componentWillUnmount() {
    window.dadaCanvas.resetSketchpadVar();
  }

  render() {
    const {
      children,
    } = this.props;

    return (children);
  }
}

export default DrawingCanvas;
