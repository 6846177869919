import React, { Component } from 'react';
import {
  Button, Grid, Hidden,
} from '@material-ui/core';
import Tree from '@10pearls/react-d3-tree';
import Scrollbar from 'react-scrollbars-custom';
import * as PropTypes from 'prop-types';
import titleEditIcon from '../../assets/images/titleEditIcon.svg';
import VCTreeBranchNode from '../../components/VCTreeBranchNode';
import LevelsComponent from '../../components/LevelsComponent';
import TimeAgo from '../../components/TimeAgo';
import CollageModal from '../../components/CollageModal';
import ShareTooltip from '../../components/ShareTooltip';
import { isEnterKey } from '../../utils/validations';
import { TREE_EXTERNAL_ACTIVE, TREE_EXTERNAL_COLLAPSE } from '../../constants';

import shuffle from '../../assets/images/shuffle.svg';
import './index.scss';

const treeProps = {
  collapsible: false,
  shouldCollapseNeighborNodes: false,
  boundHeight: true,
  pathFunc: (d, orientation) => orientation
    && `M${d.source.y},${d.source.x}H${d.source.y + (d.target.y - d.source.y) / 1.25}V${d.target.x}H${d.target.y}`,
  nodeSize: { x: 180, y: 70 },
  nodeSvgShape: { shape: 'none' },
  separation: { siblings: 1, nonSiblings: 2 },
  translate: { x: -83, y: 133 },
  scaleExtent: { min: 1, max: 1 },
  zoomable: false,
  allowForeignObjects: true,
  styles: { links: { stroke: '#E9E9E9', strokeWidth: 2 } },
  transitionDuration: 500,
  externalCollapse: TREE_EXTERNAL_COLLAPSE,
  externalActive: TREE_EXTERNAL_ACTIVE,
  externalNodeStyles: true,
};

class VCBranchTree extends Component {
  constructor(props) {
    super(props);
    this.vcTitleRef = React.createRef();
    this.focusVcTitle = this.focusVcTitle.bind(this);
    const { metaData: { titleText } } = props;
    this.state = {
      isTransitioning: false,
      editableTitle: false,
      stateTitle: titleText,
      isCollageModalOpen: false,
    };

    this.nodeClickHandler = this.nodeClickHandler.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.isOwnerDrawing = this.isOwnerDrawing.bind(this);
    this.editVcTitle = this.editVcTitle.bind(this);
    this.editTitleToggle = this.editTitleToggle.bind(this);
  }

  onChangeTitle(e) {
    this.setState({ stateTitle: e.target.value });
  }

  openCollageModal() {
    this.setState({ isCollageModalOpen: true });
  }

  closeCollageModal() {
    this.setState({ isCollageModalOpen: false });
  }

  focusVcTitle() {
    this.vcTitleRef.current.focus();
    this.vcTitleRef.current.click();
  }

  isOwnerDrawing() {
    const {
      metaData: {
        ownerId, authenticatedUserDetails: { userId },
      },
    } = this.props;
    return ownerId === userId;
  }

  editTitleToggle() {
    const { editableTitle } = this.state;
    if (!editableTitle) {
      this.setState({ editableTitle: true }, () => {
        this.focusVcTitle();
      });
    } else {
      this.setState({ editableTitle: false });
    }
  }

  canShowLoading() {
    const { shouldShow, isTreeDataLoaded } = this.props;
    return shouldShow && !isTreeDataLoaded;
  }

  canShowBranch() {
    const { shouldShow, isTreeDataLoaded } = this.props;
    return shouldShow && isTreeDataLoaded;
  }

  nodeClickHandler(nodeData) {
    const { isTransitioning } = this.state;
    const { onNodeClick } = this.props;
    if (isTransitioning) {
      return;
    }

    onNodeClick(nodeData.name);

    // Lock node toggling while transition takes place
    this.setState({ isTransitioning: true });
    // Await transitionDuration + 100 ms before unlocking node toggling again
    setTimeout(
      () => this.setState({ isTransitioning: false }),
      treeProps.transitionDuration + 100,
    );
  }

  editVcTitle() {
    const { editVcDetails, metaData: { level: authLevel, id }, vcBranchStateTitle } = this.props;
    const { stateTitle: title } = this.state;
    editVcDetails(id, authLevel, title);
    vcBranchStateTitle(title);
    this.editTitleToggle();
  }

  handleKeyPress(e) {
    if (isEnterKey(e)) {
      this.editVcTitle();
    }
  }


  render() {
    const { isCollageModalOpen } = this.state;
    const {
      metaData: {
        level, last_modified_at, authenticatedUserDetails,
      },
      treeData, editVcDetailsInitiated, imgData, selectedId,
    } = this.props;
    const { name } = treeData;
    const { editableTitle, stateTitle } = this.state;
    const imgArray = imgData.map(x => (`${x.src.split('.png')[0]}.png`));
    return (
      <Grid container direction="row">
        <Grid item>
          <div className="wrap">
            <Grid container direction="column" alignContent="center" className="dirColRes">
              <Grid item>
                <Grid container direction="row" alignContent="center" className="VCBranchTitleWrapper">
                  <Grid item>
                    <input
                      type="text"
                      ref={this.vcTitleRef}
                      value={stateTitle.toLowerCase()}
                      className="VCBranchTitle"
                      disabled={!editableTitle || editVcDetailsInitiated}
                      onChange={(e) => { this.onChangeTitle(e); }}
                      onKeyPress={(e) => { this.handleKeyPress(e); }}
                      onBlur={() => { this.editVcTitle(); }}
                    />
                  </Grid>
                  {this.isOwnerDrawing()
                    ? (
                      <Grid item>
                        <Button className="editVCBranchTitle" onClick={this.editTitleToggle}>
                          <img className="editTitleImg" alt="" src={titleEditIcon} />
                        </Button>
                      </Grid>
                    ) : null}
                </Grid>
              </Grid>
              <Grid item>
                <LevelsComponent level={level} />
              </Grid>
            </Grid>
            <Hidden smDown>
              <div className="rootBranchImage" style={{ backgroundImage: `url(${treeData.attributes.src})` }} />
            </Hidden>
            <p>last updated <TimeAgo datetime={last_modified_at} /></p>
            <Grid container direction="row" alignContent="center" justify="center">


              {/* <ShareTooltip onClick={() => this.openCollageModal()} /> */}

              <Hidden smUp>
                <Button className="btnSecondary">
                  <img src={shuffle} alt={shuffle} />
                </Button>
              </Hidden>

              <div role="button" tabIndex={0} className="shareForLightbox" onClick={() => this.openCollageModal()} />
              {isCollageModalOpen && (

                <CollageModal
                  title={stateTitle}
                  openCollageModal={isCollageModalOpen}
                  onCloseCollage={() => this.closeCollageModal()}
                  activity_id={name}
                  selectedId={selectedId}
                  data={imgArray}
                />

              )}
            </Grid>
          </div>
        </Grid>
        <Grid item className="itemWidth">
          {this.canShowBranch()
            && (
              <Scrollbar
                style={{ height: '280px' }}
                className="branchingWrapper carouselScrollHorizontalTrack"
              >
                <div style={{ width: `${treeData.currentMaxDepth * treeProps.nodeSize.x}px`, height: '100%' }}>
                  <Tree
                    data={[treeData]}
                    onClick={this.nodeClickHandler}
                    nodeLabelComponent={{
                      render: <VCTreeBranchNode authenticatedUserDetails={authenticatedUserDetails} />,
                      foreignObjectWrapper: {
                        y: -25,
                        height: 45,
                        width: 90,
                      },
                    }}
                    {...treeProps}
                  />
                </div>
              </Scrollbar>
            )
          }
          {
            this.canShowLoading() && <div className="spinner"><div /><span /></div>
          }
        </Grid>
      </Grid>
    );
  }
}
VCBranchTree.propTypes = {
  metaData: PropTypes.object.isRequired,
  treeData: PropTypes.object.isRequired,
  isTreeDataLoaded: PropTypes.bool.isRequired,
  shouldShow: PropTypes.bool.isRequired,
  onNodeClick: PropTypes.func.isRequired,
  imgData: PropTypes.array,
  selectedId: PropTypes.number,
};

VCBranchTree.defaultProps = {
  selectedId: 0,
  imgData: [],
};
export default VCBranchTree;
