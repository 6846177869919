
import React from 'react';
import * as PropTypes from 'prop-types';
import './index.scss';

const LevelsComponent = (props) => {
  const { level } = props;
  const levelsArray = [...Array(level).keys()];
  return (
    <div>
      {(levelsArray.length < 4) ? (
        <ul className="userProfileLevel">
          {levelsArray.map(elem => <li key={elem} />)}
        </ul>
      ) : (
        <ul className="userMaxLevel">
          <li />
        </ul>
      )}
    </div>
  );
};

LevelsComponent.propTypes = {
  level: PropTypes.number.isRequired,
};
export default LevelsComponent;
