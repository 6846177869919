import { fromJS } from 'immutable';
import {
  GET_MODAL_VC_FEED_FAILURE,
  GET_MODAL_VC_FEED_INITIATED,
  GET_MODAL_VC_FEED_SUCCESS,
  CLEAR_MODAL_VC_FEED,
  OPEN_CONVERSATION_VIEW,
  CLOSE_CONVERSATION_VIEW,
} from './actions';

const initialState = fromJS({
  list: [],
  isRequesting: false,
  error: null,
  isConversationViewOpen: false,
});

const modalVcFeed = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CONVERSATION_VIEW:
      return state.merge({
        isConversationViewOpen: true,
      });
    case CLOSE_CONVERSATION_VIEW:
      return state.merge({
        isConversationViewOpen: false,
      });
    case GET_MODAL_VC_FEED_INITIATED:
      return state.merge({ isRequesting: true });
    case GET_MODAL_VC_FEED_SUCCESS: {
      return state.merge({
        isRequesting: false,
        error: null,
        list: action.list,
      });
    }
    case GET_MODAL_VC_FEED_FAILURE:
      return state.merge({ isRequesting: false, error: action.error });
    case CLEAR_MODAL_VC_FEED:
      return fromJS(initialState);
    default:
      return state;
  }
};

export default modalVcFeed;
