/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for,jsx-a11y/anchor-has-content */

import React, { createRef, PureComponent, useCallback } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import forEach from 'lodash/forEach';
// import './index.scss';
import { ContactsOutlined } from '@material-ui/icons';
import domtoimage from 'dom-to-image';
import { throttle } from 'lodash';
import BrushSizeIcon from '../BrushSizeIcon';
import DrawingCanvas from '../DrawingCanvas';
import ColorPickerSelector from '../ColorPickerSelector/color_display';
import ColorDisplay from '../ColorPicker/color_display';
import ColorPicker from '../ColorPicker/color_picker';
import LinearLoader from '../linearLoader';
import { visualConversationStore } from '../../utils/vcUtils';
import { sizes, eraserSizes } from './icons';
import './index.scss';

function mapStateToProps(state, { vcId, selectedId, ...rest }) {
  const vcData = state[visualConversationStore(vcId)];
  const activeBranchData = vcData.get('activeBranchData').toJS();

  const imageTrail = [];
  forEach(activeBranchData, (data) => {
    if (data.id === selectedId) {
      imageTrail.push(data.src);
      return false;
    }
    // imageTrail.push(data.src);
    return true;
  });

  return {
    ...rest,
    imageTrail,
  };
}

class ReplyDrawingCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.imageCanvas = React.createRef();
    this.scrollToRight = this.scrollToRight.bind(this);
    this.ref = React.createRef();
    this.canvasRef = React.createRef();
    this.divCircle = React.createRef();
    this.state = {
      showFGColorWheel: false,
      showBGColorWheel: false,
      FGcolor: '#fff',
      BGcolor: '#fff',
      colorPicked: '',
      bgcolorPicked: '',
      isDragDisable: false,
      colorpicker: '#00FF00',
      FGcolorPickerPosition: { x: 0, y: 0 },
      BGcolorPickerPosition: { x: 0, y: 0 },
      BrushSize: 2,
      backgroundColorPick: false,
      colorPickerActive: false,
      imageLoaded: true,
      dummy_canvas: null,
    };
  }

  componentDidMount() {
    const { onSuccessfulMount } = this.props;
    document.addEventListener('keydown', this.handleHideDropdown, true);
    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener('mousemove', this.moveMouse, true);
    onSuccessfulMount();
    this.scrollToRight();
    this.getMainCanvas();
    // this.imgToCanvas();
  }


  componentWillUnmount() {
    const { dummy_canvas } = this.state;
    // document.removeEventListener('keydown', this.handleHideDropdown, true);
    // document.removeEventListener('click', this.handleClickOutside, true);
    // document.removeEventListener('mousemove', this.moveMouse, true);
    // this.canvasRef.current.removeEventListener('click', e => this.handleColorPickerClick(e, this.canvasRef.current), true);
    // this.canvasRef.current.removeEventListener('mousemove', this.replyCanvasThrottle(), true);
    // dummy_canvas.removeEventListener('click', e => this.handleColorPickerClick(e, this.canvasRef.current), true);
    // dummy_canvas.removeEventListener('mousemove', this.dummyCanvasThrottle(), true);
    // this.canvasRef.current.addEventListener('mouseout', this.resetCanvasStyle(this.canvasRef.current), true);
    // dummy_canvas.addEventListener('mouseout', this.resetCanvasStyle(dummy_canvas), true);
  }

  ironWheelMouseOver = () => {
    this.divCircle.current.style.display = 'none';
  }

  ironWheelMouseOut = () => {
    this.divCircle.current.style.display = 'block';
  }

  replyCanvasThrottle = e => throttle((e) => {
    this.changeCircleDivColor(e, this.canvasRef.current);
  }, 300);

  dummyCanvasThrottle = e => throttle((e) => {
    const { final_canvas, dummy_canvas } = this.state;
    this.changeCircleDivColor(e, dummy_canvas, true);
  }, 300);

  getMainCanvas = () => {
    const canvas = document.querySelectorAll('#canvasContainer .kineticjs-content canvas')[1];
    // const final_canvas = document.getElementById('composed_canvas');
    // const canvas = document.getElementById('dummy_canvas');
    this.setState({
      dummy_canvas: canvas,
    });
    canvas.addEventListener('click', e => this.handleColorPickerClick(e, canvas, true), true);
    canvas.addEventListener('mousemove', this.dummyCanvasThrottle(), true);
    canvas.addEventListener('mouseout', this.resetCanvasStyle(canvas), true);
  }

  resetCanvasStyle = (canvas) => {
  }

  toggleColorPickerActive = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const state = this.state;
    this.setState({
      ...state,
      colorPickerActive: !state.colorPickerActive,
    });
  }

  moveMouse = async (e) => {
    if (!this.divCircle.current) { return; }
    this.divCircle.current.style.left = `${(e.clientX - 25)}px`;
    this.divCircle.current.style.top = `${(e.clientY - 25)}px`;
  }

  imgToCanvas = async () => {
    const dom = await domtoimage.toJpeg(this.imageCanvas.current, { quality: 1, cacheBust: true });
    const state = this.state;
    this.setState({
      ...state,
      imageLoaded: false,
    });
    const canvas = this.canvasRef.current;
    const context = canvas.getContext('2d');

    const dummyImage = new Image();
    // eslint-disable-next-line func-names
    dummyImage.onload = function () {
      context.drawImage(dummyImage, 0, 0, canvas.width, canvas.height);
    };
    dummyImage.src = dom;

    canvas.addEventListener('click', e => this.handleColorPickerClick(e, canvas), true);
    canvas.addEventListener('mousemove', this.replyCanvasThrottle(), true);
    canvas.addEventListener('mouseout', this.resetCanvasStyle(canvas), true);


    // };
  };


  getMousePos = (canvas, evt) => {
    const rect = canvas.getBoundingClientRect();
    return {
      x: (evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width,
      y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height,
    };
  }

  changeCircleDivColor = (e, canvasObj, finalCanvas = null) => {
    if (!this.divCircle.current) { return; }
    try {
      const mousePos = this.getMousePos(canvasObj, e);
      let ctx = null;
      let imageData = null;
      let rgbaColor;
      if (finalCanvas) {
        imageData = this.composeCanvas(mousePos.x, mousePos.y);
        rgbaColor = imageData;
        //  ctx.getImageData(mousePos.x, mousePos.y, 1, 1).data;
      } else {
        ctx = canvasObj.getContext('2d');
        imageData = ctx.getImageData(mousePos.x, mousePos.y, 1, 1).data;
        rgbaColor = `rgba(${imageData[0]},${imageData[1]},${imageData[2]},1)`;
      }
      this.divCircle.current.style.backgroundColor = rgbaColor;
    } catch (err) {
      //
    }
  }


  handleColorPickerClick = (e, canvasObj, finalCanvas = null) => {
    if (!this.divCircle.current) { return; }
    // debugger
    // finalCanvas.show();
    const { showFGColorWheel, showBGColorWheel } = this.state;
    const mousePos = this.getMousePos(canvasObj, e);
    let ctx = null;
    let imageData = null;
    let rgbaColor;
    if (finalCanvas) {
      imageData = this.composeCanvas(mousePos.x, mousePos.y);
      rgbaColor = imageData;
      //  ctx.getImageData(mousePos.x, mousePos.y, 1, 1).data;
    } else {
      ctx = canvasObj.getContext('2d');
      imageData = ctx.getImageData(mousePos.x, mousePos.y, 1, 1).data;
      rgbaColor = `rgba(${imageData[0]},${imageData[1]},${imageData[2]},1)`;
    }
    // const hex = `#${(`000000${this.rgbToHex(imageData[0], imageData[1], imageData[2])}`).slice(-6)}`;
    if (showFGColorWheel) {
      this.setState({
        colorPicked: rgbaColor,
        FGcolor: rgbaColor,
      });
      // eslint-disable-next-line no-param-reassign
      window.dadaCanvas.actualColor.rgb = rgbaColor;
      window.dadaCanvas.actualColor.rgba = rgbaColor;
      if (window.dadaCanvas.previousBrushName) {
        window.dadaCanvas.setBrushType(
          window.dadaCanvas.previousBrushName,
        );
      }
      this.FGColorWheel();
    }
    if (showBGColorWheel) {
      this.setState({
        bgcolorPicked: rgbaColor,
        FGcolor: rgbaColor,
      });
      this.BGColorWheel();
    }
    // }
    // window.dadaCanvas.actualColor.rgb_obj = color.rgba;
    // window.dadaCanvas.actualColor.hex = color.hexString;
  }

  rgbToHex = (r, g, b) => {
    // eslint-disable-next-line no-throw-literal
    if (r > 255 || g > 255 || b > 255) { throw 'Invalid color component'; }
    // eslint-disable-next-line no-bitwise
    return ((r << 16) | (g << 8) | b).toString(16);
  }

  onClose = () => {
    const state = this.state;
    this.setState({
      ...state,
      showFGColorWheel: false,
      showBGColorWheel: false,
    });
    // window.dadaCanvas.actualColor.rgba = FGcolor;
    // window.dadaCanvas.setBrushType(window.dadaCanvas.previousBrushName);
  };

  composeCanvas = (xPosition, yPosition) => {
    const opcc = document.createElement('CANVAS');
    opcc.id = 'opcc';
    opcc.height = 1;
    opcc.width = 1;
    const opcc_ctx = opcc.getContext('2d');
    const backgroundCanvas = document.querySelectorAll('#canvasContainer .kineticjs-content canvas')[0];
    const foregroundCanvas = document.querySelectorAll('#canvasContainer .kineticjs-content canvas')[1];
    opcc_ctx.drawImage(backgroundCanvas, xPosition, yPosition, 1, 1, 0, 0, 1, 1);
    opcc_ctx.drawImage(foregroundCanvas, xPosition, yPosition, 1, 1, 0, 0, 1, 1);
    const pixel = opcc_ctx.getImageData(0, 0, 1, 1);
    const rgba = `rgba(${pixel.data[0]},${pixel.data[1]},${pixel.data[2]},1`;
    return rgba;
  }

  FGColorWheel = () => {
    const { state, showFGColorWheel, dummy_canvas } = this.state;

    this.setState({
      ...state,
      showFGColorWheel: !showFGColorWheel,
      showBGColorWheel: false,
    });
    // this.toggleColorPickerActive();
    window.dadaCanvas.previousBrushName = window.dadaCanvas.actualBrush.options.id_tag;
    if (showFGColorWheel) {
      dummy_canvas.style.zIndex = '';
      // window.dadaCanvas.setBrushType('eyedropper');
      dummy_canvas.classList.remove('iroWheelCanvasPicker');
    } else {
      // window.dadaCanvas.setBrushType('eyedropper');
      dummy_canvas.style.zIndex = '999';
      dummy_canvas.classList.add('iroWheelCanvasPicker');
    }
  };

  BGColorWheel = () => {
    const { state, showBGColorWheel, dummy_canvas } = this.state;
    this.setState({
      ...state,
      showBGColorWheel: !showBGColorWheel,
      showFGColorWheel: false,
    });
    if (showBGColorWheel) {
      dummy_canvas.style.zIndex = '';
      dummy_canvas.classList.remove('iroWheelCanvasPicker');
    } else {
      // window.dadaCanvas.setBrushType('eyedropper');
      dummy_canvas.style.zIndex = '999';
      dummy_canvas.classList.add('iroWheelCanvasPicker');
    }
  };

  dragEndFGPicker = (event, dragElement) => {
    this.setState({
      FGcolorPickerPosition: { x: dragElement.x, y: dragElement.y },
    });
  };

  dragEndBGPicker = (event, dragElement) => {
    this.setState({
      BGcolorPickerPosition: { x: dragElement.x, y: dragElement.y },
    });
  };

  disableDrag = (disable) => {
    const state = this.state;
    this.setState({
      ...state,
      isDragDisable: disable,
    });
  };

  handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      this.onClose();
      this.disableDrag(false);
    }
  };

  assignBrush = (brush) => {
    window.dadaCanvas.previousBrushName = brush;
  }

  handleClickOutside = (event) => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      //  this.onClose();
      this.disableDrag(false);
    }
  };

  scrollToRight() {
    this.slider.current.scrollLeft = this.slider.current.scrollWidth;
  }

  render() {
    const {
      disableSaveDraft,
      children,
      onSaveDraftClick,
      showLoader,
      imageTrail,
    } = this.props;

    const {
      colorPicked,
      FGcolor,
      BGcolor,
      bgcolorPicked,
      isDragDisable,
      FGcolorPickerPosition,
      BGcolorPickerPosition,
      showFGColorWheel,
      showBGColorWheel,
      BrushSize,
      imageLoaded,
    } = this.state;

    return (
      <DrawingCanvas>
        <div>
          {(showFGColorWheel || showBGColorWheel)
            ? <div id="mouse-circle" ref={this.divCircle} />
            : null
          }

          <div className="customDrawingCanvasLib">
            <section className="overlay-conversation-modal">
              <article className="overlay-modal-in">
                <section className="horscroll_modal scrollarea">
                  <div
                    className="railarea modalConversationSketchpad"
                    ref={this.slider}
                  >
                    {imageLoaded
                      ? (
                        <div className="canvasImages">
                          <img ref={this.imageCanvas} src={imageTrail[0]} alt="" onLoad={() => this.imgToCanvas()} />
                        </div>
                      )
                      : (
                        <canvas
                          id="canvas"
                          ref={this.canvasRef}
                          height="600"
                          width="800"
                          className={showFGColorWheel || showBGColorWheel
                            ? 'canvasImages iroWheelCanvasPicker'
                            : 'canvasImages iroWheelCanvas'}
                        />
                      )
                    }


                    <div className="content-base type-height-middle3">
                      <div className="canvasLoader">
                        {showLoader && <LinearLoader scope="drawingCanvas" />}
                      </div>
                      <div className="secc-sketchpad the-content">
                        <div className="secc-sketchpad-in toolbox-cont">
                          <div className="interactionBox sketchpad-area">
                            <div className="sketchpad-box">
                              <div id="sketchpad_container">
                                <div className="span" id="all_canvas_container">
                                  <div id="dummyCanvasContainer">
                                    <div id="background_dummy_canvas_container" />
                                  </div>
                                  <div
                                    className="hide"
                                    id="canvasContainer_final"
                                  />
                                  <div id="canvasContainer" />
                                  <div
                                    className="hide"
                                    id="canvasBlankContainer"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Draggable
                        axis="both"
                        cancel="#alpha_slider, #brush_size_slider"
                      >
                        <div className="toolkit-hor">
                          <div className="middle3 toolbox-area toolbar-horz">
                            <div className="toggleToolboxBtn">
                              <i className="fa fa-minus" />
                            </div>
                            <div className="toolbox-area-in">
                              <div className="toolbox-cover kit-logo-h">
                                <div className="toolbox custom-color">
                                  <div className="tool-icons" id="toolBox">
                                    <ul>
                                      <li className="marker_li toolbox_click">
                                        {/* toolbox_click highlightBtn */}
                                        <div
                                          className="button button-pen stroke-tool"
                                          id="marker"
                                          onClick={() => {
                                            this.assignBrush('marker');

                                            window.dadaCanvas.setBrushType('marker');
                                            window.dadaCanvas.setBrushSize(
                                              { type: 'marker', size: BrushSize },
                                            );
                                          }
                                          }
                                          role="button"
                                          tabIndex={0}
                                        >
                                          <figure className="icon marker" />
                                        </div>

                                        <div className="marker_size_slider size-slider">
                                          <div
                                            id="marker_slider"
                                            className="marker_slider_in size-inner"
                                          >
                                            <ul
                                              id="brush-size-list"
                                              className="index-list"
                                            >
                                              {sizes.map(item => (
                                                <li
                                                  id={`marker-li-brush-size-${item.id}`}
                                                  key={`brush-size-${item.id}`}
                                                >
                                                  <a
                                                    aria-hidden
                                                    onClick={() => {
                                                      window.dadaCanvas.setBrushSize(
                                                        { type: 'marker', size: item.id },
                                                      );
                                                      this.setState({
                                                        BrushSize: item.id,
                                                      });
                                                    }
                                                    }
                                                  >
                                                    {/* <BrushSizeIcon size={size} /> */}
                                                    <img
                                                      src={item.src}
                                                      className="bsize"
                                                      key={item.id}
                                                      // onClick={() => setDotFilters(item.title)}
                                                      alt={item.alt}
                                                    />
                                                  </a>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>

                                      </li>
                                      <li className="round_brush_li toolbox_click">
                                        {/* toolbox_click brushBtn */}
                                        <div
                                          className="button button-pen"
                                          id="round_brush"
                                          onClick={() => {
                                            this.assignBrush('round_brush');

                                            window.dadaCanvas.setBrushType(
                                              'round_brush',
                                            );
                                            window.dadaCanvas.setBrushSize(
                                              { type: 'round-brush', size: BrushSize },
                                            );
                                          }
                                          }
                                          role="button"
                                          tabIndex={0}
                                        >
                                          <figure className="icon round_brush" />
                                        </div>

                                        <div className="round_size_slider size-slider">
                                          <div
                                            id="round_brush_slider"
                                            className="round_slider_in size-inner"
                                          >
                                            <ul
                                              id="brush-size-list"
                                              className="index-list"
                                            >
                                              {sizes.map(item => (
                                                <li
                                                  id={`round-brush-li-brush-size-${item.id}`}
                                                  key={`brush-size-${item.id}`}
                                                >
                                                  <a
                                                    aria-hidden
                                                    onClick={() => {
                                                      window.dadaCanvas.setBrushSize(
                                                        { type: 'round-brush', size: item.id },
                                                      );
                                                      this.setState({
                                                        BrushSize: item.id,
                                                      });
                                                    }
                                                    }
                                                  >
                                                    {/* <BrushSizeIcon size={size} /> */}
                                                    <img
                                                      src={item.src}
                                                      className="bsize"
                                                      key={item.id}
                                                      // onClick={() => setDotFilters(item.title)}
                                                      alt={item.alt}
                                                    />
                                                  </a>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>
                                      </li>
                                      <li className="flat_brush_li toolbox_click">
                                        {/* toolbox_click brushBtn */}
                                        <div
                                          className="button button-pen stroke-tool"
                                          id="flat_brush"
                                          onClick={() => {
                                            this.assignBrush('flat_brush');

                                            window.dadaCanvas.setBrushType(
                                              'flat_brush',
                                            );
                                            window.dadaCanvas.setBrushSize(
                                              { type: 'flat-brush', size: BrushSize },
                                            );
                                          }
                                          }
                                          role="button"
                                          tabIndex={0}
                                        >
                                          <figure className="icon flat_brush" />
                                        </div>

                                        <div className="flat_size_slider size-slider">
                                          <div
                                            id="flat_brush_slider"
                                            className="flat_slider_in size-inner"
                                          >
                                            <ul
                                              id="brush-size-list"
                                              className="index-list"
                                            >
                                              {sizes.map(item => (
                                                <li
                                                  id={`flat-brush-li-brush-size-${item.id}`}
                                                  key={`brush-size-${item.id}`}
                                                >
                                                  <a
                                                    aria-hidden
                                                    onClick={() => {
                                                      window.dadaCanvas.setBrushSize(
                                                        { type: 'flat-brush', size: item.id },
                                                      );
                                                      this.setState({
                                                        BrushSize: item.id,
                                                      });
                                                    }
                                                    }
                                                  >
                                                    {/* <BrushSizeIcon size={size} /> */}
                                                    <img
                                                      src={item.src}
                                                      className="bsize"
                                                      key={item.id}
                                                      // onClick={() => setDotFilters(item.title)}
                                                      alt={item.alt}
                                                    />
                                                  </a>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>

                                      </li>
                                      <li className="pencil_li toolbox_click">
                                        {/* toolbox_click pencilBtn */}
                                        <div
                                          className="button button-pen stroke-tool"
                                          id="pencil"
                                          onClick={() => {
                                            this.assignBrush('pencil');

                                            window.dadaCanvas.setBrushType(
                                              'pencil',
                                            );
                                          }
                                          }
                                          role="button"
                                          tabIndex={0}
                                        >
                                          <figure className="icon pencil" />
                                        </div>
                                      </li>
                                      <li className="eraser_li toolbox_click">
                                        {/* toolbox_click eraserBtn */}
                                        <div
                                          className="button button-eraser stroke-tool"
                                          id="eraser"
                                          onClick={() => {
                                            this.assignBrush('eraser');
                                            window.dadaCanvas.setBrushType('eraser');
                                            window.dadaCanvas.setBrushSize(
                                              { type: 'eraser', size: BrushSize },
                                            );
                                          }
                                          }
                                          role="button"
                                          tabIndex={0}
                                        >
                                          <figure className="icon eraser" />
                                        </div>

                                        <div className="eraser_size_slider size-slider">
                                          <div
                                            id="eraser_slider"
                                            className="eraser_slider_in size-inner"
                                          >
                                            <ul
                                              id="brush-size-list"
                                              className="index-list flip-slider"
                                            >
                                              {eraserSizes.map(item => (
                                                <li
                                                  id={`eraser-li-brush-size-${item.id}`}
                                                  key={`brush-size-${item.id}`}
                                                >
                                                  <a
                                                    aria-hidden
                                                    onClick={() => {
                                                      window.dadaCanvas.setBrushSize(
                                                        { type: 'eraser', size: item.id },
                                                      );
                                                      this.setState({
                                                        BrushSize: item.id,
                                                      });
                                                    }
                                                    }
                                                  >
                                                    {/* <BrushSizeIcon size={size} /> */}
                                                    <img
                                                      src={item.src}
                                                      className="bsize"
                                                      key={item.id}
                                                      // onClick={() => setDotFilters(item.title)}
                                                      alt={item.alt}
                                                    />
                                                  </a>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>

                                      </li>

                                      <li className="go_back_li">
                                        {/* undoBtn */}
                                        <div
                                          className="button button-undo"
                                          id="btn_undo"
                                        >
                                          <figure className="icon goBack" />
                                        </div>
                                      </li>
                                      <li className="go_forward_li">
                                        {/* redoBtn  */}
                                        <div
                                          className="button button-redo"
                                          disabled="disabled"
                                          id="btn_redo"
                                        >
                                          <figure className="icon goForward" />
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="main-alpha-tool hidden">
                                    <div className="alpha-spinner-container input-group">
                                      <input
                                        className="form-control"
                                        id="alpha_box"
                                        value="100"
                                      />
                                    </div>
                                    <div className="alpha_slider">
                                      <div className="alpha_slider_in hidden">
                                        <i className="fa fa-times close_alpha_slider" />
                                        <label
                                          className="btn btn-sm btn-white tooltip_target hidden"
                                          id="show_alpha_slider"
                                        >
                                          <span id="alpha_amount" />
                                        </label>
                                        <div
                                          className="fade bottom in"
                                          id="alpha_slider_container"
                                        >
                                          <div id="alpha_slider" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="outside colorsWrap">
                                    <ColorPickerSelector
                                      onClick={this.FGColorWheel}
                                      color={FGcolor}
                                      className="colorPickerPallete"
                                    />
                                    <ColorPickerSelector
                                      onClick={this.BGColorWheel}
                                      color={BGcolor}
                                      className="overlay colorBgPallete"
                                    />
                                  </div>
                                  <div
                                    className="color-picker"
                                    style={{ display: 'none' }}
                                  >
                                    <input
                                      className="color-frame color1"
                                      id="color_picker"
                                      style={{ display: 'none' }}
                                      type="text"
                                      value="#000000"
                                    />
                                    <input
                                      className="color-frame color2"
                                      id="bg_color_picker"
                                      style={{ display: 'none' }}
                                      type="text"
                                      value="#ffffff"
                                    />
                                  </div>
                                  <div className="clear-button">
                                    <div
                                      className="btn btn-block btn-sm btn-reset"
                                      data-placement="bottom"
                                      data-title="Clear"
                                      onClick={() => window.dadaCanvas.erase(true)
                                      }
                                      role="button"
                                      tabIndex={0}
                                    >
                                      <figure className="toTrash" />
                                    </div>
                                  </div>
                                </div>
                                <div className="hintSave hide">
                                  <div className="savingHintTextHor">
                                    <span className="saveText">save</span>
                                    <span className="savingText hide">
                                      saving...
                                    </span>
                                  </div>
                                </div>
                                {/* <button
                              className="save-button-toolbox"
                              disabled={disableSaveDraft}
                              onClick={onSaveDraftClick}
                              type="button"
                              /> */}
                                <div className="toolbox-bg-last" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Draggable>

                      {showFGColorWheel && (
                        <div className="colorpick-wrap">
                          <Draggable
                            axis="both"
                            disabled={isDragDisable}
                            defaultPosition={FGcolorPickerPosition}
                            onStop={this.dragEndFGPicker}
                          >
                            <div className="colorpick-drag" ref={this.ref}>
                              <div className="colorpick-container">
                                <div className="color-shortcut">
                                  <div
                                    className="color-button black"
                                    onClick={() => {
                                      this.setState({ FGcolor: 'rgba(0,0,0,1)', colorPicked: 'rgba(0,0,0,1)' });
                                      window.dadaCanvas.actualColor.rgb = 'rgba(0,0,0)';
                                      window.dadaCanvas.actualColor.rgba = 'rgba(0,0,0,1)';
                                      window.dadaCanvas.actualColor.rgb_obj = {
                                        r: 0, g: 0, b: 0, a: 1,
                                      };
                                      window.dadaCanvas.actualColor.hex = '#000000';
                                    }}
                                  />
                                  <div
                                    className="color-button white"
                                    onClick={() => {
                                      this.setState({ FGcolor: 'rgba(255,255,255,1)', colorPicked: 'rgba(255,255,255,1)' });
                                      window.dadaCanvas.actualColor.rgb = 'rgba(255,255,255)';
                                      window.dadaCanvas.actualColor.rgba = 'rgba(255,255,255,1)';
                                      window.dadaCanvas.actualColor.rgb_obj = {
                                        r: 255, g: 255, b: 255, a: 1,
                                      };
                                      window.dadaCanvas.actualColor.hex = '#ffffff';
                                    }}
                                  />
                                </div>
                                <ColorDisplay
                                  disableDrag={this.disableDrag}
                                  onClose={this.onClose}
                                  initialColor={FGcolor}
                                  colorPicked={colorPicked}
                                  onBlur={this.handleBlur}
                                  ironWheelMouseOut={this.ironWheelMouseOut}
                                  ironWheelMouseOver={this.ironWheelMouseOver}
                                  onChange={(color) => {
                                    this.setState({
                                      FGcolor: color.rgbString,
                                      colorPicked: color.rgbaString,
                                    });
                                    window.dadaCanvas.actualColor.rgb = color.rgbString;
                                    window.dadaCanvas.actualColor.rgba = color.rgbaString;
                                    window.dadaCanvas.actualColor.rgb_obj = color.rgba;
                                    window.dadaCanvas.actualColor.hex = color.hexString;
                                  // if (window.dadaCanvas.previousBrushName) {
                                  //   window.dadaCanvas.setBrushType(
                                  //     window.dadaCanvas.previousBrushName,
                                  //   );
                                  // }
                                  }}
                                />
                              </div>
                            </div>
                          </Draggable>
                        </div>
                      )}

                      {showBGColorWheel && (
                        <div className="colorpick-wrap">
                          <Draggable
                            defaultPosition={BGcolorPickerPosition}
                            onStop={this.dragEndBGPicker}
                            axis="both"
                            disabled={isDragDisable}
                          >
                            <div className="colorpick-drag" ref={this.ref}>
                              <div className="color-shortcut color-shortcut-bg">
                                <div
                                  className="color-button black"
                                  onClick={() => {
                                    this.setState({ BGcolor: 'rgba(0,0,0,1)', bgcolorPicked: 'rgba(0,0,0,1)' });
                                  }}
                                />
                                <div
                                  className="color-button white"
                                  onClick={() => {
                                    this.setState({ BGcolor: 'rgba(255,255,255,1)', bgcolorPicked: 'rgba(255,255,255,1)' });
                                  }}
                                />
                              </div>
                              <div className="colorpick-container">
                                <ColorDisplay
                                  backgroundColorPick
                                  disableDrag={this.disableDrag}
                                  onClose={this.onClose}
                                  initialColor={BGcolor}
                                  bgcolorPicked={bgcolorPicked}
                                  onBlur={this.handleBlur}
                                  ironWheelMouseOut={this.ironWheelMouseOut}
                                  ironWheelMouseOver={this.ironWheelMouseOver}
                                  onChange={(selected_color) => {
                                    this.setState({
                                      BGcolor: selected_color.rgbaString,
                                      bgcolorPicked: selected_color.rgbaString,
                                    });
                                    // if (window.dadaCanvas.previousBrushName) {
                                    //   window.dadaCanvas.setBrushType(
                                    //     window.dadaCanvas.previousBrushName,
                                    //   );
                                    // }
                                    window.dadaCanvas.setCanvasBackgroundColor(
                                      selected_color,
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </Draggable>
                        </div>
                      )}
                    </div>
                    {children}
                  </div>
                </section>
              </article>
            </section>
          </div>
        </div>
      </DrawingCanvas>
    );
  }
}

ReplyDrawingCanvas.propTypes = {
  disableSaveDraft: PropTypes.bool.isRequired,
  onSuccessfulMount: PropTypes.func.isRequired,
  onSaveDraftClick: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  vcId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  selectedId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ReplyDrawingCanvas);
