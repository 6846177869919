import { fromJS } from 'immutable';
import {
  GET_EXPLORE_PROFILES_FAILURE,
  GET_EXPLORE_PROFILES_INITIATED,
  GET_EXPLORE_PROFILES_SUCCESS,
  CLEAR_EXPLORE_PROFILES_STATE,
  GET_SEARCH_RESULTS_PROFILES_SUCCESS,
} from './actions';
import { getUniqueList } from '../../utils/dataTransform';

const intialState = fromJS({
  list: [],
  isRequesting: false,
  hasNext: false,
  error: null,
  nextPage: 1,
});

const profileCarousel = (state = intialState, action) => {
  switch (action.type) {
    case GET_EXPLORE_PROFILES_INITIATED:
      return state.merge({ isRequesting: true });
    case GET_EXPLORE_PROFILES_SUCCESS: {
      const profiles = getUniqueList(state.get('list').toJS(), action.list);
      return state.merge({
        isRequesting: false,
        error: null,
        nextPage: action.nextPage,
        hasNext: action.hasNextPage,
        list: profiles,
      });
    }
    case GET_SEARCH_RESULTS_PROFILES_SUCCESS: {
      return state.merge({
        isRequesting: false,
        error: null,
        nextPage: action.nextPage,
        hasNext: action.hasNextPage,
        list: action.list,
      });
    }
    case GET_EXPLORE_PROFILES_FAILURE:
      return state.merge({ isRequesting: false, hasNext: false, error: action.error });
    case CLEAR_EXPLORE_PROFILES_STATE:
      return intialState;
    default:
      return state;
  }
};

export default profileCarousel;
