/* eslint-disable max-len */
import React from 'react';
import './index.scss';

const LikeIcon = (props) => {
  const { liked } = props;
  return (
    <span className={`likeIcon ${liked ? 'liked' : 'like'}`} />
  );
};

export default LikeIcon;
