import React, { Fragment, Component, createRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';

import { isEmpty } from 'lodash';
import VisualConversationFeed from '../../containers/VisualConversationFeed';
import ExploreConversationFeed from '../../containers/ExploreConversationFeed';
import ProfileCarousel from '../../containers/ProfileCarousel';
import PictureCarousel from '../../containers/PictureCarousel';
import Loader from '../Loader';

import closeModal from '../../assets/images/close-modal.svg';
import SearchText from '../SearchText';
import ReactTippyWrapper from '../ReactTippyWrapper/index';
import './index.scss';
import styles from './styles';
import {
  dots,
  logo,
  exploreLine,
  zoom,
  add,
  filterFive,
} from './images';

class ExploreView extends Component {
  constructor(props) {
    super(props);
    this.elementRef = createRef();
    this.state = {
      criteria: '',
      isFilter: false,
      dotFilters: [...dots],
      filterIndex: null,
      queryType: '',
    };
  }

  setSearchResultData = (values) => {
    const { dataFetcher, clearExploreViewState } = this.props;
    clearExploreViewState();
    const query = values.join(',');
    this.updateState('', query);
    if (!isEmpty(query)) {
      dataFetcher({ query });
    }
  };

  setDotFilters = (query, index) => {
    const {
      getExploreVcFeedByQueryType,
      clearExploreViewState,
      getExploreRequestInitiated,
    } = this.props;
    getExploreRequestInitiated();
    clearExploreViewState();
    this.elementRef.current.clearInput();
    this.updateState('filter', query, true, index);
    getExploreVcFeedByQueryType({ query }, 'filter');
  };

  setChallenge = () => {
    const {
      getExploreVcFeedByQueryType,
      clearExploreViewState,
      getExploreRequestInitiated,
    } = this.props;
    getExploreRequestInitiated();
    clearExploreViewState();
    this.elementRef.current.clearInput();
    this.updateState('challenge');
    getExploreVcFeedByQueryType({}, 'challenge');
  };

  modalClose = () => {
    const { onClose } = this.props;
    this.updateState();
    onClose();
  };

  updateState(
    queryType = '',
    criteria = '',
    isFilter = false,
    filterIndex = null,
  ) {
    this.setState({
      criteria,
      isFilter,
      filterIndex,
      queryType,
    });
  }

  filters() {
    const { dotFilters, filterIndex } = this.state;
    const { userlevel } = this.props;
    const updateFilters = [...dotFilters];
    if (userlevel >= 4) {
      updateFilters.pop();
      updateFilters.push(filterFive);
    }

    return (
      <Fragment>
        {updateFilters.map((item, index) => (
          <ReactTippyWrapper title={item.title} position="top" key={item.id}>
            <div
              onClick={() => {
                this.setDotFilters(item.value, index);
              }}
              role="button"
              tabIndex={item.id}
            >
              <img
                src={item.src}
                className={`dot ${index === filterIndex ? 'dot-selected' : ''}`}
                key={item.id}
                alt={item.alt}
              />
            </div>
          </ReactTippyWrapper>
        ))}
      </Fragment>
    );
  }

  render() {
    const {
      classes,
      isOpen,
      challenge,
      history,
      isNewRequest,
      userlevel,
      isDataFetched,
    } = this.props;
    const { criteria, isFilter, queryType } = this.state;

    const LoaderComponent = (
      <div>
        <div className="scroller-wrapper">
          <Loader
            count={4}
            image={{ width: '70px', height: '70px' }}
            content={{ width: '230px', height: '10px' }}
          />
        </div>
        <div className="scroller-wrapper">
          <Loader count={3} box={{ width: '440px', height: '430px' }} />
        </div>
        <div className="scroller-wrapper">
          <Loader count={1} box={{ width: '1500px', height: '430px' }} />
        </div>
      </div>
    );

    const MessageComponent = (
      <h3>
        We couldn’t find what you are looking for. Wanna give it another try?
      </h3>
    );

    const CriteriaResults = (
      <div>
        {!isFilter && (
          <div>
            <ProfileCarousel criteria={criteria} isNewRequest={isNewRequest} />
            <PictureCarousel
              criteria={criteria}
              history={history}
              isNewRequest={isNewRequest}
            />
          </div>
        )}
        <ExploreConversationFeed
          criteria={criteria}
          isNewRequest={isNewRequest}
          scrollParentRef={this.scrollParentRef}
          isFilter={isFilter}
          history={history}
          queryType={queryType}
        />
      </div>
    );

    return (
      <div className="exploreContainer">
        <Dialog fullScreen open={isOpen} className="materialModalScrollFix">
          <DialogContent className={classes.test}>
            <div className="exploreViewWrapper">
              <div className="exploreAside">
                <div className="exploreLogo">
                  <img src={logo} alt="explore logo" />
                </div>
                <div className="explore-dots">
                  {userlevel && this.filters()}
                </div>
                <div className="searchField-container">
                  <img src={zoom} className="zoom" alt="zoom" />
                  <SearchText
                    ref={this.elementRef}
                    icon={add}
                    onValueChange={(values) => {
                      this.setSearchResultData(values);
                    }}
                  />
                </div>
                <div className="line-seperator">
                  <img src={exploreLine} className="line" alt="line" />
                </div>
                {challenge && (
                  <div className="challenge">
                    <h4>{challenge.title}</h4>
                    <div
                      onClick={() => this.setChallenge()}
                      role="button"
                      tabIndex={0}
                    >
                      <img
                        src={challenge.src}
                        alt=""
                        className="challengeImage"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div
                className="exploreContent"
                ref={(ref) => {
                  this.scrollParentRef = ref;
                }}
              >
                <div className="exploreContentHeader">
                  <button
                    className="closeLightbox"
                    type="button"
                    onClick={() => this.modalClose()}
                  >
                    <img src={closeModal} alt="" />
                  </button>
                </div>
                <div className="exploreContentBody">
                  {criteria || !isEmpty(queryType) ? (
                    <Fragment>
                      {!isNewRequest ? (
                        <Fragment>
                          {isDataFetched ? CriteriaResults : MessageComponent}
                        </Fragment>
                      ) : (
                        LoaderComponent
                      )}
                    </Fragment>
                  ) : (
                    <div>
                      <VisualConversationFeed
                        isExplore
                        scrollParentRef={this.scrollParentRef}
                        history={history}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ExploreView);
