const domContentParser = (content) => {
  const doc = new DOMParser().parseFromString(content, 'text/html');
  const { body } = doc;
  const domTree = {};
  const paragraph = body.getElementsByTagName('p');
  const figure = body.getElementsByTagName('figure');
  // const img = figure[0].getElementsByTagName('img');
  // const caption = figure[0].getElementsByTagName('figcaption');
  for (const p of paragraph) {
    const anchers = p.getElementsByTagName('a');
    for (const a of anchers) {
      a.setAttribute('target', '_blank');
    }
  }
  const paragraphArray = Array.prototype.slice
    .call(paragraph)
    .map(p => p.innerHTML);
  const heading = paragraphArray.shift();
  const paragraphStr = paragraphArray.join('');
  const textContent = body.textContent || body.innerText;
  domTree.heading = heading;
  // domTree.img = img;
  // domTree.caption = caption;
  domTree.textContent = textContent;
  domTree.allContent = paragraphStr;
  return domTree;
};

export default domContentParser;
