import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProfileView from '../../components/ProfileView';
import {
  openProfileView,
  closeProfileView,
  getUserInfo,
  getUserActivities,
  updateUserProfile,
  followUser,
  clearUserProfile,
  loadSelectedVc,
  resetUpdateProfileMessages,
  resetUserActivities,
} from './actions';
import {
  openLoginForm,
  REQUEST_AUTH_PROFILE_VIEW_FOLLOW,
} from '../AuthModal/actions';
import { openDrawingTool } from '../DrawingTool/actions';

class ProfileViewContainer extends Component {
  constructor(props) {
    super(props);
    this.displayProfile = this.displayProfile.bind(this);
    this.closeProfileWrapper = this.closeProfileWrapper.bind(this);
    this.updateProfileInfo = this.updateProfileInfo.bind(this);
    this.followUser = this.followUser.bind(this);
    this.getUserActivitiesWrapper = this.getUserActivitiesWrapper.bind(this);
    this.loadSelectedVcWrapper = this.loadSelectedVcWrapper.bind(this);
    this.renderProfile = this.renderProfile.bind(this);
    this.checkRoute = this.checkRoute.bind(this);
    this.openDrawingToolWrapper = this.openDrawingToolWrapper.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;

    history.listen(({ search }, action) => {
      const {
        resetUserActivities,
        clearUserProfile,
        isProfileViewOpen,
        history,
      } = this.props;
      if (action === 'POP' && !search) {
        clearUserProfile();
        return;
      }
      if (isProfileViewOpen) {
        if (this.checkRoute()) {
          resetUserActivities();
          this.renderProfile(search);
          history.location.state = search;
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invalidUser) {
      prevProps.clearUserProfile();
      prevProps.history.push('/not-found-404');
    }
  }

  getUserActivitiesWrapper() {
    const {
      history: {
        location: { search },
      },
      getUserActivities,
      nextPage,
      userActivitiesInitiated,
      selectedVc,
    } = this.props;
    if (!userActivitiesInitiated) {
      getUserActivities(search.slice(1),
        { page: nextPage }, selectedVc);
    }
  }

  displayProfile() {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    if (this.checkRoute()) {
      this.renderProfile(search);
    }
  }

  checkRoute() {
    const {
      history: {
        location: { pathname, search, state = '' },
      },
      userActivitiesInitiated,
    } = this.props;
    return pathname === '/profile' && search !== null && search !== state && !userActivitiesInitiated;
  }

  closeProfileWrapper() {
    const { history, clearUserProfile, statesLocation } = this.props;
    const pathname = statesLocation.previous ? statesLocation.previous : '/';
    history.push({
      pathname,
      previous: statesLocation.previous,
    });
    clearUserProfile();
  }

  updateProfileInfo(payload) {
    const { currentUserId, updateUserProfile } = this.props;
    updateUserProfile(currentUserId, payload);
  }

  followUser(followedId) {
    const { isAuthenticated, followUser, openLoginForm } = this.props;
    if (!isAuthenticated) {
      return openLoginForm();
    }
    return followUser(followedId);
  }

  loadSelectedVcWrapper(activityId, vcId) {
    const { loadSelectedVc } = this.props;
    loadSelectedVc(activityId, vcId);
  }

  openDrawingToolWrapper() {
    const { openDrawingTool } = this.props;
    openDrawingTool();
  }

  renderProfile(search) {
    const { openProfileView, getUserInfo, isProfileViewOpen } = this.props;
    if (!isProfileViewOpen) {
      openProfileView();
    }
    getUserInfo(search.slice(1));
    this.getUserActivitiesWrapper();
  }

  render() {
    const {
      isProfileViewOpen,
      userView,
      userActivities,
      hasNext,
      userEmail,
      selectedDrawing,
      selectedVc,
      currentUserId,
      isUpdatingProfile,
      apiError,
      successfulUpdate,
      resetUpdateProfileMessages,
      history,
    } = this.props;
    if (!isProfileViewOpen) {
      this.displayProfile();
    }
    return (
      <ProfileView
        isOpen={isProfileViewOpen}
        onClose={this.closeProfileWrapper}
        userView={userView}
        userActivities={userActivities}
        userEmail={userEmail}
        history={history}
        currentUserId={currentUserId}
        updateProfile={this.updateProfileInfo}
        getUserActivities={this.getUserActivitiesWrapper}
        hasNext={hasNext}
        loadSelectedVc={this.loadSelectedVcWrapper}
        followUser={this.followUser}
        selectedVc={selectedVc}
        selectedDrawing={selectedDrawing}
        isUpdatingProfile={isUpdatingProfile}
        apiError={apiError}
        successfulUpdate={successfulUpdate}
        resetUpdateProfileMessages={resetUpdateProfileMessages}
        openDrawingTool={this.openDrawingToolWrapper}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { profileView, authenticatedUserDetails, authenticationStatus } = state;
  return {
    isProfileViewOpen: profileView.get('isProfileViewOpen'),
    userView: profileView.get('userView').toJS(),
    userActivities: profileView.get('userActivities').toJS(),
    hasNext: profileView.get('hasNext'),
    nextPage: profileView.get('nextPage'),
    userActivitiesInitiated: profileView.get('userActivitiesInitiated'),
    currentUserId: authenticatedUserDetails.get('userId'),
    userEmail: authenticatedUserDetails.get('email'),
    isAuthenticated: authenticationStatus.get('isAuthenticated'),
    selectedVc: profileView.get('selectedVc'),
    selectedDrawing: profileView.get('selectedDrawing'),
    isUpdatingProfile: profileView.get('updatingUserProfile'),
    apiError: profileView.get('error'),
    successfulUpdate: profileView.get('successfulUpdate'),
    invalidUser: profileView.get('invalidUser'),
    statesLocation: state.router.location,
  };
};

const mapDispatchToProps = dispatch => ({
  openProfileView: () => dispatch(openProfileView()),
  onClose: () => dispatch(closeProfileView()),
  clearUserProfile: () => dispatch(clearUserProfile()),
  getUserInfo: userId => dispatch(getUserInfo(userId)),
  updateUserProfile: (userId, payload) => dispatch(updateUserProfile(userId, payload)),
  followUser: id => dispatch(followUser(id)),
  openLoginForm: () => dispatch(openLoginForm(REQUEST_AUTH_PROFILE_VIEW_FOLLOW)),
  getUserActivities: (userId, params, selectedVc) => dispatch(getUserActivities(userId, params, selectedVc)),
  loadSelectedVc: (activityId, vcId) => dispatch(loadSelectedVc(activityId, vcId)),
  resetUpdateProfileMessages: () => dispatch(resetUpdateProfileMessages()),
  resetUserActivities: () => dispatch(resetUserActivities()),
  openDrawingTool: () => dispatch(openDrawingTool()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileViewContainer);
