export const SAVE_AUTHENTICATED_TOKEN = 'SAVE_AUTHENTICATED_TOKEN';
export const SAVE_AUTHENTICATED_USER = 'SAVE_AUTHENTICATED_USER';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_REQUEST_INITIATED = 'AUTHENTICATION_REQUEST_INITIATED';
export const AUTHENTICATION_REQUEST_FAILED = 'AUTHENTICATION_REQUEST_FAILED';
export const AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';
export const UNAUTHENTICATE = 'UNAUTHENTICATE';
export const UPDATE_AUTHENTICATED_USER = 'UPDATE_AUTHENTICATED_USER';
export const UPDATE_AUTHENTICATED_TOKENS = 'UPDATE_AUTHENTICATED_TOKENS';

export const LOGOUT_HOME_PAGE = 'LOGOUT_HOME_PAGE';
export const LOGOUT_INDIVIDUAL_VC_PAGE = 'LOGOUT_INDIVIDUAL_VC_PAGE';

export const authenticationSuccess = (tokensObject, userObject) => ({
  type: AUTHENTICATION_SUCCESS,
  data: {
    ...tokensObject,
    ...userObject,
  },
});

export const saveAuthenticatedToken = (accessToken, uid, client) => ({
  type: SAVE_AUTHENTICATED_TOKEN,
  data: {
    accessToken,
    uid,
    client,
  },
});

export const saveAuthenticatedUser = (userId, name, email, photo, level, isCurator, is_blogger) => ({
  type: SAVE_AUTHENTICATED_USER,
  data: {
    userId,
    name,
    email,
    photo,
    level,
    isCurator,
    is_blogger,
  },
});

export const authenticationRequest = headers => ({
  type: AUTHENTICATION_REQUEST,
  headers,
});

export const authenticationRequestInitiated = () => ({
  type: AUTHENTICATION_REQUEST_INITIATED,
});

export const authenticationRequestFailed = () => ({
  type: AUTHENTICATION_REQUEST_FAILED,
});

export const notAuthenticated = () => ({
  type: NOT_AUTHENTICATED,
});

export const unauthenticate = () => ({
  type: UNAUTHENTICATE,
});

export const logout = actionType => ({
  type: actionType,
});

export const updateAuthenticatedUser = (email, name, photo) => ({
  type: UPDATE_AUTHENTICATED_USER, email, name, photo,
});

export const updateAuthenticatedTokens = uid => ({
  type: UPDATE_AUTHENTICATED_TOKENS, uid,
});
