// Rxjs imports
import {
  tap, ignoreElements, mergeMap, map,
} from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';

// Project Imports
import createCommentsReducers from '../VCComments/reducerCreator';
import createVCReducers from '../VisualConversation/reducerCreator';
import attachDynamicReducers from '../../reducers/reducerInjector';

import { CREATE_VC_DYNAMIC_REDUCER, INITIATE_INDIVIDUAL_VC_FEED_PAGE } from './actions';
import { toggleExpansionPanel } from '../VisualConversation/actions';
import { getSpecificConversationTree$ } from '../VisualConversation/epics';
import { visualConversationStore } from '../../utils/vcUtils';

export const createVCDynamicReducers$ = (id, state) => of(null).pipe(
  tap(
    () => attachDynamicReducers({
      [visualConversationStore(id)]: createVCReducers(id),
      [`vcComments${id}`]: createCommentsReducers(id),
    }),
  ),
  ignoreElements(),
);

export const initiateVcFeedPageEpic = (action$, state$) => action$.ofType(INITIATE_INDIVIDUAL_VC_FEED_PAGE).pipe(
  mergeMap(
    action => concat(
      createVCDynamicReducers$(action.id, state$.value),
      getSpecificConversationTree$({ vcId: action.id, fetchId: action.id }, state$.value),
      of(toggleExpansionPanel(action.id, true)),
    ),
  ),
);


export const createVCDynamicReducersEpic = (action$, state$) => action$.ofType(CREATE_VC_DYNAMIC_REDUCER).pipe(
  map(action => createVCDynamicReducers$(action.id, state$)),
);
