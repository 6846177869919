export const LIKE_ACTIVITY = 'LIKE_ACTIVITY';
export const LIKE_ACTIVITY_SUCCESS = id => `LIKE_ACTIVITY_SUCCESS_${id}`;
export const LIKE_ACTIVITY_FAILURE = id => `LIKE_ACTIVITY_FAILURE_${id}`;
export const LIKE_ACTIVITY_INITIATED = id => `LIKE_ACTIVITY_INITIATED_${id}`;
export const CURATOR_ACTIVITY = 'CURATOR_ACTIVITY';
export const CURATOR_ACTIVITY_SUCCESS = id => `CURATOR_ACTIVITY_SUCCESS_${id}`;
export const CURATOR_ACTIVITY_FAILURE = id => `CURATOR_ACTIVITY_FAILURE_${id}`;
export const CURATOR_ACTIVITY_INITIATED = id => `CURATOR_ACTIVITY_INITIATED_${id}`;
export const UPDATE_VC = id => `UPDATE_VC_${id}`;
export const SAVE_VC_CONVERSATION = id => `SAVE_VC_CONVERSATION_${id}`;
export const UPDATE_VC_CONVERSATION_BRANCH = id => `UPDATE_VC_CONVERSATION_BRANCH_${id}`;
export const UPDATE_VC_CONVERSATION_BRANCH_ACTIVITY = id => `UPDATE_VC_CONVERSATION_BRANCH_ACTIVITY_${id}`;
export const GET_CONVERSATION_TREE = 'GET_CONVERSATION_TREE';
export const GET_CONVERSATION_TREE_INITIATED = id => `GET_CONVERSATION_TREE_INITIATED_${id}`;
export const GET_CONVERSATION_TREE_SUCCESS = id => `GET_CONVERSATION_TREE_SUCCESS_${id}`;
export const GET_CONVERSATION_TREE_FAILURE = id => `GET_CONVERSATION_TREE_FAILURE_${id}`;
export const REFRESH_VC = id => `REFRESH_VC_${id}`;
export const EDIT_VC_DETAILS = 'EDIT_VC_DETAILS';
export const EDIT_VC_INITITATED = 'EDIT_VC_INITITATED';
export const EDIT_VC_SUCCESS = 'EDIT_VC_SUCCESS';
export const EDIT_VC_FAILIURE = 'EDIT_VC_FAILIURE';
export const TOGGLE_VC_EXPANSION_PANEL = id => `TOGGLE_VC_EXPANSION_PANEL_${id}`;
export const UPDATE_ACTIVE_BRANCH_DATA = id => `UPDATE_ACTIVE_BRANCH_DATA_${id}`;

export const getConversationTree = (vcId, lastIdOfActiveTrail) => (
  { type: GET_CONVERSATION_TREE, id: vcId, lastIndexId: lastIdOfActiveTrail });
export const getConversationTreeInitiated = vcId => ({
  type: GET_CONVERSATION_TREE_INITIATED(vcId),
});
export const getConversationTreeSuccess = (vcId, data, activeTrail) => ({
  type: GET_CONVERSATION_TREE_SUCCESS(vcId),
  data,
  activeTrail,
});
export const getConversationTreeFailure = vcId => ({
  type: GET_CONVERSATION_TREE_FAILURE(vcId),
});
export const likeActivity = (vcId, activityId, likesCount, parentIdsTrail) => ({
  type: LIKE_ACTIVITY, activityId, likesCount, vcId, parentIdsTrail,
});
export const likeActivitySuccess = vcId => ({ type: LIKE_ACTIVITY_SUCCESS(vcId) });
export const likeActivityFailure = vcId => ({ type: LIKE_ACTIVITY_FAILURE(vcId) });
export const likeActivityInitiated = vcId => ({ type: LIKE_ACTIVITY_INITIATED(vcId) });
export const updateVc = (id, data) => ({ type: UPDATE_VC(id), data });
export const saveVcConversation = (id, data, activeTrail) => ({ type: SAVE_VC_CONVERSATION(id), data, activeTrail });
export const updateVcConversationBranch = (id, data, activeTrail, selectedId) => ({
  type: UPDATE_VC_CONVERSATION_BRANCH(id), data, activeTrail, selectedId,
});
export const updateVcConversationBranchActivity = (id, selectedId) => ({
  type: UPDATE_VC_CONVERSATION_BRANCH_ACTIVITY(id), selectedId,
});
export const curatorActivity = (vcId, activityId) => ({
  type: CURATOR_ACTIVITY, vcId, activityId,
});
export const curatorActivitySuccess = vcId => ({ type: CURATOR_ACTIVITY_SUCCESS(vcId) });
export const curatorActivityFailure = vcId => ({ type: CURATOR_ACTIVITY_FAILURE(vcId) });
export const curatorActivityInitiated = vcId => ({ type: CURATOR_ACTIVITY_INITIATED(vcId) });
export const editVcDetails = (id, level, title) => ({
  type: EDIT_VC_DETAILS, id, level, title,
});
export const editVcInitiated = () => ({ type: EDIT_VC_INITITATED });
export const editVcSuccess = () => ({ type: EDIT_VC_SUCCESS });
export const editVcFailiure = () => ({ type: EDIT_VC_FAILIURE });
export const refreshVc = vcId => ({ type: REFRESH_VC(vcId) });
export const toggleExpansionPanel = (id, isExpanded) => ({
  type: TOGGLE_VC_EXPANSION_PANEL(id), isExpanded,
});
export const updateActiveBranchData = (id, data, activeTrail) => ({ type: UPDATE_ACTIVE_BRANCH_DATA(id), data, activeTrail });
