/* eslint-disable import/prefer-default-export */
// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import { mergeMap, catchError, throttleTime } from 'rxjs/operators';

import {
  GET_EDITORIAL_DATA,
  editorialDataRequestInitiated,
  editorialDataRequestSuccess,
  editorialDataRequestFailure,
} from './actions';
import { openSnackbar } from '../ApplicationSnackbar/actions';

import { getEditorildata } from '../../api/index';
import { UNEXPECTED_ERROR } from '../../constants';

export const getEditorialResultsEpic = (action$, state$) => action$.ofType(GET_EDITORIAL_DATA).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    of(editorialDataRequestInitiated()),
    from(getEditorildata()).pipe(
      mergeMap(data => [editorialDataRequestSuccess(data)]),
      catchError(error => [
        editorialDataRequestFailure(error),
        openSnackbar(UNEXPECTED_ERROR),
      ]),
    ),
  )),
);
