import isObjectLike from 'lodash/isObjectLike';
import { isJSONString } from './validations';

class StorageService {
  constructor(storage) {
    this.storage = storage;
  }

  setItem(key, value) {
    let setValue = value;
    if (isObjectLike(value)) {
      setValue = JSON.stringify(value);
    }
    return this.storage.setItem(key, setValue);
  }

  getItem(key) {
    const value = this.storage.getItem(key);
    return isJSONString(value) ? JSON.parse(value) : value;
  }

  removeItem(key) {
    return this.storage.removeItem(key);
  }

  clearAll() {
    this.storage.clear();
  }

  removeItems(keys) {
    keys.forEach(key => this.removeItem(key));
  }
}

class LocalStorage extends StorageService {}

class SessionStorage extends StorageService {}

export const localStorageService = new LocalStorage(window.localStorage);
export const sessionStorageService = new SessionStorage(window.sessionStorage);

class DefaultStorage {
  constructor() {
    this.storage = localStorageService;
  }

  setStorage(selected) {
    switch (selected) {
      case 'session':
        this.storage = sessionStorageService;
        break;
      case 'local':
        this.storage = localStorageService;
        break;
      default:
        break;
    }
  }

  getStorage() {
    return this.storage;
  }
}

export const defaultStorageService = new DefaultStorage();
