import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { Subject, from } from 'rxjs';
import {
  debounceTime, switchMap,
} from 'rxjs/operators';

import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Paper from '@material-ui/core/Paper/Paper';
import Chip from '@material-ui/core/Chip/Chip';
import Avatar from '@material-ui/core/Avatar/Avatar';
import ChipInput from 'material-ui-chip-input';

import Autosuggest from 'react-autosuggest';
import { isString, isValidEmail } from '../../utils/validations';
import ReactTippyWrapper from '../ReactTippyWrapper/index';
import { DEFAULT_USER_THUMBNAIL } from '../../constants';
import './index.scss';

const removeChipsComponent = (<div className="removeChips" />);
const chipAvatarComponent = src => (<Avatar src={src || DEFAULT_USER_THUMBNAIL} className="chipsAvatar" />);
const chipTooltipComponent = name => (<div className="inviteFriendsToolip"> {name} </div>);

function chipComponent(value, key) {
  return (
    <ReactTippyWrapper
      key={key}
      size="regular"
      html={chipTooltipComponent(value.name)}
    >
      {chipAvatarComponent(value.photo)}
    </ReactTippyWrapper>
  );
}

function chipRenderer(
  data, key,
) {
  const {
    value,
    handleDelete,
  } = data;

  return (
    <div className="customInviteFriends">
      <Chip
        key={key}
        avatar={chipComponent(value, key)}
        deleteIcon={removeChipsComponent}
        className="customInviteChips"
        onDelete={handleDelete}
      />
    </div>
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      onMouseDown={e => e.preventDefault()} // prevent the click causing the input to be blurred
    >
      <div>
        {suggestion.isEmpty ? null : (
          <div className="avatar">
            <img alt="" src={suggestion.photo} />
          </div>
        ) }
        <div className="suggestedUserName">
          {suggestion.name}
        </div>
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function generateNoResultSuggestion() {
  return [{ name: 'No Results Found', isEmpty: true }];
}

class InviteFriends extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      value: [],
      textFieldInput: '',
      chipInputEmailError: false,
      chipInputRepeatedError: false,
    };

    this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
    this.handleTextFieldInputChange = this.handleTextFieldInputChange.bind(this);
    this.handleAddChip = this.handleAddChip.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.onBeforeStringAdd = this.onBeforeStringAdd.bind(this);
    this.chipInputCustomAdd = this.chipInputCustomAdd.bind(this);

    this.handleSuggestionsRequestSubject = new Subject();
    this.chipInputRef = null;
  }

  componentDidMount() {
    this.handleSuggestionsRequestSubscription = this.handleSuggestionsRequestSubject.pipe(
      debounceTime(350),
      switchMap((value) => {
        if (value) {
          const { dataFetcher } = this.props;
          return from(dataFetcher(value));
        }

        return from(Promise.resolve([]));
      }),
    ).subscribe((response) => {
      this.setState({
        suggestions: response.length ? response : generateNoResultSuggestion(),
      });
    });
  }

  componentWillUnmount() {
    this.handleSuggestionsRequestSubscription.unsubscribe();
  }

  onBeforeStringAdd(userInput) {
    const { value } = this.state;
    const isEmailValid = isValidEmail(userInput);
    let isRepeated = false;
    if (isEmailValid) {
      isRepeated = value.filter(data => data.isNewUser).find(data => data.name === userInput);
    }
    this.setState({ chipInputEmailError: !isEmailValid, chipInputRepeatedError: isRepeated });
    return isEmailValid && !isRepeated;
  }

  handleSuggestionsFetchRequested({ value }) {
    this.handleSuggestionsRequestSubject.next(value);
  }

  handleSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  handleTextFieldInputChange(event, { newValue }) {
    this.setState({
      textFieldInput: newValue,
    });
  }

  handleAddChip(chip) {
    const { value } = this.state;
    let data = chip;
    let isDataStringValid = false;
    const isDataString = isString(data);

    if (isDataString) {
      isDataStringValid = this.onBeforeStringAdd(data);
    }

    if ((isDataString && !isDataStringValid) || data.isEmpty) {
      return;
    }

    if (isDataString && isDataStringValid) {
      data = { name: chip.toLowerCase(), photo: undefined, isNewUser: true };
    } else {
      const isUserRepeated = value.filter(data => !data.isNewUser).find(data => data.id === chip.id);
      if (isUserRepeated) {
        this.setState({ chipInputRepeatedError: isUserRepeated });
        return;
      }
    }

    this.setState(state => ({
      value: [...state.value, data],
      chipInputEmailError: false,
      chipInputRepeatedError: false,
      textFieldInput: '',
    }), () => {
      this.triggerPropsOnChange();
    });
  }

  handleDeleteChip(chip, index) {
    this.setState((state) => {
      const newState = [...state.value];
      newState.splice(index, 1);
      return { value: newState };
    }, () => {
      this.triggerPropsOnChange();
    });
  }

  triggerPropsOnChange() {
    const { onValueChange } = this.props;
    const { value } = this.state;

    onValueChange(value);
  }

  chipInputCustomAdd() {
    this.handleAddChip(this.chipInputRef.value);
  }

  renderInput(inputProps) {
    const {
      classes, autoFocus, value, onChange, onAdd, onDelete, chips, ref, ...other
    } = inputProps;

    const { chipInputEmailError, chipInputRepeatedError } = this.state;
    let helperText = null;

    if (chipInputEmailError) {
      helperText = 'Invalid Email';
    } else if (chipInputRepeatedError) {
      helperText = 'Already added !';
    }

    return (
      <Fragment>
        <div className="customChips inviteFriends">
          <ChipInput
            clearInputValueOnChange
            allowDuplicates={false}
            blurBehavior="ignore"
            onUpdateInput={onChange}
            chipRenderer={chipRenderer}
            onAdd={onAdd}
            placeholder="Invite Friends"
            onDelete={onDelete}
            error={chipInputEmailError || chipInputRepeatedError}
            helperText={helperText}
            value={chips}
            className="customChipsInputField"
            inputRef={(inputNode) => {
              this.chipInputRef = inputNode;
              ref(inputNode);
            }}
            {...other}
          />
          <button type="button" className="btn btnReset addChipBtn" onClick={this.chipInputCustomAdd}>
            <span className="addBtnImg" />
          </button>
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      classes, dataFetcher, onValueChange, ...rest
    } = this.props;
    const { suggestions, value, textFieldInput } = this.state;
    return (
      <Autosuggest
        theme={{
          container: 'customAutoSuggestion',
          suggestionsContainerOpen: 'suggestionContainer',
          suggestionsList: 'suggestionListing',
          suggestion: 'suggestionItem',
        }}
        renderInputComponent={this.renderInput}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(e, { suggestion }) => {
          this.handleAddChip(suggestion);
          e.preventDefault();
        }}
        focusInputOnSuggestionClick
        inputProps={{
          classes,
          chips: value,
          onChange: this.handleTextFieldInputChange,
          value: textFieldInput,
          onAdd: this.handleAddChip,
          onDelete: this.handleDeleteChip,
          ...rest,
        }}
      />
    );
  }
}

InviteFriends.propTypes = {
  classes: PropTypes.object,
  dataFetcher: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

InviteFriends.defaultProps = {
  classes: {},
};

export default InviteFriends;
