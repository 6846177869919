
// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import {
  debounceTime, mergeMap, catchError,
} from 'rxjs/operators';

import { openSnackbar } from '../ApplicationSnackbar/actions';

import {
  GET_EXPLORE_PROFILES,
  getExploreProfileInitiated,
  getExploreProfileSuccess,
  getExploreProfileFailure,
} from './actions';

import { searchUsersByParams } from '../../api';
import { UNEXPECTED_ERROR } from '../../constants';

import { apiResponseParserforProfiles } from '../ExploreView/epic';

const requestGetExploreProfileEpic = (action$, state$) => action$.ofType(GET_EXPLORE_PROFILES).pipe(
  debounceTime(500),
  mergeMap(action => concat(
    of(getExploreProfileInitiated()),
    from(searchUsersByParams(action.params))
      .pipe(
        mergeMap((response) => {
          const {
            profileActions,
            profileIds,
            page,
            hasNextPage,
          } = apiResponseParserforProfiles(response);
          return [...profileActions, getExploreProfileSuccess(profileIds, page, hasNextPage)];
        }),
        catchError(error => [
          getExploreProfileFailure(UNEXPECTED_ERROR),
          openSnackbar(UNEXPECTED_ERROR),
        ]),
      ),
  )),
);

export default requestGetExploreProfileEpic;
