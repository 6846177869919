import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import noop from 'lodash/noop';

// custom assets
import { AUTO_SAVE_TIME_DURATION } from '../../constants';
import closeModal from '../../assets/images/close-modal.svg';

import {
  closeDraftModal,
  deleteDraft, getDraftIndex, openSaveDraftConfirmationModal, postDraft, postDrawing, putDraft,
  saveDraftRequest, postConfirmationModal, requestPost, postDrawingDynamic, postCloseDrawingTool, closeDrawingTool,
} from './actions';
import PostSketchForm from '../../components/postSketchForm';
import { postDraftSchema, putDraftSchema } from './schema';
import DraftsModal from '../../components/DraftModal';
import SaveDraftConfirmationModal from '../../components/SaveDraftModal';
import { openLoginForm, REQUEST_AUTH_DRAWING_TOOL } from '../AuthModal/actions';
import DraftSelectionModal from '../../components/DraftSelectionModal';
import SeedDrawingCanvas from '../../components/SeedDrawingCanvas';
import ReplyDrawingCanvas from '../../components/ReplyDrawingCanvas';

import './index.scss';

import '../../packages/dada_canvas/canvas/dist';

class DrawingTool extends Component {
  constructor(props) {
    super(props);
    this.timerReferenceForAutoSave = null;
    this.saveDraftDrawingCanvas = this.saveDraft.bind(this, false);
    this.saveDraftConfirmationModal = this.saveDraft.bind(this, true);
    this.closeDraftConfirmation = this.closeDraftConfirmation.bind(this);
    this.openDraftConfirmation = this.openDraftConfirmation.bind(this);
    this.checkExistingDraftOnLoad = this.checkExistingDraft.bind(this, false);
    this.checkExistingDraftAfterLoad = this.checkExistingDraft.bind(this, true);
    this.isDraftAction = this.isDraftAction.bind(this);
    this.resetAutoSave = this.resetAutoSave.bind(this);
    this.autoSaveDraft = this.autoSaveDraft.bind(this);
    this.setAutoSaveInterval = this.setAutoSaveInterval.bind(this);
    this.stopAutoSave = this.stopAutoSave.bind(this);
    this.onDraftsModalConfirmHandler = this.onDraftsModalConfirmHandler.bind(this);
    this.onDraftsModalDeleteHandler = this.onDraftsModalDeleteHandler.bind(this);
    this.onWorkingDraftSelect = this.onWorkingDraftSelect.bind(this);
    this.onExistingDraftSelect = this.onExistingDraftSelect.bind(this);
    this.importImageOnCanvas = this.importImageOnCanvas.bind(this);
    this.drawingCanvasProps = this.drawingCanvasProps.bind(this);
    this.onPostDrawing = this.onPostDrawing.bind(this);
    this.displayCorrectCanvas = this.displayCorrectCanvas.bind(this);
  }


  componentDidUpdate(prevProps) {
    const {
      saveOnNoDraft, openSaveDraftModal, isAuthenticated, open,
      openDraftSelectionModal, openDraft, confirmPosting, fetchExistingDraft,
    } = this.props;

    if (prevProps.saveOnNoDraft === false && saveOnNoDraft === true && !window.dadaCanvas.isCanvasEmpty()) {
      if (openSaveDraftModal) {
        this.saveDraftConfirmationModal();
      } else {
        this.saveDraftDrawingCanvas();
      }
    }
    if (isAuthenticated && open && !this.timerReferenceForAutoSave && openDraftSelectionModal === false
      && openSaveDraftModal === false && openDraft === false && confirmPosting === false) {
      this.setAutoSaveInterval();
    }
    if (prevProps.open === true && open === false) {
      this.stopAutoSave();
    }
    if ((prevProps.openDraftSelectionModal === false && openDraftSelectionModal === true)
      || (prevProps.openSaveDraftModal === false && openSaveDraftModal === true)
      || (prevProps.openDraft === false && openDraft === true)
      || (prevProps.confirmPosting === false && confirmPosting === true)
    ) {
      this.stopAutoSave();
    }

    if (prevProps.fetchExistingDraft === false && fetchExistingDraft === true
      && confirmPosting === false) {
      this.checkExistingDraftAfterLoad();
    }
  }

  onDraftsModalConfirmHandler() {
    const { closeDraftModal } = this.props;

    this.importImageOnCanvas();
    closeDraftModal();
  }

  onDraftsModalDeleteHandler() {
    const {
      deleteDraft, draft_id, closeDraftModal,
    } = this.props;

    deleteDraft(draft_id);
    closeDraftModal();
  }

  onWorkingDraftSelect() {
    const {
      isFromSaveConfirmationModal,
      closeDraftModal, isFromPostConfirmationModal,
    } = this.props;

    if (isFromPostConfirmationModal) {
      closeDraftModal();
    } else if (isFromSaveConfirmationModal) {
      this.saveDraftConfirmationModal();
    } else {
      this.saveDraftDrawingCanvas();
    }
  }

  onClose() {
    const { handleClose } = this.props;
    handleClose();
  }

  onExistingDraftSelect() {
    const {
      isFromSaveConfirmationModal, handleClose, closeDraftModal,
    } = this.props;

    if (isFromSaveConfirmationModal) {
      this.onClose();
    } else {
      this.importImageOnCanvas();
      closeDraftModal();
    }
  }

  onPostDrawing(payload) {
    const {
      postDrawing, vcId, previousActivityId, isReply,
    } = this.props;
    postDrawing(payload, isReply, previousActivityId, vcId);
  }

  setAutoSaveInterval() {
    this.timerReferenceForAutoSave = setInterval(this.autoSaveDraft, AUTO_SAVE_TIME_DURATION);
  }

  resetAutoSave() {
    clearInterval(this.timerReferenceForAutoSave);
    this.setAutoSaveInterval();
  }

  stopAutoSave() {
    if (this.timerReferenceForAutoSave) {
      clearInterval(this.timerReferenceForAutoSave);
      this.timerReferenceForAutoSave = null;
    }
  }

  isDraftAction() {
    const { gettingDraft, checkingDraft, savingDraft } = this.props;
    return gettingDraft || checkingDraft || savingDraft;
  }

  closeDraftConfirmation() {
    const { handleClose } = this.props;
    this.onClose();
  }

  openDraftConfirmation() {
    const { handleClose, openSaveDraftConfirmationModal } = this.props;
    if (window.dadaCanvas.isCanvasEmpty()) {
      this.onClose();
    } else {
      openSaveDraftConfirmationModal();
    }
  }

  importImageOnCanvas() {
    const { draft, bgColor } = this.props;

    window.dadaCanvas.importCanvasImage(draft, bgColor);
  }

  checkExistingDraft(isSaveDraftRequested) {
    const {
      previousActivityId, vcId, getDraftIndex, isAuthenticated, gettingDraft, checkingDraft,
    } = this.props;
    let workingDraft = null;
    if (isAuthenticated && (!gettingDraft || !checkingDraft)) {
      if (!window.dadaCanvas.isCanvasEmpty()) {
        workingDraft = window.dadaCanvas.exportCanvasImage();
      }

      getDraftIndex({ parentId: previousActivityId, rootId: vcId }, isSaveDraftRequested, workingDraft);
    }
  }

  saveDraft(isFromConfirmationClose) {
    const {
      postDraft, savingDraft, previousActivityId, vcId, postUpdatedDraft, draft_id, isAuthenticated, openLoginForm,
      saveDraftRequest,
    } = this.props;

    if (!isAuthenticated) {
      saveDraftRequest();
      openLoginForm();
    } else if (!savingDraft) {
      if (window.dadaCanvas.isCanvasEmpty()) {
        // eslint-disable-next-line no-alert
        window.alert(' Your canvas is empty! Please draw something to save as draft ');
        return;
      }
      window.dadaCanvas.exportCanvasBlobAndBackground().then((resp) => {
        if (draft_id === null) {
          const payload = postDraftSchema(previousActivityId, vcId, resp);
          postDraft(payload, isFromConfirmationClose);
        } else {
          const payload = putDraftSchema(resp);
          postUpdatedDraft(draft_id, payload, isFromConfirmationClose);
        }
        this.resetAutoSave();
      }).catch(noop);
    }
  }

  autoSaveDraft() {
    if (window.dadaCanvas.isCanvasEmpty()) {
      this.resetAutoSave();
      return;
    }
    this.saveDraftDrawingCanvas();
  }

  drawingCanvasProps() {
    const {
      savingDraft, isPosting, deletingDraft, isAuthenticated,
    } = this.props;

    return {
      onSuccessfulMount: this.checkExistingDraftOnLoad,
      onSaveDraftClick: this.saveDraftDrawingCanvas,
      disableSaveDraft: savingDraft,
      showLoader: this.isDraftAction() || isPosting || deletingDraft,
      isAuthenticated,
    };
  }

  drawingCanvasChildren() {
    const {
      userlevel, isAuthenticated,
      confirmPosting, postConfirmationModal, openLoginForm,
      setRequestPost, isReply, previousActivityId,
      title, confirmToPost, requestedPost, fetchExistingDraft,
      isPosting,
    } = this.props;

    return (
      <Fragment>
        <PostSketchForm
          postDrawing={this.onPostDrawing}
          userLevel={userlevel}
          isAuthenticated={isAuthenticated}
          confirmPosting={confirmPosting}
          postConfirmationModal={postConfirmationModal}
          openLoginForm={openLoginForm}
          setRequestPost={setRequestPost}
          isReply={isReply}
          activityId={previousActivityId}
          title={title}
          confirmToPost={confirmToPost}
          requestedPost={requestedPost}
          checkExistingDraftOnCancel={this.checkExistingDraftAfterLoad}
          fetchExistingDraft={fetchExistingDraft}
          isDraftAction={this.isDraftAction}
          isPosting={isPosting}
        />
        <Button
          className="closeLightbox"
          onClick={this.openDraftConfirmation}
          disabled={this.isDraftAction()}
        >
          <img src={closeModal} alt="" />
        </Button>
      </Fragment>
    );
  }

  displayCorrectCanvas() {
    const { isReply, vcId, previousActivityId } = this.props;
    return isReply ? (
      <ReplyDrawingCanvas
        {...this.drawingCanvasProps()}
        vcId={vcId}
        selectedId={previousActivityId}
      >
        { this.drawingCanvasChildren() }
      </ReplyDrawingCanvas>
    )
      : (
        <SeedDrawingCanvas
          {...this.drawingCanvasProps()}
        >
          { this.drawingCanvasChildren() }
        </SeedDrawingCanvas>
      );
  }

  render() {
    const {
      savingDraft, openDraft, deletingDraft, openSaveDraftModal, open,
      isReply, openDraftSelectionModal, workingDraft,
      isFromPostConfirmationModal, imgWithBg,
    } = this.props;

    if (open) {
      const blurEffect = document.querySelector('#blurWrapper');
      blurEffect.classList.add('bgBlur');
    } else {
      const blurRem = document.querySelector('#blurWrapper');
      blurRem.classList.remove('bgBlur');
    }

    return (
      <div>
        <Dialog
          open={open}
          fullScreen
          scroll="body"
          className={`materialModalScrollFix drawingCanvas ${isReply ? 'drawingReply' : 'drawingSeed'}`}
          classes={{ paper: 'drawingCanvasPaper' }}
        >
          <DialogContent className="drawingCanvasContent">
            {
              open && this.displayCorrectCanvas()
            }

            <DraftsModal
              openDraftModal={openDraft}
              draft={imgWithBg}
              deletingDraft={deletingDraft}
              onConfirm={this.onDraftsModalConfirmHandler}
              onDelete={this.onDraftsModalDeleteHandler}
            />
            <SaveDraftConfirmationModal
              open={openSaveDraftModal}
              saveDraft={this.saveDraftConfirmationModal}
              onClose={this.closeDraftConfirmation}
              savingDraft={savingDraft}
              isDraftAction={this.isDraftAction}
            />
            <DraftSelectionModal
              openDraftSelectionModal={openDraftSelectionModal}
              existingDraft={imgWithBg}
              workingDraft={workingDraft}
              isFromPostConfirmationModal={isFromPostConfirmationModal}
              onWorkingDraftSelect={this.onWorkingDraftSelect}
              onExistingDraftSelect={this.onExistingDraftSelect}
            />
            <Dialog
              open={false}
              className="dadaModal dadaEmptyModal materialModalScrollFix"
              scroll="body"
              classes={{ paper: 'dadaDialogPaper', container: 'vhAlignCenter' }}
              BackdropProps={{
                classes: {
                  root: 'dadaBackDrop',
                },
              }}
            >
              <DialogTitle
                disableTypography
                className="dadaModalTitle"
              >
                <Button className="btnReset closeModalIcon">
                  <span className="icon" />
                </Button>
                <h3 className="dadaTitle">Your Canvas is empty..</h3>
              </DialogTitle>
              <DialogActions className="dadaModalFooter">
                <h3>..make a drawing, first!</h3>
                <Button className="btn btnGreen">Okay</Button>
              </DialogActions>
            </Dialog>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { DrawingToolReducer, authenticatedUserDetails, authenticationStatus } = state;
  return {
    open: DrawingToolReducer.get('isOpen'),
    previousActivityId: DrawingToolReducer.get('previousActivityId'),
    vcId: DrawingToolReducer.get('vcId'),
    savingDraft: DrawingToolReducer.get('savingDraft'),
    draft_id: DrawingToolReducer.get('draft_id'),
    openDraft: DrawingToolReducer.get('openDraft'),
    draft: DrawingToolReducer.get('draft'),
    deletingDraft: DrawingToolReducer.get('deletingDraft'),
    checkingDraft: DrawingToolReducer.get('checkingDraft'),
    gettingDraft: DrawingToolReducer.get('gettingDraft'),
    isAuthenticated: authenticationStatus.get('isAuthenticated'),
    openSaveDraftModal: DrawingToolReducer.get('openSaveDraftModal'),
    userlevel: authenticatedUserDetails.get('level'),
    confirmPosting: DrawingToolReducer.get('confirmPosting'),
    requestedPost: DrawingToolReducer.get('requestedPost'),
    isReply: DrawingToolReducer.get('isReply'),
    title: DrawingToolReducer.get('title'),
    fetchExistingDraft: DrawingToolReducer.get('fetchExistingDraft'),
    openDraftSelectionModal: DrawingToolReducer.get('openDraftSelectionModal'),
    workingDraft: DrawingToolReducer.get('workingDraft'),
    isFromSaveConfirmationModal: DrawingToolReducer.get('isFromSaveConfirmationModal'),
    saveOnNoDraft: DrawingToolReducer.get('saveOnNoDraft'),
    isFromPostConfirmationModal: DrawingToolReducer.get('isFromPostConfirmationModal'),
    isPosting: DrawingToolReducer.get('isPosting'),
    bgColor: DrawingToolReducer.get('bgColor'),
    imgWithBg: DrawingToolReducer.get('imgWithBg'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClose: () => {
    dispatch(postCloseDrawingTool(ownProps.postCloseAction));
    dispatch(closeDrawingTool());
  },
  postDrawing: (payload, isReply, previousActivityId, vcId) => {
    dispatch(postDrawing());
    dispatch(postDrawingDynamic(ownProps.postDrawingAction, payload, isReply, previousActivityId, vcId));
  },
  postConfirmationModal: () => { dispatch(postConfirmationModal()); },
  openLoginForm: () => { dispatch(openLoginForm(REQUEST_AUTH_DRAWING_TOOL)); },
  setRequestPost: () => { dispatch(requestPost()); },
  postDraft: (payload, isFromConfirmationClose) => {
    dispatch(postDraft(payload, isFromConfirmationClose, ownProps.postCloseAction));
  },
  postUpdatedDraft: (id, payload, isFromConfirmationClose) => {
    dispatch(putDraft(id, payload, isFromConfirmationClose, ownProps.postCloseAction));
  },
  getDraftIndex: (paramData, isSaveDraftRequested, workingDraft) => {
    dispatch(getDraftIndex(paramData, isSaveDraftRequested, workingDraft));
  },
  closeDraftModal: () => { dispatch(closeDraftModal()); },
  deleteDraft: (id) => { dispatch(deleteDraft(id)); },
  openSaveDraftConfirmationModal: () => dispatch(openSaveDraftConfirmationModal()),
  saveDraftRequest: () => dispatch(saveDraftRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawingTool);
