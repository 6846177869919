import { fromJS } from 'immutable';
import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from './actions';

const initialState = fromJS({
  open: false,
  message: null,
});

const snackbarStatus = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return state.merge({ open: true, message: action.message });
    case CLOSE_SNACKBAR:
      return state.merge({ open: false, message: null });
    default:
      return state;
  }
};

export default snackbarStatus;
