import { fromJS } from 'immutable';
import { REFRESH_PICTURES, SAVE_VC_PICTURE, UPDATE_VC_PICTURE } from './actions';

const createReducer = (pictureId) => {
  const initialState = fromJS({});

  const vcUserPicture = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_VC_PICTURE(pictureId):
        return state.merge(action.data);
      case REFRESH_PICTURES(pictureId):
        return initialState;
      case UPDATE_VC_PICTURE(pictureId):
        return state.merge(action.data);
      default:
        return state;
    }
  };
  return vcUserPicture;
};

export default createReducer;
