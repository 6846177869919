import config from './config';
// Error Codes
export const HttpStatus = {
  BAD_GATEWAY: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};

// Error Messages
export const UNEXPECTED_ERROR = 'Something unexpected happened. Please contact administrator.';
export const UNAUTHORIZED_ERROR = 'Invalid Credentials';
export const EMAIL_NOT_FOUND = 'Email Not Found';
export const INVALID_REQUEST = 'INVALID REQUEST';
export const RESET_PASSWORD_EXPIRED = 'Please try forget password again';

// Constants
export const TREE_EXTERNAL_COLLAPSE = 'collapsed';
export const TREE_EXTERNAL_ACTIVE = 'isActive';
export const TREE_NODE_ACTIVE_OPACITY = 1;
export const TREE_NODE_INACTIVE_OPACITY = 0.5;
export const AUTO_SAVE_TIME_DURATION = 30000; // 30 seconds
export const MAX_PHOTO_UPLOAD_SIZE = 4194304; // 4MB
export const ACCEPTABLE_PHOTO_FORMATS = 'image/png, image/jpeg';

// API Endpoints
export const SIGN_UP_ENDPOINT = '/api/v1/auth.json';
export const SIGN_IN_ENDPOINT = '/api/v1/auth/sign_in.json';
export const VALIDATE_TOKEN_ENDPOINT = '/api/v1/auth/validate_token';
export const REQUEST_FORGET_PASSWORD_ENDPOINT = '/api/v1/auth/password.json';
export const REQUEST_RESET_PASSWORD_ENDPOINT = '/api/v1/auth/password.json';
export const CONVERSATION_TREES_ENDPOINT = '/api/v1/conversation_trees';
export const CONVERSATION_TREES_ENDPOINT_BY_ID = id => `/api/v1/conversation_trees/${id}`;
export const GET_ALL_CONVERSATIONS_ENDPOINT = '/api/v1/conversations';
export const ACTIVITIES_LIKE_TOGGLE_ENDPOINT = id => `/api/v1/activities/${id}/likes_toggle`;
export const COMMENT_LIKE_TOGGLE_ENDPOINT = id => `/api/v1/comments/${id}/comment_likes_toggle`;
export const GET_COMMENTS_ENDPOINT = id => `/api/v1/conversations/${id}/comments`;
export const POST_COMMENTS_ENDPOINT = id => `/api/v1/conversations/${id}/comments`;
export const POST_VISUAL_STATUS_ENDPOINT = '/api/v1/visual_statuses.json';
export const GET_ACTIVITY_LIKES = activityId => `/api/v1/activities/${activityId}/likes`;
export const GET_COMMENT_LIKES = commentId => `/api/v1/comments/${commentId}/comment_likes`;
export const POST_DRAFT_ENDPOINT = '/api/v1/sketchpad_storages';
export const PUT_DRAFT_ENDPOINT = id => `/api/v1/sketchpad_storages/${id}`;
export const GET_DRAFT_INDEX_ENDPOINT = '/api/v1/sketchpad_storages';
export const GET_DRAFT_ENDPOINT = id => `/api/v1/sketchpad_storage_images/${id}`;
export const DELETE_DRAFT_ENDPOINT = id => `/api/v1/sketchpad_storages/${id}`;
export const GET_SEARCH_RESULTS = '/api/v1/search';
export const PUT_VISUAL_STATUS_ENDPOINT = id => `/api/v1/visual_statuses/${id}`;
export const GET_USER_BY_ID = userId => `/api/v1/users/${userId}`;
export const GET_USER_ACTIVITIES_BY_ID = userId => `/api/v1/users/${userId}/activities`;
export const ACTIVITIES_CURATIONS_ENDPOINT = id => `/api/v1/activities/${id}/curations_toggle`;
export const PUT_USER_PROFILE_ENDPOINT = id => `/api/v1/users/${id}`;
export const POST_FOLLOW_USER_ENDPOINT = '/api/v1/followers_toggle';
export const GET_CONVERSATIONS_BY_ID_ENDPOINT = conversationId => `/api/v1/conversations/${conversationId}`;
export const GET_EXPLORE_CHALLENGES = '/api/v1/daily_challenges.json';
export const GET_EXPLORE_CHALLENGE_CONVERSATION = '/api/v1/daily_challenges/daily_challenges_activities';

// Default avatar for profile images from S3 bucket
export const DEFAULT_USER_THUMBNAIL = config.DEFAULT_USER_THUMBNAIL;

// Country Data from third party API
export const GET_COUNTRY_DATA_API_URL = config.GET_COUNTRY_DATA_API_URL;
export const GET_COUNTRY_DATA_API_URL_KEY = config.GET_COUNTRY_DATA_API_URL_KEY;

// Medium Blog third party API
export const MEDIUM_BLOGS_API_URL = config.MEDIUM_BLOGS_API_URL;
export const GET_BLOGS_RESULTS_URL = '/api/v1/blogs.json';
export const GET_PUBLIC_BLOGS_RESULTS_URL = '/api/v1/blogs/public_blogs.json';
export const PUT_CREATE_OR_UPDATE_BLOGS_ENDPOINT = '/api/v1/blogs/create_or_update';
// export const GET_BLOGS_RESULTS = '/api/v1/blogs.json';
// export const PUT_CREATE_OR_UPDATE_BLOGS_ENDPOINT = id => `/api/v1/blogs.json/${id}`;

export const CONTENT_CATEGORIES_LIST = [
  'nft',
  'dao',
  'token-economy',
  'community',
  'crypto',
  'cryptoart',
  'dada',
  'conservation',
  'nft-collectibles',
  'art',
];
