import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Button from '@material-ui/core/Button/Button';
import Avatar from '@material-ui/core/Avatar';

import { shouldUploadPhoto } from '../../utils/validations';
import {
  ACCEPTABLE_PHOTO_FORMATS,
  MAX_PHOTO_UPLOAD_SIZE,
} from '../../constants';

import closeIcon from '../../assets/images/close.png';
import styles from '../ProfileView/styles';
import '../ProfileView/index.scss';
import LevelBar from '../levelBar';

class EditProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: props.photo,
      photoError: null,
    };
    this.fileInput = React.createRef();
    this.handleVisibility = this.handleVisibility.bind(this);
    this.showError = this.showError.bind(this);
    this.isTouched = this.isTouched.bind(this);
    this.handlePhotoUpload = this.handlePhotoUpload.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { successfulUpdate, onCancel } = this.props;

    if (prevProps.successfulUpdate === false && successfulUpdate === true) {
      onCancel();
    }
  }

  onClose() {
    const { onCancel, resetUpdateProfileMessages } = this.props;
    resetUpdateProfileMessages();
    onCancel();
  }

  showError() {
    const { errors, apiError } = this.props;
    const { photoError } = this.state;
    return errors.email || errors.userName || photoError || apiError;
  }

  isTouched() {
    const { touched, apiError } = this.props;
    const { photoError } = this.state;
    return touched.email || touched.userName || photoError || apiError;
  }

  handleVisibility() {
    const { setTouched, resetUpdateProfileMessages } = this.props;
    this.setState({
      photoError: null,
    });
    resetUpdateProfileMessages();
    setTouched(false);
  }

  handlePhotoUpload(event) {
    const { setFieldValue } = this.props;
    const file = event.currentTarget.files[0];
    if (shouldUploadPhoto(file.type, file.size, MAX_PHOTO_UPLOAD_SIZE)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => this.setState({
        photo: reader.result,
      });
      setFieldValue('pic', file);
    } else {
      this.setState({
        photoError: 'Incorrect file type or size',
      });
      this.fileInput.current.value = null;
    }
  }

  render() {
    const {
      points,
      values,
      handleChange,
      drawingsCount,
      maxPointsByLevel,
      isUpdatingProfile,
      level,
    } = this.props;
    const { userName, email, location } = values;
    const { photo } = this.state;

    return (
      <Form>
        <div className="profileAsideHeader">
          {this.showError() && this.isTouched() && (
            <div className="responseMsg error">
              {this.showError()}
              <Button className="close" onClick={this.handleVisibility}>
                <img src={closeIcon} alt="" />
              </Button>
            </div>
          )}
          <div className="profileInfo">
            <div className="profileAvatarWrap">
              <label className="uploadLabel" htmlFor="file">
                <Avatar alt="" src={photo} className="profileAvatar" />
                <input
                  id="file"
                  type="file"
                  className="inputFile"
                  ref={this.fileInput}
                  accept={ACCEPTABLE_PHOTO_FORMATS}
                  onChange={(event) => {
                    this.handlePhotoUpload(event);
                  }}
                />
              </label>
            </div>
            <div className="profileData">
              <div className="profileTitles">
                <input
                  name="userName"
                  value={userName.toLowerCase()}
                  placeholder="Enter name"
                  onChange={handleChange}
                  className="profileName"
                />
                <input
                  value={location}
                  className=""
                  placeholder="Enter Address"
                  name="location"
                  onChange={handleChange}
                  // readOnly
                />
              </div>
              {/* <LevelBar
                points={points}
                maxPointsByLevel={maxPointsByLevel}
                level={level}
              /> */}
            </div>
            {/* <div className="drawingCountWrap">
              <div className="drawingCount">
                <span>{drawingsCount}</span>
              </div>
            </div> */}
          </div>
          <div className="profileAction">
            <div>
              <label className="ActionLabel" htmlFor="userEmail">
                email
                <input
                  id="userEmail"
                  className="ActionField"
                  type="text"
                  value={email}
                  placeholder="myemail@gmail.com"
                  name="email"
                  onChange={handleChange}
                />
              </label>
            </div>
            <button
              className="btn hasProgress"
              type="submit"
              disabled={isUpdatingProfile}
            >
              {isUpdatingProfile && (
                <div className="CircularProgress">
                  <CircularProgress size={14} thickness={4} />
                </div>
              )}
              save
            </button>
            <button
              className="btn btnTransparent"
              type="button"
              onClick={this.onClose}
            >
              cancel
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

const ProfileViewWithFormik = withFormik({
  mapPropsToValues: props => ({
    userName: props.name,
    email: props.email,
    pic: props.photo,
    location: props.location,
  }),
  validationSchema: Yup.object().shape({
    userName: Yup.string()
      .max(30, 'Name should be 30 characters')
      .required('Name is required!'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
  }),
  handleSubmit: (values, { props }) => {
    const {
      userName, email, location, pic,
    } = values;
    const { updateProfile } = props;
    updateProfile({
      email,
      name: userName,
      photo: pic,
      country: location,
    });
  },
})(EditProfileView);

export default withStyles(styles)(ProfileViewWithFormik);
