import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import {
  Avatar, Button, Grid,
} from '@material-ui/core';

import TimeAgo from '../../components/TimeAgo';
import LikeIcon from '../../components/LikeIcon';
import LoadingData from '../../components/LoadingData';

import { getVcComments, likeComment, postComment } from './actions';
import { interactionRequested } from '../VisualConversationFeed/actions';
import { updateVc } from '../VisualConversation/actions';
import { openLoginForm } from '../AuthModal/actions';
import postCommentsEndpointSchema from './schemas';
import LikeListContainer from '../LikeListContainer';
import { getCommentsLikes } from '../../utils/commentsUtils';
import { visualConversationStore } from '../../utils/vcUtils';

import './index.scss';

class VCComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      shouldNotPost: true,
      error: '',
    };
    this.scrollWrapper = React.createRef();
    this.onLikeActivity = this.onLikeActivity.bind(this);
    this.onPost = this.onPost.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEnter = this.onEnter.bind(this);
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    const { postedToggle, list } = this.props;
    if (!prevProps.list || !prevProps.list.length) {
      this.scrollToBottom();
    } else if (prevProps.list.length < list.length && !postedToggle) {
      this.newDataScroll(prevProps.list.length);
    }
    if (prevProps.postedToggle !== postedToggle) {
      this.scrollToBottom();
      this.onNewComment();
    }
  }

  onLikeActivity(commendId, likes_count) {
    const {
      vcId, onLikeComment, isAuthenticated, openLoginDialog, interactionRequested,
    } = this.props;
    if (isAuthenticated) {
      onLikeComment(vcId, commendId, likes_count);
    } else {
      interactionRequested(vcId);
      openLoginDialog();
    }
  }

  onEnter(comment, e) {
    if (e.keyCode === 13) {
      this.onPost(comment);
    }
  }

  onPost(comment) {
    const trimComment = comment.trim();
    const {
      vcId, postComment, isAuthenticated, openLoginDialog,
    } = this.props;

    if (trimComment === '') {
      this.setState({
        error: 'Please type a message to continue.',
      });
      return;
    }

    const payload = postCommentsEndpointSchema(trimComment);
    if (isAuthenticated) {
      this.setState({
        comment: '',
        shouldNotPost: true,
        error: '',
      });
      postComment(vcId, payload);
    } else {
      openLoginDialog();
    }
  }

  onNewComment() {
    const { vcData, updateCommentCount, vcId } = this.props;
    updateCommentCount(vcId, vcData);
  }

  loadMore() {
    const {
      vcId, loadVcComments, nextPage, isRequesting,
    } = this.props;
    if (!isRequesting) {
      loadVcComments(vcId, { page: nextPage });
    }
  }

  handleChange(e) {
    this.setState({
      comment: e.target.value,
    });
    if (e.target.value === '') {
      this.setState({
        shouldNotPost: true,
      });
    } else {
      this.setState({
        shouldNotPost: false,
        error: '',
      });
    }
  }

  scrollToBottom() {
    const scrollHeight = this.scrollWrapper.current.scrollHeight;
    const height = this.scrollWrapper.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.scrollWrapper.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  newDataScroll(prevListLength) {
    const { list } = this.props;
    const scrollHeight = this.scrollWrapper.current.scrollHeight;
    const height = this.scrollWrapper.current.clientHeight;
    const newCommentsLoaded = list.length - prevListLength;
    const singleCommentHeight = Math.round(scrollHeight / list.length);
    const scrollAdded = newCommentsLoaded * singleCommentHeight;
    this.scrollWrapper.current.scrollTop = scrollAdded - height;
  }

  render() {
    const {
      list, hasNext, isLikeRequestInitiated, isPostRequestInitiated, isAuthenticated, statesLocation,
    } = this.props;
    const {
      comment, shouldNotPost, error,
    } = this.state;
    return (
      <Fragment>
        <div className="commentsScroll" ref={this.scrollWrapper}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMore}
            hasMore={hasNext}
            initialLoad
            isReverse
            loader={<LoadingData key={0} />}
            useWindow={false}
          >
            {list.map(elem => (
              <div className="SingleComment" key={elem.id}>
                <Grid container alignItems="center" justify="space-between">
                  <Grid item>
                    <a>
                      <Link to={{
                        pathname: '/profile',
                        search: elem.profile.profileId,
                        state: statesLocation.search,
                      }}
                      >
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Avatar
                              alt={elem.profile.name}
                              src={elem.profile.photo}
                              className="profileAvatar"
                            />
                          </Grid>
                          <Grid item>
                            <div className="profileName">{elem.profile.name}</div>
                          </Grid>
                        </Grid>
                      </Link>
                    </a>
                  </Grid>
                  <Grid item>
                    <Grid container className="profileFooter">
                      <Grid item><TimeAgo datetime={elem.created_at} /> </Grid>
                      <Grid item>
                        <button
                          disabled={isLikeRequestInitiated}
                          type="button"
                          className="dFlex btnReset likeButton"
                          onClick={e => this.onLikeActivity(e, elem.id, elem.comment_likes_count)}
                        >
                          <LikeIcon liked={elem.liked_by_user} />
                          <LikeListContainer
                            identifier={elem.id}
                            count={elem.comment_likes_count}
                            dataFetcher={getCommentsLikes}
                          />
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div className="profileWrapper">
                  <p className="profileMessage">{elem.comment}</p>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
        <div className="commentsFooter">
          <Grid container direction="row">
            <Grid item xs className="setWidth">
              <input
                value={comment}
                disabled={!isAuthenticated}
                placeholder="say something"
                className="placeComment"
                onChange={this.handleChange}
                onKeyDown={e => this.onEnter(comment, e)}
              />
              <span className="commentIcon" />
            </Grid>
            <Grid item xs>
              <Button
                className="btnSecondary"
                disabled={isPostRequestInitiated || shouldNotPost || !isAuthenticated}
                onClick={() => this.onPost(comment)}
              >post
              </Button>
            </Grid>
          </Grid>
          {error && <span>{error}</span>}
        </div>
      </Fragment>
    );
  }
}
VCComments.propTypes = {
  list: PropTypes.array.isRequired,
  nextPage: PropTypes.number,
  hasNext: PropTypes.bool.isRequired,
  isLikeRequestInitiated: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isPostRequestInitiated: PropTypes.bool.isRequired,
  vcId: PropTypes.number.isRequired,
  loadVcComments: PropTypes.func.isRequired,
  onLikeComment: PropTypes.func.isRequired,
  openLoginDialog: PropTypes.func.isRequired,
  postComment: PropTypes.func.isRequired,
  postedToggle: PropTypes.bool.isRequired,
  vcData: PropTypes.object.isRequired,
  updateCommentCount: PropTypes.func.isRequired,
  authCommentAction: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
};

VCComments.defaultProps = {
  nextPage: null,
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const vcComments = state[`vcComments${id}`];
  const vcData = state[visualConversationStore(id)];
  return {
    list: vcComments.get('list').toJS(),
    nextPage: parseInt(vcComments.get('nextPage'), 10),
    hasNext: vcComments.get('hasNext'),
    isRequesting: vcComments.get('isRequesting'),
    isLikeRequestInitiated: vcComments.get('isLikeRequestInitiated'),
    vcId: id,
    isAuthenticated: state.authenticationStatus.get('isAuthenticated'),
    isPostRequestInitiated: vcComments.get('isPostRequestInitiated'),
    postedToggle: vcComments.get('postedToggle'),
    vcData: vcData.get('data'),
    statesLocation: state.router.location,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadVcComments: (id, params) => {
    dispatch(getVcComments(id, params));
  },
  onLikeComment: (vcId, commentId, likesCount) => {
    dispatch(likeComment(vcId, commentId, likesCount));
  },
  openLoginDialog: () => dispatch(openLoginForm(ownProps.authCommentAction)),
  postComment: (vcId, payload) => {
    dispatch(postComment(vcId, payload));
  },
  updateCommentCount: (vcId, data) => {
    const updateData = data.toJS();
    updateData.attributes.comments_count += 1;
    dispatch(updateVc(vcId, updateData));
  },
  interactionRequested: vcId => dispatch(interactionRequested(vcId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VCComments);
