import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LevelDots from '../../components/levelDots';

import './LevelMenu.scss';

class LevelDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      anchorEl: null,
      userLevelValue: props.userLevel,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.printLevel = this.printLevel.bind(this);
    this.triggerLevelInputChange = this.triggerLevelInputChange.bind(this);
    this.onMenuSelectHandler = this.onMenuSelectHandler.bind(this);
  }

  onMenuSelectHandler(level) {
    this.setState({ anchorEl: null, userLevelValue: level });
    this.triggerLevelInputChange(level);
  }

  getLevels(level, isBlog) {
    const levels = [];
    const { userLevelValue } = this.state;
    const start = isBlog ? 0 : 1;

    if (level >= 4 || isBlog) {
      for (let i = 4; i >= start; i--) {
        levels.push(
          <MenuItem
            key={i}
            onClick={() => this.onMenuSelectHandler(i)}
            className={userLevelValue === i ? 'selectedItem' : ''}
          >
            <LevelDots
              level={i}
              trail
              // eslint-disable-next-line no-unneeded-ternary
              isBlog={isBlog ? true : false}
            />
            {i === level && <span className="lineIcon" />}
          </MenuItem>,
        );
      }
    } else {
      for (let i = level; i >= start; i--) {
        levels.push(
          <MenuItem
            key={i}
            onClick={() => this.onMenuSelectHandler(i)}
            className={userLevelValue === i ? 'selectedItem' : ''}
          >
            <LevelDots
              level={i}
              trail
              // eslint-disable-next-line no-unneeded-ternary
              isBlog={isBlog ? true : false}
            />
            {i === level && <span className="lineIcon" /> }
          </MenuItem>,
        );
      }
    }
    return levels;
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  triggerLevelInputChange(level) {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
    nativeInputValueSetter.call(this.myRef.current, level);
    this.myRef.current.dispatchEvent(new window.Event('input', { bubbles: true }));
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  printLevel() {
    const { userLevelValue } = this.state;
    const { userLevel } = this.props;
    /* eslint-disable */
    return isNaN(userLevelValue) || !(userLevelValue > -1) ? userLevel : userLevelValue;
  }

  render() {
    const { anchorEl, userLevelValue } = this.state;
    const { handleChange, userLevel, isBlog } = this.props;
    const levelToShow = this.printLevel();
    return (
      <div className="customLevelWrap"> {userLevel >= 0 ? (
        <div>
          <Button
            aria-owns={anchorEl ? 'simple-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className="levelBtn"
          >
            <span className="selectedLevel">
              {(levelToShow > -1 ) && <LevelDots level={(levelToShow > 4 ? 4 : levelToShow)} trail isBlog={isBlog? true : false} />}
            </span>
            <span className="lineIcon" />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            className="LevelDropdown"
            PopoverClasses={{
              paper: 'LevelDropdownContent'
            }}
          >
              {this.getLevels(userLevel,isBlog)}
          </Menu>
          <input
            name="level"
            value={userLevelValue}
            onChange={handleChange}
            ref={this.myRef}
            className="dNone"
          />
        </div>
      ) : null}
      </div>
    );
  }
}
export default LevelDropdown;
