/* eslint-disable import/prefer-default-export */
// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { concat } from 'rxjs/observable/concat';
import { mergeMap, catchError, throttleTime } from 'rxjs/operators';
import { getExploreChallenge, searchAllByParams } from '../../api/index';
import { UNEXPECTED_ERROR } from '../../constants';

import { saveVCPicture } from '../VCPicture/actions';
import createPictureReducer from '../VCPicture/reducerCreator';
import createProfileReducer from '../VCProfile/reducerCreator';
import { saveVCProfile } from '../VCProfile/actions';
import { saveVcConversation } from '../VisualConversation/actions';
import attachDynamicReducers from '../../reducers/reducerInjector';

import {
  conversationTreesResponseParser,
  generateActiveTrail,
  generateTreeData,
} from '../../utils/vcUtils';

import { openSnackbar } from '../ApplicationSnackbar/actions';
import createVCReducers from '../VisualConversation/reducerCreator';
import createCommentsReducers from '../VCComments/reducerCreator';

import {
  getSearchResultsPictureSuccess,
  clearIndividualPicturesState,
} from '../PictureCarousel/actions';

import {
  getSearchResultsProfileSuccess,
  clearExploreProfileState,
} from '../ProfileCarousel/actions';

import {
  getSearchResultsExploreVcFeedSuccess,
  clearExploreVcFeedState,
} from '../ExploreConversationFeed/actions';

import {
  GET_EXPLORE_CHALLENGE,
  exploreChallengeSuccess,
  exploreChallengeFailure,
  GET_SEARCH_RESULTS,
  getExploreRequestInitiated,
  getExploreRequestSuccess,
  getSearchResultsSuccess,
  getSearchResultsFailure,
  CLEAR_EXPLORE_VIEW_STATE,
} from './actions';

export const getExploreChallengeEpic = (action$, state$) => action$.ofType(GET_EXPLORE_CHALLENGE).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    from(getExploreChallenge(action)).pipe(
      mergeMap(data => [exploreChallengeSuccess(data[0])]),
      catchError(error => of(exploreChallengeFailure(error))),
    ),
  )),
);

export const clearExploreViewState = (action$, state$) => action$
  .ofType(CLEAR_EXPLORE_VIEW_STATE)
  .pipe(
    mergeMap(action => concat(
      of(clearExploreProfileState()),
      of(clearIndividualPicturesState()),
      of(clearExploreVcFeedState()),
    )),
  );

export const getSearchResultsEpic = (action$, state$) => action$.ofType(GET_SEARCH_RESULTS).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    of(getExploreRequestInitiated()),
    from(searchAllByParams(action.params)).pipe(
      mergeMap((data) => {
        const { userResults, tagResults, conversationResults } = data;
        const {
          profileActions,
          profileIds,
          hasNextPage: profileHasNextPage,
          page: profilePage,
        } = apiResponseParserforProfiles(userResults);

        const {
          pictureActions,
          pictureProfileActions,
          pictureIds,
          hasNextPage: pictureHasNextPage,
          page: picturePage,
        } = apiResponseParserforPictures(tagResults);

        const {
          profileActions: conversationProfileActions,
          vcActions,
          idsList,
          hasNextPage: conversationHasNextPage,
          page: conversationPage,
        } = apiResponseParserForConversation(conversationResults);
        return [
          ...pictureActions,
          ...profileActions,
          ...pictureProfileActions,
          ...conversationProfileActions,
          ...vcActions,
          getSearchResultsProfileSuccess(
            profileIds,
            profilePage,
            profileHasNextPage,
          ),
          getSearchResultsPictureSuccess(
            pictureIds,
            picturePage,
            pictureHasNextPage,
          ),
          getSearchResultsExploreVcFeedSuccess(
            idsList,
            conversationPage,
            conversationHasNextPage,
          ),
          getSearchResultsSuccess(data),
          getExploreRequestSuccess(),
        ];
      }),
      catchError(error => [
        getSearchResultsFailure(error),
        openSnackbar(UNEXPECTED_ERROR),
      ]),
    ),
  )),
);

export const apiResponseParserforPictures = (response) => {
  const { links, data = [], profiles } = response;
  let hasNextPage = false;
  let url = null;
  let page = null;

  if (links && links.next) {
    url = new URL(links.next);
    page = new URLSearchParams(url.search).get('page');
    hasNextPage = true;
  }
  const pictureReducers = {};
  const profileReducers = {};
  let pictureActions = [];
  let pictureProfileActions = [];
  let pictureIds = [];
  if (data) {
    data.forEach((picture) => {
      pictureReducers[`vcPicture${picture.id}`] = createPictureReducer(
        picture.id,
      );
    });
    pictureActions = data.map(picture => saveVCPicture(picture.id, picture));
  }
  if (profiles) {
    profiles.forEach((profile) => {
      profileReducers[`vcProfile${profile.id}`] = createProfileReducer(
        profile.id,
      );
    });
    pictureProfileActions = profiles.map(profile => saveVCProfile(profile.id, profile));
    pictureIds = data.map(picture => picture.id);
  }

  attachDynamicReducers({ ...pictureReducers, ...profileReducers });
  return {
    pictureActions,
    pictureProfileActions,
    pictureIds,
    hasNextPage,
    page,
  };
};

export const apiResponseParserforProfiles = (response) => {
  const { links, data = [] } = response;
  let hasNextPage = false;
  const profiles = [...data];

  let url = null;
  let page = null;
  if (links && links.next) {
    url = new URL(links.next);
    page = new URLSearchParams(url.search).get('page');
    hasNextPage = true;
  }

  const profileReducers = {};
  let profileActions = [];
  let profileIds = [];

  if (profiles) {
    profiles.forEach((profile) => {
      profileReducers[`vcProfile${profile.id}`] = createProfileReducer(
        profile.id,
      );
    });
    profileActions = profiles.map(profile => saveVCProfile(profile.id, profile));
    profileIds = profiles.map(profile => profile.id);
  }
  attachDynamicReducers({ ...profileReducers });

  return {
    profileActions,
    profileIds,
    page,
    hasNextPage,
  };
};

export const apiResponseParserForConversation = (response) => {
  const { data, included, links } = response;

  let url = null;
  let page = null;
  let hasNextPage = false;

  if (links && links.next) {
    url = new URL(links.next);
    page = new URLSearchParams(url.search).get('page');
    hasNextPage = true;
  }

  const { nodes, profiles } = conversationTreesResponseParser({
    data,
    included,
  });
  const vcReducers = {};
  const commentReducers = {};
  nodes.forEach((vc) => {
    vcReducers[`visualConversation${vc.id}`] = createVCReducers(vc.id);
    commentReducers[`vcComments${vc.id}`] = createCommentsReducers(vc.id);
  });

  const profileReducers = {};
  profiles.forEach((profile) => {
    profileReducers[`vcProfile${profile.id}`] = createProfileReducer(
      profile.id,
    );
  });
  attachDynamicReducers({
    ...vcReducers,
    ...commentReducers,
    ...profileReducers,
  });
  const vcActions = nodes.map((vc) => {
    const parsedTree = generateTreeData(vc, 0);
    return saveVcConversation(
      vc.id,
      parsedTree,
      generateActiveTrail(parsedTree, []),
    );
  });
  const profileActions = profiles.map(profile => saveVCProfile(profile.id, profile));
  const idsList = nodes.map(vc => vc.id);
  return {
    vcActions,
    profileActions,
    idsList,
    hasNextPage,
    page,
  };
};
