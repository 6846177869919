import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import VisualConversation from '../../containers/VisualConversation';
import { isValidNumber } from '../../utils/validations';
import LoadingData from '../LoadingData';
import {
  REQUEST_AUTH_FEED_COMMENT_INDIVIDUAL_VC_PAGE,
  REQUEST_AUTH_FEED_LIKE_INDIVIDUAL_VC_PAGE,
  REQUEST_AUTH_VC_PROFILE_FOLLOW_INDIVIDUAL_VC_PAGE,
} from '../../containers/AuthModal/actions';


class IndividualVCFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIdValid: isValidNumber(props.id),
    };
  }

  componentDidMount() {
    const { isIdValid } = this.state;
    const { onSuccessfulMount } = this.props;

    if (isIdValid) {
      onSuccessfulMount();
    }
  }

  render() {
    const {
      id, isTreeDataLoaded, isTreeDataLoading, history,
    } = this.props;
    const { isIdValid } = this.state;
    let componentToRender = null;

    if (!isIdValid) {
      componentToRender = <Redirect to="/" />;
    } else if (isTreeDataLoading) {
      componentToRender = <LoadingData />;
    } else if (!isTreeDataLoading && isTreeDataLoaded) {
      componentToRender = (
        <VisualConversation
          id={id}
          history={history}
          isVisible
          disableToggle
          authLikeAction={REQUEST_AUTH_FEED_LIKE_INDIVIDUAL_VC_PAGE}
          authCommentAction={REQUEST_AUTH_FEED_COMMENT_INDIVIDUAL_VC_PAGE}
          authFollowAction={REQUEST_AUTH_VC_PROFILE_FOLLOW_INDIVIDUAL_VC_PAGE}
        />
      );
    }

    return componentToRender;
  }
}

IndividualVCFeed.propTypes = {
  id: PropTypes.number.isRequired,
  onSuccessfulMount: PropTypes.func.isRequired,
  isTreeDataLoaded: PropTypes.bool.isRequired,
  isTreeDataLoading: PropTypes.bool.isRequired,
};

export default IndividualVCFeed;
