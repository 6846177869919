import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import domtoimage from 'dom-to-image';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import calcGrid from './util/calculateGrid';

import './index.scss';
import { gernricPostRequest } from '../../utils/apiRequestUtils';
import {
  SHARE_URL, SHARE_UPLOAD, SHARE_COLLAGE, VERIFY_COLLAGE,
} from '../../utils/constants/index';

export default function CollageModal(props) {
  const {
    openCollageModal, onCloseCollage, data, activity_id, selectedId, title,
  } = props;
  const exportRef = useRef();
  const [imgArray, setImgArray] = useState([]);
  const [gridcss, setGridCss] = useState({});
  const [gridCount, setGridCount] = useState(0);
  const [loader, setLoader] = useState(true);
  const [copied, setCopied] = useState(false);
  const [count, setCount] = useState(data.length);
  const [waitText, setWaitText] = useState('Please wait while we create the collage preview link.');
  useEffect(() => {
    const res = calcGrid(data);
    setImgArray(res.imgArray);
    setGridCss(res.style);
    setGridCount(res.size);
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const uploadCollage = async () => {
      await exportAsImage(exportRef.current, source);
    };
    if (gridCount) {
      uploadCollage();
    }
    return () => {
      source.cancel('Cancelled');
    };
  }, [gridCount]);

  const exportAsImage = async (element, source) => {
    try {
      const verifyRes = await Promise.allSettled([domtoimage.toJpeg(element, { quality: 0.90, cacheBust: true }),
        gernricPostRequest(`${SHARE_URL}${VERIFY_COLLAGE}`, JSON.stringify({
          count, activity_id, selectedId,
        }), {}, { cancelToken: source.token })]);
      if (verifyRes[1].status === 'fulfilled') {
        setLoader(false);
        return;
      }


      const uploadRes = await gernricPostRequest(`${SHARE_URL}${SHARE_UPLOAD}`, JSON.stringify({
        base64: verifyRes[0].value, activity_id, selectedId, title: title.toLowerCase(), count,
      }), {}, { cancelToken: source.token });
      if (uploadRes) setLoader(false);
    } catch (err) {
      setWaitText('Some Error Occured');
    }
  };
  return (

    <Dialog
      fullWidth
      maxWidth="md"
      open={openCollageModal}
      className="dadaModal materialModalScrollFix draftModal"
      scroll="body"
      onClose={() => {
        setCopied(false);
        onCloseCollage();
      }}
      classes={{ paper: 'dadaDialogPaper', container: 'vhAlignCenter' }}
      BackdropProps={{
        classes: {
          root: 'dadaBackDrop',
        },
      }}
    >
      <div
        role="presentation"
        style={{
          position: 'absolute',
          top: '2px',
          right: '7px',
          cursor: 'pointer',
        }}
        onClick={() => { setCopied(false); onCloseCollage(); }}
      > X
      </div>
      <DialogTitle
        disableTypography
        className="dadaModalTitle text-center"
      >
        <div className="preview" ref={exportRef} style={{ gridTemplateColumns: `repeat(${gridCount}, 1fr)` }}>
          {imgArray.map((val, index) => (
            <div
              key={`${val}`}
              className="gridImg"
            >
              <img src={val} alt="test" />

            </div>
          ))}


        </div>
      </DialogTitle>
      <DialogContent className="dadaModalContent py0" style={{ width: '600px' }} />
      <DialogActions className="dadaModalFooter hasBlockBtns">
        {loader

          ? <small>{waitText}</small>


          : (
            <div style={{
              padding: 50, paddingTop: 30, display: 'flex', flexDirection: 'row',
            }}
            >
              <input
                style={{ width: 500, height: 35, fontSize: 18 }}
                value={`${SHARE_URL}${SHARE_COLLAGE}${activity_id}?branchId=${selectedId}`}
              />
              <CopyToClipboard
                text={`${SHARE_URL}${SHARE_COLLAGE}${activity_id}?branchId=${selectedId}`}
                onCopy={() => setCopied(true)}
              >
                <button type="button" style={{ height: 35, width: 100 }}>{copied ? 'Copied!' : 'Copy'}</button>
              </CopyToClipboard>
            </div>
          )}


      </DialogActions>
    </Dialog>

  );
}
