export const GET_INDIVIDUAL_PICTURES_INITIATED = 'GET_INDIVIDUAL_PICTURES_INITIATED';
export const GET_INDIVIDUAL_PICTURES = 'GET_INDIVIDUAL_PICTURES';
export const GET_INDIVIDUAL_PICTURES_SUCCESS = 'GET_INDIVIDUAL_PICTURES_SUCCESS';
export const GET_INDIVIDUAL_PICTURES_FAILURE = 'GET_INDIVIDUAL_PICTURES_FAILURE';
export const GET_SEARCH_RESULTS_PICTURE_SUCCESS = 'GET_SEARCH_RESULTS_PICTURE_SUCCESS';
export const CLEAR_INDIVIDUAL_PICTURES_STATE = 'CLEAR_INDIVIDUAL_PICTURES_STATE';

export const getIndividualPicturesInitiated = () => ({
  type: GET_INDIVIDUAL_PICTURES_INITIATED,
});
export const getIndividualPictures = params => ({
  type: GET_INDIVIDUAL_PICTURES,
  params,
});
export const getIndividualPicturesSuccess = (list, nextPage, hasNextPage) => ({
  type: GET_INDIVIDUAL_PICTURES_SUCCESS,
  list,
  nextPage,
  hasNextPage,
});
export const getIndividualPicturesFailure = error => ({
  type: GET_INDIVIDUAL_PICTURES_FAILURE,
  error,
});
export const getSearchResultsPictureSuccess = (
  list,
  nextPage,
  hasNextPage,
) => ({
  type: GET_SEARCH_RESULTS_PICTURE_SUCCESS,
  list,
  nextPage,
  hasNextPage,
});
export const clearIndividualPicturesState = () => ({
  type: CLEAR_INDIVIDUAL_PICTURES_STATE,
});
