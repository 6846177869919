const styles = theme => ({
  test: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0,
    },
  },
});

export default styles;
