export const OPEN_LOGIN_DIALOG = 'OPEN_LOGIN_DIALOG';
export const OPEN_SIGN_UP_DIALOG = 'OPEN_SIGN_UP_DIALOG';
export const OPEN_FORGOT_PASSWORD_DIALOG = 'OPEN_FORGOT_PASSWORD_DIALOG';
export const CLOSE_AUTH_DIALOG = 'CLOSE_AUTH_DIALOG';
export const RESET_AUTH_DIALOG = 'RESET_AUTH_DIALOG';

export const REQUEST_AUTH_NAVBAR_HOME_PAGE = 'REQUEST_AUTH_NAVBAR_HOME_PAGE';
export const REQUEST_AUTH_NAVBAR_INDIVIDUAL_VC_PAGE = 'REQUEST_AUTH_NAVBAR_INDIVIDUAL_VC_PAGE';

export const REQUEST_AUTH_NAVBAR_BLOG_PAGE = 'REQUEST_AUTH_NAVBAR_BLOG_PAGE';

export const REQUEST_AUTH_FEED_LIKE_HOME_PAGE = 'REQUEST_AUTH_FEED_LIKE_HOME_PAGE';
export const REQUEST_AUTH_FEED_LIKE_INDIVIDUAL_VC_PAGE = 'REQUEST_AUTH_FEED_LIKE_INDIVIDUAL_VC_PAGE';
export const REQUEST_AUTH_FEED_LIKE_PROFILE_VIEW_PAGE = 'REQUEST_AUTH_FEED_LIKE_PROFILE_VIEW_PAGE';

export const REQUEST_AUTH_FEED_COMMENT_HOME_PAGE = 'REQUEST_AUTH_FEED_COMMENT_HOME_PAGE';
export const REQUEST_AUTH_FEED_COMMENT_INDIVIDUAL_VC_PAGE = 'REQUEST_AUTH_FEED_COMMENT_INDIVIDUAL_VC_PAGE';
export const REQUEST_AUTH_FEED_COMMENT_PROFILE_VIEW_PAGE = 'REQUEST_AUTH_FEED_COMMENT_PROFILE_VIEW_PAGE';

export const REQUEST_AUTH_VC_PROFILE_FOLLOW_HOME_PAGE = 'REQUEST_AUTH_VC_PROFILE_FOLLOW_HOME_PAGE';
export const REQUEST_AUTH_VC_PROFILE_FOLLOW_INDIVIDUAL_VC_PAGE = 'REQUEST_AUTH_VC_PROFILE_FOLLOW_INDIVIDUAL_VC_PAGE';
export const REQUEST_AUTH_VC_PROFILE_FOLLOW_PROFILE_VIEW_PAGE = 'REQUEST_AUTH_VC_PROFILE_FOLLOW_PROFILE_VIEW_PAGE';

export const REQUEST_AUTH_DRAWING_TOOL = 'REQUEST_AUTH_DRAWING_TOOL';
export const REQUEST_AUTH_PROFILE_VIEW_FOLLOW = 'REQUEST_AUTH_PROFILE_VIEW_FOLLOW';

export const openLoginForm = (actionToDispatch, successMsg = null) => ({
  type: OPEN_LOGIN_DIALOG, actionToDispatch, successMsg,
});
export const openSignUpForm = actionToDispatch => ({ type: OPEN_SIGN_UP_DIALOG, actionToDispatch });
export const openForgotPasswordForm = actionToDispatch => ({ type: OPEN_FORGOT_PASSWORD_DIALOG, actionToDispatch });
export const closeAuthDialog = () => ({ type: CLOSE_AUTH_DIALOG });
export const resetAuthDialog = () => ({ type: RESET_AUTH_DIALOG });
