import { defaultStorageService } from './storageService';
import { authenticationSuccess } from '../containers/Authenticator/actions';
import { closeAuthDialog } from '../containers/AuthModal/actions';
import { reloadVcFeed } from '../containers/VisualConversationFeed/actions';
import { getConversationTree } from '../containers/VisualConversation/actions';
import { getUserInfo, updateUserProfile } from '../containers/ProfileView/actions';

export function accessTokenHandler(token) {
  if (token === undefined) {
    return defaultStorageService.getStorage().getItem('accessToken');
  }
  return defaultStorageService.getStorage().setItem('accessToken', token);
}

export function uidHandler(uid) {
  if (uid === undefined) {
    return defaultStorageService.getStorage().getItem('uid');
  }
  return defaultStorageService.getStorage().setItem('uid', uid);
}

export function clientHandler(client) {
  if (client === undefined) {
    return defaultStorageService.getStorage().getItem('client');
  }
  return defaultStorageService.getStorage().setItem('client', client);
}


export const saveAuthTokensInStorage = (accessToken, uid, client) => {
  accessTokenHandler(accessToken);
  uidHandler(uid);
  clientHandler(client);
};

export const getAuthTokensFromStorage = () => {
  const accessToken = accessTokenHandler();
  const uid = uidHandler();
  const client = clientHandler();

  if (accessToken && uid && client) {
    return {
      accessToken,
      client,
      uid,
    };
  }

  return {};
};

export const clearAuthTokensFromStorage = () => {
  defaultStorageService.getStorage().removeItems(['accessToken', 'client', 'uid']);
};

export const generateAuthHeaders = (uid, client, accessToken) => ({
  uid,
  client,
  'access-token': accessToken,
});

export const generateAuthHeaderFromStore = store => (
  generateAuthHeaders(store.authenticationTokens.get('uid'),
    store.authenticationTokens.get('client'),
    store.authenticationTokens.get('accessToken'))
);

export const parseAndProcessAuthResponse = (response, requestSuccess) => {
  const { headers } = response;
  const { uid, client } = headers;
  const accessToken = headers['access-token'];
  const data = response.data.data;
  const countryData = response.countryData;
  const { id, attributes } = data;
  const userObject = { userId: id, ...attributes };
  const tokensObject = { uid, client, accessToken };
  saveAuthTokensInStorage(accessToken, uid, client);

  const actionsToDispatch = [
    authenticationSuccess(tokensObject, userObject),
    requestSuccess(),
    closeAuthDialog(),
  ];

  if (countryData && countryData.city) {
    const { email, name, photo } = attributes;
    const { city } = countryData;
    const updateProfilePayload = {
      email, name, photo, country: city,
    };
    actionsToDispatch.splice(1, 0, updateUserProfile(id, updateProfilePayload));
  }

  return {
    userObject,
    tokensObject,
    actionsToDispatch,
  };
};

export const basicAuthenticationFlow = ({ payload }, state, response, requestSuccess) => {
  const { actionsToDispatch } = parseAndProcessAuthResponse(response, requestSuccess);
  return actionsToDispatch;
};

export const refreshFeedAuthenticationFlow = ({ payload }, state, response, requestSuccess) => {
  const { actionsToDispatch } = parseAndProcessAuthResponse(response, requestSuccess);
  const [authSuccess, ...rest] = actionsToDispatch;
  return [
    authSuccess,
    reloadVcFeed(
      { page: 1, first_id: state.loginForm.get('interactedVcsId') },
    ),
    ...rest,
  ];
};

export const refreshConversationTreeIndividualVcPageAuthenticationFlow = ({ payload }, state, response, requestSuccess) => {
  const { actionsToDispatch } = parseAndProcessAuthResponse(response, requestSuccess);
  const [authSuccess, ...rest] = actionsToDispatch;
  const vcId = state.router.location.pathname.split('/')[2];

  return [
    authSuccess,
    getConversationTree(vcId, vcId),
    ...rest,
  ];
};

export const refreshProfileViewFlow = ({ payload }, state, response, requestSuccess) => {
  const { actionsToDispatch } = parseAndProcessAuthResponse(response, requestSuccess);
  const [authSuccess, ...rest] = actionsToDispatch;
  const profileViewState = state.profileView;

  const vcId = profileViewState.get('selectedVc');
  const userId = state.router.location.search.slice(1);

  return [
    authSuccess,
    getUserInfo(userId),
    getConversationTree(vcId, vcId),
    ...rest,
  ];
};

export const logoutHomePageFlow = (action, state) => [
  reloadVcFeed(
    { page: 1 },
  ),
];

export const logoutIndividualVcPageFlow = (action, state) => {
  const vcId = state.router.location.pathname.split('/')[2];
  return [
    getConversationTree(vcId, vcId),
  ];
};
