import { concat, from, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import {
  HttpStatus,
  SIGN_IN_ENDPOINT,
  SIGN_UP_ENDPOINT,
  UNAUTHORIZED_ERROR,
  UNEXPECTED_ERROR,
} from '../../constants';
import {
  CLOSE_AUTH_DIALOG,
  REQUEST_AUTH_FEED_COMMENT_HOME_PAGE,
  REQUEST_AUTH_FEED_LIKE_HOME_PAGE,
  REQUEST_AUTH_NAVBAR_HOME_PAGE,
  REQUEST_AUTH_NAVBAR_BLOG_PAGE,
  resetAuthDialog,
  REQUEST_AUTH_DRAWING_TOOL,
  REQUEST_AUTH_VC_PROFILE_FOLLOW_HOME_PAGE,
  REQUEST_AUTH_PROFILE_VIEW_FOLLOW,
  REQUEST_AUTH_NAVBAR_INDIVIDUAL_VC_PAGE,
  REQUEST_AUTH_FEED_LIKE_INDIVIDUAL_VC_PAGE,
  REQUEST_AUTH_FEED_COMMENT_INDIVIDUAL_VC_PAGE,
  REQUEST_AUTH_VC_PROFILE_FOLLOW_INDIVIDUAL_VC_PAGE,
  REQUEST_AUTH_FEED_LIKE_PROFILE_VIEW_PAGE,
  REQUEST_AUTH_FEED_COMMENT_PROFILE_VIEW_PAGE,
  REQUEST_AUTH_VC_PROFILE_FOLLOW_PROFILE_VIEW_PAGE,
} from './actions';
import {
  requestLoginFailure,
  requestLoginInitiated,
  requestLoginSuccess,
} from '../LoginForm/actions';
import {
  requestSignUpFailure,
  requestSignUpInitiated,
  requestSignUpSuccess,
} from '../SignUpForm/actions';
import { postRequest } from '../../utils/apiRequestUtils';
import {
  refreshFeedAuthenticationFlow,
  basicAuthenticationFlow,
  refreshConversationTreeIndividualVcPageAuthenticationFlow,
  refreshProfileViewFlow,
} from '../../utils/authUtils';

import { getCountryData } from '../../api';

const loginFlow = (dynamicActions, action, state) => concat(
  of(requestLoginInitiated()),
  from(postRequest(SIGN_IN_ENDPOINT, action.payload)).pipe(
    mergeMap((response) => {
      if (response && response.data.errors && response.data.errors.length > 0) {
        return of(requestLoginFailure(response.data.errors));
      }
      return dynamicActions(action, state, response, requestLoginSuccess);
    }),
    catchError((error) => {
      const response = error.response;
      if (response && response.status === HttpStatus.UNAUTHORIZED) return of(requestLoginFailure(UNAUTHORIZED_ERROR));
      return of(requestLoginFailure(UNEXPECTED_ERROR));
    }),
  ),
);

const signUpFlow = (dynamicActions, action, state) => concat(
  of(requestSignUpInitiated()),
  from(getCountryData()).pipe(
    mergeMap((res) => {
      const { city } = res;
      if (res && res.city) {
        const {
          payload: {
            data: { attributes },
          },
        } = action;
        Object.assign(attributes, { ...attributes, country: city });
      }
      return from(postRequest(SIGN_UP_ENDPOINT, action.payload)).pipe(
        mergeMap((response) => {
          const { data } = response;
          if (
            data
              && data.status
              && +data.status === HttpStatus.UNPROCESSABLE_ENTITY
          ) {
            return of(requestSignUpFailure(data.errors[0].full_messages));
          }
          if (data.status) {
            return of(requestSignUpFailure(UNEXPECTED_ERROR));
          }
          return dynamicActions(
            action,
            state,
            response,
            requestSignUpSuccess,
          );
        }),
        catchError(error => of(requestSignUpFailure(UNEXPECTED_ERROR))),
      );
    }),
    catchError(error => of(requestSignUpFailure(UNEXPECTED_ERROR))),
  ),
);

// from(postRequest(SIGN_UP_ENDPOINT, action.payload)).pipe(
//   mergeMap((response) => {
//     const { data } = response;
//     if (data && data.status && (+data.status === HttpStatus.UNPROCESSABLE_ENTITY)) {
//       return of(requestSignUpFailure(data.errors[0].full_messages));
//     } if (data.status) {
//       return of(requestSignUpFailure(UNEXPECTED_ERROR));
//     }
//     return dynamicActions(action, state, response, requestSignUpSuccess);
//   }),
//   catchError(error => of(requestSignUpFailure(UNEXPECTED_ERROR))),
// ),

// const loginFlow = (dynamicActions, action, state) => concat(
//   of(requestLoginInitiated()),
//   from(postRequest(SIGN_IN_ENDPOINT, action.payload))
//   .pipe(switchMap(response => (from(getCountryData())
//   .pipe(mergeMap((res) => {
//       response.countryData = res;
//       return dynamicActions(action, state, response, requestLoginSuccess);
//     }))
//     )),
//     catchError((error) => {
//       const response = error.response;
//       if (response && response.status === HttpStatus.UNAUTHORIZED) return of(requestLoginFailure(UNAUTHORIZED_ERROR));
//       return of(requestLoginFailure(UNEXPECTED_ERROR));
//     }),
//   ),
// );

const requestAuthBase$ = dynamicActions => (action, state) => of(null).pipe(
  mergeMap(() => {
    const isLogin = state.authDialog.get('loginForm');

    return isLogin
      ? loginFlow(dynamicActions, action, state)
      : signUpFlow(dynamicActions, action, state);
  }),
);

const requestAuthBasic$ = requestAuthBase$(basicAuthenticationFlow);
const requestAuthRefreshFeed$ = requestAuthBase$(refreshFeedAuthenticationFlow);
const requestAuthIndividualVCPage$ = requestAuthBase$(
  refreshConversationTreeIndividualVcPageAuthenticationFlow,
);
const requestAuthProfileViewPage$ = requestAuthBase$(refreshProfileViewFlow);

const requestAuthBaseEpic = (actionType, businessLogic$) => (action$, state$) => action$.ofType(actionType).pipe(
  debounceTime(300),
  mergeMap(action => concat(
    of(showLoading('modalBar')),
    businessLogic$(action, state$.value),
    of(hideLoading('modalBar')),
  )),
);

// All epics here are automatically exported into root epic file

export const resetAuthModalEpic = action$ => action$.ofType(CLOSE_AUTH_DIALOG).pipe(
  delay(100),
  map(() => resetAuthDialog()),
);

export const requestAuthNavbarHomeEpic = requestAuthBaseEpic(
  REQUEST_AUTH_NAVBAR_HOME_PAGE,
  requestAuthRefreshFeed$,
);
export const requestAuthNavbarBlogEpic = requestAuthBaseEpic(
  REQUEST_AUTH_NAVBAR_BLOG_PAGE,
  requestAuthRefreshFeed$,
);
export const requestAuthFeedLikeHomeEpic = requestAuthBaseEpic(
  REQUEST_AUTH_FEED_LIKE_HOME_PAGE,
  requestAuthRefreshFeed$,
);
export const requestAuthFeedCommentHomeEpic = requestAuthBaseEpic(
  REQUEST_AUTH_FEED_COMMENT_HOME_PAGE,
  requestAuthRefreshFeed$,
);
export const requestAuthVCProfileFollowHomeEpic = requestAuthBaseEpic(
  REQUEST_AUTH_VC_PROFILE_FOLLOW_HOME_PAGE,
  requestAuthRefreshFeed$,
);

export const requestAuthNavbarIndividualVcPageEpic = requestAuthBaseEpic(
  REQUEST_AUTH_NAVBAR_INDIVIDUAL_VC_PAGE,
  requestAuthIndividualVCPage$,
);
export const requestAuthFeedLikeIndividualVcEpic = requestAuthBaseEpic(
  REQUEST_AUTH_FEED_LIKE_INDIVIDUAL_VC_PAGE,
  requestAuthIndividualVCPage$,
);
export const requestAuthFeedCommentIndividualVcEpic = requestAuthBaseEpic(
  REQUEST_AUTH_FEED_COMMENT_INDIVIDUAL_VC_PAGE,
  requestAuthIndividualVCPage$,
);
export const requestAuthVCProfileFollowIndividualVcEpic = requestAuthBaseEpic(
  REQUEST_AUTH_VC_PROFILE_FOLLOW_INDIVIDUAL_VC_PAGE,
  requestAuthIndividualVCPage$,
);

export const requestAuthFeedLikeProfileViewEpic = requestAuthBaseEpic(
  REQUEST_AUTH_FEED_LIKE_PROFILE_VIEW_PAGE,
  requestAuthProfileViewPage$,
);
export const requestAuthFeedCommentProfileViewEpic = requestAuthBaseEpic(
  REQUEST_AUTH_FEED_COMMENT_PROFILE_VIEW_PAGE,
  requestAuthProfileViewPage$,
);
export const requestAuthVCProfileFollowProfileViewEpic = requestAuthBaseEpic(
  REQUEST_AUTH_VC_PROFILE_FOLLOW_PROFILE_VIEW_PAGE,
  requestAuthProfileViewPage$,
);

export const requestAuthDrawingToolEpic = requestAuthBaseEpic(
  REQUEST_AUTH_DRAWING_TOOL,
  requestAuthBasic$,
);

export const requestAuthProfileViewFollowEpic = requestAuthBaseEpic(
  REQUEST_AUTH_PROFILE_VIEW_FOLLOW,
  requestAuthBasic$,
);
