import React from 'react';
import DadaLogo from '../../assets/images/logo-black-dada.svg';
import './index.scss';

const ResponsiveLogo = () => (
  <div className="responsiveLogo">
    <img src={DadaLogo} alt="dada logo" />
  </div>
);

export default (ResponsiveLogo);
