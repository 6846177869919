// React imports
import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
// Material UI Imports
import {
  Grid, Button, Dialog, Fade, withStyles,
} from '@material-ui/core';
import Scrollbar from 'react-scrollbars-custom';

import styles from './styles';
import './index.scss';

function Transition(props) {
  return <Fade {...props} />;
}
function getSortByClass(dataSet, classes) {
  if (dataSet.length === 1) {
    return classes.singleDialogPaper;
  }
  if (dataSet.length === 2) {
    return classes.doubleDialogPaper;
  }
  return classes.dialogPaper;
}

const VCModalConv = (props) => {
  const {
    isOpen, classes, onClose, dataSet, vcId, currentIndex,
  } = props;
  const [slider, setSlider] = useState(null);
  const singleSliderContainer = useRef();

  useEffect(() => {
    if (singleSliderContainer.current && slider) {
      const ratio = currentIndex - 1.3;
      const slideWidth = singleSliderContainer.current.clientWidth;
      slider.scrollLeft = slideWidth * ratio;
    }
  }, [singleSliderContainer.current, slider]);

  useEffect(() => {
    if (isOpen) {
      const blurEffect = document.querySelector('#blurWrapper');
      blurEffect.classList.add('bgBlur');
    }

    return () => {
      const blurEffect = document.querySelector('#blurWrapper');
      blurEffect.classList.remove('bgBlur');
    };
  });

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={false}
        scroll="body"
        className="materialModalScrollFix"
        classes={{
          paper: getSortByClass(dataSet, classes),
          container: 'vhAlignCenter',
        }}
        BackdropProps={{
          classes: {
            root: classes.backdropTada,
          },
        }}
      >
        <Scrollbar
          style={{ width: '100%' }}
          className="scrollContainerVCModal carouselScrollHorizontalTrackVCModal"
          ref={(ref) => {
            setSlider(ref);
          }}
        >
          <Grid container direction="row" wrap="nowrap" justify="flex-start">
            {dataSet.map((elem, index) => (
              <Grid item key={elem.id}>
                <div className="slides" ref={singleSliderContainer}>
                  <div
                    style={{ backgroundImage: `url(${elem.src})` }}
                    role="button"
                    className="sliderImageVCModal"
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Scrollbar>
        {/* <Button className="closeLightbox" onClick={onClose} /> */}
      </Dialog>
    </div>
  );
};

VCModalConv.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(VCModalConv);
