/* eslint-disable max-len */
// React imports
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import BlogPostForm from '../BlogPostForm';
import {
  getBlogsResults,
  createOrUpdateBlog,
} from '../../containers/BlogsLandingPage/action';

const createOrUpdateBlogPost = (props) => {
  const {
    isOpen, isAuthenticated, blogs, location, getBlogsResults, history,
  } = props;

  const [selectedBlog, setSelectedBlog] = React.useState({});
  const [content, setContent] = React.useState([]);
  const [metaData, setMetaData] = React.useState({});

  React.useEffect(() => {
    async function fetchData() {
      await getBlogsResults(isAuthenticated);
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    setContent(blogs);
    if (blogs.length > 0) {
      setMetaData(blogs[0].feed);
      const path = location.pathname;
      if (path !== '/editorial/category/') {
        const paramsArray = path.split('/');
        const blogId = Number(paramsArray[3]);
        const selectedBlog = blogs.find(obj => obj.id === blogId);
        if (!selectedBlog) {
          history.push('/not-found-404');
        }
        setSelectedBlog(selectedBlog);
        setContent(selectedBlog.content);
      }
    }
  }, [blogs]);
  return (
    <BlogPostForm selectedBlog={selectedBlog} content={content} metaData={metaData} />
  );
};

const mapStateToProps = (state) => {
  const { blogsPage, authenticatedUserDetails, authenticationStatus } = state;
  return {
    is_blogger: authenticatedUserDetails.get('is_blogger'),
    isAuthenticated: authenticationStatus.get('isAuthenticated'),
    blogs: blogsPage.get('blogs') ? blogsPage.get('blogs').toJS() : '',
    updatedBlog: blogsPage.get('updatedBlog'),
    statesLocation: state.router.location,
  };
};

const mapDispatchToProps = dispatch => ({
  getBlogsResults: isAuthenticated => dispatch(getBlogsResults(isAuthenticated)),
  // createOrUpdateBlog: (blogId, payload) => dispatch(createOrUpdateBlog(blogId, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(createOrUpdateBlogPost);
