import React from 'react';
import './index.scss';
import Arrow from '../../../assets/images/arrow.svg';
import leftArrow from '../../../assets/images/left-arrow.svg';
import rightArrow from '../../../assets/images/right-arrow.svg';

const BtnSlider = ({ direction, moveSlide, isVisible }) => {
  const btnClass = direction === 'next' ? 'btn-slide next' : 'btn-slide prev';
  const visibilityClass = !isVisible ? 'disable-btn' : '';
  return (
    <button
      type="button"
      onClick={isVisible ? moveSlide : null}
      className={`${btnClass} ${visibilityClass}`}
    >
      <img src={direction === 'next' ? Arrow : Arrow} alt="prev-next-icon" />
    </button>
  );
};

export default BtnSlider;
