// React & Redux Imports
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI Imports
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

// Project Imports
import styles from './styles';
import { closeWelcomeMessage } from './actions';


const WelcomeMessage = (props) => {
  const { classes, onClose, showWelcomeMsg } = props;
  return (
    <div>
      { showWelcomeMsg
    && (
    <div className={classes.welcomeMsg}>
      <div className={classes.container}>
        <p className={classes.welcomeMsgTxt}>
          Mauris non tempor quam, et lacinia sapien.
          Mauris accumsan eros eget libero posuere vulputate.
          Etiam elit elit, elementum sed varius at, adipiscing
          vitae est. Sed nec felis pellentesque, lacinia dui sed,
          ultricies sapien. Pellentesque orci lectus, consectetur
          vel posuere posuere, rutrum eu ipsum. Aliquam eget odio
          sed ligula iaculis consequat at eget orci.
        </p>
      </div>
      <Button className={classes.closeBtn} onClick={onClose}>
        <Icon className={classes.icon}>close</Icon>
      </Button>
    </div>
    )
}
    </div>
  );
};


WelcomeMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  showWelcomeMsg: PropTypes.bool.isRequired,
};

const welcomeMessageWithStyles = withStyles(styles)(WelcomeMessage);

const mapStateToProps = state => ({
  showWelcomeMsg: state.welcomeMessage.get('open'),
});

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(closeWelcomeMessage());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(welcomeMessageWithStyles);
