const styles = theme => ({
  root: {
    borderRadius: 29,
    backgroundColor: 'rgba(255, 255, 255, 0.77)',
    width: 50,
    height: 33,
  },
  iOSSwitchBase: {
    transform: 'translateX(-5px)',
    height: 33,
    '&$iOSChecked': {
      color: theme.palette.common.white,
      '& + $iOSBar': {
        backgroundColor: '#46748d',
      },
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  iOSChecked: {
    transform: 'translateX(7px)',
    '& + $iOSBar': {
      opacity: 1,
      border: 'none',
    },
  },
  iOSBar: {
    borderRadius: 13,
    width: 30,
    height: 18,
    marginTop: -9,
    marginLeft: -15,
    border: 'none',
    backgroundColor: '#46748d',
    // transition: theme.transitions.create(['background-color', 'border']),
  },
  iOSIcon: {
    width: 16,
    height: 16,
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1],
  },
});


export default styles;
