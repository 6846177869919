const styles = theme => ({
  blackbtn: {
    width: 160,
    height: 29,
    padding: 0,
    minHeight: 1,
    lineHeight: 'normal',
    backgroundColor: '#000000',
    border: 'solid 1px #000000',
    borderRadius: 2,
    color: '#fff',
    fontSize: 13,
    letterSpacing: 0.46,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      color: '#333',
      border: 'solid 1px #f5f5f5',
    },
    '&:disabled': {
      color: '#868686',
      border: 'solid 1px #dadada',
      backgroundColor: '#dadada',
      cursor: 'not-allowed',
    },
  },
  close: {
    width: 24,
    height: 'auto',
    minWidth: 1,
    minHeight: 1,
    padding: 8,
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'transparent',
    borderRadius: 0,
    fontSize: 10,
    fontWeight: 'bold',
  },
  modalFooter: {
    background: '#f5f5f5',
    color: '#50535e',
    textAlign: 'center',
    padding: '20px 0px',
    fontSize: 13,
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0px',
    },
  },
  footerLink1: {
    margin: '10px auto',
    display: 'inline-block',
    padding: '0px 4px',
    textAlign: 'center',
    fontSize: 13,
    color: '#000',
    height: 'auto',
    minHeight: 1,
    background: 'transparent',
    textTransform: 'none',
    minWidth: 1,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  aligntCenterBtn: {
    justifyContent: 'center',
  },
  dialogContent: {
    padding: 0,
  },
  spacing0: {
    margin: '6px 0px 23px',
  },
});

export default styles;
