import { fromJS } from 'immutable';
import {
  REQUEST_SIGN_UP_FAILURE, REQUEST_SIGN_UP_SUCCESS, REQUEST_SIGN_UP_INITIATED, RESET_SIGN_UP_MESSAGES,
} from './actions';

const initialState = fromJS({
  isRequesting: false,
  success: false,
  error: null,
});

const signUpForm = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SIGN_UP_INITIATED:
      return state.merge({ error: null, isRequesting: true });
    case REQUEST_SIGN_UP_FAILURE:
      return state.merge({ isRequesting: false, error: action.error });
    case REQUEST_SIGN_UP_SUCCESS:
      return state.merge({ isRequesting: false, success: true });
    case RESET_SIGN_UP_MESSAGES:
      return state.set('error', null);
    default:
      return state;
  }
};

export default signUpForm;
