// Rxjs Imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import {
  debounceTime, catchError, mergeMap,
} from 'rxjs/operators';
// Other imports
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
// Project Imports
import {
  REQUEST_RESET_PASSWORD, requestResetPasswordFailure, requestResetPasswordInitiated, requestResetPasswordSuccess,
} from './actions';
import {
  HttpStatus,
  REQUEST_RESET_PASSWORD_ENDPOINT,
  UNEXPECTED_ERROR,
  RESET_PASSWORD_EXPIRED,
} from '../../constants';
import { closeAuthDialog } from '../AuthModal/actions';
import { openSnackbar } from '../ApplicationSnackbar/actions';
import { authenticationSuccess } from '../Authenticator/actions';

const requestResetPasswordEpic = (action$, state$, { putRequest }) => action$
  .ofType(REQUEST_RESET_PASSWORD).pipe(
    debounceTime(300),
    mergeMap(action => concat(
      of(requestResetPasswordInitiated()),
      of(showLoading('modalBar')),
      from(putRequest(REQUEST_RESET_PASSWORD_ENDPOINT, {}, action.headers, action.params)).pipe(
        mergeMap((response) => {
          // const { headers } = response;
          // const { uid, client } = headers;
          // const accessToken = headers['access-token'];
          const msg = response.data.message;
          // const { attributes } = data; // attributes contain photo, level, name nad email
          // const userObject = { userId: data.id, ...attributes };
          // const tokensObject = { uid, client, accessToken };
          return [
            requestResetPasswordSuccess(msg),
            // authenticationSuccess(tokensObject, userObject),
            // closeAuthDialog(),
            // openSnackbar(response.data.msg),
          ];
        }),
        catchError((error) => {
          const { response } = error;
          if (response && response.status === HttpStatus.UNAUTHORIZED) {
            return of(requestResetPasswordFailure(RESET_PASSWORD_EXPIRED));
          }
          return of(requestResetPasswordFailure(response.data.error));
        }),
      ),
      of(push('/')), // reset route
      of(hideLoading('modalBar')),
    )),
  );

export default requestResetPasswordEpic;
