import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import styles from './styles';
import VCProfile from '../VCProfile';

// import './index.scss';

const VCPictures = (props) => {
  const {
    activityId, ownerId, src, statesLocation, history,
  } = props;

  return (
    <Grid item>
      <div className="slideContainer">
        <div style={{ margin: '10px' }}>
          <Link
            to={{
              pathname: '/conversation',
              search: JSON.stringify(activityId),
              state: statesLocation.search,
              previous: statesLocation.previous,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${src})`,
              }}
              role="button"
              className="sliderImage"
              tabIndex={0}
            />
          </Link>
          <div>
            <VCProfile id={ownerId} vcId={activityId} history={history} />
          </div>
        </div>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const vcFeedPicture = state[`vcPicture${id}`];
  const vcPicture = vcFeedPicture.toJS();
  const {
    id: activityId, ownerId, title, src,
  } = vcPicture;
  return {
    activityId,
    ownerId,
    title,
    src,
    statesLocation: state.router.location,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(VCPictures));
