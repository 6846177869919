import { fromJS } from 'immutable';
// Project imports
import {
  GET_BLOGS_RESULTS,
  GET_BLOGS_RESULTS_FAILURE,
  GET_BLOGS_RESULTS_SUCCESS,
  CREATE_OR_UPDATE_BLOG,
  CREATE_OR_UPDATE_BLOG_SUCCESS,
  CREATE_OR_UPDATE_BLOG_FAILURE,
} from './action';

const initialState = {

  blogs: null,
  error: null,
  updatedBlog: null,
};

const blogsPage = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case GET_BLOGS_RESULTS_SUCCESS:
      return state.merge({
        blogs: action.blogs,
      });
    case GET_BLOGS_RESULTS_FAILURE:
      return state.merge({
        error: action.error,
      });
    case CREATE_OR_UPDATE_BLOG_SUCCESS:
      return state.merge({
        updatedBlog: action.updatedBlog,
      });
    case CREATE_OR_UPDATE_BLOG_FAILURE:
      return state.merge({
        error: action.error,
      });
    default:
      return state;
  }
};

export default blogsPage;
