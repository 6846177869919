/* eslint-disable import/prefer-default-export */

import { Deserializer, Serializer } from 'jsonapi-serializer';
import cloneDeep from 'lodash/cloneDeep';

const defaultDeserializerOptions = {
  keyForAttribute: 'camelCase',
};

const defaultSerializationOptions = {
  keyForAttribute: 'underscore_case',
};

function fixIncluded(includedField) {
  const newIncluded = [];
  Object.keys(includedField).forEach((key) => {
    Object.keys(includedField[key]).forEach((innerId) => {
      newIncluded.push(includedField[key][innerId]);
    });
  });

  return newIncluded;
}

export function apiDeserializer(data, options) {
  const clonedData = cloneDeep(data);
  if (data.included) {
    clonedData.included = fixIncluded(data.included);
  }
  return new Deserializer({ ...defaultDeserializerOptions, ...options }).deserialize(clonedData);
}

export function apiSerializer(type, data, options) {
  return new Serializer(type, { ...defaultSerializationOptions, ...options }).serialize(data);
}
