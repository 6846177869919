export const GET_VC_COMMENTS = 'GET_VC_COMMENTS';
export const GET_VC_COMMENTS_INITIATED = id => `GET_VC_FEED_INITIATED_${id}`;
export const GET_VC_COMMENTS_SUCCESS = id => `GET_VC_FEED_SUCCESS_${id}`;
export const GET_VC_COMMENTS_COMPLETED = id => `GET_VC_FEED_COMPLETED_${id}`;
export const GET_VC_COMMENTS_FAILURE = id => `GET_VC_FEED_FAILURE_${id}`;
export const LIKE_COMMENT = 'LIKE_COMMENT';
export const LIKE_COMMENT_SUCCESS = id => `LIKE_COMMENT_SUCCESS_${id}`;
export const LIKE_COMMENT_INITIATED = id => `LIKE_COMMENT_INITIATED_${id}`;
export const LIKE_COMMENT_FAILURE = id => `LIKE_COMMENT_FAILURE_${id}`;
export const POST_COMMENT = 'POST_COMMENT';
export const POST_COMMENT_INITIATED = id => `POST_COMMENT_INITIATED_${id}`;
export const POST_COMMENT_SUCCESS = id => `POST_COMMENT_SUCCESS_${id}`;
export const POST_COMMENT_FAILURE = id => `POST_COMMENT_FAILURE_${id}`;
export const REFRESH_COMMENTS = id => `REFRESH_COMMENTS_${id}`;

export const getVcComments = (id, params) => ({
  type: GET_VC_COMMENTS, params, id,
});
export const getVcCommentsCompleted = id => ({
  type: GET_VC_COMMENTS_COMPLETED(id),
});
export const getVcCommentsInitiated = id => ({ type: GET_VC_COMMENTS_INITIATED(id) });
export const getVcCommentsSuccess = (id, list, nextPage, hasNextPage) => ({
  type: GET_VC_COMMENTS_SUCCESS(id), list, nextPage, hasNextPage,
});
export const getVcCommentsFailure = (vcId, error) => ({ type: GET_VC_COMMENTS_FAILURE(vcId), error });

export const likeComment = (vcId, commentId, likesCount) => ({
  type: LIKE_COMMENT, commentId, likesCount, vcId,
});
export const likeCommentSuccess = (vcId, commentId, likes_count, liked_by_user) => ({
  type: LIKE_COMMENT_SUCCESS(vcId), commentId, likes_count, liked_by_user,
});
export const likeCommentFailure = vcId => ({ type: LIKE_COMMENT_FAILURE(vcId) });
export const likeCommentInitiated = vcId => ({ type: LIKE_COMMENT_INITIATED(vcId) });

export const postComment = (vcId, payload) => ({
  type: POST_COMMENT, vcId, payload,
});
export const postCommentSuccess = (vcId, comment) => ({
  type: POST_COMMENT_SUCCESS(vcId), comment,
});
export const postCommentFailure = vcId => ({ type: POST_COMMENT_FAILURE(vcId) });
export const postCommentInitiated = vcId => ({ type: POST_COMMENT_INITIATED(vcId) });
export const refreshComments = vcId => ({ type: REFRESH_COMMENTS(vcId) });
