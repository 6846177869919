import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import {
  Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
} from '@material-ui/core';

import './index.scss';

import LinearProgress from '@material-ui/core/LinearProgress';
import expandIcon from '../../assets/images/expand-arrow-1.svg';
import LevelsComponent from '../LevelsComponent';
import VCComments from '../../containers/VCComments';
import VCBranchTree from '../../containers/VCBranchTree';

class VCExpansionPanel extends Component {
  constructor(props) {
    super(props);
    const {
      title,
    } = props.data.attributes;
    this.state = {
      titleText: title || 'untitled',
    };
    this.expansionPanelChangeHandler = this.expansionPanelChangeHandler.bind(this);
    this.onNodeClickHandler = this.onNodeClickHandler.bind(this);
    this.vcBranchStateTitle = this.vcBranchStateTitle.bind(this);
  }

  onNodeClickHandler(nodeDataName) {
    const { onUpdateActiveBranch } = this.props;
    onUpdateActiveBranch(nodeDataName);
  }

  expansionPanelChangeHandler(event, expanded) {
    const { onExpansionChange, disableToggle } = this.props;
    if (disableToggle) {
      return;
    }

    onExpansionChange(expanded);
  }

  vcBranchStateTitle(title) {
    if (title) {
      this.setState({ titleText: title });
    }
  }

  static renderPanelHeading({
    titleText, level, drawingCountText, commentsCountText, scrollProgress,
  }) {
    return (
      <ExpansionPanelSummary
        expandIcon={<img alt="" src={expandIcon} className="expandIcon" />}
        className="expansionPanel"
      >
        <Grid container alignItems="center" className="dColumnRes">
          <Grid item>
            <h2 className="heading">{titleText.toLowerCase()}</h2>
          </Grid>
          <Grid item className="expandableLevelSpacing">
            <LevelsComponent level={level} />
          </Grid>
          <Grid item>
            <div className="scrollProgress">
              <LinearProgress variant="determinate" value={scrollProgress} className="customColor" />
            </div>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
    );
  }

  render() {
    const {
      data, isTreeDataLoaded, isVisible, authenticatedUserDetails, editVcDetails, editVcDetailsInitiated,
      isExpanded, disableToggle, authCommentAction, selectedId, scrollProgress, imgData,
    } = this.props;

    const {
      comments_count, drawing_count, level, last_modified_at, id, title, ownerId,
    } = data.attributes;
    const drawingCountText = `${drawing_count} Drawing${drawing_count !== 1 ? 's' : ''}`;
    const commentsCountText = `${comments_count} Comment${comments_count !== 1 ? 's' : ''}`;
    const { titleText } = this.state;

    return (
      <div className="expansionPanelContainer">
        <ExpansionPanel
          onChange={this.expansionPanelChangeHandler}
          expanded={isExpanded}
          CollapseProps={{ timeout: { exit: 500 } }}
          className="boxShadowNone"
        >
          {isExpanded ? null : VCExpansionPanel.renderPanelHeading({
            titleText, level, drawingCountText, commentsCountText, scrollProgress,
          })}
          <ExpansionPanelDetails className="expandedPanel expandedPanelWrap">
            <Grid container direction="row">
              <Grid item className="commentsWrapper">
                {isExpanded && isVisible && <VCComments id={id} authCommentAction={authCommentAction} />}
              </Grid>
              <Grid item className="branching">
                <VCBranchTree
                  metaData={{
                    titleText,
                    drawingCountText,
                    level,
                    last_modified_at,
                    authenticatedUserDetails,
                    ownerId,
                    id,
                  }}
                  imgData={imgData}
                  selectedId={selectedId}
                  isTreeDataLoaded={isTreeDataLoaded}
                  shouldShow={isExpanded && isVisible}
                  treeData={data}
                  onNodeClick={this.onNodeClickHandler}
                  editVcDetails={editVcDetails}
                  editVcDetailsInitiated={editVcDetailsInitiated}
                  vcBranchStateTitle={this.vcBranchStateTitle}

                />
              </Grid>
            </Grid>
            {disableToggle ? null : (
              <div
                className="collapseIcon"
                role="button"
                tabIndex={0}
                onClick={event => this.expansionPanelChangeHandler(event, false)}
              />
            )
            }

          </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* <hr className="feedHorizontalBar" /> */}
      </div>
    );
  }
}

VCExpansionPanel.propTypes = {
  data: PropTypes.object.isRequired,
  onUpdateActiveBranch: PropTypes.func.isRequired,
  isTreeDataLoaded: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
  authenticatedUserDetails: PropTypes.object,
  isExpanded: PropTypes.bool.isRequired,
  onExpansionChange: PropTypes.func.isRequired,
  disableToggle: PropTypes.bool,
  authCommentAction: PropTypes.string.isRequired,
  imgData: PropTypes.array,
  selectedId: PropTypes.number,


};

VCExpansionPanel.defaultProps = {
  isVisible: false,
  authenticatedUserDetails: {},
  disableToggle: false,
  imgData: [],
  selectedId: 0,
};

export default VCExpansionPanel;
