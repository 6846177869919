import dotOne from '../../assets/images/explore/dots/one.svg';
import dotTwo from '../../assets/images/explore/dots/two.svg';
import dotThree from '../../assets/images/explore/dots/three.svg';
import dotFour from '../../assets/images/explore/dots/four.svg';
import dotFive from '../../assets/images/explore/dots/five.svg';

import ExploreLogo from '../../assets/images/explore/explore-Logo.svg';
import Line from '../../assets/images/explore/line.svg';
import Add from '../../assets/images/explore/add.svg';
import Check from '../../assets/images/explore/check.svg';
import UnCheck from '../../assets/images/explore/unCheck.svg';
import Zoom from '../../assets/images/explore/zoom.svg';
import InputLayer from '../../assets/images/explore/inputLayer.svg';

export const dots = [
  {
    id: 1,
    src: dotOne,
    value: 'curated',
    title: 'Curated',
    alt: 'Curated',
    isSelected: false,
  },
  {
    id: 2,
    src: dotTwo,
    value: 'undiscovered',
    title: 'Undiscovered',
    alt: 'Undiscovered',
    isSelected: false,
  },
  {
    id: 3,
    src: dotThree,
    value: 'most_recent',
    title: 'Most Recent',
    alt: 'Most Recent',
    isSelected: false,
  },
  {
    id: 4,
    src: dotFour,
    value: 'want_to_draw',
    title: 'I want to draw',
    alt: 'I want to draw',
    isSelected: false,
  },
];

export const filterFive = {
  id: 5,
  src: dotFour,
  value: 'newest_members',
  title: 'Newest Members',
  alt: 'Newest Members',
  isSelected: false,
};

export const logo = ExploreLogo;
export const exploreLine = Line;
export const add = Add;
export const zoom = Zoom;
export const inputLayer = InputLayer;
export const check = Check;
export const unCheck = UnCheck;
