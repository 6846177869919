import React, { Component, Fragment } from 'react';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// importing custom component
import { getExploreVcFeed, getExploreVcFeedByQueryType } from './actions';
import VisualConversation from '../VisualConversation';
import LoadingData from '../../components/LoadingData';
import Loader from '../../components/Loader';

// styles
import styles from './styles';
import '../../components/VCExpansionPanel/index.scss';
import {
  REQUEST_AUTH_FEED_COMMENT_HOME_PAGE,
  REQUEST_AUTH_FEED_LIKE_HOME_PAGE,
  REQUEST_AUTH_VC_PROFILE_FOLLOW_HOME_PAGE,
} from '../AuthModal/actions';

class ExploreConversationFeed extends Component {
  loadExploreVcFeed() {
    const {
      loadExploreVcFeed, nextPage, isRequesting, criteria,
    } = this.props;
    if (!isRequesting && nextPage) {
      loadExploreVcFeed({ query: criteria, page: nextPage });
    }
  }

  loadExploreVcFeedByFilters() {
    const {
      loadExploreVcFeedByFilters,
      nextPage,
      isRequesting,
      criteria,
      queryType,
    } = this.props;
    if (!isRequesting && nextPage) {
      loadExploreVcFeedByFilters({ query: criteria, page: nextPage }, queryType);
    }
  }

  render() {
    const {
      classes,
      list,
      hasNext,
      scrollParentRef,
      isFilter,
      history,
      isRequesting,
    } = this.props;

    return (
      <Fragment>
        {!isEmpty(list) && (
          <div className={classes.vc_wrapper}>
            <div>
              <List className="overFlowHidden">
                <InfiniteScroll
                  pageStart={0}
                  loadMore={() => {
                    !isFilter
                      ? this.loadExploreVcFeed()
                      : this.loadExploreVcFeedByFilters();
                  }}
                  hasMore={hasNext}
                  initialLoad={false}
                  threshold={20}
                  loader={isRequesting ? <LoadingData key={0} /> : null}
                  useWindow={false}
                  getScrollParent={() => scrollParentRef}
                >
                  {list.map(id => (
                    <div key={id}>
                      <div>
                        <ListItem className={classes.singleItem}>
                          <VisibilitySensor
                            partialVisibility
                            offset={{ top: -800, bottom: -800 }}
                          >
                            {({ isVisible }) => (
                              <VisualConversation
                                id={id}
                                isVisible={isVisible}
                                authLikeAction={
                                  REQUEST_AUTH_FEED_LIKE_HOME_PAGE
                                }
                                authCommentAction={
                                  REQUEST_AUTH_FEED_COMMENT_HOME_PAGE
                                }
                                authFollowAction={
                                  REQUEST_AUTH_VC_PROFILE_FOLLOW_HOME_PAGE
                                }
                                history={history}
                              />
                            )}
                          </VisibilitySensor>
                        </ListItem>
                      </div>
                      <hr className="feedHorizontalBar" />
                    </div>
                  ))}
                </InfiniteScroll>
              </List>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const ExploreConversationFeedWithStyles = withStyles(styles)(
  ExploreConversationFeed,
);

const mapStateToProps = state => ({
  list: state.exploreVcFeed.get('list').toJS(),
  nextPage: state.exploreVcFeed.get('nextPage'),
  hasNext: state.exploreVcFeed.get('hasNext'),
  isRequesting: state.exploreVcFeed.get('isRequesting'),
});

const mapDispatchToProps = dispatch => ({
  loadExploreVcFeed: (params) => {
    dispatch(getExploreVcFeed(params));
  },
  loadExploreVcFeedByFilters: (params, queryType) => {
    dispatch(getExploreVcFeedByQueryType(params, queryType));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExploreConversationFeedWithStyles);
