import React, { Fragment } from 'react';
import './index.scss';

const Loader = (props) => {
  const {
    count = 1, image, content, box,
  } = props;
  const loader = [];

  for (let i = 0; i < count; i++) {
    loader.push(
      <div className="loader wrapper">
        <div className="wrapper-cell">
          {image && <div className="image" style={image} />}
          {box && <div className="box" style={box} />}
          {content && (
            <div className="text">
              <div className="text-line" style={content} />
              <div className="text-line" style={content} />
              <div className="text-line" style={content} />
              <div className="text-line" style={content} />
              <div className="text-line" style={content} />
            </div>
          )}
        </div>
      </div>,
    );
  }

  return <Fragment>{loader}</Fragment>;
};

export default Loader;
