export const GET_EDITORIAL_DATA = 'GET_EDITORIAL_DATA';
export const EDITORIAL_DATA_REQUEST_INITIATED = 'EDITORIAL_DATA_REQUEST_INITIATED';
export const EDITORIAL_DATA_REQUEST_SUCCESS = 'EDITORIAL_DATA_REQUEST_SUCCESS';
export const EDITORIAL_DATA_REQUEST_FAILURE = 'EDITORIAL_DATA_REQUEST_FAILURE';
export const CLEAR_EDITORIAL_STATE = 'CLEAR_EDITORIAL_STATE';

export const getEditorialData = () => ({ type: GET_EDITORIAL_DATA });
export const editorialDataRequestInitiated = () => ({ type: EDITORIAL_DATA_REQUEST_INITIATED });
export const editorialDataRequestSuccess = response => ({ type: EDITORIAL_DATA_REQUEST_SUCCESS, blogs: response });
export const editorialDataRequestFailure = err => ({ type: EDITORIAL_DATA_REQUEST_FAILURE, error: err });
export const clearEditorialState = () => ({ type: CLEAR_EDITORIAL_STATE });
