import { fromJS } from 'immutable';
import {
  GET_INDIVIDUAL_PICTURES_INITIATED,
  GET_INDIVIDUAL_PICTURES_SUCCESS,
  GET_INDIVIDUAL_PICTURES_FAILURE,
  CLEAR_INDIVIDUAL_PICTURES_STATE,
  GET_SEARCH_RESULTS_PICTURE_SUCCESS,
} from './actions';
import { getUniqueList } from '../../utils/dataTransform';

const intialState = fromJS({
  list: [],
  isRequesting: false,
  hasNext: false,
  error: null,
  nextPage: 1,
});

const pictureCarousel = (state = intialState, action) => {
  switch (action.type) {
    case GET_INDIVIDUAL_PICTURES_INITIATED:
      return state.merge({ isRequesting: true });
    case GET_INDIVIDUAL_PICTURES_SUCCESS: {
      const pictures = getUniqueList(state.get('list').toJS(), action.list);
      return state.merge({
        isRequesting: false,
        error: null,
        nextPage: action.nextPage,
        hasNext: action.hasNextPage,
        list: pictures,
      });
    }
    case GET_SEARCH_RESULTS_PICTURE_SUCCESS: {
      return state.merge({
        isRequesting: false,
        error: null,
        nextPage: action.nextPage,
        hasNext: action.hasNextPage,
        list: action.list,
      });
    }
    case GET_INDIVIDUAL_PICTURES_FAILURE:
      return state.merge({ isRequesting: false, hasNext: false, error: action.error });
    case CLEAR_INDIVIDUAL_PICTURES_STATE:
      return intialState;
    default:
      return state;
  }
};

export default pictureCarousel;
