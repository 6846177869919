import heart1 from '../../assets/images/hearts/heart1.svg';
import heart2 from '../../assets/images/hearts/heart2.svg';
import heart3 from '../../assets/images/hearts/heart3.svg';
import heart4 from '../../assets/images/hearts/heart4.svg';
import heart5 from '../../assets/images/hearts/heart5.svg';
import heart6 from '../../assets/images/hearts/heart6.svg';
import heart7 from '../../assets/images/hearts/heart7.svg';
import heart8 from '../../assets/images/hearts/heart8.svg';
import heart9 from '../../assets/images/hearts/heart9.svg';
import heart10 from '../../assets/images/hearts/heart10.svg';
import heart11 from '../../assets/images/hearts/heart11.svg';
import heart12 from '../../assets/images/hearts/heart12.svg';
import heart13 from '../../assets/images/hearts/heart13.svg';
import heart14 from '../../assets/images/hearts/heart14.svg';
import heart15 from '../../assets/images/hearts/heart15.svg';
import heart16 from '../../assets/images/hearts/heart16.svg';
import heart17 from '../../assets/images/hearts/heart17.svg';
import heart18 from '../../assets/images/hearts/heart18.svg';
import heart19 from '../../assets/images/hearts/heart19.svg';
import heart20 from '../../assets/images/hearts/heart20.svg';
import heart21 from '../../assets/images/hearts/heart21.svg';
import heart22 from '../../assets/images/hearts/heart22.svg';
import heart23 from '../../assets/images/hearts/heart23.svg';
import heart24 from '../../assets/images/hearts/heart24.svg';
import heart25 from '../../assets/images/hearts/heart25.svg';
import heart26 from '../../assets/images/hearts/heart26.svg';
import heart27 from '../../assets/images/hearts/heart27.svg';
import heart28 from '../../assets/images/hearts/heart28.svg';
import heart29 from '../../assets/images/hearts/heart29.svg';
import heart30 from '../../assets/images/hearts/heart30.svg';
import heart31 from '../../assets/images/hearts/heart31.svg';
import heart32 from '../../assets/images/hearts/heart32.svg';
import heart33 from '../../assets/images/hearts/heart33.svg';
import heart34 from '../../assets/images/hearts/heart34.svg';
import heart35 from '../../assets/images/hearts/heart35.svg';
import heart36 from '../../assets/images/hearts/heart36.svg';
import heart37 from '../../assets/images/hearts/heart37.svg';
import heart38 from '../../assets/images/hearts/heart38.svg';
import heart39 from '../../assets/images/hearts/heart39.svg';
import heart40 from '../../assets/images/hearts/heart40.svg';
import heart41 from '../../assets/images/hearts/heart41.svg';
import heart42 from '../../assets/images/hearts/heart42.svg';
import heart43 from '../../assets/images/hearts/heart43.svg';
import heart44 from '../../assets/images/hearts/heart44.svg';
import heart45 from '../../assets/images/hearts/heart45.svg';
import heart46 from '../../assets/images/hearts/heart46.svg';

const hearts = [
  {
    src: heart1,
    alt: 'heart1',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart2,
    alt: 'heart2',
    style: {
      width: '50px',
      height: '50px',
      position: 'absolute',
    },
  },
  {
    src: heart3,
    alt: 'heart3',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart4,
    alt: 'heart4',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart5,
    alt: 'heart5',
    style: {
      width: '50px',
      height: '50px',
      position: 'absolute',
    },
  },
  {
    src: heart6,
    alt: 'heart6',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart7,
    alt: 'heart7',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart8,
    alt: 'heart8',
    style: {
      width: '60px',
      height: '60px',
      position: 'absolute',
    },
  },
  {
    src: heart9,
    alt: 'heart9',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart10,
    alt: 'heart10',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart11,
    alt: 'heart11',
    style: {
      width: '40px',
      height: '40px',
      position: 'absolute',
    },
  },
  {
    src: heart12,
    alt: 'heart12',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart13,
    alt: 'heart13',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart14,
    alt: 'heart14',
    style: {
      width: '70px',
      height: '70px',
      position: 'absolute',
    },
  },
  {
    src: heart15,
    alt: 'heart15',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart16,
    alt: 'heart16',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart17,
    alt: 'heart17',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart18,
    alt: 'heart18',
    style: {
      width: '50px',
      height: '50px',
      position: 'absolute',
    },
  },
  {
    src: heart19,
    alt: 'heart19',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart20,
    alt: 'heart20',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart21,
    alt: 'heart21',
    style: {
      width: '40px',
      height: '40px',
      position: 'absolute',
    },
  },
  {
    src: heart22,
    alt: 'heart22',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart23,
    alt: 'heart23',
    style: {
      width: '60px',
      height: '60px',
      position: 'absolute',
    },
  },
  {
    src: heart24,
    alt: 'heart24',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart25,
    alt: 'heart25',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart26,
    alt: 'heart26',
    style: {
      width: '70px',
      height: '70px',
      position: 'absolute',
    },
  },
  {
    src: heart27,
    alt: 'heart27',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart28,
    alt: 'heart28',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart29,
    alt: 'heart29',
    style: {
      width: '50px',
      height: '50px',
      position: 'absolute',
    },
  },
  {
    src: heart30,
    alt: 'heart30',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart31,
    alt: 'heart31',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart32,
    alt: 'heart32',
    style: {
      width: '70px',
      height: '70px',
      position: 'absolute',
    },
  },
  {
    src: heart33,
    alt: 'heart33',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart34,
    alt: 'heart34',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart35,
    alt: 'heart35',
    style: {
      width: '50px',
      height: '50px',
      position: 'absolute',
    },
  },
  {
    src: heart36,
    alt: 'heart36',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart37,
    alt: 'heart37',
    style: {
      width: '60px',
      height: '60px',
      position: 'absolute',
    },
  },
  {
    src: heart38,
    alt: 'heart38',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart39,
    alt: 'heart39',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart40,
    alt: 'heart40',
    style: {
      width: '40px',
      height: '40px',
      position: 'absolute',
    },
  },
  {
    src: heart41,
    alt: 'heart41',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart42,
    alt: 'heart42',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart43,
    alt: 'heart43',
    style: {
      width: '70px',
      height: '70px',
      position: 'absolute',
    },
  },
  {
    src: heart44,
    alt: 'heart44',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart45,
    alt: 'heart45',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
  {
    src: heart46,
    alt: 'heart46',
    style: {
      width: '30px',
      height: '30px',
      position: 'absolute',
    },
  },
];

export default hearts;
