// React and redux imports
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
// Material ui imports
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
// Project imports
import Routes from './Routes';
import { store, history } from './store';
import './index.scss';
import LoadingBar from './components/linearLoader';

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'mr-eaves-sans',
      'serif',
    ].join(','),
  },
  palette: {
    background: {
      default: '#ffffff',
    },
  },
});

render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <div>
          <LoadingBar />
          <CssBaseline />
          <Routes />
        </div>
      </ConnectedRouter>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
