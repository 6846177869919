import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import LevelsComponent from '../../components/LevelsComponent';

import styles from './styles';
import './index.scss';

class ExploreProfile extends PureComponent {
  render() {
    const {
      classes,
      name,
      location,
      src,
      level,
      userId,
      statesLocation,
      onAvatarClick,
    } = this.props;
    return (
      <Grid item>
        <Link
          to={{
            pathname: '/profile',
            search: userId,
            state: statesLocation.search,
            previous: statesLocation.previous,
          }}
        >
          <div className="slideContainer">
            <Grid container className={classes.root} alignItems="flex-start">
              <div className="userexploreProfile">
                <div className="exploreProfileLevelContainer">
                  <Avatar
                    alt={name}
                    src={src}
                    className="exploreBigAvatar"
                    onClick={onAvatarClick}
                  />
                  <div className="userProfileLevel">
                    <LevelsComponent level={level} />
                  </div>
                </div>
                <div className="userexploreProfileInfo">
                  <div className="exploreProfileName">{name}</div>
                  <div className="exploreProfileLocation">{location}</div>
                </div>
                <div />
              </div>
            </Grid>
          </div>
        </Link>
      </Grid>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { id } = props;
  const vcFeedProfile = state[`vcProfile${id}`];
  const profile = vcFeedProfile.toJS();
  const {
    name, photo, level, country,
  } = profile;
  return {
    userId: id,
    name,
    src: photo,
    level,
    location: country,
    statesLocation: state.router.location,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ExploreProfile));
