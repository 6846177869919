export default {
  BASE_API_URL: process.env.REACT_APP_API_ENDPOINT,
  DEFAULT_USER_THUMBNAIL: process.env.REACT_APP_DEFAULT_USER_THUMBNAIL,
  GET_COUNTRY_DATA_API_URL: process.env.REACT_APP_GET_COUNTRY_DATA_API_URL,
  GET_COUNTRY_DATA_API_URL_KEY: process.env.REACT_APP_GET_COUNTRY_DATA_API_URL_KEY,
  MEDIUM_BLOGS_API_URL: process.env.REACT_APP_MEDIUM_BLOGS_API_URL,
  AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  AWS_SECRET_KEY: process.env.REACT_APP_AWS_SECRET_KEY,
  AWS_BUCKET_NAME: process.env.REACT_APP_AWS_BUCKET_NAME,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
};
