import React from 'react';
import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

const SaveDraftConfirmationModal = (props) => {
  const {
    open, saveDraft, onClose, isDraftAction,
  } = props;
  return (
    <Dialog
      open={open}
      className="dadaModal materialModalScrollFix dadaActionModal"
      scroll="body"
      classes={{ paper: 'dadaDialogPaper', container: 'vhAlignCenter' }}
      BackdropProps={{
        classes: {
          root: 'dadaBackDrop',
        },
      }}
    >
      <DialogTitle
        disableTypography
        className="dadaModalTitle text-center"
      >
        <Button className="btnReset closeModalIcon" onClick={onClose}>
          <span className="icon" />
        </Button>
        <h3 className="dadaTitle">Do you want to save it as draft?</h3>
      </DialogTitle>
      <DialogActions className="dadaModalFooter hasBlockBtns">
        <Button className="btn btnGreen" onClick={saveDraft}>
          {isDraftAction() && <CircularProgress />}
          Save as Draft
        </Button>
        <Button className="btn btnTransparent" onClick={onClose}>
          No, cancel.
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SaveDraftConfirmationModal;
