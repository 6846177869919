const styles = () => ({
  btn: {
    width: 65,
    height: 32,
    borderRadius: 2,
    fontSize: 13,
    fontWeight: 'normal',
    lineHeight: '1.1538461538461537',
    letterSpacing: 0.46,
    background: '#000',
    padding: '0',
    color: '#fff',
    textTransform: 'none',
    marginBottom: 24,
    '&:hover': {
      background: '#000',
    },
  },
  btnWhite: {
    width: 65,
    height: 32,
    borderRadius: 2,
    marginBottom: 24,
    fontSize: 13,
    fontWeight: 'normal',
    textTransform: 'none',
    lineHeight: '1.1538461538461537',
    letterSpacing: '0.4642857015132904px',
    background: '#fff',
    color: '#000',
    padding: 0,
    border: '1px solid #000',
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  headerMain: {

    background: '#fff',
    height: 60,
    padding: '0px 15px',
    boxShadow: 'none',
    paddingTop: 23,
  },
  logoMedia: {
    width: 120,
    height: 86,
    minHeight: 86,
    minWidth: 120,
  },
  welcomeMsg: {
    padding: '29px 0px',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.5)',
    backgroundColor: 'rgba(250, 244, 236, 0.7)',
    border: 'solid 1px #000000',
    marginBottom: 30,
    position: 'relative',
  },
  container: {
    maxWidth: 645,
    margin: '0px auto',
  },
  welcomeMsgTxt: {
    fontSize: 15,
    letterSpacing: 0.035,
    color: '#1e1e1e',
  },
  closeBtn: {
    width: 'auto',
    height: 'auto',
    border: 'none',
    borderRadius: 0,
    padding: 5,
    minWidth: 1,
    minHeight: 1,
    display: 'block',
    fontSize: 0,
    background: 'transparent',
    position: 'absolute',
    right: 0,
    top: 0,
    lineHeight: 0,
  },
});

export default styles;
