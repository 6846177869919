const userActivitiesPageParser = (response) => {
  let nextPage = 1;
  let hasNext = false;
  if (response[0] && response[0].links.next) {
    const url = new URL(response[0].links.next);
    nextPage = new URLSearchParams(url.search).get('page');
    hasNext = true;
  }
  return {
    hasNext, nextPage, activities: response,
  };
};

export default userActivitiesPageParser;
