import { fromJS } from 'immutable';
import {
  GET_VC_COMMENTS_COMPLETED,
  GET_VC_COMMENTS_FAILURE,
  GET_VC_COMMENTS_INITIATED,
  GET_VC_COMMENTS_SUCCESS, LIKE_COMMENT_FAILURE, LIKE_COMMENT_INITIATED, LIKE_COMMENT_SUCCESS,
  POST_COMMENT_INITIATED, POST_COMMENT_SUCCESS, POST_COMMENT_FAILURE, REFRESH_COMMENTS,
} from './actions';

const createReducer = (id) => {
  const initialState = fromJS({
    list: [],
    isRequesting: false,
    isLikeRequestInitiated: false,
    isPostRequestInitiated: false,
    hasNext: true,
    error: null,
    nextPage: 1,
    postedToggle: false,
  });

  const vcComments = (state = initialState, action) => {
    switch (action.type) {
      case GET_VC_COMMENTS_INITIATED(id):
        return state.merge({ isRequesting: true });
      case GET_VC_COMMENTS_SUCCESS(id): {
        const tempState = state.updateIn(['list'], arr => fromJS([...action.list, ...arr.toJS()]));
        return tempState.merge({
          error: null, nextPage: action.nextPage, hasNext: action.hasNextPage,
        });
      }
      case GET_VC_COMMENTS_COMPLETED(id):
        return state.set('isRequesting', false);
      case GET_VC_COMMENTS_FAILURE(id):
        return state.merge({ isRequesting: false, error: action.error });
      case LIKE_COMMENT_INITIATED(id):
        return state.set('isLikeRequestInitiated', true);
      case LIKE_COMMENT_SUCCESS(id): {
        const indexOfListToUpdate = state.get('list').findIndex(listItem => listItem.get('id') === action.commentId);
        let tempState = state.setIn(['list', indexOfListToUpdate, 'comment_likes_count'], action.likes_count);
        tempState = tempState.setIn(['list', indexOfListToUpdate, 'liked_by_user'], action.liked_by_user);
        return tempState.set('isLikeRequestInitiated', false);
      }
      case LIKE_COMMENT_FAILURE(id):
        return state.set('isLikeRequestInitiated', false);
      case POST_COMMENT_INITIATED(id):
        return state.set('isPostRequestInitiated', true);
      case POST_COMMENT_SUCCESS(id): {
        const tempState = state.updateIn(['list'], arr => arr.push(fromJS(action.comment)));
        return tempState.merge({
          isPostRequestInitiated: false,
          error: null,
          postedToggle: !tempState.get('postedToggle'),
        });
      }
      case POST_COMMENT_FAILURE(id):
        return state.merge({ isRequesting: false, error: action.error });
      case REFRESH_COMMENTS(id):
        return initialState;
      default:
        return state;
    }
  };
  return vcComments;
};

export default createReducer;
