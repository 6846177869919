
// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import {
  debounceTime, mergeMap, catchError,
} from 'rxjs/operators';

import { openSnackbar } from '../ApplicationSnackbar/actions';

import {
  GET_INDIVIDUAL_PICTURES,
  getIndividualPicturesInitiated,
  getIndividualPicturesSuccess,
  getIndividualPicturesFailure,
} from './actions';

import { searchTagsByParams } from '../../api';
import { UNEXPECTED_ERROR } from '../../constants';
import { apiResponseParserforPictures } from '../ExploreView/epic';

const requestGetIndividualPictureEpic = (action$, state$) => action$.ofType(GET_INDIVIDUAL_PICTURES).pipe(
  debounceTime(500),
  mergeMap(action => concat(
    of(getIndividualPicturesInitiated()),
    from(searchTagsByParams(action.params))
      .pipe(
        mergeMap((response) => {
          const {
            pictureActions,
            pictureProfileActions,
            pictureIds,
            hasNextPage,
            page,
          } = apiResponseParserforPictures(response);
          return [...pictureActions, ...pictureProfileActions, getIndividualPicturesSuccess(pictureIds, page, hasNextPage)];
        }),
        catchError(error => [
          getIndividualPicturesFailure(UNEXPECTED_ERROR),
          openSnackbar(UNEXPECTED_ERROR),
        ]),
      ),
  )),
);

export default requestGetIndividualPictureEpic;
