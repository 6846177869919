// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import { debounceTime, mergeMap, catchError } from 'rxjs/operators';
import { openSnackbar } from '../ApplicationSnackbar/actions';
import { UNEXPECTED_ERROR, GET_ALL_CONVERSATIONS_ENDPOINT } from '../../constants';
import {
  GET_EXPLORE_VC_FEED,
  GET_EXPLORE_VC_FEED_BY_QUERYTYPE,
  getExploreVcFeedFailure,
  getExploreVcFeedInitiated,
  getExploreVcFeedSuccess,
} from './actions';
import { generateAuthHeaderFromStore, uidHandler } from '../../utils/authUtils';
import { apiResponseParserForConversation } from '../ExploreView/epic';
import { getExploreRequestSuccess } from '../ExploreView/actions';
import { searchConversationsByParams, getExploreVCFeedByEndpoint } from '../../api';

export const exploreVCFeedEpic = (action$, state$) => action$.ofType(GET_EXPLORE_VC_FEED).pipe(
  debounceTime(500),
  mergeMap(action => concat(
    of(getExploreVcFeedInitiated()),
    from(searchConversationsByParams(action.params)).pipe(
      mergeMap((response) => {
        const {
          profileActions,
          vcActions,
          idsList,
          page,
          hasNextPage,
        } = apiResponseParserForConversation(response);
        return [
          ...profileActions,
          ...vcActions,
          getExploreVcFeedSuccess(idsList, page, hasNextPage),
        ];
      }),
      catchError(error => [
        getExploreVcFeedFailure(UNEXPECTED_ERROR),
        openSnackbar(UNEXPECTED_ERROR),
      ]),
    ),
  )),
);

export const exploreVCFeedFilterEpic = (action$, state$) => action$.ofType(GET_EXPLORE_VC_FEED_BY_QUERYTYPE).pipe(
  debounceTime(500),
  mergeMap(action => concat(
    of(getExploreVcFeedInitiated()),
    from(getExploreVCFeedByEndpoint(action.params, action.queryType, generateAuthHeaderFromStore(state$.value))).pipe(
      mergeMap((response) => {
        const {
          profileActions,
          vcActions,
          idsList,
          page,
          hasNextPage,
        } = apiResponseParserForConversation(response.data);
        return [
          ...profileActions,
          ...vcActions,
          getExploreVcFeedSuccess(idsList, page, hasNextPage),
          getExploreRequestSuccess(),
        ];
      }),
      catchError(error => [
        getExploreVcFeedFailure(UNEXPECTED_ERROR),
        openSnackbar(UNEXPECTED_ERROR),
      ]),
    ),
  )),
);
