// React and Redux Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
// Project Imports
import { generateAuthHeaders, getAuthTokensFromStorage } from '../../utils/authUtils';
import { authenticationRequest, notAuthenticated } from './actions';
import { isEmpty } from '../../utils/validations';

const AuthenticatorHOC = (WrappedComponent) => {
  class Authenticator extends Component {
    componentDidMount() {
      const { authenticate, isAuthenticated, history } = this.props;
      if (!isAuthenticated) {
        authenticate(history);
      }
    }

    render() {
      const { isAuthenticating } = this.props;
      if (isAuthenticating) {
        return null;
      }
      return (<WrappedComponent {...this.props} />);
    }
  }
  Authenticator.propTypes = {
    isAuthenticating: PropTypes.bool.isRequired,
    authenticate: PropTypes.func.isRequired,
  };
  const mapStateToProps = (state, props) => ({
    ...props,
    isAuthenticating: state.authenticationStatus.get('isRequesting'),
    isAuthenticated: state.authenticationStatus.get('isAuthenticated'),
  });

  const mapDispatchToProps = dispatch => ({
    authenticate: (history) => {
      const authTokens = getAuthTokensFromStorage();
      if (isEmpty(authTokens)) {
        dispatch(notAuthenticated());
        // history.push('/login');
        return;
      }
      const authHeaders = generateAuthHeaders(
        authTokens.uid,
        authTokens.client,
        authTokens.accessToken,
      );
      dispatch(authenticationRequest(authHeaders));
    },
  });

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(Authenticator));
};

export default AuthenticatorHOC;
