// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import {
  mergeMap, catchError, throttleTime,
} from 'rxjs/operators';
import { openSnackbar } from '../ApplicationSnackbar/actions';
import { generateAuthHeaderFromStore, uidHandler } from '../../utils/authUtils';
import {
  getBlogsResultsAPI,
  createOrUpdateBlogApi,
} from '../../api/index';
import {
  GET_BLOGS_RESULTS,
  CREATE_OR_UPDATE_BLOG,
  getBlogsResultsSuccess,
  blogsResultsFailure,
  createOrUpdateBlog,
  createOrUpdateBlogFailure,
  createOrUpdateBlogSuccess,
} from './action';

// eslint-disable-next-line import/prefer-default-export
export const getBlogsResultsEpic = (action$, state$) => action$.ofType(GET_BLOGS_RESULTS).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    from(
      getBlogsResultsAPI(action, generateAuthHeaderFromStore(state$.value)),
    ).pipe(
      mergeMap(data => [getBlogsResultsSuccess(data)]),
      // eslint-disable-next-line function-paren-newline
      catchError((error) => {
        const err = 'No Blogs available';
        return of((blogsResultsFailure(err)));
      }),
    ),
  )),
);

export const createOrUpdateBlogEpic = (action$, state$) => action$.ofType(CREATE_OR_UPDATE_BLOG).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    from(
      createOrUpdateBlogApi(action, generateAuthHeaderFromStore(state$.value)),
    ).pipe(
      mergeMap((data) => {
        const { is_published } = action.payload.data.attributes;
        let artMessage = 'Article is successfully unpublished.';
        if (is_published) {
          artMessage = 'Article is successfully published.';
        }
        return [createOrUpdateBlogSuccess(data), openSnackbar(artMessage)];
      }),
      // eslint-disable-next-line arrow-body-style
      catchError((error) => {
        // const err = 'No Blogs available';
        return of((createOrUpdateBlogFailure(error)));
      }),
    ),
  )),
);
