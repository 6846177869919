import { fromJS } from 'immutable';
import {
  GET_EXPLORE_VC_FEED_FAILURE,
  GET_EXPLORE_VC_FEED_INITIATED,
  GET_EXPLORE_VC_FEED_SUCCESS,
  GET_SEARCH_RESUTLS_EXPLORE_VC_FEED_SUCCESS,
  CLEAR_EXPLORE_VC_FEED_STATE,
} from './actions';
import { getUniqueList } from '../../utils/dataTransform';

const intialState = fromJS({
  list: [],
  isRequesting: false,
  hasNext: false,
  error: null,
  nextPage: 1,
});

const exploreVcFeed = (state = intialState, action) => {
  switch (action.type) {
    case GET_EXPLORE_VC_FEED_INITIATED:
      return state.merge({ isRequesting: true });
    case GET_EXPLORE_VC_FEED_SUCCESS: {
      const uniqueVcIdsList = getUniqueList(
        state.get('list').toJS(),
        action.list,
      );
      return state.merge({
        isRequesting: false,
        error: null,
        nextPage: action.nextPage,
        list: uniqueVcIdsList,
        hasNext: action.hasNextPage,
      });
    }
    case GET_SEARCH_RESUTLS_EXPLORE_VC_FEED_SUCCESS: {
      return state.merge({
        isRequesting: false,
        error: null,
        nextPage: action.nextPage,
        hasNext: action.hasNextPage,
        list: action.list,
      });
    }
    case GET_EXPLORE_VC_FEED_FAILURE:
      return state.merge({
        isRequesting: false,
        hasNext: false,
        error: action.error,
      });
    case CLEAR_EXPLORE_VC_FEED_STATE:
      return intialState;
    default:
      return state;
  }
};

export default exploreVcFeed;
