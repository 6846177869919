// React and Redux Imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
// Material UI Imports
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Grid from '@material-ui/core/Grid/Grid';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
// Project imports
import LoginForm from '../LoginForm';
import SignUpForm from '../SignUpForm';
import ForgetPassword from '../ForgetPasswordForm';
import ResetPassword from '../ResetPassword';
import styles from './styles';
import { closeAuthDialog } from './actions';
import LoadingBar from '../../components/linearLoader';
import { openResetPasswordForm } from '../ResetPassword/actions';
// Image imports
import signupLog from '../../assets/images/signup-log.jpg';

function Transition(props) {
  return <Fade {...props} />;
}

class AuthModal extends Component {
  componentDidMount() {
    const { openResetPasswordForm } = this.props;
    if (window.location.pathname.toLowerCase() === '/forget-password') {
      openResetPasswordForm();
    }
  }

  render() {
    const {
      open, closeAuthDialog, loginForm, signUpForm, forgotPasswordForm, resetPasswordForm, classes,
    } = this.props;
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          onClose={() => {
            closeAuthDialog();
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className="zIndexLogin"
          classes={{ paper: classes.dialogPaper }}
          BackdropProps={{
            classes: {
              root: classes.backdropTada,
            },
          }}
        >
          <LoadingBar scope="modalBar" />
          <div>
            <Grid
              className={classes.dialogLogo}
              container
              direction="row"
              justify="center"
              alignItems="flex-end"
            >
              <img src={signupLog} alt="" className={`${classes.media}  ${classes.card}`} />
            </Grid>
          </div>
          <DialogTitle
            open={open}
            id="alert-dialog-slide-title"
            className={classes.dialogHeader}
            disableTypography
          >
            <h2 className={classes.modalTitle}>Living art</h2>
          </DialogTitle>
          {signUpForm ? <SignUpForm /> : ''}
          {loginForm ? <LoginForm /> : ''}
          {forgotPasswordForm ? <ForgetPassword /> : ''}
          {resetPasswordForm ? <ResetPassword /> : ''}
          {}
        </Dialog>
      </div>
    );
  }
}

AuthModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loginForm: PropTypes.bool.isRequired,
  signUpForm: PropTypes.bool.isRequired,
  forgotPasswordForm: PropTypes.bool.isRequired,
  resetPasswordForm: PropTypes.bool.isRequired,
  closeAuthDialog: PropTypes.func.isRequired,
  openResetPasswordForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { authDialog } = state;
  return {
    open: authDialog.get('isOpen'),
    loginForm: authDialog.get('loginForm'),
    forgotPasswordForm: authDialog.get('forgotPasswordForm'),
    signUpForm: authDialog.get('signUpForm'),
    resetPasswordForm: authDialog.get('resetPasswordForm'),
  };
};

const mapDispatchToProps = dispatch => ({
  closeAuthDialog: () => { dispatch(closeAuthDialog()); },
  openResetPasswordForm: () => { dispatch(openResetPasswordForm()); },
});

const AuthModalWithStyles = withStyles(styles)(AuthModal);
export default connect(mapStateToProps, mapDispatchToProps)(AuthModalWithStyles);
