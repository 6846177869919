/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
// import { Typography } from '@mui/material';

import Dialog from '@material-ui/core/Dialog/Dialog';
import { Form, withFormik, Formik } from 'formik';
import * as Yup from 'yup';

import ChipInput from 'material-ui-chip-input';
import LevelDropdown from '../../containers/DrawingTool/LevelMenu';
import SectionDropdown from '../../containers/BlogsLandingPage/BlogsSectionMenu';

import './index.scss';


function BlogEdit(props) {
  const {
    values, userLevel,
    handleChange,
    editEditorial,
    setFieldValue, isOpen, data, onClose,
  } = props;
  const [tagsInputRef, setTagsInput] = React.useState(null);
  const [tags, setTags] = React.useState([]);
  const [published, setPublished] = React.useState(data.is_published);
  const blogsSection = ['DADArlings', 'Faqs and F*cks', 'DADA in Action', 'DADAaffairs', 'Our Worldview', 'News'];
  React.useEffect(() => { setPublished(data.is_published); }, [data.is_published]);
  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = 'title is required';
    } else if (values.title.length > 300) {
      errors.title = 'title is too big';
    } else if (!values.section && data.is_published) {
      errors.title = 'section is mandatory';
    }
    return errors;
  };

  const togglePublished = (e) => {
    setPublished(e.target.checked);
  };

  const submitForm = (values) => {
    const payload = {
      data: {
        attributes:
        {
          title: values.title,
          accesability_level: Number(values.level),
          is_published: published,
          section: values.section,
        },
      },
    };
    editEditorial(data.id, payload);
  };

  const artButton = data.is_published ? 'Update' : 'Post';

  const initialValues = {
    title: data.title,
    level: data.accesability_level,
    section: data.sections,
  };


  useEffect(() => {
    if (isOpen) {
      const blurEffect = document.querySelector('#blurWrapper');
      blurEffect.classList.add('bgBlur');
    }

    return () => {
      const blurEffect = document.querySelector('#blurWrapper');
      blurEffect.classList.remove('bgBlur');
    };
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      className="blogPanel blogCanvas"
      scroll="body"
      classes={{ paper: 'dadaDialogPaper editModalBox', container: 'vhAlignCenter' }}
      BackdropProps={{
        classes: {
          root: 'dadaBackDrop',
        },
      }}
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={submitForm}
      >
        {(formik) => {
          const {
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
          } = formik;
          return (
            <form onSubmit={handleSubmit}>
              <div className="blogBoxWrapper">
                <input
                  placeholder="enter blog name..."
                  name="title"
                  value={values.title}
                  maxLength="50"
                  onChange={handleChange}
                  className="blogTitleName"
                />
                {errors.title && touched.title && (
                  <span className="error">{errors.title}</span>
                )}
                <div className="line-sep" />
                <div className="levelsInteractions">
                  <LevelDropdown
                    handleChange={handleChange}
                    userLevel={data.accesability_level}
                    setFieldValue={setFieldValue}
                    isBlog
                  />
                  <div className="customChips">
                    <SectionDropdown
                      handleChange={handleChange}
                      blogsSection={blogsSection}
                      setFieldValue={setFieldValue}
                      selectedSection={data.sections}
                      published={data.is_published}
                    />
                    <div>
                      <FormControlLabel
                        className="checkBox"
                        label={(
                          <Typography variant="h6">
                            publish an article!
                          </Typography>
                        )}
                        control={<Checkbox checked={published} onChange={togglePublished} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>

          );
        }}
      </Formik>
    </Dialog>
  );
}


export default BlogEdit;
