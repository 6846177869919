(function () {
    dadaCanvas.SketchpadStorage = {
        params: {},
        storages_array: [],
        initialize: function (root_id, parent_id) {
            if (root_id == null) {
                root_id = 0;
            }
            if (parent_id == null) {
                parent_id = 0;
            }
            return this.params = {
                id: 0,
                img_low: "",
                img_large: "",
                img_large_path: "",
                bg_color: "rgb(255, 255, 255)",
                root_id: root_id,
                parent_id: parent_id,
                delete_flag: false,
                blob_file: null
            };
        },
        saveSketchpad: function (button, post) {
            // this.createSketchpadStorage(button, post);
            return true;
        },
        createSketchpadStorage: function (btn, post) {
            this.params.delete_flag = post;
            $.ajax({
                url: "/api/v1/sketchpad_storages.json",
                type: "POST",
                data: this.get_form_data(),
                contentType: false,
                processData: false,
                success: function (response) {
                    dadaCanvas.SketchpadStorage.params.id = response.id;
                    // dadaCanvas.restart_auto_save();
                    if (btn) {
                        return setTimeout((function () {
                            $('.save_loading').addClass('hidden');
                            return btn.button('reset');
                        }), 10000);
                    }
                },
                error: function (response) {
                    // dadaCanvas.restart_auto_save();
                    if (btn) {
                        $('.save_loading').addClass('hidden');
                        btn.button('reset');
                        return DadaAlert.error('oops! your drawing was not saved. please try again.');
                    }
                }
            });
            return true;
        },
        updateSketchpadStorage: function (btn) {
            $.ajax({
                url: Routes.api_v1_sketchpad_storage_path(this.params.id),
                type: "PUT",
                data: this.get_form_data(),
                contentType: false,
                processData: false,
                success: function (response) {
                    // dadaCanvas.restart_auto_save();
                    if (btn) {
                        return setTimeout((function () {
                            $('.save_loading').addClass('hidden');
                            return btn.button('reset');
                        }), 10000);
                    }
                },
                error: function (response) {
                    // dadaCanvas.restart_auto_save();
                    if (btn) {
                        $('.save_loading').addClass('hidden');
                        btn.button('reset');
                        return DadaAlert.error('oops! your drawing was not saved. please try again.');
                    }
                }
            });
            return true;
        },
        get_form_data: function () {
            var data;
            data = new FormData;
            data.append('blob_file', this.params.blob_file);
            data.append('id', this.params.id);
            data.append('img_low', null);
            data.append('img_large', null);
            data.append('bg_color', this.params.bg_color);
            data.append('root_id', this.params.root_id);
            data.append('parent_id', this.params.parent_id);
            data.append('delete_flag', this.params.delete_flag);
            return data;
        }
    };

}).call(this);
