import uniq from 'lodash/uniq';
import { isValidFilledObject, isFunction } from './validations';

export function flattenObj(object, separator = '.') {
  const isSeparatorFunc = isFunction(separator);

  const walker = (child, path = []) => Object.assign({}, ...Object.keys(child).map((key) => {
    const generatedKey = isSeparatorFunc
      ? path.concat([key]).reduce(separator, '')
      : path.concat([key]).join(separator);

    return isValidFilledObject(child[key])
      ? walker(child[key], path.concat([key]))
      : { [generatedKey]: child[key] };
  }));

  return Object.assign({}, walker(object));
}

export function flattenJSONApiParams(obj) {
  return flattenObj(obj, (accumulator, currentValue) => {
    if (accumulator) {
      return `${accumulator}[${currentValue}]`;
    }

    return currentValue;
  });
}

export const getUniqueList = (list1, list2) => {
  const updatedList = [].concat(list1, list2);
  return uniq(updatedList);
};

export function appendFormDataObj(obj) {
  const data = new window.FormData();
  Object.keys(obj).forEach(key => data.append(key, obj[key]));
  return data;
}

export function convertToFormDataObject(payload) {
  return appendFormDataObj(flattenJSONApiParams(payload));
}
