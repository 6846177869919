import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// importing custom component
import { getVcFeed } from './actions';
import { generateAuthHeaderFromStore } from '../../utils/authUtils';
import VisualConversation from '../VisualConversation';
import LoadingData from '../../components/LoadingData';

// styles
import styles from './styles';
import '../../components/VCExpansionPanel/index.scss';
import {
  REQUEST_AUTH_FEED_COMMENT_HOME_PAGE,
  REQUEST_AUTH_FEED_LIKE_HOME_PAGE,
  REQUEST_AUTH_VC_PROFILE_FOLLOW_HOME_PAGE,
} from '../AuthModal/actions';

class VisualConversationFeed extends Component {
  componentDidMount() {
    const { isExplore } = this.props;

    const queryParams = new URLSearchParams(window.location.search);
    const first_Id = queryParams.get('first_Id');

    if (!isExplore) {
      this.loadMore();
    }
  }

  loadMore() {
    const {
      loadVcFeed, authHeaders, nextPage, isRequesting,
    } = this.props;

    const queryParams = new URLSearchParams(window.location.search);
    const first_id = queryParams.get('first_id');
    const params = (first_id) ? { page: nextPage, first_id } : { page: nextPage };
    if (!isRequesting && nextPage) {
      loadVcFeed(authHeaders, params);
    }
  }

  render() {
    const {
      classes, list, nextPage, isExplore, scrollParentRef, history, location,
    } = this.props;

    return (
      <div className={classes.vc_wrapper}>
        <div>
          <List className="overFlowHidden">
            <InfiniteScroll
              pageStart={0}
              loadMore={() => { this.loadMore(); }}
              hasMore={!!nextPage}
              initialLoad={false}
              threshold={20}
              loader={<LoadingData key={0} />}
              useWindow={!isExplore}
              getScrollParent={() => scrollParentRef}
            >
              {list.map(id => (
                <div key={id}>
                  <div>
                    <ListItem className={classes.singleItem}>
                      <VisibilitySensor
                        partialVisibility
                        offset={{ top: -800, bottom: -800 }}
                      >
                        {({ isVisible }) => (
                          <VisualConversation
                            id={id}
                            isVisible={isVisible}
                            authLikeAction={REQUEST_AUTH_FEED_LIKE_HOME_PAGE}
                            authCommentAction={REQUEST_AUTH_FEED_COMMENT_HOME_PAGE}
                            authFollowAction={REQUEST_AUTH_VC_PROFILE_FOLLOW_HOME_PAGE}
                            history={history}
                            location={location}
                          />
                        )}
                      </VisibilitySensor>
                    </ListItem>
                  </div>
                  <hr className="feedHorizontalBar" />
                </div>
              ))}
            </InfiniteScroll>
          </List>
        </div>
      </div>
    );
  }
}
const VisualConversationFeedWithStyles = withStyles(styles)(VisualConversationFeed);

VisualConversationFeed.propTypes = {
  list: PropTypes.object.isRequired,
  nextPage: PropTypes.string.isRequired,
  authHeaders: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  list: state.vcFeed.get('list'),
  nextPage: state.vcFeed.get('nextPage'),
  authHeaders: generateAuthHeaderFromStore(state),
});

const mapDispatchToProps = dispatch => ({
  loadVcFeed: (headers, params) => {
    dispatch(getVcFeed(headers, params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(VisualConversationFeedWithStyles);
