// React & Redux Imports
import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Material UI Imports
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';

// Project Imports
import styles from './styles';
import ReactTippyWrapper from '../../components/ReactTippyWrapper';
import ResponsiveNav from './ResponsiveNav';
import { openSignUpForm, openLoginForm } from '../AuthModal/actions';
import { openDrawingTool } from '../DrawingTool/actions';
import { logout } from '../Authenticator/actions';
import ResponsiveLogo from '../../components/LogoComponent';
import LevelDots from '../../components/levelDots';

// Assets import
import blogIcon from '../../assets/images/blog.svg';
import help from '../../assets/images/help.jpg';
import explore from '../../assets/images/explore1.svg';
import logout4x from '../../assets/images/logout4x.svg';
import notification from '../../assets/images/notification.svg';
import horizontalline from '../../assets/images/horizontal-line.svg';
import verticalline from '../../assets/images/vertical-line.svg';
import reply from '../../assets/images/startThread.svg';
import Ellipse from '../../assets/images/Ellipse.svg';

import './index.scss';

const Navbar = (props) => {
  const {
    classes,
    logout,
    openSignUpForm,
    openLoginForm,
    isAuthenticated,
    userPhoto,
    username,
    openDrawingTool,
    userId,
    level,
    authAction,
    logoutAction,
    history,
  } = props;

  let userView;
  if (!isAuthenticated) {
    userView = (
      <div className="preLoginWrap">
        {/* <Button
          className={classes.btn}
          onClick={() => {
            openSignUpForm();
          }}
        >
          Sign Up
        </Button> */}
        <Button
          className={classes.btnWhite}
          onClick={() => {
            openLoginForm();
          }}
        >
          Log In
        </Button>
      </div>
    );
  } else {
    userView = (
      <div className="profile-icon-wrap">
        <div className="user-logout" id="mouse_over">
          <Button
            className="btnYellow"
            onClick={() => {
              logout();
            }}
          >
            <img className="logoutImg" alt="" src={logout4x} />
          </Button>
        </div>
        <div className="profileLevel">
          <Link
            to={{
              pathname: '/profile',
              search: userId,
            }}
          >
            <Button className="profileBtn">
              <img alt={username} src={userPhoto} />
            </Button>
          </Link>
          <LevelDots level={level} />
        </div>

        <div className="verticalLine">
          <img alt="" src={verticalline} />
        </div>

        <div className="notification">
          <Button className="btnEdit btnNotify">
            <img alt="" src={notification} />
          </Button>
          <img alt="" src={horizontalline} />
        </div>
      </div>
    );
  }
  return (
    <div>
      <ResponsiveNav authAction={authAction} logoutAction={logoutAction} />
      <Hidden xsDown>
        <AppBar position="fixed" className={classes.headerMain}>
          <Link to={{ pathname: '/' }}>
            <ResponsiveLogo />
          </Link>
        </AppBar>
      </Hidden>
      <Hidden xsDown>
        <div className="sidebar">
          {/* hidePanel */}
          <div className="wrapper vCenterSpaceBetween height-full">
            <div className="wrapper menuItems">
              {userView}
              <div className="exploreWrap">
                <Link to={{ pathname: '/explore' }}>
                  <Button>
                    <img alt="" src={explore} />
                  </Button>
                </Link>
              </div>

              <div className="startThreadWrap">
                <Button
                  className="btnEdit"
                  onClick={() => {
                    openDrawingTool(false, 0, 0, '');
                  }}
                >
                  <img alt="" src={reply} />
                </Button>
              </div>
              <div className="editorialWrap">
                <Link to={{ pathname: '/editorial/category' }}>
                  <Button className="btnEdit">
                    <img alt="" src={blogIcon} />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Hidden>
    </div>
  );
};

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  openLoginForm: PropTypes.func.isRequired,
  openSignUpForm: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  username: PropTypes.string,
  userPhoto: PropTypes.string,
  userId: PropTypes.string,
};

Navbar.defaultProps = {
  username: null,
  userPhoto: null,
  userId: null,
};

const navbarWithStyles = withStyles(styles)(Navbar);

const mapStateToProps = (state, props) => ({
  isAuthenticated: state.authenticationStatus.get('isAuthenticated'),
  userPhoto: state.authenticatedUserDetails.get('photo'),
  username: state.authenticatedUserDetails.get('name'),
  level: state.authenticatedUserDetails.get('level'),
  userId: state.authenticatedUserDetails.get('userId'),
  authAction: props.authAction,
  logoutAction: props.logoutAction,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  openSignUpForm: () => {
    dispatch(openSignUpForm(ownProps.authAction));
  },
  openDrawingTool: (isReply, previousActivityId, vcId, title) => {
    dispatch(openDrawingTool(isReply, previousActivityId, vcId, title));
  },
  openLoginForm: () => {
    dispatch(openLoginForm(ownProps.authAction));
  },
  logout: () => {
    dispatch(logout(ownProps.logoutAction));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(navbarWithStyles);
