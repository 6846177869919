import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Subject } from 'rxjs';

import { isEmpty } from 'lodash';
import ExploreView from '../../components/ExploreView';
import {
  openExploreView,
  clearExploreView,
  getExploreChallenge,
  getSearchResults,
  clearExploreViewState,
  getExploreRequestInitiated,
} from './actions';
import { getExploreVcFeedByQueryType } from '../ExploreConversationFeed/actions';

class ExploreViewContainer extends Component {
  constructor(props) {
    super(props);

    this.displayExplore = this.displayExplore.bind(this);
    this.closeExploreWrapper = this.closeExploreWrapper.bind(this);
    this.renderExplore = this.renderExplore.bind(this);
    this.checkRoute = this.checkRoute.bind(this);
    this.handleSearchResultRequestSubject = new Subject();
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(({ search }, action) => {
      const { isExploreViewOpen } = this.props;
      if (isExploreViewOpen) {
        if (this.checkRoute()) {
          this.renderExplore(search);
        }
      }
    });
  }

  displayExplore() {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    if (this.checkRoute()) {
      this.renderExplore(search);
    }
  }

  checkRoute() {
    const {
      history: {
        location: { pathname },
      },
      statesLocation,
    } = this.props;
    if (pathname === '/explore') {
      statesLocation.previous = '/explore';
      return true;
    }
    return false;
  }

  closeExploreWrapper() {
    const { history, clearExploreView, clearExploreViewState } = this.props;
    history.push({
      pathname: '/',
      previous: '/',
    });
    clearExploreView();
    clearExploreViewState();
  }

  renderExplore(search) {
    const {
      openExploreView,
      isExploreViewOpen,
      getExploreChallenge,
    } = this.props;
    if (!isExploreViewOpen) {
      openExploreView();
      getExploreChallenge();
    }
  }

  render() {
    const {
      isExploreViewOpen,
      challenge,
      selectedVc,
      getSearchResults,
      history,
      isNewRequest,
      userlevel,
      getExploreVcFeedByQueryType,
      clearExploreViewState,
      getExploreRequestInitiated,
      isDataFetched,
    } = this.props;

    if (!isExploreViewOpen) {
      this.displayExplore();
    }
    return (
      <div>
        <ExploreView
          isOpen={isExploreViewOpen}
          onClose={this.closeExploreWrapper}
          selectedVc={selectedVc}
          challenge={challenge}
          dataFetcher={getSearchResults}
          history={history}
          isNewRequest={isNewRequest}
          userlevel={userlevel}
          getExploreVcFeedByQueryType={getExploreVcFeedByQueryType}
          clearExploreViewState={clearExploreViewState}
          getExploreRequestInitiated={getExploreRequestInitiated}
          isDataFetched={isDataFetched}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    exploreView, authenticatedUserDetails, authenticationStatus, pictureCarousel, profileCarousel, exploreVcFeed,
  } = state;
  let isDataFetched = false;
  const challenge = exploreView.get('challenge').toJS();
  const searchResult = exploreView.get('searchResult').toJS();
  const isNewRequest = exploreView.get('isNewRequest');

  const pictureList = pictureCarousel.get('list').toJS();
  const profileList = profileCarousel.get('list').toJS();
  const exploreVCFeedlist = exploreVcFeed.get('list').toJS();

  if (!isEmpty(pictureList) || !isEmpty(profileList) || !isEmpty(exploreVCFeedlist)) {
    isDataFetched = true;
  }
  return {
    isExploreViewOpen: exploreView.get('isExploreViewOpen'),
    currentUserId: authenticatedUserDetails.get('userId'),
    userlevel: authenticatedUserDetails.get('level'),
    isAuthenticated: authenticationStatus.get('isAuthenticated'),
    selectedVc: exploreView.get('selectedVc'),
    selectedDrawing: exploreView.get('selectedDrawing'),
    apiError: exploreView.get('error'),
    successfulUpdate: exploreView.get('successfulUpdate'),
    challenge,
    searchResult,
    isNewRequest,
    statesLocation: state.router.location,
    isDataFetched,
  };
};

const mapDispatchToProps = dispatch => ({
  openExploreView: () => dispatch(openExploreView()),
  clearExploreView: () => dispatch(clearExploreView()),
  getExploreChallenge: () => dispatch(getExploreChallenge()),
  getSearchResults: params => dispatch(getSearchResults(params)),
  getExploreVcFeedByQueryType: (params, queryType) => dispatch(getExploreVcFeedByQueryType(params, queryType)),
  clearExploreViewState: () => dispatch(clearExploreViewState()),
  getExploreRequestInitiated: () => dispatch(getExploreRequestInitiated()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExploreViewContainer);
