import React from 'react';

import ApplicationSnackbar from '../../containers/ApplicationSnackbar';
import AuthModal from '../../containers/AuthModal';
import ProfileViewContainer from '../../containers/ProfileView';
import ExploreViewContainer from '../../containers/ExploreView';
import ConversationsModal from '../../containers/ConversationsModal';

import './index.scss';

const CoreShell = ({ children, history, location }) => (
  <div>
    <div className="mainWrapper">
      <ApplicationSnackbar />
      <AuthModal />
      <ProfileViewContainer
        history={history}
        location={location}
      />
      <ExploreViewContainer
        history={history}
        location={location}
      />
      <ConversationsModal
        history={history}
        location={location}
      />
      { children }
    </div>
  </div>
);

export default CoreShell;
