import { fromJS } from 'immutable';
// Project imports
import {
  REQUEST_FORGET_PASSWORD_INITIATED, REQUEST_FORGET_PASSWORD_FAILURE,
  REQUEST_FORGET_PASSWORD_SUCCESS, RESET_FORGOT_PASSWORD_MSGS,
} from './actions';

const forgetPasswordForm = (
  state = fromJS({
    isRequesting: false,
    success: false,
    error: null,
    successMsg: '',
  }),
  action,
) => {
  switch (action.type) {
    case REQUEST_FORGET_PASSWORD_INITIATED:
      return state.merge({ error: null, isRequesting: true });
    case REQUEST_FORGET_PASSWORD_FAILURE:
      return state.merge({ isRequesting: false, error: action.error });
    case REQUEST_FORGET_PASSWORD_SUCCESS:
      return state.merge({
        isRequesting: false, success: true, isOpen: false, error: null, successMsg: action.successMsg,
      });
    case RESET_FORGOT_PASSWORD_MSGS:
      return state.merge({ successMsg: '', error: null });
    default:
      return state;
  }
};

export default forgetPasswordForm;
