import axios from 'axios';
import config from '../config';

export const getRequest = (endpoint, additionalHeaders = {}, params = {}, responseType) => {
  const axiosConfig = {
    headers: { 'Content-Type': 'application/vnd.api+json', ...additionalHeaders },
    params,
    responseType,
  };
  return axios.get(`${config.BASE_API_URL}${endpoint}`, axiosConfig);
};

export const postRequest = (endpoint, payload, additionalHeaders = {}, params = {}) => {
  const axiosConfig = {
    headers: { 'Content-Type': 'application/vnd.api+json', ...additionalHeaders },
    params,
  };
  return axios.post(`${config.BASE_API_URL}${endpoint}`, payload, axiosConfig);
};

export const putRequest = (endpoint, payload, additionalHeaders = {}, params = {}) => {
  const axiosConfig = {
    headers: { 'Content-Type': 'application/vnd.api+json', ...additionalHeaders },
    params,
  };
  return axios.put(`${config.BASE_API_URL}${endpoint}`, payload, axiosConfig);
};

export const deleteRequest = (endpoint, additionalHeaders = {}) => {
  const axiosConfig = {
    headers: { 'Content-Type': 'application/vnd.api+json', ...additionalHeaders },
  };
  return axios.delete(`${config.BASE_API_URL}${endpoint}`, axiosConfig);
};

export const gernricPostRequest = (endpoint, payload, additionalHeaders = {}, cancelToken = {}) => {
  const axiosConfig = {
    headers: { 'Content-Type': 'application/json', ...additionalHeaders },
    ...cancelToken,
  };
  return axios.post(`${endpoint}`, payload, axiosConfig);
};

export const getRequestWithEndpoint = endpoint => axios.get(endpoint);
