export const OPEN_PROFILE_VIEW = 'OPEN_PROFILE_VIEW';
export const CLOSE_PROFILE_VIEW = 'CLOSE_PROFILE_VIEW';
export const GET_USER_INFO = 'GET_USER_INFO';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_INITIATED = 'UPDATE_USER_PROFILE_INITIATED';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';
export const GET_USER_ACTIVITIES = 'GET_USER_ACTIVITIES';
export const USER_ACTIVITIES_SUCCESS = 'USER_ACTIVITIES_SUCCESS';
export const USER_ACTIVITIES_FAILURE = 'USER_ACTIVITIES_FAILURE';
export const FOLLOW_USER = 'FOLLOW_USER';
export const FOLLOW_USER_INITIATED = 'FOLLOW_USER_INITIATED';
export const FOLLOW_USER_FAILURE = 'FOLLOW_USER_FAILURE';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';
export const USER_ACTIVITIES_INITIATED = 'USER_ACTIVITIES_INITIATED';
export const UPDATE_USER_VIEW = 'UPDATE_USER_VIEW';
export const LOAD_SELECTED_VC = 'LOAD_SELECTED_VC';
export const SET_SELECTED_VC = 'SET_SELECTED_VC';
export const SET_SELECTED_DRAWING = 'SET_SELECTED_DRAWING';
export const RESET_USER_ACTIVITIES = 'RESET_USER_ACTIVITIES';
export const RESET_UPDATE_PROFILE_MESSAGES = 'RESET_UPDATE_PROFILE_MESSAGES';
export const RESET_NEXT_PAGE = 'RESET_NEXT_PAGE';

export const openProfileView = () => ({ type: OPEN_PROFILE_VIEW });
export const closeProfileView = () => ({ type: CLOSE_PROFILE_VIEW });
export const getUserInfo = userId => ({ type: GET_USER_INFO, id: userId });
export const getUserActivities = (userId, params, selectedVc) => ({
  type: GET_USER_ACTIVITIES, id: userId, params, selectedVc,
});
export const updateUserProfile = (userId, payload) => ({
  type: UPDATE_USER_PROFILE,
  id: userId,
  payload,
});
export const updateUserProfileInitiated = () => ({ type: UPDATE_USER_PROFILE_INITIATED });
export const updateUserProfileSuccess = () => ({ type: UPDATE_USER_PROFILE_SUCCESS });
export const updateUserProfileFailure = error => ({ type: UPDATE_USER_PROFILE_FAILURE, error });
export const userInfoSuccess = response => ({ type: USER_INFO_SUCCESS, userView: response });
export const userActivitiesSuccess = (response, nextPage) => ({ type: USER_ACTIVITIES_SUCCESS, response, nextPage });
export const userInfoFailure = () => ({ type: USER_INFO_FAILURE });
export const userActivitiesFailure = () => ({ type: USER_ACTIVITIES_FAILURE });
export const followUser = userId => ({ type: FOLLOW_USER, id: userId });
export const followUserInitiated = () => ({ type: FOLLOW_USER_INITIATED });
export const followUserSuccess = () => ({ type: FOLLOW_USER_SUCCESS });
export const followUserFailure = () => ({ type: FOLLOW_USER_FAILURE });
export const clearUserProfile = () => ({ type: CLEAR_USER_PROFILE });
export const userActivitiesInitiated = () => ({ type: USER_ACTIVITIES_INITIATED });
export const updateUserView = updatedUserView => ({ type: UPDATE_USER_VIEW, updatedUserView });
export const loadSelectedVc = (activityId, vcId) => ({ type: LOAD_SELECTED_VC, activityId, vcId });
export const setSelectedVc = vcId => ({ type: SET_SELECTED_VC, vcId });
export const setSelectedDrawing = activity => ({ type: SET_SELECTED_DRAWING, activity });
export const resetUpdateProfileMessages = () => ({ type: RESET_UPDATE_PROFILE_MESSAGES });
export const resetUserActivities = () => ({ type: RESET_USER_ACTIVITIES });
