/* eslint-disable no-undef */
import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid/Grid';

class DraftsModal extends PureComponent {
  render() {
    const {
      openDraftModal, draft, deletingDraft, onConfirm, onDelete,
    } = this.props;
    return (
      <Dialog
        open={openDraftModal}
        className="dadaModal materialModalScrollFix draftModal"
        scroll="body"
        classes={{ paper: 'dadaDialogPaper', container: 'vhAlignCenter' }}
        BackdropProps={{
          classes: {
            root: 'dadaBackDrop',
          },
        }}
      >
        <DialogTitle
          disableTypography
          className="dadaModalTitle text-center"
        >
          <h3 className="dadaTitle">Would you like to load your saved draft?</h3>
        </DialogTitle>
        <DialogContent className="dadaModalContent py0">
          <ul className="draftsImages">
            <li>
              <img src={draft} alt="" width="390" />
            </li>
          </ul>
        </DialogContent>
        <DialogActions className="dadaModalFooter hasBlockBtns">
          <Button
            className="btn btnGreen"
            onClick={onConfirm}
          >
            Yes
          </Button>
          <Button
            className="btn btnTransparent"
            onClick={onDelete}
            disabled={deletingDraft}
          >
            No, delete it.
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
export default DraftsModal;
