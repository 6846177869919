const styles = theme => ({
  dialogPaper: {
    maxWidth: '640px',
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    margin: '0 auto',
    overflowY: 'initial', // temp fix for @material-ui/issues/10000
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 60px)',
    },
  },
  media: {
    width: 'auto',
    height: 'auto',
  },
  dialogLogo: {
    height: '120px',
  },
  modalTitle: {
    textAlign: 'center',
    fontSize: '28px',
    fontWeight: 'normal',
    margin: 0,
  },
  dialog: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ffffff',
    border: 'solid 1px #000000',
    maxWidth: '520px',
    width: '100%',
  },
  dialogHeader: {
    padding: 0,
  },
  backdropTada: {
    background: 'rgba(255, 255, 255, 0.9)',
  },
  btnLikeShare: {
    padding: 0,
    minWidth: 20,
    margin: '0px 10px',
    height: 'auto',
    '&:hover': {
      background: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      padding: '0px 8px',
      width: 'auto',
    },
  },
  rightSpace: {
    marginRight: 5,
  },
  lightboxProfile: {
    fontSize: 17,
  },
  btnReply: {
    padding: 0,
    margin: 0,
    minWidth: 1,
    minHeight: 1,
    width: 30,
    background: 'none',
    '&:hover': {
      background: 'none',
    },
  },
});

export default styles;
