/* eslint-disable import */
import pickBy from 'lodash/pickBy';
import { getRequest } from './apiRequestUtils';
import { GET_COMMENT_LIKES } from '../constants';
import { apiDeserializer } from './jsonApiSerializer';

export const commentsResponseParser = (responseFromApi) => {
  const {
    data: comments,
    links,
    included: {
      profiles,
    },
  } = responseFromApi;
  let page = null;
  let hasNextPage = false;
  if (links.next) {
    const url = new URL(links.next);
    page = new URLSearchParams(url.search).get('page');
    hasNextPage = true;
  }

  function parseProfile(profileId) {
    const { name, photo } = profiles[profileId].attributes;
    const profileLink = profiles[profileId].links.self;
    const profile = {
      profileId,
      name,
      photo,
      profileLink,
    };
    return profile;
  }

  function createComment(seed) {
    const {
      id,
      attributes: {
        comment,
        created_at,
        comment_likes_count,
        liked_by_user,
      },
      relationships: {
        profile: {
          data: {
            id: ownerId,
          },
        },
      },
    } = seed;

    const profile = parseProfile(ownerId);

    const Parsedcomment = {
      id,
      comment_likes_count,
      liked_by_user,
      comment,
      created_at: new Date(created_at * 1000),
      profile,
    };

    return Parsedcomment;
  }

  const parsedComments = comments.map(comment => createComment(comment));

  return {
    list: parsedComments,
    hasNextPage,
    nextPage: page,
  };
};

export const generateProfileFromStore = (store, profileId) => (pickBy({
  name: store.authenticatedUserDetails.get('name'),
  photo: store.authenticatedUserDetails.get('photo'),
  profileId,
  profileLink: '',
}));

export function postStateComment(seed, store) {
  const {
    data: {
      id,
      attributes: {
        comment,
        created_at,
      },
      relationships: {
        profile: {
          data: {
            id: ownerId,
          },
        },
      },
    },
  } = seed;

  const profile = generateProfileFromStore(store, ownerId);

  const Parsedcomment = {
    id,
    comment_likes_count: 0,
    comment,
    liked_by_user: false,
    created_at: new Date(created_at * 1000),
    profile,
  };

  return Parsedcomment;
}

export const getCommentsLikes = async (commentId) => {
  const { data } = await getRequest(GET_COMMENT_LIKES(commentId));
  return apiDeserializer(data);
};
