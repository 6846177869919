import { fromJS } from 'immutable';
import { CLOSE_WELCOME_MESSAGE } from './actions';

const initialState = fromJS({
  open: true,
});

const welcomeMessage = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_WELCOME_MESSAGE:
      return state.merge({ open: false });
    default:
      return state;
  }
};

export default welcomeMessage;
