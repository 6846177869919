const styles = () => ({
  singleItem: {
    paddingBottom: 15,
    padding: 0,
    display: 'block',
    backgroundColor: 'white',
    boxShadow: '0 0px 20px 3px rgb(0 0 0 / 20%)',
  },
  vc_wrapper: {
    position: 'relative',
    zIndex: '2',
  },
  overFlowHidden: {
    overflow: 'hidden',
  },

});
export default styles;
