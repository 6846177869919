/* eslint-disable max-len */
// React imports
import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { S3 } from 'aws-sdk';
import { randomBytes } from 'crypto';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import Dialog from '@material-ui/core/Dialog/Dialog';
// Material UI Imports
import Avatar from '@material-ui/core/Avatar';
// eslint-disable-next-line import/no-unresolved
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet';
import Header from '../Editorial/Header';
import {
  getBlogsResults,
  createOrUpdateBlog,
} from '../../containers/BlogsLandingPage/action';
// Porject imports
import LevelsComponent from '../LevelsComponent';
// Assets Imports
import './index.scss';
import { SHARE_BLOG, SHARE_URL } from '../../utils/constants';
import config from '../../config';

const BlogPostModal = (props) => {
  const {
    isOpen, isAuthenticated, blogs, location, getBlogsResults, history, is_blogger,
  } = props;
  const quillRef = useRef(null);
  const [content, setContent] = React.useState([]);
  const [metaData, setMetaData] = React.useState({});
  const [selectedBlog, setSelectedBlog] = React.useState({});
  const [value, setValue] = useState(selectedBlog.content ? selectedBlog.content : '');
  const [open, setOpen] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image',
  ];

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link', 'image'],
        ['clean'],
        [{ color: [] }],
      ],
    },
  };

  const {
    AWS_ACCESS_KEY_ID, AWS_SECRET_KEY, AWS_BUCKET_NAME, AWS_REGION,
  } = config;

  const s3 = new S3({
    region: AWS_REGION,
    credentials: {
      accessKeyId: AWS_ACCESS_KEY_ID,
      secretAccessKey: AWS_SECRET_KEY,
    },
  });

  React.useEffect(() => {
    async function fetchData() {
      await getBlogsResults(isAuthenticated);
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    setContent(blogs);
    if (blogs.length > 0) {
      setMetaData(blogs[0].feed);
      const path = location.pathname;
      if (path !== '/editorial/category/') {
        const paramsArray = path.split('/');
        const blogId = Number(paramsArray[3]);
        const selectedBlog = blogs.find(obj => obj.id === blogId);
        if (!selectedBlog) {
          history.push('/not-found-404');
        }
        setSelectedBlog(selectedBlog);
        setValue(selectedBlog.content);
      }
    }
  }, [blogs]);

  const uploadAndGetS3Image = async (file) => {
    const type = file.split(';')[0].split(':')[1];
    const fileName = await generateRandomKey();
    const buf = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ''), 'base64');
    const params = {
      Bucket: AWS_BUCKET_NAME,
      Key: fileName, // type is not required
      Body: buf,
      ContentEncoding: 'base64', // required
      ContentType: type, // required. Notice the back ticks
    };
    const getParams = {
      Bucket: AWS_BUCKET_NAME,
      Key: fileName, // type is not required
    };

    await s3.putObject(params).promise();
    const url = await s3.getSignedUrl('getObject', getParams);
    return url;
  };

  const handleChange = async (content, delta, source, editor) => {
    if (!quillRef.current) {
      return;
    }
    if (delta.ops && delta.ops.length > 0 && !!delta.ops[delta.ops.length - 1] && !!delta.ops[delta.ops.length - 1].insert && !!delta.ops[delta.ops.length - 1].insert.image) {
      const file = delta.ops[delta.ops.length - 1].insert.image;
      setValue(value);
      const quillObj = quillRef.current.getEditor();
      quillObj.pasteHTML(value);
      const url = await uploadAndGetS3Image(file);
      const range = quillObj.getSelection();
      if (delta.ops.length > 1) {
        quillObj.editor.insertEmbed(delta.ops[0].retain, 'image', url);
      } else {
        quillObj.editor.insertEmbed(range.index, 'image', url);
      }
      setValue(editor.getHTML());
    } else {
      setValue(content);
    }
  };

  const blogDate = selectedBlog && new Date(selectedBlog.published_at_medium);
  const options = {
    replace: ({ attribs, children }) => {
      let result = null;
      if (attribs && attribs.src && attribs.src.includes('https://medium.com/_/stat?event=post')) {
        result = <div />;
      }
      return result;
    },
  };

  const generateRandomKey = async () => ((await randomBytes(16)).toString('hex'));
  const handleRedirect = () => {
    const { history } = props;
    // history.push('/');
    window.open('https://editorial.dada.nyc/editorial', '_self');
  };

  useEffect(() => {
    if (isOpen) {
      const blurEffect = document.querySelector('#blurWrapper');
      blurEffect.classList.add('bgBlur');
    }

    return () => {
      const blurEffect = document.querySelector('#blurWrapper');
      blurEffect.classList.remove('bgBlur');
    };
  });

  return (
    <div>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:title" content={selectedBlog.title ? parse(selectedBlog.title) : ''} />
        <meta property="og:image" content={selectedBlog.thumbnail} />
      </Helmet>
      <div className="main blogWrap">
        <div className="blog">
          <div className="indvdlPage">
            <Header handleRedirect={handleRedirect} />
          </div>
          <div className="avatarWrapper">
            <div style={{ display: 'flex' }}>
              {selectedBlog && (
                <Avatar
                  alt=""
                  src={selectedBlog.thumbnail}
                  className="avatar"
                />
              )}
              {selectedBlog && (<span className="avatarName">{selectedBlog.author}</span>)}
            </div>
            <div
              role="button"
              tabIndex={0}
              style={{ 'padding-right': 35 }}
              className="shareForLightbox"
              onClick={() => setOpen(true)}
            />
            { is_blogger
              ? (
                <div
                  role="button"
                  tabIndex={0}
                  style={{ 'padding-right': 35 }}
                  className="shareForLightbox"
                  onClick={() => window.location.assign(`/editorial/category/${selectedBlog.id}/edit`)}
                />
              )
              : null
            }
          </div>

          {selectedBlog && (
            <div className="subtitle">
              <h1 className="title">{selectedBlog.title ? parse(selectedBlog.title) : ''}</h1>
            </div>
          )}
          {selectedBlog && selectedBlog.content
            && (
              <div className="blog-infor">
                <div className="mainContainer">
                  <div><p className="publishBy">Published by: {selectedBlog.author}</p></div>
                  <div>{blogDate && <p className="date">Published On: {`${blogDate.toDateString().substring(0, 3)},${blogDate.toDateString().substring(3, blogDate.toDateString().length)}`}</p>}</div>
                </div>
                <div>
                  {parse(value)}
                </div>
              </div>
            )
          }
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setCopied(false);
        }}
        // className="blogPanel blogCanvas"
        scroll="body"
        classes={{ paper: 'dadaDialogPaper editModalBox', container: 'vhAlignCenter' }}
        BackdropProps={{
          classes: {
            root: 'dadaBackDrop',
          },
        }}
      >
        <div className="share" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <p className="share-text">Share</p>
          <a
            className="close-button"
            role="button"
            tabIndex={0}
            onClick={() => { setOpen(false); setCopied(false); }}
          >
            X
          </a>
        </div>
        <div style={{
          padding: 50, paddingTop: 30, display: 'flex', flexDirection: 'row',
        }}
        >
          <input style={{ width: 500, height: 35, fontSize: 18 }} value={`${SHARE_URL}${SHARE_BLOG}${selectedBlog.id}`} />
          <CopyToClipboard
            text={`${SHARE_URL}${SHARE_BLOG}${selectedBlog.id}`}
            onCopy={() => setCopied(true)}
          >
            <button type="button" style={{ height: 35, width: 100 }}>{copied ? 'Copied!' : 'Copy'}</button>
          </CopyToClipboard>
          {/* {copied ? <span style={{color: 'green'}}>Copied.</span> : null} */}
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { blogsPage, authenticatedUserDetails, authenticationStatus } = state;
  return {
    is_blogger: authenticatedUserDetails.get('is_blogger'),
    isAuthenticated: authenticationStatus.get('isAuthenticated'),
    blogs: blogsPage.get('blogs') ? blogsPage.get('blogs').toJS() : '',
    updatedBlog: blogsPage.get('updatedBlog'),
    statesLocation: state.router.location,
  };
};
const mapDispatchToProps = dispatch => ({
  getBlogsResults: isAuthenticated => dispatch(getBlogsResults(isAuthenticated)),
  createOrUpdateBlog: (blogId, payload) => dispatch(createOrUpdateBlog(blogId, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlogPostModal);
