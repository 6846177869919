import { fromJS } from 'immutable';
// Project imports
import {
  EDITORIAL_DATA_REQUEST_INITIATED,
  EDITORIAL_DATA_REQUEST_SUCCESS,
  EDITORIAL_DATA_REQUEST_FAILURE,
  CLEAR_EDITORIAL_STATE,
} from './actions';

const initialState = fromJS({
  isRequesting: false,
  blogs: {},
  error: null,
});

const editorialReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDITORIAL_DATA_REQUEST_INITIATED:
      return state.merge({ isRequested: true });
    case EDITORIAL_DATA_REQUEST_SUCCESS:
      return state.merge({ blogs: action.blogs, isRequesting: false });
    case EDITORIAL_DATA_REQUEST_FAILURE:
      return state.merge({ error: action.error, isRequesting: false });
    case CLEAR_EDITORIAL_STATE:
      return initialState;
    default:
      return state;
  }
};

export default editorialReducer;
