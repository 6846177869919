const styles = theme => ({
  dialogPaper: {
    maxWidth: '100%',
    width: '100%',
    borderRadius: 0,
    margin: '0 auto',
    overflowY: 'initial', // temp fix for @material-ui/issues/10000
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 60px)',
    },
  },
  backdropTada: {
    background: 'rgba(105 , 105, 105, 0.95)',
  },
  singleDialogPaper: {
    width: '540px',
    borderRadius: 0,
    boxShadow: 'none',
    margin: '0 auto',
    overflowY: 'initial', // temp fix for @material-ui/issues/10000
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 60px)',
    },
  },
  doubleDialogPaper: {
    maxWidth: '1088px',
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    margin: '0 auto',
    overflowY: 'initial', // temp fix for @material-ui/issues/10000
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 300px)',
    },
  },

});

export default styles;
