export const OPEN_RESET_PASSWORD_FORM = 'OPEN_RESET_PASSWORD_FORM';
export const CLOSE_RESET_PASSWORD_FORM = 'CLOSE_RESET_PASSWORD_FORM';
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const REQUEST_RESET_PASSWORD_FAILURE = 'REQUEST_RESET_PASSWORD_FAILURE';
export const RESET_RESET_PASSWORD_MSGS = 'RESET_RESET_PASSWORD_MSGS';
export const REQUEST_RESET_PASSWORD_INITIATED = 'REQUEST_RESET_PASSWORD_INITIATED';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const openResetPasswordForm = () => ({ type: OPEN_RESET_PASSWORD_FORM });
export const requestResetPassword = (params, headers) => ({ type: REQUEST_RESET_PASSWORD, params, headers });
export const requestResetPasswordInitiated = () => ({ type: REQUEST_RESET_PASSWORD_INITIATED });
export const requestResetPasswordSuccess = msg => ({ type: RESET_PASSWORD_SUCCESS, msg });
export const requestResetPasswordFailure = error => ({ type: REQUEST_RESET_PASSWORD_FAILURE, error });
export const resetResetPasswordMsgs = () => ({ type: RESET_RESET_PASSWORD_MSGS });
