import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import { fromByteArray } from 'base64-js';
import {
  debounceTime, mergeMap, catchError, map,
} from 'rxjs/operators';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { push } from 'connected-react-router';

import {
  DELETE_DRAFT_ENDPOINT,
  GET_DRAFT_ENDPOINT,
  POST_DRAFT_ENDPOINT,
  POST_VISUAL_STATUS_ENDPOINT,
  PUT_DRAFT_ENDPOINT,
  UNEXPECTED_ERROR,
} from '../../constants';
import { generateAuthHeaderFromStore } from '../../utils/authUtils';
import {
  postDrawingInitiated,
  postDrawingFailure,
  postDrawingSuccess,
  postDraftSuccess,
  postDraftInitiated,
  postDraftFailure,
  POST_DRAFT,
  PUT_DRAFT,
  putDraftInitiated,
  putDraftFailure,
  putDraftSuccess,
  GET_DRAFT_INDEX,
  getDraftIndexInitiated,
  getDraftIndexSuccess,
  getDraftIndexFailure,
  getDraftFailure,
  getDraftInitiated,
  GET_DRAFT,
  getDraft,
  getDraftSuccess,
  DELETE_DRAFT,
  deleteDraftSuccess,
  deleteDraftInitiated,
  deleteDraftFailure,
  OPEN_DRAWING_TOOL_DIALOG,
  setCanvasInitialAuthenticationStatus,
  POST_DRAWING_HOME_PAGE,
  closeDrawingTool,
  CLOSE_DRAWING_TOOL_HOME_PAGE,
  postCloseDrawingTool,
  POST_DRAWING_INDIVIDUAL_VC_PAGE,
  CLOSE_DRAWING_TOOL_INDIVIDUAL_VC_PAGE,
} from './actions';
import { reloadVcFeed } from '../VisualConversationFeed/actions';
import { getConversationTree } from '../VisualConversation/actions';
import { getDraftIndex } from '../../api';
import { postRequest } from '../../utils/apiRequestUtils';
import { getUserActivities, getUserInfo, resetUserActivities } from '../ProfileView/actions';
import { isCurrentUser } from '../../utils/validations';

export const openDrawingToolCanvasEpic = (action$, state$) => action$.ofType(OPEN_DRAWING_TOOL_DIALOG).pipe(
  mergeMap(() => [
    setCanvasInitialAuthenticationStatus(state$.value.authenticationStatus.get('isAuthenticated')),
  ]),
);

const hasAuthenticatedMidway = state => state.DrawingToolReducer.get('initiallyAuthenticated') === false
  && state.authenticationStatus.get('isAuthenticated');

export const closeDrawingToolHomePageEpic = (action$, state$) => action$.ofType(CLOSE_DRAWING_TOOL_HOME_PAGE).pipe(
  mergeMap(() => {
    const state = state$.value;

    if (hasAuthenticatedMidway(state)) {
      return [
        reloadVcFeed({ page: 1 }),
      ];
    }

    return [];
  }),
);

export const closeDrawingToolIndividualVCPageEpic = (action$, state$) => action$.ofType(CLOSE_DRAWING_TOOL_INDIVIDUAL_VC_PAGE).pipe(
  mergeMap(() => {
    const state = state$.value;

    if (hasAuthenticatedMidway(state)) {
      const vcId = state.router.location.pathname.split('/')[2];
      return [
        getConversationTree(vcId, vcId),
      ];
    }

    return [];
  }),
);

const postDrawingBaseEpic = (actionType, postCloseDrawingToolAction, getActions) => (action$, state$) => action$.ofType(actionType).pipe(
  debounceTime(300),
  mergeMap(action => concat(
    of(postDrawingInitiated()),
    of(showLoading('drawingCanvas')),
    from(postRequest(
      POST_VISUAL_STATUS_ENDPOINT,
      action.payload,
      { ...generateAuthHeaderFromStore(state$.value), 'content-type': 'multipart/form-data' },
    )).pipe(
      mergeMap((response) => {
        const profile = state$.value.profileView.toJS();
        if (profile.isProfileViewOpen) {
          return getPostDrawingProfileActions(action, state$.value, response);
        }
        return getActions(action, state$.value, response);
      }),
      catchError((error) => {
        console.error(error);
        return [
          postDrawingFailure(UNEXPECTED_ERROR),
          postCloseDrawingTool(postCloseDrawingToolAction),
          closeDrawingTool(),
        ];
      }),
    ),
    of(hideLoading('drawingCanvas')),
  )),
);

const getDefaultPostDrawingActions = () => [postDrawingSuccess(), closeDrawingTool()];

const getPostDrawingHomePageActions = (action, state, response) => {
  const actions = getDefaultPostDrawingActions();

  if (action.isReply) {
    actions.push(getConversationTree(action.vcId, response.data.data.id));
  } else {
    actions.push(reloadVcFeed({ page: 1, first_id: response.data.data.id }));
  }

  return actions;
};

const getPostDrawingIndividualVCPageActions = (action, state, response) => {
  const actions = getDefaultPostDrawingActions();

  if (action.isReply) {
    actions.push(getConversationTree(action.vcId, response.data.data.id));
  } else {
    actions.push(push('/'));
  }

  return actions;
};

const getPostDrawingProfileActions = (action, state, response) => {
  let actions = getDefaultPostDrawingActions();
  const currentUser = state.authenticatedUserDetails.toJS();
  const profile = state.profileView.toJS();
  const profileId = profile.userView.id;
  const isCurrentUserProfile = isCurrentUser(profileId, currentUser.userId);

  if (action.isReply && isCurrentUserProfile) {
    actions = [...actions,
      getConversationTree(action.vcId, response.data.data.id),
      getUserInfo(profileId),
      resetUserActivities(),
      getUserActivities(profileId, { page: 1 }, profile.selectedVc)];
  } else if (action.isReply) {
    actions.push(getConversationTree(action.vcId, response.data.data.id));
  } else {
    actions = [...actions,
      getUserInfo(profileId),
      getUserActivities(profileId, { page: 1 }, null)];
  }
  return actions;
};

export const postDrawingHomePageEpic = postDrawingBaseEpic(
  POST_DRAWING_HOME_PAGE,
  CLOSE_DRAWING_TOOL_HOME_PAGE,
  getPostDrawingHomePageActions,
);

export const postDrawingIndividualVCPageEpic = postDrawingBaseEpic(
  POST_DRAWING_INDIVIDUAL_VC_PAGE,
  CLOSE_DRAWING_TOOL_INDIVIDUAL_VC_PAGE,
  getPostDrawingIndividualVCPageActions,
);

export const getDraftIndexEpic = (action$, state$) => action$.ofType(GET_DRAFT_INDEX).pipe(
  debounceTime(300),
  mergeMap(action => concat(
    of(getDraftIndexInitiated()),
    of(showLoading('drawingCanvas')),
    from(getDraftIndex(
      generateAuthHeaderFromStore(state$.value),
      { parentId: action.paramData.parentId, rootId: action.paramData.rootId },
    )).pipe(
      mergeMap(({ data }) => {
        let draftId = null;
        let noDraft = true;
        let bgColor = null;

        if (data.length) {
          draftId = data[0].id;
          bgColor = data[0].bgColor;
          noDraft = false;
          return concat(
            of(getDraftIndexSuccess(draftId, bgColor, noDraft)),
            of(getDraft(draftId, action.isSaveDraftRequested, action.workingDraft)),
          );
        }
        return of(getDraftIndexSuccess(draftId, bgColor, noDraft));
      }),
      catchError((error) => {
        console.error(error);
        return of(getDraftIndexFailure(UNEXPECTED_ERROR));
      }),
    ),
    of(hideLoading('drawingCanvas')),
  )),
);

export const getDraftEpic = (action$, state$, { getRequest }) => action$.ofType(GET_DRAFT).pipe(
  debounceTime(300),
  mergeMap(action => concat(
    of(getDraftInitiated()),
    of(showLoading('drawingCanvas')),
    from(getRequest(
      GET_DRAFT_ENDPOINT(action.id), generateAuthHeaderFromStore(state$.value), {}, 'arraybuffer',
    )).pipe(
      mergeMap((response) => {
        const b64encoded = fromByteArray(new Uint8Array(response.data));
        const mimetype = 'image/png';
        const img = `data:${mimetype};base64,${b64encoded}`;

        return from(window.dadaCanvas.applyBackgroundOnImage(img, state$.value.DrawingToolReducer.get('bgColor'))).pipe(
          map(imgWithBg => getDraftSuccess(img, imgWithBg, action.isSaveDraftRequested, action.workingDraft)),
        );
      }),
      catchError((error) => {
        console.error(error);
        return of(getDraftFailure(UNEXPECTED_ERROR));
      }),
    ),
    of(hideLoading('drawingCanvas')),
  )),
);

export const postDraftEpic = (action$, state$, { postRequest }) => action$.ofType(POST_DRAFT).pipe(
  debounceTime(300),
  mergeMap(action => concat(
    of(postDraftInitiated()),
    of(showLoading('drawingCanvas')),
    from(postRequest(
      POST_DRAFT_ENDPOINT, action.payload,
      { ...generateAuthHeaderFromStore(state$.value), 'content-type': 'multipart/form-data' },
    )).pipe(
      mergeMap((response) => {
        const draft_id = response.data.data.id;
        let actions = [postDraftSuccess(draft_id, action.isFromConfirmationClose)];

        if (action.isFromConfirmationClose) {
          actions = actions.concat([postCloseDrawingTool(action.closeAction), closeDrawingTool()]);
        }
        return actions;
      }),
      catchError((error) => {
        console.error(error);
        return of(postDraftFailure(UNEXPECTED_ERROR));
      }),
    ),
    of(hideLoading('drawingCanvas')),
  )),
);

export const putDraftEpic = (action$, state$, { putRequest }) => action$.ofType(PUT_DRAFT).pipe(
  debounceTime(300),
  mergeMap(action => concat(
    of(putDraftInitiated()),
    of(showLoading('drawingCanvas')),
    from(putRequest(
      PUT_DRAFT_ENDPOINT(action.id), action.payload,
      { ...generateAuthHeaderFromStore(state$.value), 'content-type': 'multipart/form-data' },
    )).pipe(
      mergeMap((response) => {
        const draft_id = response.data.data.id;
        let actions = [putDraftSuccess(draft_id, action.isFromConfirmationClose)];

        if (action.isFromConfirmationClose) {
          actions = actions.concat([postCloseDrawingTool(action.closeAction), closeDrawingTool()]);
        }
        return actions;
      }),
      catchError((error) => {
        console.error(error);
        return of(putDraftFailure(UNEXPECTED_ERROR));
      }),
    ),
    of(hideLoading('drawingCanvas')),
  )),
);

export const deleteDraftEpic = (action$, state$, { deleteRequest }) => action$.ofType(DELETE_DRAFT).pipe(
  debounceTime(300),
  mergeMap(action => concat(
    of(deleteDraftInitiated()),
    of(showLoading('drawingCanvas')),
    from(deleteRequest(
      DELETE_DRAFT_ENDPOINT(action.id), generateAuthHeaderFromStore(state$.value),
    )).pipe(
      map(response => deleteDraftSuccess()),
      catchError((error) => {
        console.error(error);
        return of(deleteDraftFailure(UNEXPECTED_ERROR));
      }),
    ),
    of(hideLoading('drawingCanvas')),
  )),
);
