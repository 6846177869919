import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';
import Header from '../../components/Editorial/Header';
import News from '../../components/Editorial/News';
import OurWorldview from '../../components/Editorial/OurWorldview';
import DadaAffairs from '../../components/Editorial/DadaAffairs';
import DADAInAction from '../../components/Editorial/DADAInAction';
import FaqsAndFucks from '../../components/Editorial/FaqsAndFucks';
import DadaDarlings from '../../components/Editorial/DadaDarlings';
import BlogLoader from '../../components/BlogLoader';

import openLink from '../../assets/images/openLink.svg';
import twitterImages from './twitterImages';
import { getEditorialData } from './actions';
import './index.scss';

class EditorialHomePage extends Component {
  componentDidMount() {
    const { getEditorialData, isAuthenticated } = this.props;
    getEditorialData(isAuthenticated);
  }

  handleRedirect = () => {
    const { history } = this.props;
    history.push('/');
  };

  redirectToBlog = (id) => {
    const { history } = this.props;
    if (id) {
      // history.push(`/editorial/category/${id}`);
      const win = window.open(`/editorial/category/${id}`, '_blank');
      win.focus();
    }
  };


  render() {
    const { blogs, isRequesting } = this.props;
    const newsData = blogs.News;
    const dadaInActionData = blogs['DADA in Action'];
    const dadaAffairsData = blogs.DADAaffairs;
    const faqsData = blogs['Faqs and F*cks'];
    const ourWorldViewData = blogs['Our Worldview'];
    const DADArlings = blogs.DADArlings;
    return (
      <div className="editorialContainer">
        {!isRequesting ? (
          <Fragment>
            <Header handleRedirect={this.handleRedirect} />
            {!isEmpty(blogs) && (
              <div className="blogBody">
                <News
                  data={newsData}
                  redirectToBlog={this.redirectToBlog}
                  icon={openLink}
                />
                <OurWorldview
                  data={ourWorldViewData}
                  redirectToBlog={this.redirectToBlog}
                  icon={openLink}
                />
                <DadaAffairs
                  data={dadaAffairsData}
                  redirectToBlog={this.redirectToBlog}
                  icon={openLink}
                />
                <DADAInAction
                  data={dadaInActionData}
                  redirectToBlog={this.redirectToBlog}
                  icon={openLink}
                />
                <FaqsAndFucks
                  data={faqsData}
                  redirectToBlog={this.redirectToBlog}
                  icon={openLink}
                />
                <DadaDarlings
                  data={DADArlings}
                  redirectToBlog={this.redirectToBlog}
                  icon={openLink}
                />
              </div>
            )}
          </Fragment>
        ) : (
          <BlogLoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { authenticationStatus, editorialReducer } = state;
  const blogs = editorialReducer.get('blogs').toJS();
  const isRequesting = editorialReducer.get('isRequesting');
  return {
    isAuthenticated: authenticationStatus.get('isAuthenticated'),
    isRequesting,
    blogs,
  };
};
const mapDispatchToProps = dispatch => ({
  getEditorialData: () => dispatch(getEditorialData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorialHomePage);
