// Modal Action Types
export const OPEN_EXPLORE_VIEW = 'OPEN_EXPLORE_VIEW';
export const CLOSE_EXPLORE_VIEW = 'CLOSE_EXPLORE_VIEW';

// Daily Challenge Action Types
export const GET_EXPLORE_CHALLENGE = 'GET_EXPLORE_CHALLENGE';
export const EXPLORE_CHALLENGE_SUCCESS = 'EXPLORE_CHALLENGE_SUCCESS';
export const EXPLORE_CHALLENGE_FAILURE = 'EXPLORE_CHALLENGE_FAILURE';

// Search Results Action Types
export const GET_EXPLORE_REQUEST_INITIATED = 'GET_EXPLORE_REQUEST_INITIATED';
export const GET_EXPLORE_REQUEST_SUCCESS = 'GET_EXPLORE_REQUEST_SUCCESS';
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const SEARCH_RESULTS_SUCCESS = 'SEARCH_RESULTS_SUCCESS';
export const SEARCH_RESULTS_FAILURE = 'SEARCH_RESULTS_FAILURE';

// Clear Explore State Action Types
export const CLEAR_EXPLORE_VIEW = 'CLEAR_EXPLORE_VIEW';
export const CLEAR_EXPLORE_VIEW_STATE = 'CLEAR_EXPLORE_VIEW_STATE';

// Modal Actions
export const openExploreView = () => ({ type: OPEN_EXPLORE_VIEW });
export const closeExploreView = () => ({ type: CLOSE_EXPLORE_VIEW });

// Daily Challenge Actions
export const getExploreChallenge = userId => ({ type: GET_EXPLORE_CHALLENGE });
export const exploreChallengeSuccess = response => ({ type: EXPLORE_CHALLENGE_SUCCESS, challenge: response });
export const exploreChallengeFailure = err => ({ type: EXPLORE_CHALLENGE_FAILURE, error: err });

// Search Results Actions
export const getExploreRequestInitiated = () => ({ type: GET_EXPLORE_REQUEST_INITIATED });
export const getExploreRequestSuccess = () => ({ type: GET_EXPLORE_REQUEST_SUCCESS });
export const getSearchResults = params => ({ type: GET_SEARCH_RESULTS, params });
export const getSearchResultsSuccess = response => ({ type: SEARCH_RESULTS_SUCCESS, data: response });
export const getSearchResultsFailure = err => ({ type: SEARCH_RESULTS_FAILURE, error: err });

// Clear Explore State Action
export const clearExploreView = () => ({ type: CLEAR_EXPLORE_VIEW });
export const clearExploreViewState = () => ({ type: CLEAR_EXPLORE_VIEW_STATE });
