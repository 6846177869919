import { fromJS } from 'immutable';
// Project imports
import {
  OPEN_DRAWING_TOOL_DIALOG,
  CLOSE_DRAWING_TOOL_DIALOG,
  POST_DRAFT_INITIATED,
  POST_DRAFT_FAILURE,
  POST_DRAFT_SUCCESS,
  PUT_DRAFT_SUCCESS,
  PUT_DRAFT_FAILURE,
  PUT_DRAFT_INITIATED,
  GET_DRAFT_INDEX_INITIATED,
  GET_DRAFT_INDEX_SUCCESS,
  GET_DRAFT_INDEX_FAILURE,
  GET_DRAFT_SUCCESS,
  GET_DRAFT_FAILURE,
  GET_DRAFT_INITIATED,
  CLOSE_DRAFT_MODAL,
  DELETE_DRAFT_INITIATED,
  DELETE_DRAFT_SUCCESS,
  DELETE_DRAFT_FAILURE,
  OPEN_SAVE_DRAFT_CONFIRMATION_MODAL,
  POST_CONFIRMATION_MODAL,
  REQUEST_POST,
  POST_DRAWING,
  POST_DRAWING_SUCCESS,
  SAVE_DRAFT_REQUEST,
  POST_DRAWING_INITIATED,
  POST_DRAWING_FAILURE,
  RELOAD_DRAWING_REDUCER_STATE,
  SET_CANVAS_INITIAL_AUTHENTICATION_STATUS,
} from './actions';
import { REQUEST_LOGIN_SUCCESS } from '../LoginForm/actions';
import { REQUEST_SIGN_UP_SUCCESS } from '../SignUpForm/actions';

const initialState = {
  isOpen: false,
  isReply: false,
  savingDraft: false,
  error: null,
  draft_id: null,
  bgColor: null,
  checkingDraft: false,
  gettingDraft: false,
  openDraft: false,
  draft: null,
  imgWithBg: null,
  deletingDraft: false,
  previousActivityId: 0,
  vcId: 0,
  confirmPosting: false,
  requestedPost: false,
  title: 'My Title',
  saveDraftRequested: false,
  fetchExistingDraft: false,
  openDraftSelectionModal: false,
  openSaveDraftModal: false,
  workingDraft: null,
  isFromSaveConfirmationModal: false,
  saveOnNoDraft: false,
  isFromPostConfirmationModal: false,
  isPosting: false,
  initiallyAuthenticated: null,
};

const DrawingToolReducer = (
  state = fromJS(initialState),
  action,
) => {
  switch (action.type) {
    case OPEN_DRAWING_TOOL_DIALOG:
      return state.merge({
        isOpen: true,
        isReply: action.isReply,
        previousActivityId: action.previousActivityId,
        vcId: action.vcId,
        title: action.title,
      });
    case CLOSE_DRAWING_TOOL_DIALOG:
      return state.merge({
        isOpen: false,
        isReply: false,
        previousActivityId: 0,
        vcId: 0,
        draft_id: null,
        bgColor: null,
        imgWithBg: null,
        openSaveDraftModal: false,
        isFromSaveConfirmationModal: false,
        openDraftSelectionModal: false,
        initiallyAuthenticated: null,
      });
    case GET_DRAFT_INDEX_INITIATED:
      return state.merge({
        checkingDraft: true,
      });
    case GET_DRAFT_INDEX_SUCCESS:
      if (state.get('fetchExistingDraft') && action.noDraft) {
        // eslint-disable-next-line no-param-reassign
        state = state.merge({ saveOnNoDraft: true });
      }
      return state.merge({
        checkingDraft: false, draft_id: action.draftId, bgColor: action.bgColor, fetchExistingDraft: false,
      });
    case GET_DRAFT_INDEX_FAILURE:
      return state.merge({
        checkingDraft: false, error: action.error,
      });
    case GET_DRAFT_INITIATED:
      return state.merge({
        gettingDraft: true,
      });
    case GET_DRAFT_SUCCESS:
      if (action.isSaveDraftRequested) {
        // Since this is IMMUTABLEJS
        // eslint-disable-next-line no-param-reassign
        state = state.merge({
          openDraftSelectionModal: true,
          openSaveDraftModal: false,
          workingDraft: action.workingDraft,
          confirmPosting: false,
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        state = state.merge({ openDraft: true });
      }
      return state.merge({
        gettingDraft: false, draft: action.draft, imgWithBg: action.imgWithBg,
      });
    case GET_DRAFT_FAILURE:
      return state.merge({
        gettingDraft: false, error: action.error,
      });
    case CLOSE_DRAFT_MODAL:
      return state.merge({
        openDraft: false, openDraftSelectionModal: false, isFromPostConfirmationModal: false,
      });
    case POST_DRAFT_INITIATED:
      return state.merge({
        savingDraft: true,
      });
    case POST_DRAFT_SUCCESS:
      return state.merge({
        savingDraft: false,
        draft_id: action.draft_id,
        openDraftSelectionModal: false,
        saveOnNoDraft: false,
        confirmPosting: false,
      });
    case POST_DRAFT_FAILURE:
      return state.merge({
        savingDraft: false,
        error: action.error,
        openDraftSelectionModal: false,
        saveOnNoDraft: false,
        confirmPosting: false,
      });
    case PUT_DRAFT_INITIATED:
      return state.merge({
        savingDraft: true,
      });
    case PUT_DRAFT_SUCCESS:
      return state.merge({
        savingDraft: false,
        draft_id: action.draft_id,
        openDraftSelectionModal: false,
        saveOnNoDraft: false,
        confirmPosting: false,
      });
    case PUT_DRAFT_FAILURE:
      return state.merge({
        savingDraft: false,
        error: action.error,
        openDraftSelectionModal: false,
        saveOnNoDraft: false,
        confirmPosting: false,
      });
    case DELETE_DRAFT_INITIATED:
      return state.merge({
        deletingDraft: true,
      });
    case DELETE_DRAFT_SUCCESS:
      return state.merge({
        deletingDraft: false, draft_id: null, draft: null, bgColor: null, imgWithBg: null,
      });
    case DELETE_DRAFT_FAILURE:
      return state.merge({
        deletingDraft: false, error: action.error,
      });
    case OPEN_SAVE_DRAFT_CONFIRMATION_MODAL:
      return state.merge({
        openSaveDraftModal: true, isFromSaveConfirmationModal: true,
      });
    case POST_CONFIRMATION_MODAL:
      return state.set('confirmPosting', !state.get('confirmPosting'));
    case REQUEST_POST:
      return state.set('requestedPost', true);
    case REQUEST_SIGN_UP_SUCCESS:
    case REQUEST_LOGIN_SUCCESS:
      if (state.get('requestedPost')) {
        // eslint-disable-next-line no-param-reassign
        state = state.merge({
          isFromPostConfirmationModal: true, fetchExistingDraft: true, requestedPost: false,
        });
        if (!window.dadaCanvas.isCanvasEmpty()) {
          // eslint-disable-next-line no-param-reassign
          state = state.merge({
            confirmPosting: true,
          });
        }
      }
      if (state.get('saveDraftRequested')) {
        // eslint-disable-next-line no-param-reassign
        state = state.merge({
          saveDraftRequested: false, fetchExistingDraft: true,
        });
      }
      return state;
    case POST_DRAWING:
      return state.merge({
        requestedPost: false, fetchExistingDraft: false,
      });
    case POST_DRAWING_SUCCESS:
      return state.merge({
        confirmPosting: false, isPosting: false,
      });
    case SAVE_DRAFT_REQUEST:
      return state.merge({
        saveDraftRequested: true,
      });
    case POST_DRAWING_INITIATED:
      return state.merge({
        isPosting: true,
      });
    case POST_DRAWING_FAILURE:
      return state.merge({
        confirmPosting: false, isPosting: false, error: action.error,
      });
    case RELOAD_DRAWING_REDUCER_STATE:
      return fromJS(initialState);
    case SET_CANVAS_INITIAL_AUTHENTICATION_STATUS:
      return state.set('initiallyAuthenticated', action.status);
    default:
      return state;
  }
};

export default DrawingToolReducer;
