import { Tooltip } from 'react-tippy';

class ReactTippyWrapper extends Tooltip {
  constructor(props) {
    super(props);
    this.destroyTippy2 = this.destroyTippy2.bind(this);
  }

  componentWillUnmount() {
    this.destroyTippy2();
  }

  destroyTippy2() {
    const popper = this.tippy.getPopperElement(this.tooltipDOM);
    this.destroyTippy();
    popper.remove();
  }
}

export default ReactTippyWrapper;
