/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog/Dialog';
import InfiniteScroll from 'react-infinite-scroller';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import closeModal from '../../assets/images/close-modal.svg';
import FollowIcon from '../FollowIcon';
import VisualConversation from '../../containers/VisualConversation';
import Activities from '../activitiesList';
import ResponsiveLogo from '../LogoComponent';
import { isCurrentUser } from '../../utils/validations';
import {
  controlCollapsedState, visualConversationStore,
} from '../../utils/vcUtils';
import EditProfileView from '../EditProfileView';
import LevelBar from '../levelBar';
import NoActivities from '../NoActivities';
import {
  REQUEST_AUTH_FEED_COMMENT_PROFILE_VIEW_PAGE,
  REQUEST_AUTH_FEED_LIKE_PROFILE_VIEW_PAGE,
  REQUEST_AUTH_VC_PROFILE_FOLLOW_PROFILE_VIEW_PAGE,
} from '../../containers/AuthModal/actions';

import {
  curatorActivity, toggleExpansionPanel,
  getConversationTree, likeActivity, updateVcConversationBranch, editVcDetails,
} from '../../containers/VisualConversation/actions';
import styles from './styles';
import './index.scss';


class ProfileView extends Component {
  static showProfileInfo(show) {
    if (show) {
      return '';
    }
    return 'dNone';
  }

  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      nodeID: '',
    };
    this.onEdit = this.onEdit.bind(this);
    this.resetSuccessMessage = this.resetSuccessMessage.bind(this);
    this.selectVc = this.selectVc.bind(this);
    this.onProfileClose = this.onProfileClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { successfulUpdate } = this.props;
    if (prevProps.successfulUpdate === false && successfulUpdate === true) {
      this.resetSuccessMessage();
    }
  }

  onEdit() {
    const { edit } = this.state;
    this.setState({
      edit: !edit,
    });
  }

  onProfileClose() {
    const { onClose } = this.props;
    this.setState({
      edit: false,
    });
    onClose();
  }

  selectVc(activity) {
    const { loadSelectedVc, selectedDrawing } = this.props;
    const { id, rootId } = activity;
    if (selectedDrawing !== id) {
      loadSelectedVc(id, rootId);
    }
  }

  resetSuccessMessage() {
    const { resetUpdateProfileMessages } = this.props;
    setTimeout(() => resetUpdateProfileMessages(), 3500);
  }

  render() {
    const {
      hasNext,
      isOpen, classes, userView, userEmail, updateProfile, userActivities, getUserActivities,
      followUser, selectedVc, selectedDrawing, currentUserId, isUpdatingProfile, apiError, successfulUpdate,
      resetUpdateProfileMessages, openDrawingTool, history, activeBranch,
    } = this.props;
    const {
      name, photo, points, drawingsCount, country, id, followedByUser, maxPointsByLevel, level,
    } = userView;
    const { edit } = this.state;
    return (
      <div className="profileContainer">
        <Dialog
          fullScreen
          open={isOpen}
          className="materialModalScrollFix"
        >
          <DialogContent
            className={classes.test}
          >
            <div className="profileViewWrapper">
              <div className="profileAside">
                {edit && (
                  <EditProfileView
                    photo={photo}
                    points={points}
                    name={name}
                    location={country}
                    email={userEmail}
                    updateProfile={updateProfile}
                    onCancel={this.onEdit}
                    drawingsCount={drawingsCount}
                    maxPointsByLevel={maxPointsByLevel}
                    isUpdatingProfile={isUpdatingProfile}
                    apiError={apiError}
                    successfulUpdate={successfulUpdate}
                    resetUpdateProfileMessages={resetUpdateProfileMessages}
                    level={level}
                  />
                )}
                <div className={`profileAsideHeader ${ProfileView.showProfileInfo(!edit)}`}>
                  <div className={`responseMsg error ${ProfileView.showProfileInfo(successfulUpdate)}`}>
                    profile saved
                  </div>
                  <div className="profileInfo">
                    <div className="profileAvatarWrap">
                      <Avatar
                        alt=""
                        src={photo}
                        className="profileAvatar"
                      />
                    </div>
                    <div className="profileData">
                      <div className="profileTitles">
                        <div>
                          <h4>{name && name.toLowerCase()}
                            <div
                              tabIndex={0}
                              role="button"
                              onClick={() => followUser(id)}
                            >
                              {!isCurrentUser(id, currentUserId) && <FollowIcon followed={followedByUser} />}
                            </div>
                          </h4>
                          {country && <h6>{country.toLowerCase()}</h6>}
                        </div>
                      </div>
                      {/* <LevelBar
                        points={points}
                        maxPointsByLevel={maxPointsByLevel}
                        level={level}
                      /> */}
                    </div>
                    {/* <div className="drawingCountWrap">
                      <div className="drawingCount">
                        <span>{drawingsCount}</span>
                      </div>
                    </div> */}
                  </div>
                  <div className="profileAction text-center">
                    <button
                      className={`btn ${isCurrentUser(id, currentUserId) ? '' : 'dNone'}`}
                      type="button"
                      onClick={this.onEdit}
                    >edit
                    </button>
                  </div>
                </div>
                <div className="profileAsideBody">
                  {
                    userActivities.length
                      ? (
                        <InfiniteScroll
                          pageStart={0}
                          loadMore={getUserActivities}
                          hasMore={hasNext}
                          useWindow={false}
                          initialLoad={false}
                          loader={<div className="loader" key={0}>{hasNext ? 'Loading ...' : null}</div>}
                        >
                          <ul className="portfolioList list-unstyled">
                            <Activities
                              userActivities={userActivities}
                              selectedDrawing={selectedDrawing}
                              selectVc={this.selectVc}
                            />
                          </ul>
                        </InfiniteScroll>
                      )
                      : (
                        <NoActivities
                          openDrawingTool={openDrawingTool}
                          loggedIn={currentUserId}
                        />
                      )
                  }
                </div>
              </div>
              <div className="profileContent">
                <div className="profileContentHeader">
                  <button className="closeLightbox" type="button" onClick={this.onProfileClose}>
                    <img src={closeModal} alt="" />
                  </button>
                </div>
                {selectedVc
                  ? (
                    <div className="profileContentBody">
                      <VisualConversation
                        id={selectedVc}
                        currentBranch={activeBranch}
                        isVisible
                        authLikeAction={REQUEST_AUTH_FEED_LIKE_PROFILE_VIEW_PAGE}
                        authCommentAction={REQUEST_AUTH_FEED_COMMENT_PROFILE_VIEW_PAGE}
                        authFollowAction={REQUEST_AUTH_VC_PROFILE_FOLLOW_PROFILE_VIEW_PAGE}
                        history={history}
                      />
                    </div>
                  )
                  : null}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}


ProfileView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
const ProfileViewtyles = withStyles(styles)(ProfileView);


export default (ProfileViewtyles);
