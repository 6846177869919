import React from 'react';

import VisualConversationFeed from '../../containers/VisualConversationFeed';
import WelcomeMessage from '../../containers/WelcomeMessage';
import CoreShell from '../CoreShell';
import DrawingTool from '../../containers/DrawingTool';
import {
  CLOSE_DRAWING_TOOL_HOME_PAGE,
  POST_DRAWING_HOME_PAGE,
} from '../../containers/DrawingTool/actions';
import { REQUEST_AUTH_NAVBAR_HOME_PAGE } from '../../containers/AuthModal/actions';
import Navbar from '../../containers/Navbar';
import { LOGOUT_HOME_PAGE } from '../../containers/Authenticator/actions';

const HomePage = ({ history, location }) => (

  <CoreShell
    history={history}
    location={location}
  >
    <Navbar
      authAction={REQUEST_AUTH_NAVBAR_HOME_PAGE}
      logoutAction={LOGOUT_HOME_PAGE}
      history={history}
    />
    <DrawingTool
      postDrawingAction={POST_DRAWING_HOME_PAGE}
      postCloseAction={CLOSE_DRAWING_TOOL_HOME_PAGE}
    />
    <VisualConversationFeed
      isExplore={false}
      history={history}
      location={location}
    />
  </CoreShell>
);

export default HomePage;
