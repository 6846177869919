(function ($) {
    // $(window).load(function(){
    // initCanvas();
    // initLayerVisorControls();
    // });
    $(document).ready(function () {
        dadaCanvas.init_color_pickers()
    })(jQuery);
});
var auto_save = false;
// var auto_save_interval = null;
var undoRedoStack = new Array(), maxStackSize = 20, actualStackIndex = -1;
var undoRedoStackFinal = new Array(), maxStackSizeFinal = 20, actualStackIndexFinal = -1;
var wacomPluginPresent = false;
var final_line;
var canvas;
dadaCanvas.ctx;
dadaCanvas.ctx_final;
dadaCanvas.ctx_dummy;
dadaCanvas.stroke_properties = [];
var dot_flag = false;
var flag = dot_flag;
var counter_strokes = 1;
var flag_localstorage = false;

dadaCanvas.prevX = 0;
dadaCanvas.currX = 0;
dadaCanvas.prevY = 0;
dadaCanvas.currY = 0;
dadaCanvas.ctxs = { ctx: null, ctx_final: null };
var previus_ctx_final, first_save = true;
dadaCanvas.actualBrush;
dadaCanvas.lastmousetime;
var pps, w, h;
var sketchpad_storage = {};
// var sketchpad_storage = {
//   canvas_layer_0:"",
//   canvas_layer_1:"",
//   canvas_final_layer_0:"",
//   canvas_final_layer_1:""
// };
dadaCanvas.mousetravel = 0;
var canvas_initial_width, canvas_initial_height;
var canvas_final_width, canvas_final_height;
dadaCanvas.actualColor = {
    hex: "#000000",
    rgb: 'rgb(0,0,0)',
    rgb_obj: { r: 0, g: 0, b: 0 },
    rgba: 'rgba(0,0,0,1)'
};
dadaCanvas.rainbow;
var layers = new Array(),
    $dummy_canvas = $composed_canvas = $composed_canvas_final = $canvas = $canvas_final = dataURL = click = null,
    created_layers = 0, dragging_visor = false;
var initCanvasInitialized = false;
var initColorPickerInitialized = false;
var blank_blob = null;
var pressure;
dadaCanvas.resetSketchpadVar = resetSketchpadVar1;

function resetSketchpadVar1() {
    undoRedoStack = new Array(), maxStackSize = 20, actualStackIndex = -1;
    undoRedoStackFinal = new Array(), maxStackSizeFinal = 20, actualStackIndexFinal = -1;
    var final_line;
    var canvas;
    var ctx;
    var ctx_final;
    var ctx_dummy;
    var dummy;
    var ctxs = {
        ctx: null,
        ctx_final: null
    };
    var stroke_properties = [];
    var prevX = 0;
    var currX = 0;
    var prevY = 0;
    var currY = 0;
    var dot_flag = false;
    var flag = dot_flag;
    var counter_strokes = 1;
    var flag_localstorage = false;
    var sketchpad_storage = {};
    // var sketchpad_storage = {
    //   canvas_layer_0:"",
    //   canvas_layer_1:"",
    //   canvas_final_layer_0:"",
    //   canvas_final_layer_1:""
    // };

    dadaCanvas.actualBrush = null;
    dadaCanvas.lastmousetime = null;
    pps, w, h = null;
    dadaCanvas.mousetravel = 0;
    dadaCanvas.actualColor = {
        hex: "#000000",
        rgb: 'rgb(0,0,0)',
        rgb_obj: { r: 0, g: 0, b: 0 },
        rgba: 'rgba(0,0,0,1)'
    };
    dadaCanvas.SketchpadStorage.params.bg_color = 'rgb(255,255,255)';
    dadaCanvas.rainbow = null;
    layers = new Array(), $dummy_canvas = $composed_canvas = $composed_canvas_final = $canvas = $canvas_final = dataURL = click = null, created_layers = 0, dragging_visor = false;
    initCanvasInitialized = false;
    initColorPickerInitialized = false;
    if (dadaCanvas.eyedropper.options.circle_color != null)
        dadaCanvas.eyedropper.options.circle_color.remove();
    // $('#brush_size_slider').slider({orientation: "horizontal"});

}

function initCanvas(sketchpad_container_selector, conf_json) {
    // init_auto_save();

    if ($(sketchpad_container_selector).data('container-type') == 'modal') {
        if (conf_json.size) {
            canvas_initial_width = conf_json.size.width;
            canvas_initial_height = conf_json.size.height;
        } else {
            canvas_initial_width = $(sketchpad_container_selector).width();
            canvas_initial_height = $(sketchpad_container_selector).height();
        }
        $('header').addClass("svg-blur blur-object")
        $('.uparea').addClass("svg-blur blur-object")
        $('#sketchpad_button_region').addClass("svg-blur blur-object")
        $('#activity_region').addClass("svg-blur blur-object")
        $('footer').addClass("svg-blur blur-object")
    } else {
        if (conf_json.size) {
            canvas_initial_width = conf_json.size.width;
            canvas_initial_height = conf_json.size.height;
            canvas_final_width = 2048
            canvas_final_height = 1536
        } else {
            canvas_initial_width = $(sketchpad_container_selector).width() - 20;
            canvas_initial_height = $(sketchpad_container_selector).height() - 10;
            canvas_final_width = 2048
            canvas_final_height = 1536
        }
    }
    $('#all_canvas_container').css('height', canvas_initial_height);
    $('#background_dummy_canvas_container').attr('style', $('#background_dummy_canvas_container').attr('style') + ';height:' + canvas_initial_height + 'px;width:' + canvas_initial_width + 'px;');
    //creating dummy canvas to capture mouse events. Mouse positions and events are
    //captured using the dummy object and then are translated to the selected layer's context.
    $dummy_canvas = $("<canvas id='dummy_canvas' width='" + canvas_initial_width + "' height='" + canvas_initial_height + "'></canvas>");

    $canvas_blank = $("<canvas id='canvas_blank' width='" + canvas_final_width + "' height='" + canvas_final_height + "'></canvas>");
    // set control blank canvas blob value
    $canvas_blank[0].toBlob(function (blob) {
        blank_blob = blob
    });

    // $dummy_canvas=$("<canvas id='dummy_canvas' width='1280' height='960'></canvas>");
    $dummy_canvas.appendTo("#dummyCanvasContainer");
    $canvas_blank.appendTo("#canvasBlankContainer");
    $composed_canvas = $("<canvas id='composed_canvas' width='" + canvas_initial_width + "' height='" + canvas_initial_height + "' class='hide'></canvas>");

    $composed_canvas_final = $("<canvas id='composed_canvas_final' width='" + canvas_final_width + "' height='" + canvas_final_height + "' class='hide'></canvas>");
    // $composed_canvas=$("<canvas id='composed_canvas' width='640' height='1080' class='hide'></canvas>");
    $composed_canvas.appendTo("#dummyCanvasContainer");
    $composed_canvas_final.appendTo("#dummyCanvasContainer");
    //creating kinectic canvas container
    $canvas = new Kinetic.Stage({
        container: 'canvasContainer',
        id: 'canvas',
        height: canvas_initial_height,
        width: canvas_initial_width
    });
    $canvas_final = new Kinetic.Stage({
        container: 'canvasContainer_final',
        id: 'canvas_final',
        height: canvas_final_height,
        width: canvas_final_width
    });

    if (conf_json.initializeLayersVisor) {
        initLayerVisorControls();
        $("#toggle_layers_control").click(function (event) {
            event.preventDefault();
            if ($('#toggle_layers_control').hasClass('active'))
                $('#toggle_layers_control').removeClass('active');
            else $('#toggle_layers_control').addClass('active');
            $('#layers_visor').toggle('slow');
        });
    } else {
        $("#toggle_layers_control").remove();
    }

    //creating initial canvas: background and the first layer
    //TODO put create_visor_item outside the json array

    addLayers([{
        clearBeforeDraw: true,
        id: 'layer_' + created_layers++,
        name: 'background',
        create_visor_item: conf_json.initializeLayersVisor
    }, {
        clearBeforeDraw: false,
        id: 'layer_' + created_layers,
        name: 'new_layer_' + created_layers++,
        create_visor_item: conf_json.initializeLayersVisor
    }], true, $canvas);

    addLayers([{
        clearBeforeDraw: true,
        id: 'layer_' + created_layers++,
        name: 'background',
        create_visor_item: conf_json.initializeLayersVisor
    }, {
        clearBeforeDraw: false,
        id: 'layer_' + created_layers,
        name: 'new_layer_' + created_layers++,
        create_visor_item: conf_json.initializeLayersVisor
    }], true, $canvas_final);


    dadaCanvas.dummy = $('#dummy_canvas')[0];
    dadaCanvas.ctx_dummy = $('#dummy_canvas')[0].getContext('2d');
    dadaCanvas.dummy.style.cssText = 'position:absolute;z-index:999;';
    $('#composed_canvas')[0].style.cssText = 'position:absolute;z-index:0;';
    $('#composed_canvas_final')[0].style.cssText = 'position:absolute;z-index:-1;';
    $('canvas').css('cursor', 'crosshair');
    //$('canvas').css('margin-left' , '10px');
    //dadaCanvas.ctx=layers[1].getContext();


    dadaCanvas.rainbow = new dadaCanvas.Rainbow();
    // fill_background('white', $canvas);
    // fill_background('white', $canvas_final);
    putStateInStack();
    // print_current_image_size();

    dadaCanvas.setBrushType('pencil');
    if ($('#pencil').length)
        $('#pencil').addClass('down');
    color(dadaCanvas.actualColor);

    dadaCanvas.dummy.addEventListener("touchmove", function (event) {
        event.preventDefault();
    }, false);
    dadaCanvas.dummy.addEventListener("mousemove", function (e) {
        findxy('move', e);
    }, false);
    dadaCanvas.dummy.addEventListener("touchmove", function (e) {
        findxy('tmove', e);
    }, false);
    dadaCanvas.dummy.addEventListener("mousedown", function (e) {
        e.preventDefault();
        findxy('down', e);
    }, false);
    dadaCanvas.dummy.addEventListener("touchstart", function (e) {
        findxy('tdown', e);
    }, false);
    dadaCanvas.dummy.addEventListener("mouseup", function (e) {
        findxy('up', e);
    }, false);
    dadaCanvas.dummy.addEventListener("touchend", function (e) {
        findxy('tup', e);
    }, false);
    dadaCanvas.dummy.addEventListener("mouseout", function (e) {
        findxy('out', e);
    }, false);
    $dummy_canvas.mouseleave(function (e) {
        findxy('leave', e);
    });
    $dummy_canvas.mouseenter(function (e) {
        findxy('enter', e);
    });
    dadaCanvas.dummy.addEventListener("contextmenu", function (e) {
        e.preventDefault();
        return false;
    }, false);


    if ($('#morph_btn_footer_right'))
        $('#morph_btn_footer_right').click(function () {
            // prepare_sketchpad_form();
            set_blob_and_send();
        });

    if ($('#btn_undo'))
        $("#btn_undo").click(function () {
            undo();
        });

    if ($('#btn_redo'))
        $("#btn_redo").click(function () {
            redo();
        });
    //init brush size slider

    if ($("#brush_size_slider")) {

        $("#brush_size_slider").slider({
            orientation: $("#brush_size_slider").attr("data-orientation") ? $("#brush_size_slider").attr("data-orientation") : "vertical",
            range: "min",
            min: 1,
            max: 10,
            step: 1,
            value: 1,
            slide: function (event, ui) {
                $("#amount").text(ui.value);
                setLineWidth(ui.value);
            }
        });
        if ($("#amount")) {
            $("#amount").text($("#brush_size_slider").slider("value"));
            setLineWidth(parseInt($("#amount").text()));
        }
        $("#show_brush_size_slider").click(function (event) {
            event.preventDefault();
            if ($('#show_brush_size_slider').hasClass('active')) {
                $('#show_brush_size_slider').removeClass('active');
                // $('#brush_size_slider_container').addClass('hide');
                if ($('#brush_size_slider_container').css('display') != undefined) $('#brush_size_slider_container').css('display', '');

            } else {
                $('#show_brush_size_slider').addClass('active');
                // $('#brush_size_slider_container').removeClass('hide');
                if ($('#brush_size_slider_container').css('display') != undefined) $('#brush_size_slider_container').css('display', '');

            }

        });
    }
    // wacomPluginPresent = isPluginLoaded();
}

dadaCanvas.initCanvas = initCanvas;

function init_sketchpad_storage() {
    dadaCanvas.SketchpadStorage.initialize()
}

var interval = null;

// Init Auto Save
function init_auto_save() {
    auto_save = true;
    first_save = true;
    // auto_save_interval = setInterval(auto_save_by_time, 300000);
}

function init_color_pickers(options) {

    $("#color_picker").spectrum({
        cancelText: "Cancel",
        chooseText: "Ok",
        clickoutFiresChange: true,
        color: "#000000",
        localStorageKey: "dada.am.sketchpad",
        palette: ['#C2CD4A', '#F57B20', '#4A9E9C', '#D00012', '#60718B', 'black'],
        preferredFormat: "hex6", // valid formats: hex (3 chars hex), hsl, rgb, name
        showAlpha: false,
        showButtons: true,
        showInitial: true,
        showInput: true,
        showPalette: true,
        showSelectionPalette: true,
        //events
        change: function (selected_color) {
            rgb_obj = selected_color.toRgb();
            color({
                hex: selected_color.toHexString(),
                rgb: "rgb(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + ")",
                rgb_obj: rgb_obj,
                rgba: "rgba(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + "," + parseInt($('#alpha_box').val()) / 100 + ")"
            });
            $("#color_picker").spectrum("set", dadaCanvas.actualColor.rgba);

        },
        move: function (selected_color) {
            rgb_obj = selected_color.toRgb();
            color({
                hex: selected_color.toHexString(),
                rgb: "rgb(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + ")",
                rgb_obj: rgb_obj,
                rgba: "rgba(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + "," + parseInt($('#alpha_box').val()) / 100 + ")"
            });
            $("#color_picker").spectrum("set", dadaCanvas.actualColor.rgba);
            dadaCanvas.eyedropper.options.color_already_selected = true;
        },
        show: function (selected_color) {
            $(this).spectrum('container').draggable();
            $dummy_canvas.addClass("colorPickerCursor");
            dadaCanvas.eyedropper.set_last_brush_selected(false);
            dadaCanvas.setBrushType('eyedropper');
        },
        hide: function (selected_color) {
            $dummy_canvas.removeClass("colorPickerCursor");
            if (dadaCanvas.eyedropper.options.last_brush_button_selected && dadaCanvas.eyedropper.options.last_brush_button_selected.length > 0)
                dadaCanvas.eyedropper.options.last_brush_button_selected.click();
            else
                dadaCanvas.eyedropper.reset();
        }
    });
    $("#bg_color_picker").spectrum({
        cancelText: "Cancel",
        chooseText: "Ok",
        clickoutFiresChange: true,
        color: "#ffffff",
        localStorageKey: "dada.am.sketchpad",
        palette: ['#C2CD4A', '#F57B20', '#4A9E9C', '#D00012', '#60718B', 'black', 'rgba(0,0,0,0)', 'white'],
        preferredFormat: "hex6", // valid formats: hex (3 chars hex), hsl, rgb, name
        showAlpha: false,
        showButtons: true,
        showInitial: true,
        showInput: true,
        showPalette: true,
        showSelectionPalette: true,
        //events
        change: function (selected_color) {
            dadaCanvas.SketchpadStorage.params.bg_color = selected_color.toRgbString();
            rgb_obj = selected_color.toRgb();
            bg_color({
                hex: selected_color.toHexString(),
                rgb: "rgb(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + ")",
                rgb_obj: rgb_obj,
                rgba: selected_color.toRgbString()
            }, $canvas);
            bg_color({
                hex: selected_color.toHexString(),
                rgb: "rgb(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + ")",
                rgb_obj: rgb_obj,
                rgba: selected_color.toRgbString()
            }, $canvas_final);
        },
        move: function (selected_color) {
            dadaCanvas.SketchpadStorage.params.bg_color = selected_color.toRgbString();
            rgb_obj = selected_color.toRgb();
            bg_color({
                hex: selected_color.toHexString(),
                rgb: "rgb(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + ")",
                rgb_obj: rgb_obj,
                rgba: selected_color.toRgbString()
            }, $canvas);
            bg_color({
                hex: selected_color.toHexString(),
                rgb: "rgb(" + rgb_obj.r + "," + rgb_obj.g + "," + rgb_obj.b + ")",
                rgb_obj: rgb_obj,
                rgba: selected_color.toRgbString()
            }, $canvas_final);
            dadaCanvas.eyedropper.options.color_already_selected = true;
        },
        show: function (selected_color) {
            $(this).spectrum('container').draggable();
            $dummy_canvas.addClass("colorPickerCursor");
            dadaCanvas.eyedropper.set_last_brush_selected(true);
            dadaCanvas.setBrushType('eyedropper');
        },
        hide: function (selected_color) {
            $dummy_canvas.removeClass("colorPickerCursor");
            if (dadaCanvas.eyedropper.options.last_brush_button_selected && dadaCanvas.eyedropper.options.last_brush_button_selected.length > 0)
                dadaCanvas.eyedropper.options.last_brush_button_selected.click();
            else
                dadaCanvas.eyedropper.reset();
        }
    });
    $("<a href='#' class='btn'>Cancel</a>").appendTo($('.sp-button-container')).click(function (e) {
        e.preventDefault();
        $('.sp-cancel').click();
    });
    $("<a href='#' class='btn'>Ok</a>").appendTo($('.sp-button-container')).click(function (e) {
        e.preventDefault();
        $('.sp-choose').click();
    });
    $('.sp-cancel').addClass('hide');
    $('.sp-choose').addClass('hide');
    dadaCanvas.alphaValue = 100;

    if ($("#alpha_slider")) {
        $("#alpha_slider").slider({
            orientation: $("#alpha_slider").attr("data-orientation") ? $("#alpha_slider").attr("data-orientation") : "vertical",
            range: "min",
            min: 1,
            max: 100,
            step: 1,
            value: dadaCanvas.alphaValue,
            slide: function (event, ui) {
                $("#alpha_amount").text(ui.value);
                $('#alpha_box').val(ui.value);
                rgb_alpha = 'rgba(' + dadaCanvas.actualColor.rgb_obj.r + ',' + dadaCanvas.actualColor.rgb_obj.g + ',' + dadaCanvas.actualColor.rgb_obj.b + ',' + parseInt(ui.value) * 1 / 100 + ')';
                $("#color_picker").spectrum("set", rgb_alpha);
                dadaCanvas.actualColor.rgba = rgb_alpha;
                // Set Alpha in local storage
                dadaCanvas.alphaValue = isNaN(ui.value) ? 100 : ui.value;
            },
            stop: function (event, ui) {
                color(dadaCanvas.actualColor);
            },
            change: function (event, ui) {
                rgb_alpha = 'rgba(' + dadaCanvas.actualColor.rgb_obj.r + ',' + dadaCanvas.actualColor.rgb_obj.g + ',' + dadaCanvas.actualColor.rgb_obj.b + ',' + parseInt(ui.value) * 1 / 100 + ')';
                $("#color_picker").spectrum("set", rgb_alpha);
                dadaCanvas.actualColor.rgba = rgb_alpha;
                color(dadaCanvas.actualColor);
            }
        });
        if ($("#alpha_amount")) {
            $("#alpha_amount").text($("#alpha_slider").slider("value"));
            rgb_alpha = 'rgba(' + dadaCanvas.actualColor.rgb_obj.r + ',' + dadaCanvas.actualColor.rgb_obj.g + ',' + dadaCanvas.actualColor.rgb_obj.b + ',' + parseInt($("#alpha_slider").slider("value")) * 1 / 100 + ')';
            $("#color_picker").spectrum("set", rgb_alpha);
            dadaCanvas.actualColor.rgba = rgb_alpha;
        }
        // Set Slider Percentage
        var alpha_value = dadaCanvas.alphaValue;
        alpha_value = parseInt(alpha_value);
        alpha_value = isNaN(alpha_value) ? 100 : alpha_value;
        $('#alpha_box').val(alpha_value);
    }

    if ($('#alpha_box')) {
        $("#alpha_box").bind({
            focus: function () {
                $("#show_alpha_slider").parent().removeClass('hidden');
                $("#show_alpha_slider").removeClass('hide');
                if ($("#show_alpha_slider").hasClass('active')) ;
                $("#alpha_slider_container").removeClass('hide');
            },
            keydown: function (event) {
                if (!event.shiftKey && !event.altKey && !event.ctrlKey && (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
                    // 0-9 or numpad 0-9
                } else if (event.keyCode != 8 && event.keyCode != 46 && event.keyCode != 37 && event.keyCode != 39) { // not esc, del, left or right
                    event.preventDefault();
                }
            },
            keyup: function (event) {
                if (!event.shiftKey && !event.altKey && !event.ctrlKey && (event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || dadaCanvas._.contains([8, 46, 37, 39], event.keyCode)) {
                    if (parseInt($('#alpha_box').val())) {
                        alpha_val = parseInt($('#alpha_box').val())
                    } else {
                        alpha_val = 0
                    }
                    // 0-9 or numpad 0-9
                    $("#alpha_amount").text(alpha_val);
                    rgb_alpha = 'rgba(' + dadaCanvas.actualColor.rgb_obj.r + ',' + dadaCanvas.actualColor.rgb_obj.g + ',' + dadaCanvas.actualColor.rgb_obj.b + ',' + alpha_val * 1 / 100 + ')';
                    $("#color_picker").spectrum("set", rgb_alpha);
                    dadaCanvas.actualColor.rgba = rgb_alpha;
                    $("#alpha_slider").slider('value', alpha_val);
                }
            }

        });
    }


}

dadaCanvas.init_color_pickers = init_color_pickers;

function fill_background(bcolor, canvas) {
    bg_color($("#bg_color_picker").spectrum("get").toRgbString(), canvas);
}

/*
selected_color is from iro.js
function is used in dada_react repo
 */
function set_canvas_background(selected_color) {
    // console.log('canvas web selected color');
    // console.log(selected_color);
    dadaCanvas.SketchpadStorage.params.bg_color = selected_color.rgbString;
    rgb_obj = selected_color.rgb;
    bg_color({
        hex: selected_color.hexString,
        rgb: selected_color.rgbString,
        rgb_obj: rgb_obj,
        rgba: selected_color.rgbaString
    }, $canvas);
    bg_color({
        hex: selected_color.hexString,
        rgb: selected_color.rgbString,
        rgb_obj: rgb_obj,
        rgba: selected_color.rgbaString
    }, $canvas_final);
}

dadaCanvas.setCanvasBackgroundColor = set_canvas_background;

function erase(confirmation) {
    if (confirmation) {
        var m = confirm("Are you sure you want to clear the canvas?");
        if (m) {
            dadaCanvas.ctx.clearRect(0, 0, canvas_initial_width, canvas_initial_height);
            dadaCanvas.ctx_final.clearRect(0, 0, canvas_final_width, canvas_final_height);
        }
    } else {
        dadaCanvas.ctx.clearRect(0, 0, canvas_initial_width, canvas_initial_height);
        dadaCanvas.ctx_final.clearRect(0, 0, canvas_final_width, canvas_final_height);
    }
}

dadaCanvas.erase = erase;

function findxy(res, e) {
    switch (true) {
        case(res == 'down' || res == 'tdown'):
            if (dadaCanvas.actualBrush.options.isBrush) {
                click = true
                // dadaCanvas.currX=e.layerX;
                // dadaCanvas.currY=e.layerY;
                dadaCanvas.currX = e.pageX - $(dadaCanvas.dummy).offset().left;
                dadaCanvas.currY = e.pageY - $(dadaCanvas.dummy).offset().top;
                if (res == 'tdown') {
                    dadaCanvas.currX = e.targetTouches[0].pageX - $(dadaCanvas.dummy).offset().left;
                    dadaCanvas.currY = e.targetTouches[0].pageY - $(dadaCanvas.dummy).offset().top;
                }
                flag = true;
                dot_flag = true;

                if (dot_flag) {
                    for (i = 0; i < dadaCanvas.actualBrush.options.strokes; i++) {
                        dadaCanvas.actualBrush.options.bristles[i].dx = dadaCanvas.currX;
                        dadaCanvas.actualBrush.options.bristles[i].dy = dadaCanvas.currY;
                    }


                    dadaCanvas.ctx_dummy.beginPath();
                    dadaCanvas.ctx_dummy.fillStyle = dadaCanvas.actualBrush.options.lineColor;

                    if (wacomPluginPresent && dadaCanvas.actualBrush.options.usePressure) {
                        if (dadaCanvas.actualBrush.is_mutant() && getWacomPlugin().penAPI.pointerType != 0) {
                            pressure = getWacomPlugin() ? getWacomPlugin().penAPI.pressure : 1.0;
                            dadaCanvas.actualBrush.options.lineWeight = dadaCanvas.actualBrush.calculateLineWeightUsingPresure(pressure);
                            dadaCanvas.actualBrush.calculateColor(dadaCanvas.actualBrush.options.lineWeight);
                        } else if (!dadaCanvas.actualBrush.is_mutant() && getWacomPlugin().penAPI.pointerType != 0) {
                            pressure = getWacomPlugin() ? getWacomPlugin().penAPI.pressure : 1.0;
                            dadaCanvas.actualBrush.options.lineWeight = dadaCanvas.actualBrush.calculateLineWeightUsingPresure(pressure);
                            dadaCanvas.actualBrush.calculateColor(dadaCanvas.actualBrush.options.lineWeight);
                        } else {
                            dadaCanvas.actualBrush.calculateColor(dadaCanvas.actualBrush.options.lineWeight);
                            dadaCanvas.actualBrush.mouseSpeedCalcs();
                        }
                    } else {
                        if (dadaCanvas.actualBrush.is_mutant())
                            dadaCanvas.actualBrush.mouseSpeedCalcs();
                    }

                    dadaCanvas.ctx_dummy.arc(dadaCanvas.currX, dadaCanvas.currY, dadaCanvas.actualBrush.options.lineWeight / 2, 0, 2 * Math.PI);
                    dadaCanvas.ctx_dummy.closePath();
                    dadaCanvas.ctx_dummy.fill();

                    dadaCanvas.stroke_properties = [{
                        x: dadaCanvas.currX,
                        y: dadaCanvas.currY,
                        dx: dadaCanvas.currX,
                        dy: dadaCanvas.currY,
                        lineWidth: dadaCanvas.actualBrush.options.lineWeight,
                        lineColor: dadaCanvas.actualBrush.options.lineColor
                    }]

                    dot_flag = false;
                }
            } else {
                if (dadaCanvas.actualBrush.options.tmp_ctx == null) {
                    // if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                    //   // res = (e.which == 3 ? 'rdown' : res);
                    // else if ("button" in e)  // IE, Opera
                    //   // res = (e.button == 2 ? 'rdown' : res);
                    if (("which" in e && e.which != 3) || ("button" in e && e.button != 2))
                        dadaCanvas.actualBrush.down(res, e);
                    else return;
                }
            }
            ;
            break;
        case (res == 'rdown'):
            if (!dadaCanvas.actualBrush.options.isBrush) {
                dadaCanvas.actualBrush.down(res, e);
            }
            break;
        case (res == 'up' || res == "out" || res == 'tup'):
            if (dadaCanvas.actualBrush.options.isBrush) {
                flag = false;
                dadaCanvas.actualBrush.up();
                //saves the actual canvas state
                if (res != "out") {
                    putStateInStack();
                    // print_current_image_size();
                    // setLocalStorage();
                }
            }
            ;
            break;
        case (res == 'enter' || res == 'leave'):
            if (!dadaCanvas.actualBrush.options.isBrush) {
                if (res == 'enter') {
                    dadaCanvas.actualBrush.enter(res, e);
                } else {
                    dadaCanvas.actualBrush.leave(res, e);
                }
            }
            ;
            break;
        case (res == 'move' || res == 'tmove'):
            if (dadaCanvas.actualBrush.options.isBrush) {
                if (flag) {
                    dadaCanvas.prevX = dadaCanvas.currX;
                    dadaCanvas.prevY = dadaCanvas.currY;
                    // dadaCanvas.currX=e.layerX;
                    // dadaCanvas.currY=e.layerY;
                    dadaCanvas.currX = e.pageX - $(dadaCanvas.dummy).offset().left;
                    dadaCanvas.currY = e.pageY - $(dadaCanvas.dummy).offset().top;
                    if (res == 'tmove') {
                        dadaCanvas.currX = e.targetTouches[0].pageX - $(dadaCanvas.dummy).offset().left;
                        dadaCanvas.currY = e.targetTouches[0].pageY - $(dadaCanvas.dummy).offset().top;
                    }


                    if (wacomPluginPresent && dadaCanvas.actualBrush.options.usePressure) {

                        if (dadaCanvas.actualBrush.is_mutant() && getWacomPlugin().penAPI.pointerType != 0) {
                            pressure = getWacomPlugin() ? getWacomPlugin().penAPI.pressure : 1.0;
                            dadaCanvas.actualBrush.options.lineWeight = dadaCanvas.actualBrush.calculateLineWeightUsingPresure(pressure);
                            dadaCanvas.actualBrush.calculateColor(dadaCanvas.actualBrush.options.lineWeight);
                        } else if (!dadaCanvas.actualBrush.is_mutant() && getWacomPlugin().penAPI.pointerType != 0) {
                            pressure = getWacomPlugin() ? getWacomPlugin().penAPI.pressure : 1.0;
                            dadaCanvas.actualBrush.options.lineWeight = dadaCanvas.actualBrush.calculateLineWeightUsingPresure(pressure);
                            dadaCanvas.actualBrush.calculateColor(dadaCanvas.actualBrush.options.lineWeight);
                        } else {
                            dadaCanvas.actualBrush.calculateColor(dadaCanvas.actualBrush.options.lineWeight);
                            dadaCanvas.actualBrush.mouseSpeedCalcs();
                        }
                    } else {
                        if (dadaCanvas.actualBrush.is_mutant())
                            dadaCanvas.actualBrush.mouseSpeedCalcs();
                    }
                    dadaCanvas.actualBrush.draw();
                }
            } else {
                if (dadaCanvas.actualBrush.options.tmp_ctx == null) {
                    dadaCanvas.actualBrush.move(res, e);
                }
            }
            ;
            break;
    }
}

function setLineWidth(width) {
    dadaCanvas.actualBrush.calcBrushSize(parseInt(width));
}

var brushes = {};
brushes.pencil = dadaCanvas.pencil;
brushes.round_brush = dadaCanvas.round_brush;
brushes.flat_brush = dadaCanvas.flat_brush;
brushes.marker = dadaCanvas.marker;
brushes.eraser = dadaCanvas.eraser;
brushes.eyedropper = dadaCanvas.eyedropper;


function setBrushType(name) {

    // reset current brush if method present before setting the new brush
    $(".size-slider").addClass('hidden');
    $('#toolBox').find('.down').removeClass('down');
    if (dadaCanvas.actualBrush && 'reset' in dadaCanvas.actualBrush)
        dadaCanvas.actualBrush.reset();

        $(".size-inner").parent().addClass('hidden');
        $(".size-inner").addClass('hidden');

        if(name === 'pencil' && dadaCanvas.actualBrush){
            setLineWidth(1);

        }
    if (name == 'pencil' || name == 'eyedropper') {

    } else {
        $(`#${name}_slider`).parent().removeClass('hidden');
        $(`#${name}_slider`).removeClass('hide');
        $(`#${name}_slider`).removeClass('hidden');
        if ($(`#${name}_slider`).hasClass('active'))
            $(`#${name}_slider`).removeClass('hide');
    }

    if (name != 'eraser') {
        //setting again the context in order to paint color instead of cutting out color,
        dadaCanvas.ctx_dummy.globalCompositeOperation = "source-over";
        dadaCanvas.ctx.globalCompositeOperation = "source-over";
        dadaCanvas.ctx_final.globalCompositeOperation = "source-over";
    }
    dadaCanvas.actualBrush = brushes[name];
    dadaCanvas.actualBrush.init();
    $('#' + name).addClass('down');
}

dadaCanvas.setBrushType = setBrushType;

function setBrushSize(options) {
    const type = options.type;
    const size = options.size;

    $(`#brush-size-list li`).removeClass("active-size");
    $(`#${type}-li-brush-size-${String(size)}`).addClass('active-size');
    setLineWidth(parseInt(size));

}

dadaCanvas.setBrushSize = setBrushSize;

function prepare_sketchpad_form() {
    if (validateInput("#email", true) && validateInput("#name", true)) {
        if (validateInput("#email", false) && validateInput("#name", false)) {
            $("#frm_save_sketch").submit();
        } else {
            showAlert("Please type your name and email");
        }
    } else {
        $("#frm_save_sketch").submit();
    }
}

function validateInput(selector, validate_existence) {
    if (validate_existence)
        return $(selector).length > 0;
    else
        return $(selector).val() != null && $(selector).val() != ''
}


function getLocalStorage(bgcolor, img_large_path, confirm_modal) {
    flag_localstorage = true

    var headers = new Headers();
    headers.append('SSIMGREQUEST', true);
    var fetch_params = {
        method: 'GET',
        headers: headers,
        cache: 'default'
    };
    // mode: 'cors',
    $.each($canvas.getChildren(), function (index, canvas_layer) {
        if (index == 1) {
            bg_color({
                rgba: bgcolor
            }, $canvas);
            dadaCanvas.ctx = canvas_layer.getContext()._context;
        }
    });
    $.each($canvas_final.getChildren(), function (index, canvas_layer) {
        if (index == 1) {
            bg_color({
                rgba: bgcolor
            }, $canvas_final);
            dadaCanvas.ctx_final = canvas_layer.getContext()._context;
        }
    });

    if (dadaCanvas.SketchpadStorage.params.img_large == null) {
        // response => response.blob() is equivalent to
        // implement: function(response){return response.blob()}
        fetch(img_large_path, fetch_params)
            .then(function (response) {
                return response.blob();
            })
            .then(function (blob) {
                return createImageBitmap(blob);
            })
            .then(function (imageBitmap) {
                dadaCanvas.ctx.drawImage(imageBitmap, 0, 0, canvas_initial_width, canvas_initial_height);
                return dadaCanvas.ctx_final.drawImage(imageBitmap, 0, 0, canvas_final_width, canvas_final_height, 0, 0, canvas_initial_width, canvas_initial_height);
            })
            .catch(function (err) {
                confirm_modal.ui.sketchpad_storage_img_item.button('reset');
            })
            .then(function (event) {
                confirm_modal.ui.sketchpad_storage_img_item.button('reset');
                confirm_modal._parent.ui.sketchpad_storages_modal.modal('hide');
                return print_current_image_size();
            });
    } else {
        // Legacy code to inject image into canvas
        img = new Image;
        img.onload = function () {
            dadaCanvas.ctx.drawImage(img, 0, 0, canvas_initial_width, canvas_initial_height);
            // return
            dadaCanvas.ctx_final.drawImage(img, 0, 0, canvas_final_width, canvas_final_height, 0, 0, canvas_initial_width, canvas_initial_height);
            confirm_modal.ui.sketchpad_storage_img_item.button('reset');
            confirm_modal._parent.ui.sketchpad_storages_modal.modal('hide');
            // print_current_image_size();
        };
        img.src = dadaCanvas.SketchpadStorage.params.img_large;
    }
    ;
}

function setLocalStorage(save, button, post) {
    save = save || false;
    button = button || false;
    post = post || false;

    if (save == true) {
        if (!flag_localstorage) {
            // localStorage.clear();
            flag_localstorage = true
            $(".get_localStorage").remove()
            $(".alert_local_storage").remove()
        }
        var composed_ctx, composed_ctx_final;
        // localStorage.clear();
        composed_ctx = $("#composed_canvas")[0].getContext("2d");
        composed_ctx_final = $("#composed_canvas_final")[0].getContext("2d");

        counter_strokes = 1;

        // stop_auto_save();
        // $canvas_final.find('#layer_3')[0].getCanvas()._canvas.toBlob(exec_sketchpad_storage_save(button, post));


    } else {
        counter_strokes++;
    }
}

function exec_sketchpad_storage_save(button, post) {
    return function (blob) {
        dadaCanvas.SketchpadStorage.params.blob_file = blob;
        // Verify if Canvas is blank
        if (!(blob.size == blank_blob.size)) {
            // Verify if image has changed or it is a first save
            if ((first_save == true) || (previus_ctx_final != undefined && !(previus_ctx_final.size == blob.size))) {
                dadaCanvas.SketchpadStorage.saveSketchpad(button, post);
            } else {
                // dadaCanvas.restart_auto_save();
            }
        } else {
            // dadaCanvas.restart_auto_save();
        }


        // Set old_ctx for next auto_save
        previus_ctx_final = blob
        first_save = false;
    }
}

// check into this code later to see if the comparisson between the content on two blobs can be achieved
function equal_blobs(blob1, blob2) {
    var reading_blob1 = true, reading_blob2 = true, blob1_reader = new FileReader(), blob2_reader = new FileReader(),
        blob1_buffer = null, blob2_buffer = null;
    blob1_reader.addEventListener("loadend", function () {
        blob1_buffer = this.result;
        reading_blob1 = false;
    });
    blob2_reader.addEventListener("loadend", function () {
        blob2_buffer = this.result;
        reading_blob2 = false;
    });
    blob1_reader.readAsArrayBuffer(blob1);
    blob2_reader.readAsArrayBuffer(blob2);

    while (reading_blob1 || reading_blob2) {
        // this is a failed attempt to create a loop while reading the blobs an to wait for the read to finish
    }

    if (blob1_buffer.byteLength != blob2_buffer.byteLength) return false;
    var dv1 = new Int8Array(blob1_buffer);
    var dv2 = new Int8Array(blob2_buffer);
    for (var i = 0; i != blob1_buffer.byteLength; i++) {
        if (dv1[i] != dv2[i]) return false;
    }
    return true;
}

function putStateInStack() {
    //delete forward stack elements if actual stack is behind the stack size
    if (undoRedoStack.length > 0 && actualStackIndex < undoRedoStack.length - 1) {
        undoRedoStack.splice(actualStackIndex + 1, undoRedoStack.length - actualStackIndex + 1);
    }

    //delete the first element in the stack to preserve memory if the max stack size was reached,
    //this open space is used to push a new element at the end of the array
    if (maxStackSize == undoRedoStack.length) {
        undoRedoStack.shift();
    }

    //push the new element
    undoRedoStack.push({
        'ctx': dadaCanvas.ctx,
        'imageData': dadaCanvas.ctx.getImageData(0, 0, canvas_initial_width, canvas_initial_height),
    });


    //###########################FINAL################################
    if (undoRedoStackFinal.length > 0 && actualStackIndexFinal < undoRedoStackFinal.length - 1) {
        undoRedoStackFinal.splice(actualStackIndexFinal + 1, undoRedoStackFinal.length - actualStackIndexFinal + 1);
    }

    if (maxStackSizeFinal == undoRedoStackFinal.length) {
        undoRedoStackFinal.shift();
    }
    undoRedoStackFinal.push({
        'ctx_final': dadaCanvas.ctx_final,
        'imageDataFinal': dadaCanvas.ctx_final.getImageData(0, 0, canvas_final_width, canvas_final_height)
    });
    //###########################FINAL################################


    if (actualStackIndex < maxStackSize - 1) actualStackIndex++;

    if (actualStackIndexFinal < maxStackSizeFinal - 1) actualStackIndexFinal++;

    if (actualStackIndex > 0) $("#btn_undo").attr('disabled', null);
    if (actualStackIndex == undoRedoStack.length - 1) $("#btn_redo").attr('disabled', 'disabled');
    else if (actualStackIndex < undoRedoStack.length - 1) $("#btn_redo").attr('disabled', null);

}

function undo() {
    if ($("#btn_undo").attr('disabled') == 'disabled') return false;
    else {
        if (actualStackIndex > 0) actualStackIndex--;
        if (actualStackIndexFinal > 0) actualStackIndexFinal--;

        undoRedoStack[actualStackIndex].ctx.clearRect(0, 0, canvas_initial_width, canvas_initial_height);
        dadaCanvas.ctx.putImageData(undoRedoStack[actualStackIndex].imageData, 0, 0);

        //###########################FINAL################################
        undoRedoStackFinal[actualStackIndexFinal].ctx_final.clearRect(0, 0, canvas_final_width, canvas_final_height);
        dadaCanvas.ctx_final.putImageData(undoRedoStackFinal[actualStackIndexFinal].imageDataFinal, 0, 0);
        //###########################FINAL################################

        if (actualStackIndex == 0) $("#btn_undo").attr('disabled', 'disabled');
        else if (actualStackIndex > 0) $("#btn_undo").attr('disabled', null);
        if ($("#btn_redo").attr('disabled') == 'disabled') $("#btn_redo").attr('disabled', null);
    }
    // print_current_image_size();
}

function redo() {
    if ($("#btn_redo").attr('disabled') == 'disabled') return false;
    else {
        if (actualStackIndex < maxStackSize - 1) actualStackIndex++;
        if (actualStackIndexFinal < maxStackSizeFinal - 1) actualStackIndexFinal++;

        undoRedoStack[actualStackIndex].ctx.clearRect(0, 0, canvas_initial_width, canvas_initial_height);
        undoRedoStack[actualStackIndex].ctx.putImageData(undoRedoStack[actualStackIndex].imageData, 0, 0);

        //###########################FINAL################################
        undoRedoStackFinal[actualStackIndexFinal].ctx_final.clearRect(0, 0, canvas_final_width, canvas_final_height);
        undoRedoStackFinal[actualStackIndexFinal].ctx_final.putImageData(undoRedoStackFinal[actualStackIndexFinal].imageDataFinal, 0, 0);
        //###########################FINAL################################

        if (actualStackIndex == undoRedoStack.length - 1) $("#btn_redo").attr('disabled', 'disabled');
        else if (actualStackIndex < undoRedoStack.length - 1) $("#btn_redo").attr('disabled', null);
        if ($("#btn_undo").attr('disabled') == 'disabled') $("#btn_undo").attr('disabled', null);
    }
    // print_current_image_size();
}

function color(color_json) {
    // $color = $("#"+name);
    // $(".color-selected").removeClass("color-selected");
    // $color.addClass("color-selected");
    if (dadaCanvas.actualBrush.options.isBrush) {
        if (dadaCanvas.actualBrush.options.name == 'Pencil') {
            dadaCanvas.actualBrush.setBrushColor(color_json.hex);
        } else {
            dadaCanvas.actualBrush.setBrushColor(color_json.rgba);
        }
    }
    ;
    dadaCanvas.actualColor = color_json;
}

function bg_color(color_json, canvas) {
    if (canvas.attrs.id == "canvas") {
        var bg_id = "layer_0";
        var wi = canvas_initial_width
        var he = canvas_initial_height
    } else {
        var bg_id = "layer_2";
        var wi = canvas_final_width
        var he = canvas_final_height
    }
    var layer = canvas.get('#' + bg_id)[0];
    var bg = canvas.get('#' + bg_id + '_bg');
    var bgcolor = (color_json.rgba == undefined ? color_json : color_json.rgba);
    if (bg[0] == undefined) {
        layer.add(new Kinetic.Rect({
            id: bg_id + '_bg',
            width: wi,
            height: he,
            fill: bgcolor
        }));
    } else bg[0].setFill(bgcolor);
    canvas.draw();
}

dadaCanvas.bg_color = bg_color;

function addLayers(options, create_visor_item, canvas) {

    var new_canvas = layer = null;

    if (options instanceof Array) {
        for (i = 0; i < options.length; i++) {
            layer = new Kinetic.Layer(options[i]);
            layers.push(layer);
            canvas.add(layer);
            if (create_visor_item)
                new_canvas = createLayerVisorItem(layer, canvas);
        }
    } else {
        layer = new Kinetic.Layer(options);
        layers.push(layer);
        canvas.add(layer);
        if (create_visor_item)
            new_canvas = createLayerVisorItem(layer, canvas);
    }

    $('canvas').css('cursor', 'crosshair');
    //$('canvas').css('margin-left' , '10px');
    //new_canvas.addClass('selected-layer');
    if (create_visor_item)
        toggleSelectedVisorItem(new_canvas, canvas);
}

function createLayerVisorItem(layer, canvas) {
    var new_canvas = $(
        "<div id='" + layer.getId() + "' class='row visor-item' style='margin-left:0;'>" +
        "<div class='span cells visibility-layer-cell'>" +
        "<i class='icon-eye-open'></i>" +
        "</div>" +
        "<div class='span2 cells layer-name-cell'>" +
        "<div class='span' style='margin-left: -5px; padding-left: 5px;'>" +
        "<i class='icon-folder-open'></i>" +
        "</div>" +
        "<div class='span layer-name' style='width:125px;margin-left:0;'>&nbsp;" + layer.getName() + "</div>" +
        "</div>" +
        "</div>"
    );
    new_canvas.prependTo('.visor-content');
    $('#' + layer.getId()).children('.visibility-layer-cell').click(function () {
        toggleLayerVisibility($(this), canvas);
    });
    if (new_canvas.attr('id') != 'layer_0')
        $('#' + layer.getId()).children('.layer-name-cell').click(function () {
            toggleSelectedVisorItem($(this).parent(), canvas);
        });
    else new_canvas.children('.layer-name-cell').css('cursor', 'default');
    return new_canvas;
}

function toggleLayerVisibility(visor_item, canvas) {
    if (visor_item.children('i').hasClass('icon-eye-open')) {
        visor_item.children('i').removeClass('icon-eye-open');
        visor_item.children('i').addClass('icon-eye-close');
        canvas.get('#' + visor_item.parent().attr('id'))[0].hide();
        // console.log($canvas.get('#'+visor_item.parent().attr('id'))[0].isVisible());
    } else {
        visor_item.children('i').removeClass('icon-eye-close');
        visor_item.children('i').addClass('icon-eye-open');
        canvas.get('#' + visor_item.parent().attr('id'))[0].show();
        // console.log($canvas.get('#'+visor_item.parent().attr('id'))[0].isVisible());
    }
}

function toggleSelectedVisorItem(visor_item, canvas) {
    $('.selected-layer').removeClass('selected-layer');
    visor_item.addClass('selected-layer');
    // ASIGNA CTX OJO
    if (canvas.attrs.id == "canvas") {
        dadaCanvas.ctx = canvas.get('#' + visor_item.attr('id'))[0].getContext()._context;
        dadaCanvas.ctxs.ctx = dadaCanvas.ctx
    } else {
        dadaCanvas.ctx_final = canvas.get('#' + visor_item.attr('id'))[0].getContext()._context;
        scale_final = canvas_final_width / canvas_initial_width
        dadaCanvas.ctx_final.scale(scale_final, scale_final);
        dadaCanvas.ctxs.ctx_final = dadaCanvas.ctx_final
    }
    // dadaCanvas.ctx_final=$canvas.get('#'+visor_item.attr('id'))[0].getContext();
    if (dadaCanvas.actualBrush != undefined) {
        dadaCanvas.actualBrush.init();
        color(dadaCanvas.actualColor);
        if ($("#amount")) {
//          $("#amount").text($("#brush_size_slider").slider("value"));
            setLineWidth(parseInt($("#amount").text()));
        }
    }
}

function initLayerVisorControls() {
    // Template Build
    source = $("#layers_visor_template").html()
    template = Handlebars.compile(source);
    $(template(null)).appendTo('#sketchpad_container');


    $('#btn_add_layer').click(function () {
        addLayers({
            clearBeforeDraw: false,
            id: 'layer_' + created_layers,
            name: 'new_layer_' + created_layers++
        }, true);
    });
    $('#btn_trash').click(function () {
        $(".selected-layer").each(function () {
            $canvas.get('#' + this.id)[0].destroy();
            $(this).remove();
        });
    });
    $('#btn_close_visor').click(function () {
        $("#toggle_layers_control").click();
    });
    $('#layers_visor').css('left', canvas_initial_width - $('#layers_visor').width());
    $('#layers_visor').draggable({
        cursor: 'move',
        handdle: '.visor-title',
        containment: 'parent'
    });

}

// returns the data
function test() {
    // console.debug("Test")
}

function getComposedLayersDataURL() {
    var composed_ctx = $composed_canvas[0].getContext("2d");
    var composed_ctx_final = $composed_canvas_final[0].getContext("2d");
    $.each($canvas_final.getChildren(), function (index, canvas_layer) {
        if (canvas_layer.isVisible()) {
            if (scaleFactor() > 1) {
                composed_ctx_final.drawImage(canvas_layer.getCanvas()._canvas, 0, 0, canvas_final_width, canvas_final_height);
                // composed_ctx_final.drawImage(canvas_layer.getCanvas().element,0,0,w,h);
            } else {
                composed_ctx_final.drawImage(canvas_layer.getCanvas()._canvas, 0, 0);
            }
        }
    });
    //$composed_canvas[0].toDataURL().replace(/^data:image\/(png|jpg);base64,/, "");
    return $composed_canvas_final[0].toDataURL().replace(/^data:image\/(png|jpg);base64,/, "");
}

function scaleFactor() {
    if ('devicePixelRatio' in window) {
        if (window.devicePixelRatio > 1) {
            return window.devicePixelRatio;
        }
    }
    return 1;
}

// *********************************************************************
// getWacomPlugin
//   Returns correct plugin instance depending on whether running on
//   Internet Explorer or Firefox (and similar: Chrome, Safari, Opera).
//
function getWacomPlugin() {
    return document.getElementById('wtPlugin');
    // return $('#wtPlugin');
}

// *********************************************************************
// isPluginLoaded
//   Returns loaded status as plugin version string (eg: "2.0.0.2"),
//   or an empty string if plugin can't be loaded or found.
//
function isPluginLoaded() {
    // var retVersion = "";
    var pluginVersion = getWacomPlugin().version;
    //alert("pluginVersion: [" + pluginVersion + "]");

    if (pluginVersion != undefined) {
        // retVersion = pluginVersion;
        return true
    } else
        return false;
    // return retVersion;
}


function injectBackground(paper_object) {
    if (paper_object.paper_standard == null || paper_object.paper_high == null) {
        fill_background('white', $canvas);
        fill_background('white', $canvas_final);
    }
    else {
        bg_ctx = $canvas.find('.background')[0].getContext()._context;
        bg_canvas = $canvas.find('.background')[0].canvas._canvas
        bg_final_ctx = $canvas_final.find('.background')[0].getContext()._context;

        var bg_img = new Image();
        bg_img.crossOrigin = "Anonymous";
        bg_img.onload = function () {
            bg_ctx.drawImage(bg_img, 0, 0);
        };
        bg_img.src = paper_object.paper_standard;
        // make sure the load event fires for cached images too
        if (bg_img.complete || bg_img.complete === undefined) {
            bg_img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            bg_img.src = paper_object.paper_standard;
        }

        var bg_final_img = new Image();
        bg_final_img.crossOrigin = "Anonymous";
        bg_final_img.onload = function () {
            bg_final_ctx.drawImage(bg_final_img, 0, 0);
        };
        bg_final_img.src = paper_object.paper_high;
        // make sure the load event fires for cached images too
        if (bg_final_img.complete || bg_final_img.complete === undefined) {
            bg_final_img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            bg_final_img.src = paper_object.paper_high;
        }
    }
}

dadaCanvas.injectBackground = injectBackground;

// Auto Save every 5 minutes
function auto_save_by_time() {
    // if (auto_save == true) {
    //     setLocalStorage(true);
    // }
}

// Function to set auto_save in false
function stop_auto_save() {
    // auto_save = false
}

// Function to set auto_save in true
function restart_auto_save() {
    // auto_save = true;
}

// dadaCanvas.restart_auto_save = restart_auto_save;

////////////////////////////////

var blob_file = null;
var tmpimg_height = 1536, tmpimg_width = 2048;

// begin: calc and print current size in MB or KB
function print_current_image_size() {
    var composed_ctx_final = $composed_canvas_final[0].getContext("2d");
    $.each($canvas_final.getChildren(), function (index, canvas_layer) {
        if (canvas_layer.isVisible()) {
            if (scaleFactor() > 1) {
                composed_ctx_final.drawImage(canvas_layer.getCanvas()._canvas, 0, 0, canvas_final_width, canvas_final_height);
                // composed_ctx_final.drawImage(canvas_layer.getCanvas().element,0,0,w,h);
            } else {
                composed_ctx_final.drawImage(canvas_layer.getCanvas()._canvas, 0, 0);
            }
        }
    });
    $composed_canvas_final[0].toBlob(calc_image_size);
}

function calc_image_size(blob) {
    var blob_size = blob.size, formatted_size = null;

    if (blob_size < 1048576) {
        formatted_size = (blob_size / 1024).toFixed(2) + 'KB';
    } else {
        formatted_size = (blob_size / 1048576).toFixed(2) + 'MB';
    }

    if ($('#drawing_size_counter')) {
        $('#drawing_size_counter').html(formatted_size);
    }
}

// end: calc and print current size in MB or KB
