export const GET_EXPLORE_PROFILES_INITIATED = 'GET_EXPLORE_PROFILES_INITIATED';
export const GET_EXPLORE_PROFILES = 'GET_EXPLORE_PROFILES';
export const GET_EXPLORE_PROFILES_SUCCESS = 'GET_EXPLORE_PROFILES_SUCCESS';
export const GET_EXPLORE_PROFILES_FAILURE = 'GET_EXPLORE_PROFILES_FAILURE';
export const GET_SEARCH_RESULTS_PROFILES_SUCCESS = 'GET_SEARCH_RESULTS_PROFILES_SUCCESS';
export const CLEAR_EXPLORE_PROFILES_STATE = 'CLEAR_EXPLORE_PROFILES_STATE';

export const getExploreProfileInitiated = () => ({
  type: GET_EXPLORE_PROFILES_INITIATED,
});
export const getExploreProfile = params => ({
  type: GET_EXPLORE_PROFILES,
  params,
});
export const getExploreProfileSuccess = (list, nextPage, hasNextPage) => ({
  type: GET_EXPLORE_PROFILES_SUCCESS,
  list,
  nextPage,
  hasNextPage,
});
export const getExploreProfileFailure = error => ({
  type: GET_EXPLORE_PROFILES_FAILURE,
  error,
});
export const getSearchResultsProfileSuccess = (
  list,
  nextPage,
  hasNextPage,
) => ({
  type: GET_SEARCH_RESULTS_PROFILES_SUCCESS,
  list,
  nextPage,
  hasNextPage,
});
export const clearExploreProfileState = () => ({
  type: CLEAR_EXPLORE_PROFILES_STATE,
});
