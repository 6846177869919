import { isEmpty } from 'lodash';

// TODO: Fix attributes misspelling when API fixes it.
export const searchResultGetParser = data => data.map(({
  id, atributes: {
    name, photo, level, location,
  },
}) => ({
  id,
  name,
  level,
  photo,
  location,
}));

export function prepareInviteFriendsData(userList) {
  const users = [];
  const emails = [];

  userList
    && userList.forEach(data => (data.isNewUser ? emails.push(data.name) : users.push(data.id)));
  return {
    users,
    emails,
  };
}

export const userResultGetParser = (res) => {
  const { links, data = [] } = res;
  return {
    links,
    data: data.map(({
      id, atributes: {
        name, photo, level, location,
      },
    }) => ({
      id,
      name,
      level,
      photo,
      country: location,
    })),
  };
};

export const tagsResultGetParser = (res, owners) => {
  const { links, tagList = [] } = res;
  const profiles = [];
  if (owners) {
    Object.entries(owners).forEach(([key, value]) => profiles.push(createProfile(value)));
  }
  return {
    links,
    data: tagList.map(
      ({
        id,
        type,
        attributes: { src, title },
        relationships: {
          owner: {
            data: { id: ownerId },
          },
        },
      }) => ({
        id,
        src,
        title,
        ownerId,
        type,
      }),
    ),
    profiles,
  };
};

export const createProfile = (profile) => {
  const {
    id,
    type,
    attributes: {
      name, level, followed_by_user, photo, country,
    },
  } = profile;

  const parsedProfile = {
    id,
    type,
    name,
    followed_by_user,
    level,
    photo,
    country,
  };
  return parsedProfile;
};

export const edittorialResultGetParser = (res) => {
  const { data } = res;
  const blogObj = {};
  const keys = [
    'News',
    'DADA in Action',
    'DADAaffairs',
    'Faqs and F*cks',
    'Our Worldview',
    'DADArlings',
  ];

  keys.forEach((key) => {
    const filterData = data.filter((d) => {
      if (d.sections && !isEmpty(d.sections)) {
        return d.sections.includes(key);
      }
      return false;
    });
    blogObj[key] = sortByDate(filterData);
  });
  return blogObj;
};

export const sortByDate = array => array.sort((a, b) => new Date(b.published_at) - new Date(a.published_at));
