const styles = theme => ({
  dialogPaper: {
    zIndex: 10000,
    maxWidth: 520,
    width: '100%',
    textAlign: 'center',
    borderRadius: 2,
    boxShadow: '0 2px 4px 0px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      maxHeight: '100%',
      height: '100vh',
    },
  },
  media: {
    width: 'auto',
    height: 'auto',
  },
  dialogLogo: {
    height: 120,
  },
  modalTitle: {
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 'normal',
    margin: 0,
  },
  dialog: {
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ffffff',
    border: 'solid 1px #000000',
    maxWidth: 520,
    width: '100%',
  },
  dialogHeader: {
    padding: 0,
  },
  backdropTada: {
    background: 'rgba(200, 200, 200, 0.5)',
  },
});

export default styles;
