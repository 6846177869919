/* eslint-disable max-len */
// React imports
import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { S3 } from 'aws-sdk';
import { randomBytes } from 'crypto';
import { connect } from 'react-redux';
import { Form, withFormik, Formik } from 'formik';
import {
  FormControlLabel, Checkbox, Typography, Button,
} from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Avatar from '@material-ui/core/Avatar';
import Header from '../Editorial/Header';
import {
  getBlogsResults,
  createOrUpdateBlog,
} from '../../containers/BlogsLandingPage/action';
// Porject imports
import MultipleSelectChip from '../MultiSelect';
// Assets Imports
import './index.scss';
import {
  CONTENT_CATEGORIES_LIST, ACCEPTABLE_PHOTO_FORMATS,
  MAX_PHOTO_UPLOAD_SIZE,
} from '../../constants';
import LevelDropdown from '../../containers/DrawingTool/LevelMenu';
import { shouldUploadPhoto } from '../../utils/validations';
import config from '../../config';

const BlogPostForm = (props) => {
  const {
    selectedBlog, content, createOrUpdateBlog, updatedBlog, setFieldValue, is_blogger,
  } = props;

  const [published, setPublished] = useState(false);
  const blogsSections = ['DADArlings', 'Faqs and F*cks', 'DADA in Action', 'DADAaffairs', 'Our Worldview', 'News'];
  const quillRef = useRef(null);
  const avatarRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState(content);
  const [title, setTitle] = useState(selectedBlog ? selectedBlog.title : '');
  const [level, setLevel] = useState(selectedBlog ? selectedBlog.accesability_level : 0);
  const [section, setSection] = useState(selectedBlog && selectedBlog.sections && selectedBlog.sections.length > 0 ? selectedBlog.sections[0] : '');
  const [categories, setCategories] = useState(selectedBlog ? selectedBlog.categories : []);
  const [thumbnail, setThumbnail] = useState(selectedBlog ? selectedBlog.thumbnail : '');
  const [thumbnailError, setThumbnailError] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
  ];

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link', 'image', 'video'],
        ['clean'],
        // [{ color: [] }],
      ],
    },
  };

  const categoriesOptions = CONTENT_CATEGORIES_LIST;

  const initialValues = {
  };
  useEffect(() => {
    if (!is_blogger) {
      window.location.assign('/');
    }
  }, []);

  useEffect(() => {
    if (selectedBlog) {
      setTitle(selectedBlog.title);
      setHtmlContent(selectedBlog.content);
      setPublished(selectedBlog.is_published);
      setLevel(selectedBlog.accesability_level);
      setCategories(selectedBlog.categories);
      setThumbnail(selectedBlog.thumbnail);
      setSection(selectedBlog.sections && selectedBlog.sections.length > 0 ? selectedBlog.sections[0] : '');
    }
  }, [selectedBlog]);

  useEffect(() => {
    if (!selectedBlog && updatedBlog && !isNaN(updatedBlog.id)) {
      window.location.assign(`category/${updatedBlog.id}`);
    }
  }, [updatedBlog]);

  const validate = () => {
    let error = '';
    if (!title) {
      error = 'title is required';
    } else if (title.length > 300) {
      error = 'title is too big';
    } else if (!section && published) {
      error = 'section is mandatory';
    } else if (!thumbnail) {
      error = 'thumbnail is mandatory';
    }
    if (error) {
      setErrorMsg(error);
      return false;
    }
    return true;
  };

  const handleThumbnailUpload = async (event) => {
    // const { setFieldValue } = this.props;
    const file = event.currentTarget.files[0];
    const type = file.type;
    if (shouldUploadPhoto(type, file.size, MAX_PHOTO_UPLOAD_SIZE)) {
      const fileName = await generateRandomKey();
      const params = {
        Bucket: AWS_BUCKET_NAME,
        Key: fileName, // type is not required
        Body: file,
        ContentType: type, // required. Notice the back ticks
      };
      const getParams = {
        Bucket: AWS_BUCKET_NAME,
        Key: fileName, // type is not required
      };

      await s3.putObject(params).promise();
      const url = await s3.getSignedUrl('getObject', getParams);
      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = () => setThumbnail(reader.result);
      // setFieldValue('pic', file);
      setThumbnail(url);
    } else {
      setThumbnailError('Incorrect file type or size');
      avatarRef.current.value = null;
    }
  };

  const submitForm = async () => {
    if (validate()) {
      const payload = {
        data: {
          attributes:
          {
            id: selectedBlog ? selectedBlog.id : null,
            title,
            accesability_level: Number(level),
            is_published: published,
            section,
            categories,
            content: htmlContent,
            thumbnail,
          },
        },
      };
      await createOrUpdateBlog(payload);
      setErrorMsg('');
      if (selectedBlog) { window.location.reload(); }
    }
  };


  const artButton = selectedBlog && selectedBlog.is_published ? 'Update' : 'Post';


  const {
    AWS_ACCESS_KEY_ID, AWS_SECRET_KEY, AWS_BUCKET_NAME, AWS_REGION,
  } = config;

  const s3 = new S3({
    region: AWS_REGION,
    credentials: {
      accessKeyId: AWS_ACCESS_KEY_ID,
      secretAccessKey: AWS_SECRET_KEY,
    },
  });


  const uploadAndGetS3Image = async (file) => {
    const type = file.split(';')[0].split(':')[1];
    const fileName = await generateRandomKey();
    const buf = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ''), 'base64');
    const params = {
      Bucket: AWS_BUCKET_NAME,
      Key: fileName, // type is not required
      Body: buf,
      ContentEncoding: 'base64', // required
      ContentType: type, // required. Notice the back ticks
    };
    const getParams = {
      Bucket: AWS_BUCKET_NAME,
      Key: fileName, // type is not required
    };

    await s3.putObject(params).promise();
    const url = await s3.getSignedUrl('getObject', getParams);
    return url;
  };

  const handleContentChange = async (content, delta, source, editor) => {
    if (!quillRef.current) {
      return;
    }
    if (delta.ops && delta.ops.length > 0 && !!delta.ops[delta.ops.length - 1] && !!delta.ops[delta.ops.length - 1].insert && !!delta.ops[delta.ops.length - 1].insert.image) {
      const file = delta.ops[delta.ops.length - 1].insert.image;
      setHtmlContent(htmlContent);
      const quillObj = quillRef.current.getEditor();
      if (htmlContent) {
        quillObj.pasteHTML(htmlContent);
      }
      const url = await uploadAndGetS3Image(file);
      const range = quillObj.getSelection();
      if (delta.ops.length > 1) {
        quillObj.editor.insertEmbed(delta.ops[0].retain, 'image', url);
      } else {
        quillObj.editor.insertEmbed(range.index, 'image', url);
      }
      setHtmlContent(editor.getHTML());
    } else {
      setHtmlContent(content);
    }
  };

  const blogDate = selectedBlog && new Date(selectedBlog.published_at_medium);
  const options = {
    replace: ({ attribs, children }) => {
      let result = null;
      if (attribs && attribs.src && attribs.src.includes('https://medium.com/_/stat?event=post')) {
        result = <div />;
      }
      return result;
    },
  };

  const generateRandomKey = async () => ((await randomBytes(16)).toString('hex'));
  const handleRedirect = () => {
    const { history } = props;
    // history.push('/');
    window.open('https://editorial.dada.nyc/editorial', '_self');
  };

  return (
    <div>
      <div className="main blogWrap">
        <div className="blog">
          <div className="indvdlPage">
            <Header handleRedirect={handleRedirect} />
            <div className="main blogWrap">

              <Formik>
                {(formik) => {
                  const {
                    values,
                    // handleChange,
                    // handleSubmit,
                    errors,
                    touched,
                  } = formik;
                  return (
                    <div className="blog-infor">
                      <div className="mainContainer">
                        <form>
                          <div className="blogBoxWrapper">
                            <div className="text-center">
                              <Button
                                // type="submit"
                                className="BlogbtnPost"
                                onClick={submitForm}
                              > <span className="blogProm blogBtn">{artButton}</span>
                              </Button>
                              {
                                selectedBlog
                                  ? (
                                    <Button
                                      // type="submit"
                                      className="BlogbtnPost"
                                      onClick={() => window.location.assign(`/editorial/category/${selectedBlog.id}`)}
                                    > <span className="blogProm blogBtn">Preview</span>
                                    </Button>
                                  ) : null
                              }
                            </div>
                            <div>
                              <p style={{ color: 'red' }}>{errorMsg || ''}</p>
                            </div>
                            <label className="uploadLabel" htmlFor="file">
                              <Avatar alt="" src={thumbnail} className="profileAvatar" />
                              <input
                                id="file"
                                type="file"
                                className="inputFile"
                                ref={avatarRef}
                                accept={ACCEPTABLE_PHOTO_FORMATS}
                                onChange={(event) => {
                                  handleThumbnailUpload(event);
                                }}
                              />
                            </label>
                            <input
                              placeholder="enter blog name..."
                              name="title"
                              value={title}
                              maxLength="50"
                              onChange={e => setTitle(e.target.value)}
                              className="blogTitleName"
                            />
                            <MultipleSelectChip
                              name="Categories"
                              options={categoriesOptions}
                              value={categories}
                              setValues={setCategories}
                            />
                            {/* <Select options={categoriesOptions} /> */}
                            {errors.title && touched.title && (
                              <span className="error">{errors.title}</span>
                            )}
                            <div className="line-sep" />
                            <div className="levelsInteractions">
                              <LevelDropdown
                                handleChange={e => setLevel(e.target.value)}
                                userLevel={level}
                                setFieldValue={setFieldValue}
                                isBlog
                              />
                              <div className="customChips">
                                <InputLabel id="demo-simple-select-label">Section</InputLabel>
                                <Select
                                  labelId="section-select-label"
                                  id="section-select"
                                  value={section}
                                  label="Section"
                                  onChange={e => setSection(e.target.value)}
                                >
                                  {
                                    blogsSections.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                                  }
                                </Select>
                                <div>
                                  <FormControlLabel
                                    className="checkBox"
                                    label={(
                                      <Typography variant="h6">
                                        publish an article!
                                      </Typography>
                                    )}
                                    control={<Checkbox checked={published} onChange={e => setPublished(e.target.checked)} />}
                                  />
                                </div>
                              </div>
                              <div>
                                <ReactQuill
                                  ref={quillRef}
                                  theme="snow"
                                  value={htmlContent}
                                  onChange={(content, delta, source, editor) => {
                                    if (source === 'user') {
                                      handleContentChange(content, delta, source, editor);
                                    }
                                  }
                                  }
                                  modules={modules}
                                  formats={formats}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { blogsPage, authenticatedUserDetails, authenticationStatus } = state;
  return {
    updatedBlog: blogsPage.get('updatedBlog') ? blogsPage.get('updatedBlog').toJS() : null,
    statesLocation: state.router.location,
    is_blogger: authenticatedUserDetails.get('is_blogger'),
  };
};
const mapDispatchToProps = dispatch => ({
  getBlogsResults: isAuthenticated => dispatch(getBlogsResults(isAuthenticated)),
  createOrUpdateBlog: payload => dispatch(createOrUpdateBlog(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlogPostForm);
