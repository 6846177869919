export const GET_VC_FEED = 'GET_VC_FEED';
export const GET_VC_FEED_INITIATED = 'GET_VC_FEED_INITIATED';
export const GET_VC_FEED_SUCCESS = 'GET_VC_FEED_SUCCESS';
export const GET_VC_FEED_FAILURE = 'GET_VC_FEED_FAILURE';
export const RELOAD_VC_FEED = 'RELOAD_VC_FEED';
export const REFRESH_VC_FEED_STATE = 'REFRESH_VC_FEED_STATE';
export const INTERACTION_REQUESTED = 'INTERACTION_REQUESTED';


export const getVcFeed = (headers, params) => ({ type: GET_VC_FEED, headers, params });
export const reloadVcFeed = params => ({ type: RELOAD_VC_FEED, params });
export const getVcFeedInitiated = () => ({ type: GET_VC_FEED_INITIATED });
export const getVcFeedSuccess = (list, nextPage) => ({ type: GET_VC_FEED_SUCCESS, list, nextPage });
export const refreshVcFeed = () => ({ type: REFRESH_VC_FEED_STATE });
export const getVcFeedFailure = error => ({ type: GET_VC_FEED_FAILURE, error });
export const interactionRequested = vcId => ({ type: INTERACTION_REQUESTED, vcId });
