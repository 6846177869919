// TODO: Start refactoring all API calls to be called from here.

import {
  GET_DRAFT_INDEX_ENDPOINT,
  GET_SEARCH_RESULTS,
  PUT_VISUAL_STATUS_ENDPOINT,
  GET_USER_BY_ID,
  GET_USER_ACTIVITIES_BY_ID,
  PUT_USER_PROFILE_ENDPOINT,
  POST_FOLLOW_USER_ENDPOINT,
  GET_COUNTRY_DATA_API_URL,
  GET_COUNTRY_DATA_API_URL_KEY,
  GET_EXPLORE_CHALLENGES,
  GET_CONVERSATIONS_BY_ID_ENDPOINT,
  GET_BLOGS_RESULTS_URL,
  PUT_CREATE_OR_UPDATE_BLOGS_ENDPOINT,
  GET_PUBLIC_BLOGS_RESULTS_URL,
  GET_ALL_CONVERSATIONS_ENDPOINT,
  GET_EXPLORE_CHALLENGE_CONVERSATION,
} from '../constants';
import {
  getRequest,
  putRequest,
  postRequest,
  getRequestWithEndpoint,
} from '../utils/apiRequestUtils';
import {
  userResultGetParser,
  tagsResultGetParser,
  searchResultGetParser,
  edittorialResultGetParser,
} from './dataParsers';
import { apiDeserializer, apiSerializer } from '../utils/jsonApiSerializer';
import {
  convertToFormDataObject,
  flattenJSONApiParams,
} from '../utils/dataTransform';

async function searchResults(params) {
  const searchResults = await getRequest(GET_SEARCH_RESULTS, {}, params);
  const {
    data: {
      search_results: {
        user_results: { data: userList },
      },
    },
  } = searchResults.data;

  return searchResultGetParser(userList);
}

export function searchUsers(query) {
  return searchResults({ query_type: 'users', query });
}

export function getSketchpadStorageParam(data) {
  return apiSerializer('sketchpad_storages', data, {
    attributes: ['parentId', 'rootId'],
  });
}

export async function getDraftIndex(headers, { parentId, rootId }) {
  let params = getSketchpadStorageParam({ parentId, rootId });
  params = flattenJSONApiParams(params);

  const response = await getRequest(GET_DRAFT_INDEX_ENDPOINT, headers, params);
  response.data = await apiDeserializer(response.data);

  return response;
}

export async function getUserInfo(userId, headers) {
  const userInfo = await getRequest(GET_USER_BY_ID(userId), headers);
  userInfo.data = await apiDeserializer(userInfo.data);
  return userInfo;
}

export function putRequestSchemaforEditVcDetails({ level: authLvl, title }) {
  return apiSerializer(
    'visual_statuses',
    { authLvl, title },
    { attributes: ['authLvl', 'title'] },
  );
}

export async function putRequestForEditVcDetails(headers, data) {
  const editVcResponse = await putRequest(
    PUT_VISUAL_STATUS_ENDPOINT(data.id),
    putRequestSchemaforEditVcDetails(data),
    headers,
  );
  editVcResponse.data = await apiDeserializer(editVcResponse.data);
  return editVcResponse;
}

export async function userActivities(userId, params) {
  const userActivities = await getRequest(
    GET_USER_ACTIVITIES_BY_ID(userId),
    null,
    params,
  );
  userActivities.data = await apiDeserializer(userActivities.data);
  return userActivities;
}

export function updateProfilePayload(payload) {
  return apiSerializer('users', payload, {
    attributes: ['email', 'name', 'photo', 'country'],
  });
}

export async function updateProfile(userId, payload, headers) {
  const formDataPayload = convertToFormDataObject(
    updateProfilePayload(payload),
  );
  const updateProfile = await putRequest(
    PUT_USER_PROFILE_ENDPOINT(userId),
    formDataPayload,
    headers,
  );
  updateProfile.data = await apiDeserializer(updateProfile.data);
  return updateProfile.data;
}

export function postRequestSchemaforFollowUser(followedId) {
  return apiSerializer(
    'follows',
    { followedId },
    { attributes: ['followedId'] },
  );
}

export async function followUserAPI(userId, headers) {
  const followUser = await postRequest(
    POST_FOLLOW_USER_ENDPOINT,
    postRequestSchemaforFollowUser(userId),
    headers,
  );
  followUser.data = await apiDeserializer(followUser.data);
  return followUser;
}

export const createCountryDataApiURL = () => `${GET_COUNTRY_DATA_API_URL}/?api_key=${GET_COUNTRY_DATA_API_URL_KEY}`;

export const getCountryData = async () => {
  const url = createCountryDataApiURL();
  try {
    const res = await getRequestWithEndpoint(url);
    return res && res.data ? res.data : {};
  } catch (e) {
    return {};
  }
};

//= ============== Explore ==================//

export async function getExploreChallenge(a) {
  try {
    const challenge = await getRequest(GET_EXPLORE_CHALLENGES, null);
    challenge.data = await apiDeserializer(challenge.data);
    return challenge && challenge.data ? challenge.data : {};
  } catch (e) {
    return 'No Challenge Found for today';
  }
}

export async function getExploreVCFeedByEndpoint(params, queryType, headers) {
  let endpoint;
  if (queryType === 'filter') {
    endpoint = GET_ALL_CONVERSATIONS_ENDPOINT;
  }
  if (queryType === 'challenge') {
    endpoint = GET_EXPLORE_CHALLENGE_CONVERSATION;
  }
  const result = await getRequest(endpoint, headers, params);
  return result;
}

async function searchResultsByParams(params, key) {
  const searchResults = await getRequest(GET_SEARCH_RESULTS, {}, params);
  const { data } = searchResults;
  const {
    data: {
      search_results: {
        user_results: userList,
        tag_results: {
          data: tagList,
          links,
          included: { profiles },
        },
        conversation_results: conversationResults,
      },
    },
  } = data;

  const userResults = userResultGetParser(userList);
  const tagResults = tagsResultGetParser({ tagList, links }, profiles);

  if (key === 'user_results') {
    return userResults;
  }
  if (key === 'tag_results') {
    return tagResults;
  }
  if (key === 'conversation_results') {
    return conversationResults;
  }

  return { userResults, tagResults, conversationResults };
}

export const searchAllByParams = params => searchResultsByParams({ query_type: 'all', ...params });

export const searchTagsByParams = params => searchResultsByParams({ query_type: 'tags', ...params }, 'tag_results');

export const searchConversationsByParams = params => searchResultsByParams(
  { query_type: 'conversations', ...params },
  'conversation_results',
);

export const searchUsersByParams = params => searchResultsByParams({ query_type: 'users', ...params }, 'user_results');

export async function getConversationsById(params) {
  const conversationList = await getRequest(
    GET_CONVERSATIONS_BY_ID_ENDPOINT(params),
  );
  conversationList.data = await apiDeserializer(conversationList.data);
  return conversationList;
}
//= ================== Blogs ============//

export async function getBlogsResultsAPI(action, headers) {
  try {
    let blogs = [];
    if (action.isAuthenticated) {
      blogs = await getRequest(GET_BLOGS_RESULTS_URL, headers);
    } else {
      blogs = await getRequest(GET_PUBLIC_BLOGS_RESULTS_URL);
    }
    return blogs && blogs.data ? blogs.data : {};
  } catch (e) {
    return 'No Blogs Available';
  }
}

export async function getEditorildata() {
  try {
    const blogs = await getRequest(GET_PUBLIC_BLOGS_RESULTS_URL);
    return blogs && blogs.data && blogs.data.data
      ? edittorialResultGetParser(blogs.data)
      : [];
  } catch (e) {
    throw e;
  }
}
// --- Edit BLOG ---
export async function createOrUpdateBlogApi(action, headers) {
  const updatedBlog = await putRequest(
    PUT_CREATE_OR_UPDATE_BLOGS_ENDPOINT,
    action.payload,
    headers,
  );
  updatedBlog.data = await apiDeserializer(updatedBlog.data);
  return updatedBlog.data;
}

// export async function getConversationsById(params) {
//   const conversationList = await getRequest(GET_CONVERSATIONS_BY_ID_ENDPOINT(params.id));
//   conversationList.data = await apiDeserializer(conversationList.data);
//   return conversationList;
// }
// export async function createOrUpdateBlogApi(action, headers) {
//   const updatedBlog = await putRequest(
//     PUT_CREATE_OR_UPDATE_BLOGS_ENDPOINT(action.blogId), action.payload, headers,
//   );
//   updatedBlog.data = await apiDeserializer(updatedBlog.data);
//   return updatedBlog.data;
// }

// export async function getConversationsById(params) {
//   const conversationList = await getRequest(GET_CONVERSATIONS_BY_ID_ENDPOINT(params.id));
//   conversationList.data = await apiDeserializer(conversationList.data);
//   return conversationList;
// }
