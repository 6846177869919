/* eslint-disable max-len */
// React imports
import React, { useEffect, useState, useRef } from 'react';
import BlogPostForm from '../BlogPostForm';

const NewBlogPost = props => (
  <BlogPostForm />
);

export default NewBlogPost;
