import { fromJS } from 'immutable';
// Project imports
import {
  OPEN_EXPLORE_VIEW,
  CLOSE_EXPLORE_VIEW,
  EXPLORE_CHALLENGE_SUCCESS,
  EXPLORE_CHALLENGE_FAILURE,
  CLEAR_EXPLORE_VIEW,
  GET_EXPLORE_REQUEST_INITIATED,
  GET_EXPLORE_REQUEST_SUCCESS,
  SEARCH_RESULTS_SUCCESS,
  SEARCH_RESULTS_FAILURE,
} from './actions';

const initialState = fromJS({
  isExploreViewOpen: false,
  challenge: {},
  error: null,
  searchResult: [],
  isNewRequest: false,
});

const exploreView = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_EXPLORE_VIEW:
      return state.merge({
        isExploreViewOpen: true,
      });
    case CLOSE_EXPLORE_VIEW:
      return state.merge({
        isExploreViewOpen: false,
      });
    case EXPLORE_CHALLENGE_SUCCESS:
      return state.merge({
        challenge: action.challenge,
      });
    case EXPLORE_CHALLENGE_FAILURE:
      return state.merge({
        error: action.error,
      });
    case GET_EXPLORE_REQUEST_INITIATED:
      return state.merge({
        isNewRequest: true,
      });
    case GET_EXPLORE_REQUEST_SUCCESS:
      return state.merge({
        isNewRequest: false,
      });
    case SEARCH_RESULTS_SUCCESS:
      return state.merge({
        searchResult: action.data,
      });
    case SEARCH_RESULTS_FAILURE:
      return state.merge({
        isNewRequest: false,
        error: action.error,
      });
    case CLEAR_EXPLORE_VIEW:
      return initialState;
    default:
      return state;
  }
};

export default exploreView;
