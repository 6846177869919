import generateDefaultSchema from '../../utils/apiSchemaGenerator';

const loginEndpointSchema = (email, password) => (
  generateDefaultSchema('user', {
    email,
    password,
  })
);

export default loginEndpointSchema;
