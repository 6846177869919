const calcGrid = (imgArray) => {
  const arraySize = imgArray.length;

  if (arraySize === 0) {
    return { imgArray: [], size: 0 };
  }
  let tempArray = [...imgArray];
  let gridCol = 0;


  if (arraySize === 1) return { imgArray: tempArray, size: 1 };
  if (arraySize === 2) return { imgArray: tempArray, size: 2 };
  if (arraySize === 3) return { imgArray: tempArray, size: 3 };

  if (arraySize === 4) return { imgArray: tempArray, size: 2 };
  if (arraySize === 22) { tempArray = tempArray.slice(0, 12); return { imgArray: tempArray, size: getGridCol(tempArray.length) }; }
  if (arraySize % 2 === 0) {
    gridCol = getGridCol(arraySize);
  } else {
    gridCol = getGridCol(arraySize - 1);
    tempArray.shift();
  }
  return { imgArray: tempArray, size: gridCol };
};

const getGridCol = (arraySize) => {
  let row = 0;
  let col = 0;
  for (let i = 1; i * i <= arraySize; i++) {
    if (arraySize % i === 0) {
      row = i; col = arraySize / i;
    }
  }

  return col;
};
export default calcGrid;
