const styles = theme => ({
  root: {
    padding: '4px 8px',
  },
  ml5: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 5,
    },
  },
  Order3: {
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
});
export default styles;
