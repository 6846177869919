import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import IndividualVCFeed from '../../components/IndividualVCFeed';
import { initiateIndividualVCFeedPage } from './actions';
import CoreShell from '../../components/CoreShell';
import { visualConversationStore } from '../../utils/vcUtils';
import {
  CLOSE_DRAWING_TOOL_INDIVIDUAL_VC_PAGE,
  POST_DRAWING_INDIVIDUAL_VC_PAGE,
} from '../DrawingTool/actions';
import DrawingTool from '../DrawingTool';
import { REQUEST_AUTH_NAVBAR_INDIVIDUAL_VC_PAGE } from '../AuthModal/actions';
import Navbar from '../Navbar';
import { LOGOUT_INDIVIDUAL_VC_PAGE } from '../Authenticator/actions';

function mapStateToProps(state, props) {
  const { match } = props;
  const id = match.params.vcId;
  const vc = state[visualConversationStore(id)] || null;

  return {
    id,
    isTreeDataLoading: vc ? vc.get('isGetTreeRequestInitiated') : false,
    isTreeDataLoaded: vc ? vc.get('isTreeLoaded') : false,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initiateComponent: id => dispatch(initiateIndividualVCFeedPage(id)),
  };
}

class IndividualVCFeedPage extends Component {
  constructor(props) {
    super(props);

    this.onSuccessfulMount = this.onSuccessfulMount.bind(this);
  }

  onSuccessfulMount() {
    const { id, initiateComponent } = this.props;

    initiateComponent(id);
  }

  render() {
    const {
      id, isTreeDataLoaded, isTreeDataLoading, history, location,
    } = this.props;

    return (
      <CoreShell
        history={history}
        location={location}
      >
        <Navbar
          authAction={REQUEST_AUTH_NAVBAR_INDIVIDUAL_VC_PAGE}
          logoutAction={LOGOUT_INDIVIDUAL_VC_PAGE}
        />
        <DrawingTool
          postDrawingAction={POST_DRAWING_INDIVIDUAL_VC_PAGE}
          postCloseAction={CLOSE_DRAWING_TOOL_INDIVIDUAL_VC_PAGE}
        />
        <IndividualVCFeed
          id={+id}
          onSuccessfulMount={this.onSuccessfulMount}
          isTreeDataLoaded={isTreeDataLoaded}
          isTreeDataLoading={isTreeDataLoading}
        />
      </CoreShell>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndividualVCFeedPage));
