import picture1 from '../../assets/images/twitterImages/image1.jpeg';
import picture2 from '../../assets/images/twitterImages/image2.jpeg';
import picture3 from '../../assets/images/twitterImages/image3.jpeg';
import picture4 from '../../assets/images/twitterImages/image4.jpeg';
import picture5 from '../../assets/images/twitterImages/image5.jpeg';
import picture6 from '../../assets/images/twitterImages/image6.jpeg';
import picture7 from '../../assets/images/twitterImages/image7.jpeg';
import picture8 from '../../assets/images/twitterImages/image8.jpeg';
import picture9 from '../../assets/images/twitterImages/image9.jpeg';

const twitterImages = [
  {
    image_1: {
      id: 1,
      src: picture1,
      alt: 'picture1',
    },
    image_2: {
      id: 2,
      src: picture2,
      alt: 'picture2',
    },
    image_3: {
      id: 3,
      src: picture3,
      alt: 'picture3',
    },
  },
  {
    image_1: {
      id: 4,
      src: picture4,
      alt: 'picture4',
    },
    image_2: {
      id: 5,
      src: picture5,
      alt: 'picture5',
    },
    image_3: {
      id: 6,
      src: picture6,
      alt: 'picture6',
    },
  },
  {
    image_1: {
      id: 4,
      src: picture7,
      alt: 'picture7',
    },
    image_2: {
      id: 5,
      src: picture8,
      alt: 'picture8',
    },
    image_3: {
      id: 6,
      src: picture9,
      alt: 'picture9',
    },
  },
];
export default twitterImages;
