// Rxjs imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat';
import {
  throttleTime, mergeMap, catchError, delay, map,
} from 'rxjs/operators';
// Project Imports
import {
  COMMENT_LIKE_TOGGLE_ENDPOINT, GET_COMMENTS_ENDPOINT, UNEXPECTED_ERROR, POST_COMMENTS_ENDPOINT,
} from '../../constants';
import { commentsResponseParser, postStateComment } from '../../utils/commentsUtils';
import {
  GET_VC_COMMENTS, getVcCommentsCompleted, getVcCommentsFailure,
  getVcCommentsInitiated, getVcCommentsSuccess, LIKE_COMMENT,
  likeCommentInitiated, likeCommentSuccess, likeCommentFailure, postCommentInitiated, POST_COMMENT, postCommentFailure,
  postCommentSuccess,
} from './actions';
import { generateAuthHeaderFromStore } from '../../utils/authUtils';

export const requestGetVCCommentsEpic = (action$, state$, { getRequest }) => action$.ofType(GET_VC_COMMENTS).pipe(
  throttleTime(350),
  mergeMap(action => concat(
    of(getVcCommentsInitiated(action.id)),
    from(getRequest(GET_COMMENTS_ENDPOINT(action.id), generateAuthHeaderFromStore(state$.value), action.params)).pipe(
      mergeMap((response) => {
        const comments = commentsResponseParser(response.data);
        const list = comments.list.reverse();
        return concat(
          of(getVcCommentsSuccess(action.id, list, comments.nextPage, comments.hasNextPage)),
          of(getVcCommentsCompleted(action.id)).pipe(
            delay(300),
          ),
        );
      }),
      catchError((error) => {
        console.error(error);
        return of(getVcCommentsFailure(action.id, UNEXPECTED_ERROR));
      }),
    ),
  )),
);

export const likeCommentsEpic = (action$, state$, { postRequest }) => action$.ofType(LIKE_COMMENT).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    of(likeCommentInitiated(action.vcId)),
    from(postRequest(
      COMMENT_LIKE_TOGGLE_ENDPOINT(action.commentId), {}, generateAuthHeaderFromStore(state$.value),
    )).pipe(
      map((response) => {
        const isLiked = response.data.data.attributes.action === 'liked';
        const { commentId, vcId } = action;
        let likes_count = action.likesCount;
        let liked_by_user = false;
        if (isLiked) {
          likes_count += 1;
          liked_by_user = true;
        } else {
          likes_count -= 1;
          liked_by_user = false;
        }

        return likeCommentSuccess(vcId, commentId, likes_count, liked_by_user);
      }),
      catchError((error) => {
        console.error(error);
        return of(likeCommentFailure(action.vcId));
      }),
    ),
  )),
);

export const postCommentsEpic = (action$, state$, { postRequest }) => action$.ofType(POST_COMMENT).pipe(
  throttleTime(300),
  mergeMap(action => concat(
    of(postCommentInitiated(action.vcId)),
    from(postRequest(
      POST_COMMENTS_ENDPOINT(action.vcId), action.payload, generateAuthHeaderFromStore(state$.value),
    )).pipe(
      map((response) => {
        const comment = postStateComment(response.data, state$.value);
        return postCommentSuccess(action.vcId, comment);
      }),
      catchError((error) => {
        console.error(error);
        return of(postCommentFailure(action.vcId));
      }),
    ),
  )),
);
