// React and redux imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
// Form validation Imports
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
// Material UI Imports
import Button from '@material-ui/core/Button/Button';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import Grid from '@material-ui/core/Grid/Grid';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core';

// Project Imports
import forgetPasswordEndpointParamsSchema from './schemas';
import styles from './styles';
import { requestForgotPassword, resetForgotPasswordMsgs } from './actions';
// slides
import closeIcon from '../../assets/images/close.png';
import './index.scss';
import { closeAuthDialog } from '../AuthModal/actions';

class ForgetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.handleVisibility = this.handleVisibility.bind(this);
  }

  componentWillUnmount() {
    const { setTouched, resetAllMsgs } = this.props;
    setTouched(false);
    resetAllMsgs();
  }

  handleVisibility() {
    const { setTouched, resetAllMsgs } = this.props;
    setTouched(false);
    resetAllMsgs();
  }

  render() {
    const {
      closeAuthDialog, values, handleChange, touched, errors, classes, isRequesting, apiError,
    } = this.props;
    const { email } = values;

    return (
      <Fragment>
        <div className="forgetPassword">
          <Form>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.formLabel}>Forgot your password?</div>
              {
                (errors.email)
                  && (touched.email)
                  ? (
                    <div
                      className="responseMsg error"
                    >
                      {(errors.email)}
                      <Button className={classes.close} onClick={this.handleVisibility}>
                        <img src={closeIcon} alt="" />
                      </Button>
                    </div>
                  ) : apiError
                  && (
                    <div
                      className="responseMsg error"
                    >
                      {apiError}
                      <Button className={classes.close} onClick={this.handleVisibility}>
                        <img src={closeIcon} alt="" />
                      </Button>

                    </div>
                  )
              }
              <DialogContentText id="alert-dialog-slide-description">
                <input
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  className="responseMsg error"
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions
              className="aligntCenterBtn spacing0"
            >
              <Grid>
                <Button type="submit" className={`${classes.blackbtn} ${classes.mb40}`} disabled={isRequesting}>
                  Reset password
                </Button>
              </Grid>
            </DialogActions>
          </Form>
        </div>
        <Hidden smUp>
          <Button className="closeBtn" onClick={closeAuthDialog}>
            <Icon className={classes.icon}>close</Icon>
          </Button>
        </Hidden>
      </Fragment>
    );
  }
}

const ForgetPasswordWithFormik = () => withFormik(
  {
    mapPropsToValues: props => ({
      email: '',
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required!'),
    }),
    handleSubmit: (values, { props }) => {
      const { email } = values;
      const { requestForgotPassword } = props;
      const redirect_url = `${window.location.protocol}//${window.location.host}/forget-password`;
      const params = forgetPasswordEndpointParamsSchema(email, redirect_url);
      requestForgotPassword(params);
    },
  },
)(ForgetPasswordForm);

ForgetPasswordForm.propTypes = {
  values: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  classes: PropTypes.object.isRequired,
  apiError: PropTypes.string,
  isRequesting: PropTypes.bool.isRequired,
  setTouched: PropTypes.func.isRequired,
  resetAllMsgs: PropTypes.func.isRequired,
  closeAuthDialog: PropTypes.func.isRequired,
};

ForgetPasswordForm.defaultProps = {
  apiError: null,
};

const mapStateToProps = (state) => {
  const { forgetPasswordForm } = state;
  return {
    success: forgetPasswordForm.get('success'),
    isRequesting: forgetPasswordForm.get('isRequesting'),
    apiError: forgetPasswordForm.get('error'),
    successMsg: forgetPasswordForm.get('successMsg'),
  };
};

const mapDispatchToProps = dispatch => ({
  closeAuthDialog: () => {
    dispatch(closeAuthDialog());
  },
  requestForgotPassword: (params) => {
    dispatch(requestForgotPassword(params));
  },
  resetAllMsgs: () => {
    dispatch(resetForgotPasswordMsgs());
  },
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordWithFormik()));
