import React, { useEffect, useState, memo } from 'react';
import CloneDeep from 'lodash/cloneDeep';
import generateRandomNumber from '../../utils/generateRandomNumber';
import Styles from './styles';

const Hearts = (props) => {
  const { likeCounts, identifier } = props;
  const [count, setCount] = useState(0);

  useEffect(() => {
    const maxCount = likeCounts > 46 ? 46 : likeCounts;
    setCount(maxCount);
  }, [identifier, likeCounts]);

  const generateRandomImages = (imageCount = 46, d_top = 250, d_left = 300, d_right = 300, d_bottom = 250) => {
    const minLimit = 20;
    const i = generateRandomNumber(imageCount);
    const top = minLimit + generateRandomNumber(d_top);
    const left = minLimit + generateRandomNumber(d_left);
    const right = minLimit + generateRandomNumber(d_right);
    const bottom = minLimit + generateRandomNumber(d_bottom);
    const image = CloneDeep(Styles[i]);
    image.style = {
      ...image.style,
      top,
      left,
      right,
      bottom,
    };
    return image;
  };

  return (
    <div className="hearts">
      {Styles.slice(0, count).map((item, index) => {
        const key = index;
        const image = generateRandomImages();
        return (
          <img src={image.src} alt={image.alt} key={key} style={image.style} />
        );
      })}
    </div>
  );
};

export default memo(Hearts);
