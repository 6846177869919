import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';
import { withStyles } from '@material-ui/core/styles';
import { withWidth, Grid, Switch } from '@material-ui/core';
import last from 'lodash/last';
import ReactTippyWrapper from '../ReactTippyWrapper';
import LikeIcon from '../LikeIcon';
import VcModalConversation from '../VcModalConversation';
import TimeAgo from '../TimeAgo';
import LikeListContainer from '../../containers/LikeListContainer';
import VCProfile from '../../containers/VCProfile';
import { getActivityLikes } from '../../utils/vcUtils';
import Hearts from '../Hearts';

import add from '../../assets/images/replyIcon.svg';
import './index.scss';
import styles from './styles';

class VcCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLightboxOpen: false,
      lightboxIndex: 0,
    };
    this.onLightboxNext = this.onLightboxNext.bind(this);
    this.onLightboxPrevious = this.onLightboxPrevious.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.disable = this.disable.bind(this);
    this.slider = React.createRef();
    this.singleSliderContainer = React.createRef();
  }

  componentDidMount() {
    const {
      isAuthenticated, level, location, data, getConversationScrollProgress,
    } = this.props;
    const path = location.pathname;
    if (isAuthenticated && level >= 2) {
      this.slider.scrollToRight();
      getConversationScrollProgress(100);
    }
    if (path.includes('conversation')) {
      const paramsArray = path.split('/');
      const conversationID = Number(paramsArray[paramsArray.length - 1]);
      const index = data.find(obj => obj.id === conversationID);
      if (index) {
        if (index.id === conversationID) {
          this.setState({ isLightboxOpen: true, lightboxIndex: index });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedId, data } = this.props;
    const { selectedId: prevSelectedId } = prevProps;
    const { clientWidth } = this.singleSliderContainer.current;
    if (selectedId !== prevSelectedId) {
      const index = data.findIndex(elem => elem.id === selectedId);
      this.slider.scrollLeft = clientWidth * index;
    }
  }

  onLightboxNext() {
    const { lightboxIndex } = this.state;
    this.setState({ lightboxIndex: lightboxIndex + 1 });
  }

  onLightboxPrevious() {
    const { lightboxIndex } = this.state;
    this.setState({ lightboxIndex: lightboxIndex - 1 });
  }

  openLightbox = (event, index) => {
    if (event.target.className === 'likeIcon like'
      || event.target.className === 'likeIcon liked'
      || event.target.className === 'likeButton'
      || event.target.className === 'replyBtn') {
      event.preventDefault();
      return;
    }
    this.setState({ isLightboxOpen: true, lightboxIndex: index });
  }

  // eslint-disable-next-line react/sort-comp
  disable(user_can_reply, givenLevel) {
    const { isAuthenticated, level } = this.props;
    return !isAuthenticated || givenLevel > level || !user_can_reply;
  }

  closeLightbox(index) {
    const { history } = this.props;
    this.setState({ isLightboxOpen: false, lightboxIndex: 0 });
    history.push('/');
  }

  onScroll = (scrollEvent) => {
    const { scrollLeft, clientWidth, scrollWidth } = scrollEvent;
    const { getConversationScrollProgress } = this.props;
    getConversationScrollProgress(this.calculateProgress(scrollLeft, scrollWidth, clientWidth));
  }

  handleRedirectToConversation = (e, id) => {
    e.stopPropagation();
    const { history } = this.props;
    history.push(`/conversation/${id}`);
  }

  calculateProgress = (scrollLeft, scrollWidth, clientWidth) => Math.round((scrollLeft / (scrollWidth - clientWidth)) * 100);

  render() {
    const {
      isVisible, data, onLikeActivity, isLikeRequestInitiated, isCurationRequestInitiated, onCurationActivity,
      isCurator, isAuthenticated, openDrawingTool, vcId, level, classes, authFollowAction, history,
    } = this.props;
    const {
      lightboxIndex, isLightboxOpen,
    } = this.state;
    const slides = data;
    const lastSlide = last(slides);
    return (
      <div className="feedCarouselWrapper">
        {isLightboxOpen && (
          <div className="lightboxImage">
            <VcModalConversation
              isOpen={isLightboxOpen}
              currentIndex={lightboxIndex}
              onClose={this.closeLightbox}
              dataSet={slides}
              disable={this.disable}
              openDrawingTool={openDrawingTool}
              vcId={vcId}
            />
          </div>
        )
        }
        <Scrollbar
          style={{ width: '100%' }}
          className="scrollContainer carouselScrollHorizontalTrack"
          ref={(ref) => { this.slider = ref; }}
          onScroll={this.onScroll}
        >
          <Grid container direction="row" wrap="nowrap">
            <div className="content-wrap">
              {
                slides.map((elem, index) => (
                  <Grid item key={elem.id}>
                    <div className="slideContainer" ref={this.singleSliderContainer}>
                      {isVisible && (
                        <div
                          onClick={(e) => {
                            this.handleRedirectToConversation(e, elem.id);
                          }
                          }
                          role="button"
                          tabIndex={0}
                        >
                          <div
                            role="button"
                            onClick={e => this.openLightbox(e, index)}
                            tabIndex={0}
                          >
                            <div
                              style={{ backgroundImage: `url(${elem.src})` }}
                              role="button"
                              className="sliderImage"
                              tabIndex={0}
                            >
                              <Hearts likeCounts={elem.likes_count} identifier={elem.id} />
                            </div>
                            <div>
                              <Grid className="likeArtWrap" container direction="row" justify="space-between">
                                <Grid item>
                                  <VCProfile id={elem.ownerId} authFollowAction={authFollowAction} vcId={vcId} history={history} />
                                </Grid>
                                <Grid>
                                  <div className="interactions">
                                    <div className="dFlex">
                                      {isAuthenticated && isCurator && (
                                        <Switch
                                          checked={elem.curated_by_user}
                                          disabled={isCurationRequestInitiated}
                                          onChange={() => onCurationActivity(elem.id)}
                                          classes={{
                                            root: classes.root,
                                            switchBase: classes.iOSSwitchBase,
                                            bar: classes.iOSBar,
                                            icon: classes.iOSIcon,
                                            iconChecked: classes.iOSIconChecked,
                                            checked: classes.iOSChecked,
                                          }}
                                        />
                                      )}
                                      <button
                                        type="button"
                                        onClick={e => onLikeActivity(e, elem.id, elem.likes_count, elem.parentIdsTrail)}
                                        disabled={isLikeRequestInitiated}
                                        className="likeButton"
                                      >
                                        <LikeIcon liked={elem.liked_by_user} />
                                      </button>
                                      <ReactTippyWrapper
                                        title={!this.disable(elem.user_can_reply, slides[0].level) ? ' '
                                          : 'Your experience level does not allow you to reply!'}
                                        position="bottom"
                                        theme="transparent"
                                      >
                                        {(level < 4 || !level || index === slides.length - 1) ? null : (
                                          <button
                                            type="button"
                                            disabled={this.disable(elem.user_can_reply, slides[0].level)}
                                            onClick={() => {
                                              openDrawingTool(true, elem.id, vcId, elem.title);
                                            }
                                            }
                                          >
                                            <img className="replyBtn" src={add} alt="" />
                                          </button>
                                        )}
                                      </ReactTippyWrapper>
                                    </div>
                                    <p className="duration"><TimeAgo datetime={elem.created_at} /></p>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      )
                      }
                    </div>
                  </Grid>
                ))}
              <div className="singleSlideItem">
                <div className="slideContainer">
                  {isVisible
                    && (
                      <Grid container alignContent="center" alignItems="center" className="replySlideWrapper">
                        <ReactTippyWrapper
                          title={!this.disable(lastSlide.user_can_reply, lastSlide.level) ? ' '
                            : 'Your experience level does not allow you to reply!'}
                          position="bottom"
                        >
                          <Grid item>
                            <button
                              type="button"
                              style={{ backgroundImage: `url(${add})`, border: 'none' }}
                              className="sliderImage replySlide"
                              disabled={this.disable(lastSlide.user_can_reply, slides[0].level)}
                              onClick={() => {
                                openDrawingTool(true, lastSlide.id, vcId,
                                  lastSlide.title);
                              }}
                            />
                          </Grid>
                        </ReactTippyWrapper>
                      </Grid>
                    )
                  }
                </div>
              </div>
            </div>
          </Grid>
        </Scrollbar>
      </div>
    );
  }
}

VcCarousel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  isVisible: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  onLikeActivity: PropTypes.func.isRequired,
  isLikeRequestInitiated: PropTypes.bool.isRequired,
  selectedId: PropTypes.number,
  level: PropTypes.number,
  authFollowAction: PropTypes.string.isRequired,
};

VcCarousel.defaultProps = {
  selectedId: null,
  isVisible: false,
  level: null,
};

// withWidth is used so that props trigger when width changes.
export default withStyles(styles)(withWidth()(VcCarousel));
