import { fromJS } from 'immutable';
import {
  GET_VC_FEED_FAILURE, GET_VC_FEED_INITIATED, GET_VC_FEED_SUCCESS, REFRESH_VC_FEED_STATE,
} from './actions';
import { getUniqueList } from '../../utils/dataTransform';

const intialState = fromJS({
  list: [],
  isRequesting: false,
  hasNext: true,
  error: null,
  nextPage: '1',
});

const vcFeed = (state = intialState, action) => {
  switch (action.type) {
    case GET_VC_FEED_INITIATED:
      return state.merge({ isRequesting: true });
    case GET_VC_FEED_SUCCESS: {
      const uniqueVcIdsList = getUniqueList(state.get('list').toJS(), action.list);
      return state.merge({
        isRequesting: false, error: null, nextPage: action.nextPage, list: uniqueVcIdsList,
      });
    }
    case GET_VC_FEED_FAILURE:
      return state.merge({ isRequesting: false, error: action.error });
    case REFRESH_VC_FEED_STATE:
      return intialState;
    default:
      return state;
  }
};

export default vcFeed;
