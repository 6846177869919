// eslint-disable-next-line import/prefer-default-export
export const GET_BLOGS_RESULTS = 'GET_BLOGS_RESULTS';
export const GET_BLOGS_RESULTS_SUCCESS = 'GET_BLOGS_RESULTS_SUCCESS';
export const GET_BLOGS_RESULTS_FAILURE = 'GET_BLOGS_RESULTS_FAILURE';
export const CREATE_OR_UPDATE_BLOG = 'CREATE_OR_UPDATE_BLOG';
export const CREATE_OR_UPDATE_BLOG_SUCCESS = 'CREATE_OR_UPDATE_BLOG_SUCCESS';
export const CREATE_OR_UPDATE_BLOG_FAILURE = 'CREATE_OR_UPDATE_BLOG_FAILURE';

// Search Blogs Actions
export const getBlogsResults = isAuthenticated => ({ type: GET_BLOGS_RESULTS, isAuthenticated });
export const getBlogsResultsSuccess = response => ({ type: GET_BLOGS_RESULTS_SUCCESS, blogs: response.data });
export const blogsResultsFailure = err => ({ type: GET_BLOGS_RESULTS_FAILURE, error: err });
export const createOrUpdateBlog = payload => ({ type: CREATE_OR_UPDATE_BLOG, payload });
export const createOrUpdateBlogSuccess = updatedBlog => ({ type: CREATE_OR_UPDATE_BLOG_SUCCESS, updatedBlog });
export const createOrUpdateBlogFailure = error => ({ type: CREATE_OR_UPDATE_BLOG_FAILURE, error });
