import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { followUser } from '../ProfileView/actions';
import { openLoginForm } from '../AuthModal/actions';
import FollowIcon from '../../components/FollowIcon';
import LevelsComponent from '../../components/LevelsComponent';
import { isCurrentUser } from '../../utils/validations';
import { interactionRequested } from '../VisualConversationFeed/actions';
import { DEFAULT_USER_THUMBNAIL } from '../../constants';
import styles from './styles';
import './index.scss';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.followUser = this.followUser.bind(this);
  }

  handleRedirectToUser = (e, userId) => {
    e.stopPropagation();
    const { history, statesLocation } = this.props;
    history.push({
      pathname: '/profile',
      search: userId,
      state: statesLocation.search,
      previous: statesLocation.previous,
    });
  }

  followUser(e, followedId) {
    e.stopPropagation();
    const {
      isAuthenticated,
      followUser,
      openLoginForm,
      interactionRequested,
    } = this.props;
    if (!isAuthenticated) {
      interactionRequested();
      return openLoginForm();
    }
    return followUser(followedId);
  }

  render() {
    const {
      classes,
      name,
      location,
      src,
      level,
      userId,
      followed,
      currentUserId,
      statesLocation,
      onAvatarClick,
    } = this.props;

    return (
      <Grid container className={classes.root} alignItems="flex-start">
        <div className="userProfile">
          <div className="profileLevelContainer">
            <div
              onClick={e => this.handleRedirectToUser(e, userId)}
              role="button"
              tabIndex={0}
            >
              <Avatar
                alt={name}
                src={src || DEFAULT_USER_THUMBNAIL}
                className="bigAvatar"
                onClick={onAvatarClick}
              />
            </div>
            <LevelsComponent level={level} />
          </div>
          <div className="userProfileInfo">
            <div
              tabIndex={0}
              role="button"
              onClick={e => this.followUser(e, userId)}
            >
              {!isCurrentUser(userId, currentUserId) && (
                <FollowIcon followed={followed} />
              )}
            </div>
            <div
              className="profileName"
              role="button"
              tabIndex={0}
              onClick={e => this.handleRedirectToUser(e, userId)}
            >{name}
            </div>
            <div className="profileLocation">{location}</div>
          </div>
          <div />
        </div>
      </Grid>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  location: PropTypes.string,
  authFollowAction: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  onAvatarClick: PropTypes.func,
  vcId: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
};

UserProfile.defaultProps = {
  location: null,
  onAvatarClick: undefined,
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const vcFeedProfile = state[`vcProfile${id}`];
  const profile = vcFeedProfile.toJS();
  const {
    name, photo, level, country, followed_by_user,
  } = profile;
  return {
    userId: id,
    name,
    src: photo,
    level,
    location: country,
    followed: followed_by_user,
    isAuthenticated: state.authenticationStatus.get('isAuthenticated'),
    currentUserId: state.authenticatedUserDetails.get('userId'),
    statesLocation: state.router.location,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  followUser: id => dispatch(followUser(id)),
  openLoginForm: () => dispatch(openLoginForm(ownProps.authFollowAction)),
  interactionRequested: () => dispatch(interactionRequested(ownProps.vcId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(UserProfile));
