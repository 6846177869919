// React Imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
// Form Validation Imports
import * as Yup from 'yup';
import { Form, withFormik } from 'formik';
// Material UI Imports
import Button from '@material-ui/core/Button/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
// Project Imports
import { closeAuthDialog, openForgotPasswordForm, openSignUpForm } from '../AuthModal/actions';
import { requestLogin, resetLoginMessages } from './actions';
import loginEndpointSchema from './schema';
import styles from './styles';
import closeIcon from '../../assets/images/close.png';
import './index.scss';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.handleVisibility = this.handleVisibility.bind(this);
    this.signUp = this.signUp.bind(this);
    this.forgetPassword = this.forgetPassword.bind(this);
  }

  componentWillUnmount() {
    const { setTouched, resetAllMsgs } = this.props;
    setTouched(false);
    resetAllMsgs();
  }

  handleVisibility() {
    const { setTouched, resetAllMsgs } = this.props;
    setTouched(false);
    resetAllMsgs();
  }

  signUp() {
    const { openSignUpForm, actionToDispatch } = this.props;
    openSignUpForm(actionToDispatch);
  }

  forgetPassword() {
    const { openForgetPasswordForm, actionToDispatch } = this.props;
    openForgetPasswordForm(actionToDispatch);
  }

  render() {
    const {
      closeAuthDialog, values, handleChange, touched, errors, classes,
    } = this.props;
    const {
      isRequesting, apiError, successMsg,
    } = this.props;

    const { email, password } = values;

    return (
      <Fragment>
        <div className="centerAlignForm">
          <Form>
            <DialogContent>
              {
                ((((errors.email || errors.password) && (touched.email || touched.password)) || apiError)
                  && (
                    <div className="responseMsg error">
                      {(errors.email || errors.password || apiError)}
                      <Button className={classes.close} onClick={this.handleVisibility}>
                        <img src={closeIcon} alt="" />
                      </Button>
                    </div>
                  ))
                || ((successMsg)
                  && (
                    <div
                      className="responseMsg success"
                    >
                      {successMsg}
                      <Button className={classes.close} onClick={this.handleVisibility}>
                        <img src={closeIcon} alt="" />
                      </Button>
                    </div>
                  ))
              }
              <DialogContentText id="alert-dialog-slide-description">
                <input
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={email}
                  className="responseMsg error"
                />
                <input
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  value={password}
                  className="responseMsg error"
                  type="password"
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions
              className={`${classes.aligntCenterBtn} ${classes.spacing0}`}
            >
              <Grid
                container
                direction="column"
                justify="center"
              >
                <Grid item>
                  <Button type="submit" disabled={isRequesting} className={classes.blackbtn}>
                    Log in
                  </Button>
                </Grid>

                <Grid item>
                  <div>
                    <Button className={classes.forgotTxt} onClick={this.forgetPassword}>
                      Forgot Password ?
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </div>
        {/* <div className="modalFooter">
          <div>
            New to Dada?
            <Button onClick={this.signUp} className={classes.footerLink1}>
              Create an account.
            </Button>
          </div>
        </div> */}
        <Hidden smUp>
          <Button className="closeBtn" onClick={closeAuthDialog}>
            <Icon className={classes.icon}>close</Icon>
          </Button>
        </Hidden>
      </Fragment>
    );
  }
}
const LoginWithFormik = withFormik(
  {
    mapPropsToValues: props => ({
      email: '',
      password: '',
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required!'),
      password: Yup.string()
        .min(8, 'Password has to be longer than 8 characters!')
        .required('Password is required!'),
    }),
    handleSubmit: (values, { props }) => {
      const { email, password } = values;
      const { requestLogin, actionToDispatch } = props;
      const payload = loginEndpointSchema(email, password);
      requestLogin(actionToDispatch, payload);
    },
  },
)(LoginForm);

LoginForm.propTypes = {
  values: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  classes: PropTypes.object.isRequired,
  apiError: PropTypes.string,
  isRequesting: PropTypes.bool.isRequired,
  setTouched: PropTypes.func.isRequired,
  resetAllMsgs: PropTypes.func.isRequired,
  successMsg: PropTypes.string,
  openForgetPasswordForm: PropTypes.func.isRequired,
  openSignUpForm: PropTypes.func.isRequired,
  closeAuthDialog: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  apiError: null,
  successMsg: null,
};

const mapStateToProps = state => ({
  isRequesting: state.loginForm.get('isRequesting'),
  apiError: state.loginForm.get('error'),
  successMsg: state.loginForm.get('successMsg'),
  actionToDispatch: state.authDialog.get('actionToDispatch'),
});

const mapDispatchToProps = dispatch => ({
  closeAuthDialog: () => dispatch(closeAuthDialog()),
  openForgetPasswordForm: actionToDispatch => dispatch(openForgotPasswordForm(actionToDispatch)),
  openSignUpForm: actionToDispatch => dispatch(openSignUpForm(actionToDispatch)),
  requestLogin: (actionToDispatch, payload) => {
    dispatch(requestLogin(actionToDispatch, payload));
  },
  resetAllMsgs: () => { dispatch(resetLoginMessages()); },
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LoginWithFormik));
