import { hideLoading, showLoading } from 'react-redux-loading-bar';
// rxjs Imports
import { of } from 'rxjs/observable/of';
import { from } from 'rxjs/observable/from';
import { concat } from 'rxjs/observable/concat'; // added line
import {
  debounceTime, map, mergeMap, catchError, delay,
} from 'rxjs/operators';
// Project Imports
import { VALIDATE_TOKEN_ENDPOINT } from '../../constants';
import {
  AUTHENTICATION_REQUEST, LOGOUT_HOME_PAGE,
  authenticationRequestFailed,
  authenticationRequestInitiated,
  authenticationSuccess, unauthenticate, LOGOUT_INDIVIDUAL_VC_PAGE,
} from './actions';
import { clearAuthTokensFromStorage, logoutHomePageFlow, logoutIndividualVcPageFlow } from '../../utils/authUtils';

export const authenticateEpic = (action$, state$, { getRequest }) => action$
  .ofType(AUTHENTICATION_REQUEST).pipe(
    debounceTime(300),
    mergeMap(action => concat(
      of(showLoading()),
      of(authenticationRequestInitiated()),
      from(getRequest(VALIDATE_TOKEN_ENDPOINT, action.headers)).pipe(
        mergeMap((response) => {
          const { headers } = response;
          const { uid, client } = headers;
          const accessToken = headers['access-token'];
          const data = response.data.data;
          const { attributes } = data; // attributes contain photo, level, name, isCurator and email
          const userObject = { userId: attributes.id, ...attributes };
          const tokensObject = { uid, client, accessToken };
          return [
            authenticationSuccess(tokensObject, userObject),
          ];
        }),
        catchError(() => {
          clearAuthTokensFromStorage();
          return of(authenticationRequestFailed());
        }),
      ),
      of(hideLoading()),
    )),
  );

const logoutBaseEpic = (actionType, getActions) => (action$, state$) => action$.ofType(actionType).pipe(
  debounceTime(500),
  delay(400),
  mergeMap((action) => {
    clearAuthTokensFromStorage();
    return [unauthenticate(), ...getActions(action, state$.value)];
  }),
);

export const logoutHomePageEpic = logoutBaseEpic(
  LOGOUT_HOME_PAGE,
  logoutHomePageFlow,
);

export const logoutIndividualVcPageEpic = logoutBaseEpic(
  LOGOUT_INDIVIDUAL_VC_PAGE,
  logoutIndividualVcPageFlow,
);
