// React redux imports
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
// Material UI Imports
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
// Project imports
import { closeSnackbar, openSnackbar } from './actions';
import styles from './styles';

const variantIcon = {
  success: CheckCircleIcon,
};

const ApplicationSnackbar = (props) => {
  const {
    isOpen, handleClose, classes, message,
  } = props;
  const Icon = variantIcon.success;
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <SnackbarContent
          className={classNames(classes.success)}
          aria-describedby="client-snackbar"
          message={(
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              {message}
            </span>
          )}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </div>
  );
};

ApplicationSnackbar.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

ApplicationSnackbar.defaultProps = {
  message: null,
};

const SnackbarWithStyles = withStyles(styles)(ApplicationSnackbar);

const mapStateToProps = (state, props) => ({
  isOpen: state.snackbarStatus.get('open'),
  message: state.snackbarStatus.get('message'),
});

const mapDispatchToProps = dispatch => ({
  handleOpen: () => {
    dispatch(openSnackbar());
  },
  handleClose: (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(SnackbarWithStyles);
