export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGIN_FAILURE = 'REQUEST_LOGIN_FAILURE';
export const REQUEST_LOGIN_INITIATED = 'REQUEST_LOGIN_INITIATED';
export const RESET_LOGIN_MESSAGES = 'RESET_LOGIN_MESSAGES';

export const requestLogin = (actionToDispatch, payload) => ({
  type: actionToDispatch, payload,
});
export const requestLoginSuccess = () => ({ type: REQUEST_LOGIN_SUCCESS });
export const requestLoginFailure = error => ({ type: REQUEST_LOGIN_FAILURE, error });
export const requestLoginInitiated = () => ({ type: REQUEST_LOGIN_INITIATED });
export const resetLoginMessages = () => ({ type: RESET_LOGIN_MESSAGES });
