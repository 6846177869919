dadaCanvas.pencil = {
    options: {
        name: 'Pencil',
        id_tag: 'pencil',
        strokes: 1,
        bristles: [],
        actualLineWeight: 1,
        lineWeight: 1,
        max: 2,
        min: 0.3,
        lineColor: '#000000',
        mutant: true,
        usePressure: true,
        isBrush: true
    },
    init: function init() {
        this.options.bristles = [];
        for (i = 0; i < this.options.strokes; i++) {
            var ease = Math.random() * 0.05 + 0.6; //original formula Math.random() * 0.05 + 0.7
            this.options.bristles.push({
                dx: dadaCanvas.dummy.width / 2,
                dy: dadaCanvas.dummy.height / 2,
                ax: 0,
                ay: 0,
                div: 0.3, //original value = 0.1
                ease: ease
            });
        }
        dadaCanvas.ctx_dummy.lineJoin = "round";
        dadaCanvas.ctx_dummy.lineCap = "round";

        dadaCanvas._.each(dadaCanvas.ctxs, function (value, key, list) {
            value.lineJoin = "round";
            value.lineCap = "round";
        })

        this.setBrushColor(dadaCanvas.actualColor.hex);
    },
    up: function () {

        dadaCanvas.ctx_dummy.clearRect(0, 0, dadaCanvas.dummy.width, dadaCanvas.dummy.height);
        pressure = 0;
        this.options.lineWeight = this.options.actualLineWeight;
        this.options.lineColor = ('#' + dadaCanvas.rainbow.colourAt(12));
        for (i = 0; i < this.options.strokes; i++) {
            this.options.bristles[i].ax = 0;
            this.options.bristles[i].ay = 0;
        }
        dadaCanvas.lastmousetime = null;
        if (dadaCanvas.stroke_properties.length > 0) {

            if (dadaCanvas.stroke_properties.length == 1) {
                dadaCanvas._.each(dadaCanvas.ctxs, function (value, key, list) {
                    value.beginPath();
                    value.strokeStyle = dadaCanvas.actualBrush.options.lineColor;
                    value.arc(dadaCanvas.currX, dadaCanvas.currY, dadaCanvas.actualBrush.options.lineWeight / 180, 0, 2 * Math.PI);
                    value.stroke();
                })
            } else {
                dadaCanvas._.each(dadaCanvas.ctxs, function (value, key, list) {
                    value.moveTo(dadaCanvas.stroke_properties[0].x, dadaCanvas.stroke_properties[0].y);
                    for (i = 1; i < dadaCanvas.stroke_properties.length; i++) {
                        value.beginPath();
                        value.moveTo(dadaCanvas.stroke_properties[i].dx, dadaCanvas.stroke_properties[i].dy)
                        value.lineWidth = dadaCanvas.stroke_properties[i].lineWidth;
                        value.strokeStyle = dadaCanvas.stroke_properties[i].lineColor;
                        value.lineTo(dadaCanvas.stroke_properties[i].x, dadaCanvas.stroke_properties[i].y);
                        value.stroke();
                    }
                })
            }
        }
        dadaCanvas.stroke_properties = [];

    },
    draw: function () {
        dadaCanvas.ctx_dummy.lineWidth = (this.options.lineWeight == 1 ? 0.99 : this.options.lineWeight);
        dadaCanvas.ctx_dummy.strokeStyle = this.options.lineColor;

        for (i = 0; i < this.options.strokes; i++) {
            var dx = this.options.bristles[i].dx;
            var dy = this.options.bristles[i].dy;
            var dx1 = this.options.bristles[i].ax = (this.options.bristles[i].ax + (this.options.bristles[i].dx - dadaCanvas.currX) * this.options.bristles[i].div) * this.options.bristles[i].ease;
            this.options.bristles[i].dx -= dx1;
            var dx2 = this.options.bristles[i].dx;
            var dy1 = this.options.bristles[i].ay = (this.options.bristles[i].ay + (this.options.bristles[i].dy - dadaCanvas.currY) * this.options.bristles[i].div) * this.options.bristles[i].ease;
            this.options.bristles[i].dy -= dy1;
            var dy2 = this.options.bristles[i].dy;
            dadaCanvas.ctx_dummy.moveTo(dx, dy);
            dadaCanvas.stroke_properties.push({
                x: dx2,
                y: dy2,
                dx: dx,
                dy: dy,
                lineWidth: (this.options.lineWeight == 1 ? 0.99 : this.options.lineWeight),
                lineColor: this.options.lineColor
            })
            dadaCanvas.ctx_dummy.clearRect(0, 0, dadaCanvas.dummy.width, dadaCanvas.dummy.height);
            dadaCanvas.ctx_dummy.moveTo(dadaCanvas.stroke_properties[0].x, dadaCanvas.stroke_properties[0].y);

            for (j = 1; j < dadaCanvas.stroke_properties.length; j++) {
                dadaCanvas.ctx_dummy.beginPath();
                dadaCanvas.ctx_dummy.moveTo(dadaCanvas.stroke_properties[j].dx, dadaCanvas.stroke_properties[j].dy)
                dadaCanvas.ctx_dummy.lineWidth = dadaCanvas.stroke_properties[j].lineWidth;
                dadaCanvas.ctx_dummy.strokeStyle = dadaCanvas.stroke_properties[j].lineColor;
                dadaCanvas.ctx_dummy.lineTo(dadaCanvas.stroke_properties[j].x, dadaCanvas.stroke_properties[j].y);
                dadaCanvas.ctx_dummy.stroke();
                dadaCanvas.ctx_dummy.closePath();
            }
        }
    },

    out: function () {
        pressure = 0;
        this.options.lineWeight = this.options.actualLineWeight;
        this.options.lineColor = ('#' + dadaCanvas.rainbow.colourAt(12));
        for (i = 0; i < this.options.strokes; i++) {
            this.options.bristles[i].ax = 0;
            this.options.bristles[i].ay = 0;
        }
        dadaCanvas.lastmousetime = null;
        dadaCanvas.stroke_properties = [];
    },
    mouseSpeedCalcs: function () {
        if (dadaCanvas.prevX > -1) {
            dadaCanvas.mousetravel += Math.max(Math.abs(dadaCanvas.currX - dadaCanvas.prevX), Math.abs(dadaCanvas.currY - dadaCanvas.prevY));
            var md = new Date();
            var timenow = md.getTime();
            if (dadaCanvas.lastmousetime && dadaCanvas.lastmousetime != timenow) {
                pps = Math.round(dadaCanvas.mousetravel / (timenow - dadaCanvas.lastmousetime) * 1000);
                dadaCanvas.mousetravel = 0;
                if (pps >= 0 && pps < 100)
                    this.options.lineWeight = this.calculateY(this.options.max);
                else if (pps >= 100 && pps < 200)
                    this.options.lineWeight = this.calculateY(this.options.max - 0.5);
                else if (pps >= 200 && pps < 300)
                    this.options.lineWeight = this.calculateY(this.options.actualLineWeight);
                else if (pps >= 300 && pps < 400)
                    this.options.lineWeight = this.calculateY(this.options.min + 0.5);
                else if (pps >= 400)
                    this.options.lineWeight = this.calculateY(this.options.min + 0.2);

            }
            this.calculateColor(this.options.lineWeight);
            dadaCanvas.lastmousetime = timenow;
        }
    },
    calculateY: function calculateY(val) {
        if (this.options.lineWeight == val)
            return this.options.lineWeight;
        else if (this.options.lineWeight > val)
            return this.options.lineWeight - 0.05;
        else return this.options.lineWeight + 0.05;
    },
    calculateColor: function calculateColor(val) {
        if (val <= this.options.max && val > this.options.max - 0.25)
            this.options.lineColor = "rgba(" + dadaCanvas.actualColor.rgb_obj.r + "," + dadaCanvas.actualColor.rgb_obj.g + "," + dadaCanvas.actualColor.rgb_obj.b + ",1)";
        // this.options.lineColor="#"+dadaCanvas.rainbow.colourAt(0);
        else if (val <= this.options.max - 0.25 && val > this.options.max - 0.5)
            this.options.lineColor = "rgba(" + dadaCanvas.actualColor.rgb_obj.r + "," + dadaCanvas.actualColor.rgb_obj.g + "," + dadaCanvas.actualColor.rgb_obj.b + ",0.9)";
        // this.options.lineColor="#"+dadaCanvas.rainbow.colourAt(2);
        else if (val <= this.options.max - 0.25 && val > this.options.max - 0.75)
            this.options.lineColor = "rgba(" + dadaCanvas.actualColor.rgb_obj.r + "," + dadaCanvas.actualColor.rgb_obj.g + "," + dadaCanvas.actualColor.rgb_obj.b + ",0.8)";
        // this.options.lineColor="#"+dadaCanvas.rainbow.colourAt(4);
        else if (val <= this.options.max - 0.75 && val > this.options.actualLineWeight)
            this.options.lineColor = "rgba(" + dadaCanvas.actualColor.rgb_obj.r + "," + dadaCanvas.actualColor.rgb_obj.g + "," + dadaCanvas.actualColor.rgb_obj.b + ",0.7)";
        // this.options.lineColor="#"+dadaCanvas.rainbow.colourAt(6);
        else if (val <= this.options.actualLineWeight && val > this.options.min + 0.75)
            this.options.lineColor = "rgba(" + dadaCanvas.actualColor.rgb_obj.r + "," + dadaCanvas.actualColor.rgb_obj.g + "," + dadaCanvas.actualColor.rgb_obj.b + ",0.6)";
        // this.options.lineColor="#"+dadaCanvas.rainbow.colourAt(8);
        else if (val <= this.options.min + 0.75 && val > this.options.min + 0.5)
            this.options.lineColor = "rgba(" + dadaCanvas.actualColor.rgb_obj.r + "," + dadaCanvas.actualColor.rgb_obj.g + "," + dadaCanvas.actualColor.rgb_obj.b + ",0.5)";
        // this.options.lineColor="#"+dadaCanvas.rainbow.colourAt(10);
        else if (val <= this.options.min + 0.5 && val > this.options.min + 0.25)
            this.options.lineColor = "rgba(" + dadaCanvas.actualColor.rgb_obj.r + "," + dadaCanvas.actualColor.rgb_obj.g + "," + dadaCanvas.actualColor.rgb_obj.b + ",0.4)";
        // this.options.lineColor="#"+dadaCanvas.rainbow.colourAt(11);
        else if (val <= this.options.min + 0.25 && val >= this.options.min)
            this.options.lineColor = "rgba(" + dadaCanvas.actualColor.rgb_obj.r + "," + dadaCanvas.actualColor.rgb_obj.g + "," + dadaCanvas.actualColor.rgb_obj.b + ",0.3)";
        // this.options.lineColor="#"+dadaCanvas.rainbow.colourAt(12);
    },
    calculateLineWeightUsingPresure: function calculateLineWeightUsingPresure(pressure) {
        return (this.options.actualLineWeight / 2) + this.options.actualLineWeight * pressure;
    },
    calcBrushSize: function calcBrushSize(size) {

    },
    setBrushColor: function setBrushColor(bcolor) {
        dadaCanvas.rainbow.setSpectrum(bcolor, 'FFFFFF');
        dadaCanvas.rainbow.setNumberRange(0, 15);
        this.options.lineColor = "#" + dadaCanvas.rainbow.colourAt(12);
    },
    is_mutant: function () {
        return this.options.mutant;
    }

}
