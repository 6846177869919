import { fromJS } from 'immutable';
import { REFRESH_PROFILES, SAVE_VC_PROFILE, UPDATE_VC_PROFILE } from './actions';

const createReducer = (profileId) => {
  const initialState = fromJS({});

  const vcUserProfile = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_VC_PROFILE(profileId):
        return state.merge(action.data);
      case REFRESH_PROFILES(profileId):
        return initialState;
      case UPDATE_VC_PROFILE(profileId):
        return state.merge(action.data);
      default:
        return state;
    }
  };
  return vcUserProfile;
};

export default createReducer;
